import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import { Link } from "@material-ui/core";
import dayjs from "dayjs";
import InboundClearingSheet from "../employer/settings/InboundClearingSheet";
import {formatMoney} from "../../utils";
import Loading from "../../components/Loading";
import PrimaryButton from "../../components/PrimaryButton";

export const InboundClearing = (props) => {

    const {authStore} = useContext(StoreContext);
    const [activities, setActivities] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [inboundClearingId, setInboundClearingId] = useState();
    const [loading, setLoading] = useState(true);
    const [year, setYear] = useState(false);

    useEffect(() => {
        getInbounds();
      }, [year]);

    const toggleYear = (year) => {
        setYear(year);
    }

    const openShowForm = () => {
        window.scrollTo(0,0);
        setShowForm(true);        
    }

    const closeShowForm = () => {
        setLoading(true);
        window.scrollTo(0,0);
        setSelectedInboundClearingId(null);
        getInbounds();
        setShowForm(false);
    }

    const setSelectedInboundClearingId = (id) => {
        setInboundClearingId(id);
        openShowForm(true);
    }

    const getInbounds = async () => {
        let url = `/api/v1/clearing/all`;

        if (year === true ) {
            url = `/api/v1/clearing/all/year`;
        }        
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setActivities(resp);
        setLoading(false);
    }

    if (loading === true) {
        return <Loading />;
    }
    
    return (
        <>
        {showForm === true ? 
        <InboundClearingSheet closeShowForm={closeShowForm} inboundClearingId={inboundClearingId}/>
        :
        <div className="container max-w-6xl mx-auto px-4">
        <h1 className="text-lg mb-4">Inbound Clearing Account Log</h1>
        <PrimaryButton onClick={() => toggleYear(false)} className={'mr-5'}>Show Pending</PrimaryButton>
        <PrimaryButton onClick={() => toggleYear(true)}>Show All For Last Year</PrimaryButton>
        <MaterialTable 
            components={{}}
            columns={[
                {
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedInboundClearingId(row.id)}} className={"cursor-pointer"}>
                            Edit
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'Pay Period Date',
                    field: 'payPeriodDate', 
                    render: (rowData) => {
                        if (rowData.payPeriodDate) {
                            return (<>{rowData.payPeriodDate ? dayjs(rowData.payPeriodDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Total EE Contribution Amount',
                    field: 'totalContributions',
                    render: (rowData) => {
                        if (rowData.totalContribution) {
                            return (<>{formatMoney(rowData.totalContribution)}</>);
                        } 
                    }
                },
                {
                    title: 'Total ER Match Amount',
                    field: 'totalMatch',
                    render: (rowData) => {
                        if (rowData.totalMatch) {
                            return (<>{formatMoney(rowData.totalMatch)}</>);
                        } 
                    }
                },
                {
                    title: 'Business Name',
                    field: 'company.name', 
                    render: (rowData) => {
                        if (rowData.companyName) {
                            return (<>{rowData.companyName ? rowData.companyName : ''}</>);
                        } 
                    }
                },
                {
                    title: 'EE Contribution Found',
                    field: 'matchingTransaction', 
                    render: (rowData) => {
                        if (rowData.matchingTransaction !== null) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'ER Match Found',
                    field: 'matchMatchingTransaction', 
                    render: (rowData) => {
                        if (rowData.matchMatchingTransaction !== null) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Balanced',
                    field: 'balanced', 
                    render: (rowData) => {
                        if (rowData.balanced === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Transfers to FBOs Made',
                    field: 'transfersMade', 
                    render: (rowData) => {
                        if (rowData.transfersMade === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                }

            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "Inbound Clearing Report",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={activities}
        />
        </div>
        }
        </>
        
    );
}