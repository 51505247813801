import React, {useEffect, useContext} from "react";
import SettingsMenu from "../../components/SettingsMenu";
import SettingsMenuItem from "../../components/SettingsMenuItem";
import {Switch, Route, Redirect, useRouteMatch} from "react-router-dom";
import CompanyContactSettings from "./settings/CompanyContactSettings";
import CompanySettings from "./settings/CompanySettings";
import ChangePassword from "./settings/ChangePassword";
import SavingsPlanSettings from "./settings/SavingsPlanSettings";
import CompanyBankSettings from "./settings/CompanyBankSettings";
import ErrorBoundary from "../../components/ErrorBoundary";
import AccountSettings from "../employer/settings/AccountSettings";
import OwnerList from "./OwnerList";
import NewOwner from "./NewOwner";
import {FormattedMessage} from 'react-intl';
import EmployerPersonaVerification from "../employee/settings/EmployerPersonaVerification";
import EmployerPayrollLink from "./settings/EmployerPayrollLink";
import FinchLink from "../../forms/FinchLink";
import StoreContext from "../../StoreContext";
import { useState } from "react";
import { apiGet } from "../../utils";

export default function EmployerSettings({loadStep}) {
    const {rootStore} = useContext(StoreContext);
    const [clientId, setClientId] = useState(null);
    const [sandbox, setSandbox] = useState(true);
    let {path, url} = useRouteMatch();

    const getClientId = async () => {
        const reponse = await apiGet('/api/v1/payrollProvider/get/clientId', rootStore.authStore);

        const resp = await reponse.json();

        setClientId(resp.clientId);
        setSandbox(resp.sandbox);
    }

    useEffect(() => {
        getClientId();
    }, []);

    return <div>
        <h2 className={'text-xl'}>Profile and Settings</h2>
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/profile`}>Company Profile</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/ownerList`}>Owners</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/verification`}><FormattedMessage id={'settings.id.verification'}/></SettingsMenuItem>
            <SettingsMenuItem to={`${url}/contacts`}>Authorized Administrators</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/plan`}>Plan Design</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/bank`}>Company Bank Accounts</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/payroll`}>Connect Payroll System</SettingsMenuItem>
        </SettingsMenu>
        <Switch>
            <Route path={`${path}/profile`}>
                <ErrorBoundary key ={"companySettings"}>
                    <CompanySettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/verification`}>
                <ErrorBoundary key ={"accountSettings"}>
                    <EmployerPersonaVerification loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/createOwner`}>
                <ErrorBoundary key ={"createOwner"}>
                    <NewOwner loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/ownerList/:id`}>
                <ErrorBoundary key ={"ownerDetails"}>
                    <AccountSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/ownerList`}>
                <ErrorBoundary key ={"ownerList"}>
                    <OwnerList loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/contacts`}>
                <ErrorBoundary key ={"companyContactSettings"}>
                    <CompanyContactSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/password`}>
                <ErrorBoundary key ={"changePassword"}>
                    <ChangePassword/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/plan`}>
                <ErrorBoundary key ={"savingsPlanSettings"}>
                    <SavingsPlanSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/bank`}>
                    <ErrorBoundary key ={"companyBankSettings"}>
                        <CompanyBankSettings loadStep={loadStep}/>
                    </ErrorBoundary>
            </Route>
            <Route path={`${path}/payroll`}>
            <ErrorBoundary key ={"employerPayrollLink"}>
                <FinchLink clientId={clientId} sandbox={sandbox} loadStep={loadStep}/>
            </ErrorBoundary>
        </Route>
        </Switch>
    </div>;
}
