import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";

export const Invoice = (props) => {

    const {authStore} = useContext(StoreContext);
    const [invoices, setInvoices] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    const [endDate, setEndDate] = useState(endDateDay);

    useEffect(() => {
        getInvoices();
      }, []);

    const getInvoices = async () => {
        const response = await apiGet(`/api/v1/invoice/all/${dayjs(startDate).format("MMDDYYYY")}/${dayjs(endDate).format("MMDDYYYY")}`, authStore);
        const resp = await response.json();

        setInvoices(resp);
    }
    
    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };
    
    return (
        <>
        <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <PrimaryButton style={{marginTop: "20px"}} onClick={getInvoices} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid>
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'Company',
                    field: 'companyName'
                },
                {
                    title: 'Monthly User Rate',
                    field: 'perUser',
                    render: (rowData) => {
                        if (rowData.perUser) {
                            return (<>{rowData.perUser ? `$${(rowData.perUser).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : ''}</>);
                        } 
                    }
                },
                {
                    title: 'User Count',
                    field: 'userCount'
                },
                {
                    title: 'Total',
                    field: 'total',
                    render: (rowData) => {
                        if (rowData.total) {
                            return (<>{rowData.total ? `$${(rowData.total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : ''}</>);
                        } 
                    }
                },
                // {
                //     title: 'Setup Fee Total',
                //     field: 'setupFeeTotal',
                //     render: (rowData) => {
                //         if (rowData.setupFeeTotal) {
                //             return (<>{rowData.setupFeeTotal ? `$${(rowData.setupFeeTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : ''}</>);
                //         } 
                //     }
                // },
                // {
                //     title: 'Payroll Admin Fee',
                //     field: 'payrollFee',
                //     render: (rowData) => {
                //         if (rowData.payrollFee) {
                //             return (<>{rowData.payrollFee ? `$${(rowData.payrollFee).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : ''}</>);
                //         } 
                //     }
                // },
                {
                    title: 'Created',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.created) {
                            return (<>{rowData.created ? dayjs(rowData.created).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Waive Begin',
                    field: 'waiveDateStart', 
                    render: (rowData) => {
                        if (rowData.waiveDateStart) {
                            return (<>{rowData.waiveDateStart ? dayjs(rowData.waiveDateStart).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Waive End',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.waiveDateEnd) {
                            return (<>{rowData.waiveDateEnd ? dayjs(rowData.waiveDateEnd).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                // {
                //     title: 'Bank Name',
                //     field: 'bankName',
                // },
                {
                    title: 'Full Name',
                    field: 'fullName'
                },
                // {
                //     title: 'Bank Name',
                //     field: 'bankName'
                // },
                {
                    title: 'Address 1',
                    field: 'address1'
                },
                {
                    title: 'Address 2',
                    field: 'address2'
                },
                {
                    title: 'City',
                    field: 'city'
                },
                {
                    title: 'State',
                    field: 'state'
                },
                {
                    title: 'Zip',
                    field: 'zip'
                },
                {
                    title: 'Reason',
                    field: 'reason'
                },
                {
                    title: 'NSF',
                    field: 'nsf'
                },
                {
                    title: 'Paid',
                    field: 'paid'
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "invoices",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={invoices}
        />
        </div>
        </>
    );
}