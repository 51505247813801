import React from "react";
import FieldGroup from "./FieldGroup";
import Label from "./Label";
import {Field, useFormikContext} from "formik";
import Input from "./Input";
import * as formikUtils from "../formikUtils";

const Error = ({message}) => <div className={"text-red-500 text-sm"}>{message}</div>;

export default function LabeledField({className, label, placeholder, name, component = Input, ...props}) {
    const formikContext = useFormikContext();
     let error = formikUtils.formikErrorHandler (formikContext, name);


    return <FieldGroup className={className}>
        {label && <><Label>{label}{props.required && <span className={'text-red-500 ml-1'}>*</span>}</Label> </> }
        <Field
            name={name}
            placeholder={placeholder || label}
            component={component}
            {...props}
        />
        {error ? <Error message={error}/> : null}
    </FieldGroup>
}
