import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import {observer} from "mobx-react";
import DonutChart from "../../components/DonutChart";
import OutlineBox from "../../components/OutlineBox";
import {Link, Redirect, useRouteMatch} from "react-router-dom";
import {apiGet, formatMoney} from "../../utils";
import BarChartSideLegend from "../../components/BarChartSideLegend";
import EmployerContributionChart from "../../components/EmployerContributionChart";
import EmployerBalanceChart from "../../components/EmployerBalanceChart";
import DashboardCheckListDialog from "../../components/DashboardCheckListDialog";
import TransactionDivTable from "../../components/TransactionDivTable";
import AcknowledgmentDialog from "../../components/AcknowledgmentDialog";

const Dashboard = observer(() => {
  const {rootStore, authStore} = useContext(StoreContext);
  const currentCompany = rootStore.currentCompany;
  const currentUser = rootStore.currentUser;
  const [showAck, setShowAck] = useState(false);
  const [id, setId] = useState();
  let {  url } = useRouteMatch();

  if (!currentCompany) {
    return <Redirect to={`${url}/create-company`}/>;
  }

  // const isNewAcks = async () => {
  //   const response = await apiGet(`/api/v1/user/${currentUser.id}/newAcks`, authStore);
    
  //   const resp = await response.json();

  //   if (resp.newAcks === true) {
  //     setShowAck(true);
  //    }
  // }

  const isNewAcks = async () => {
    const response = await apiGet(`/api/v1/user/${currentCompany.id}/company/newAcks`, authStore);
    
    const resp = await response.json();

    if (resp.newAcks === true && rootStore.acksShown === false) {
      setShowAck(true);
      setId(resp.accountHolderId);
      rootStore.setShowedAcks();
     }
  }

  useEffect(() => {
    if (currentCompany) {
      isNewAcks();
      currentCompany.loadTransactions({page: 1});
      currentCompany.loadCompanyTotal(currentCompany.id);
      currentCompany.loadCompanyStats(currentCompany.id);
      currentCompany.loadAddress();
      currentCompany.loadAffiliates();
      currentCompany.loadCompanyPlans(currentCompany.id);
      currentCompany.loadContributionData(currentCompany.id);
      currentCompany.loadBalanceData(currentCompany.id);
      currentCompany.checkSetup(currentCompany.id);      
    }

  }, []);

  return <div>
    {showAck ? 
    <AcknowledgmentDialog id={id}/>
    : ''}
    <h2 className={"text-xl mb-10"}>Welcome back {currentCompany && currentCompany.name}</h2>
    {currentCompany.setupItems && currentCompany.setupItems.length > 0 ?
    <DashboardCheckListDialog user={currentCompany} dialogTitle={'Welcome!'} 
      openDefault={true} showOpenButton={false} openText={''} items={currentCompany.setupItems}/>
    : ''}
    <OutlineBox className={"w-100"}>
      <div className={'flex flex-chart-column flex-row border-solid border-gray-200 rounded border-b-2 p-6'}>
        <div className={'flex-1 pt-4 xl:pr-16'}>
          <DonutChart
            data={[
              {
                label: "Employee",
                value: currentCompany.balances ? currentCompany.balances.contribution : '0',
                color: "#252369",
              },
              {
                label: "Employer",
                value: currentCompany.balances ? currentCompany.balances.match : '0',
                color: "#81c1ff",
              },
              {
                label: "Other",
                value: 0,
                color: "#0057ff",
              }
            ]}
            formatValue={formatMoney}
            legendTitle={'Account Balance By Source'}
            IsFixedWidth={true}
            legendExtraTop={<div className={'flex text-lg font-bold'}>
              <span className={'flex-1 mb-2'}>Current Plan Assets</span> <span>{formatMoney(currentCompany.balances ? currentCompany.balances.total : '0')}</span>
            </div>}
            //legendClass={'mt-8'}
          />
        </div>
        <div className={'flex-1 pt-4'}>
          <BarChartSideLegend
            displayLegend={false}
            height={160}
            width={160}
            percent={currentCompany.stats ? currentCompany.stats.percentage: '0'}
            data={{
              datasets:
                [
                  {
                    label: "Eligible Employees",
                    data: [currentCompany.stats ? currentCompany.stats.employeeCount : '0'],
                    color: '#ff8700',
                    backgroundColor: '#ff8700',
                  },
                  {
                    label: "Contributing Employees",
                    data: [currentCompany.stats ? currentCompany.stats.participatingCount : '0'],
                    color: '#ffc380',
                    backgroundColor: '#ffc380',
                  }
                ]
            }}
            barPercentage={.8}
            legendTitle={`Employee Participation Rate`}
            IsFixedWidth={true}
            //legendClass={'mt-4'}
          />
        </div>
      </div>
     
      {(currentCompany.hasContributionData() || currentCompany.hasBalanceData())?
        <div className={'flex flex-col xl:flex-row border-solid border-gray-200 border-b-2 '}>
          {currentCompany.hasContributionData() && <EmployerContributionChart contributionData={currentCompany.contributionData} />}
          {currentCompany.hasBalanceData() && <EmployerBalanceChart balanceData={currentCompany.balanceData} />}
        </div>
        : <></>
      } 
      
      <div className={'flex flex-col md:flex-row p-5'}>
        <div className={'flex-1 md:border-r border-gray-300 py-2 px-5'}>
          <h3 className={'mb-2'}>Total Contributed To-Date</h3>
          <span className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.totalSavingsToDate : '0')}</span>
        </div>
        <div className={'flex-1 md:border-r border-gray-300 py-2 px-5 md:pl-12'}>
          <h3 className={'mb-2'}>Employee Contributions</h3>
          <span
            className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.employeeContributionToDate : '0')}</span>
        </div>
        <div className={'flex-1 px-5 py-2 md:pl-12'}>
          <h3 className={'mb-2'}>Employer Contributions</h3>
          <span className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.matchToDate : '0')}</span>
        </div>
      </div>
    </OutlineBox>
    <div className={"mt-8"}>
      <div className={"flex flex-row border-solid border-gray-200 rounded border-b-2 pb-4 mb-4"}>
        <span className={"text-xl flex-1"}>Recent Transactions</span>
        <Link to={`${url}/transactions`} className={"text-primary underline"}>View all transactions</Link>
      </div>
      {/* {currentCompany.transactionPages && <TransactionTable transactions={currentCompany.transactionPages.get(1)}/>} */}
      {currentCompany.transactionPages && <TransactionDivTable transactions={currentCompany.transactionPages.get(1)}/>}
    </div>
  </div>;
});

export default Dashboard;
