import React, {useContext} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import EmployerCompanyForm from "../../forms/EmployerCompanyForm";
import Header from "../../components/Header/Header";
import {Redirect, useParams, useRouteMatch} from "react-router-dom";
import AlertProvider from "../../AlertProvider";

const NewCompany = observer(function () {
  const {rootStore, rootStore: {currentUser, currentCompany}} = useContext(StoreContext);
  const {userId} = useParams();
  let { url } = useRouteMatch();

  if (currentCompany) {
    url = url.substring(0,url.lastIndexOf("/"));
    return <Redirect to={`${url}`} />;
  }

  const createCompany = async (values) => {
    const created = await currentUser.createCompany(values);
    if (created) {
      return <Redirect to={`/approuter/${userId}/dashboard`}/>
    }
  }

  return <div className={"container mx-auto p-4"}>
    <Header/>
    <div className={"max-w-2xl mx-auto p-2"}>
      <h2 className={"text-center text-lg mb-4"}>Company Profile</h2>
      <AlertProvider/>
      <EmployerCompanyForm
        doSubmit={(values) => createCompany(values)}
        errors={rootStore.formErrors.toJSON()}
        submitting={rootStore.submitting} />
    </div>
  </div>;
});

export default NewCompany;