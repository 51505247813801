import {types, flow, getRoot} from "mobx-state-tree"
import Company from "./Company";
import Employee from "./Employee";
import {apiPost} from "../utils";

const User = types.model("User", {
    id: types.identifier,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    companies: types.maybeNull(types.array(Company)),
    employeeRecords: types.maybeNull(types.array(Employee)),
    userName: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    ssn: types.maybeNull(types.string),
    enrolledStatus: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    kycStatus: types.maybeNull(types.string),
    kycStatusDate: types.maybeNull(types.string),
    csaTieId: types.maybeNull(types.string),
    companyId: types.maybeNull(types.string),
}).views(self => ({
    get full_name() {
        return self.firstName + ' ' + self.lastName;
    },
    get company_name() {
        if (self.companies.length) {
            return self.companies[0].name;
        }
        if (self.employeeRecords.length) {
            return self.employeeRecords[0].company.name;
        }
    },
})).actions(self => ({
    setCompany: function (data) {
        if (!self.companies) {
            self.companies = [];
        }
        self.companies.push(data);
    },
    createCompany: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        data.owner = self.id;
        const response = yield apiPost('/api/v1/company/new', data, getRoot(self).authStore ,'Company Added');
        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Company");
            yield getRoot(self).setSubmitting(false);
            return false;
        } else if (response.errors) {//form specific errors
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            return false;
        }
        const comp = Company.create(response);
        self.setCompany(comp);
        yield getRoot(self).setCompany(comp);
        yield getRoot(self).setMessage('New Company Added!');
        yield getRoot(self).setSubmitting(false);
        return true;
    }),
}));

export default User;
