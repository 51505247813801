import {types} from "mobx-state-tree"
import {computed} from "mobx";


const CompanyStats = types.model("User", {
  employeeContributionToDate: types.maybeNull(types.number),
  matchToDate: types.maybeNull(types.number),
  totalSavingsToDate: types.maybeNull(types.number),
  companyId: types.identifier,
  employeeCount: types.maybeNull(types.number),
  participatingCount: types.maybeNull(types.number),

}).views(self => ({
  get percentage() {
    const empCount = self.employeeCount ? self.employeeCount : 0;
    const parCount = self.participatingCount ? self.participatingCount : 0;

    return parseFloat(parCount > 0 ? (parCount/empCount) * 100 : 0).toFixed(1)
  }
}))

export default CompanyStats;
