import React from 'react';
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import { apiPostNoAuth } from '../utils';
import { useEffect, useState } from 'react/cjs/react.production.min';
import { useHistory } from 'react-router-dom';
import MatchSavingsLogo from "../images/match-savings-logo-full-color.png";
import * as Yup from "yup";


const ResetPasswordForm = ({  authStore, errors, submitting}) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const reset = params.get('reset');
  const history = useHistory();

  const resetPassword = async (params) => {

    const user = await apiPostNoAuth(`/reset/${reset}`, params, 'Reset');
    
    let values = {user: {userId: user.id, password: params.password}};
    const response = await apiPostNoAuth(`/reset/changePassword`, values, 'Password Changed');
    
    if (!response.error) {
      authStore.setMessage('Password reset successful!');
      authStore.setEmail(user.email);
      history.push(`/login/${user.role}`);
    }
  } 

  return<>
    <div className="w-96 flex flex-col items-center">
      <h1 className="text-4xl mb-8 text-center text-primary font-bold">
          <img className="mx-auto max-w-100" src={MatchSavingsLogo}/>
      </h1>
    </div>
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      onSubmit={(values) => {
        resetPassword(values)
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
            .required('New Password is required'),
        password_confirmation: Yup.string()
            .required('Please Confirm Password'),
    })}
    >
      <Form>
        <FormErrors errors={errors}>
          <h3 className={'text-lg '}>Change Password</h3>
          <div className={''}>
            <LabeledField
              label={'New Password'}
              name={'password'}
              type={'password'}
              required
            />
          </div>
          <div className={''}>
            <LabeledField
              label={'Confirm Password'}
              name={'password_confirmation'}
              type={'password'}
              required
            />
          </div>
          <FieldGroup>
            {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
          </FieldGroup>
        </FormErrors>
      </Form>
    </Formik>
  </>;
};

export default ResetPasswordForm;
