import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import Pagination from "../../components/Pagination";
import CompanyTable from "../../components/CompanyTable";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import DateInput from "../../components/DateInput";

const AdminCompanies = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    setLoading(true);
    getCompanies();
    
  },[page])

  const CompanyFilter = (company, name) =>{    
    return  (company.name === null? false
      : name === ''? true: company.name.toLowerCase().includes(name.toLowerCase()));
  }

  const getCompanies = async () => {

    await adminStore.loadCompanies(page);
    const response = await adminStore.companies;
    if (response) {
      setCompanies(adminStore.companyPage(page));
    }
    setLoading(false);
  }

  if (!adminStore.companyPages.size || loading) {
    return <Loading />;
  }

  return <div>
    <h3 className={"text-xl"}>Companies</h3>
    <br />
    <div className={'flex flex-col md:flex-row mb-2'}>
      <div className={'mb-2'}>
        <label>Company Name</label>
        <Input
          onChange={(e) => {
            setName(e.target.value)//, setPage(1)
          }}
          value={name}
          placeholder={'Search Company by Name'}
        />
      </div>
    </div>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}
    <CompanyTable companies={companies.filter((company) => CompanyFilter(company,name))}/>
    <Pagination
      page={page}
      totalPages={adminStore.companyPageCount}
      setPage={setPage}
    />
  </div>;
});

export default AdminCompanies;
