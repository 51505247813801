import React from "react";
import FieldGroup from "./FieldGroup";
import Label from "./Label";
import { useFormikContext} from "formik";
import Input from "./Input";
import InputMask from "react-text-mask";
import * as formikUtils from "../formikUtils";

const Error = ({message}) => <div className={"text-red-500 text-sm"}>{message}</div>;

export default function MaskedLabeledField({className, label, placeholder, name, ...props}) {
    const formikContext = useFormikContext();
    let error = formikUtils.formikErrorHandler (formikContext, name);

    return <FieldGroup className={className}>
        {label && <><Label>{label}{props.required && <span className={'text-red-500 ml-1'}>*</span>}</Label> </> }
        <InputMask
            name={name}
            placeholder={placeholder || label}
            {...props}
            className={`w-full rounded-sm border border-solid border-mid-grey p-3 text-sm ${className}`}
       />

        {error ? <Error message={error}/> : null}
    </FieldGroup>
}
