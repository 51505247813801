import React from "react";
import {Link} from "react-router-dom";
import Loading from "./Loading";
import moment from "moment";

export default function OwnerTable({owners}) {
  if (!owners) {
    return <Loading/>;
  }

  return <div className={"overflow-auto w-screenn table-cuswidth md:w-full"}>
    <table className={'w-full table-auto whitespace-nowrap'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 p-3"}>Name</th>
        <th className={"text-left text-sm text-gray-400 p-3"}>Ownership</th>
        {/* <th className={"text-left text-sm text-gray-400 p-3"}>Employee ID</th> */}
        <th></th>
      </tr>
      </thead>
      <tbody>
      {owners.map((owner) => <tr key={owner.id}>
        <td className={"text-left text-sm p-3"}>{owner.firstName} {owner.lastName}</td>
        <td className={"text-left text-sm p-3"}>{owner.ownership}</td>
        {/* <td className={"text-left text-sm p-3"}>{owner.ownerId}</td> */}
        <td className={"text-left text-sm p-3"}>
          <Link to={`ownerList/${owner.id}`}
                className={"border border-solid border-gray-300 rounded p-2 text-black cursor-pointer"}>Details</Link>
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
