import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import Alert from "../../../components/Alert";
import EmployeeBankForm from "../../../forms/EmployeeBankForm";
import PlaidBankForm from "../../../forms/PlaidBankForm";
import { Link } from "react-router-dom";
import { apiGet, apiPost, scrollToTop } from "../../../utils";
import _ from "lodash";
import RadioField from "../../../components/RadioField";
import { Form, Formik } from "formik";
import CardSettings from "./CardSettings";
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../../../utils';

const EmployeeBankSettings = observer(({loadStep}) => {
  const intl = useIntl();
  const {rootStore, authStore} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;
  const [showPlaid, setShowPlaid] = useState(true);
  // const URI = isCompany 
  //     ? `/api/v1/bankConnection/company/${rootStore.currentCompany.id}/plaid`
  //     : `/api/v1/bankConnection/accountHolder/${rootStore.currentEmployeeRecord.id}/plaid`;
  
  const URI = `/api/v1/bankConnection/accountHolder/${rootStore.currentEmployeeRecord.id}/all`;
  const [banks, setBanks] = useState([]);
  const [withdrawalType, setWithdrawalType] = useState(employee.withdrawalType);

  // useEffect(() => {
  //   currentCompany.loadBanks();
  // }, []);


  if (!employee) {
    return <Loading />;
  }

  useEffect(() => {
    rootStore.setLoading(true);
    loadBanks();
  }, []);
  
  const loadBanks = async () => {    
    const response = await apiGet(URI, rootStore.authStore);//company
    const resp = await response.json();
    
    // setBanks(_.filter(resp, {plaidVerified: true}));
    setBanks(resp);
    rootStore.setLoading(false);
  }

  const toggleButton = () => {
    if (showPlaid === true) {
      setShowPlaid(false);
    } else {
      loadBanks();
      setShowPlaid(true);
    }
  }

  const update = async (values) => {

    const response = await apiPost(`/api/v1/accountHolder/withdrawalType/${employee.id}/${values.withdrawalType}`,{}, authStore, "Withdrawal Settings Updated ");    
    rootStore.loadCurrentUser(response.user.id); 
    scrollToTop(0,0);
  }

  return <div className={''}>
    <h3 className={'text-lg mt-8 pl-4 font-bold '}><FormattedMessage id={'with.title'}/></h3>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    <p className={'py-4 pl-4'}>
      <FormattedMessage id={'with.inst1'}/>
    </p>
    <p className={'py-4 pl-4'}>
      <FormattedMessage id={'with.inst2'}/>
    </p>

    <Formik
    initialValues={
      {withdrawalType: withdrawalType}
    }
    onSubmit={(values) => {
      
      // doSubmit(values);
    }}
    validate={(values) => {
      // Set match type to enable/disable subfields
      if (values.withdrawalType !== withdrawalType) {
        setWithdrawalType(values.withdrawalType);
        update(values);
      }
    }}
  >
    {({values}) => (
      <Form className={'py-4 pl-4'}>
        
        {/* <RadioField id={'input-match_type-venmo'} name={'withdrawalType'} value={'VENMO'}>
          <label htmlFor={'input-match_type-venmo'}
                  className={'text-sm'}>{'Send my withdrawal requests to my electronic wallet. (Free)'}</label>
        </RadioField> */}
        <RadioField id={'input-match_type-check'} name={'withdrawalType'} value={'CHECK'} onClick={() => setWithdrawalType('CHECK')}>
          <label htmlFor={'input-match_type-check'}
                  className={'text-sm'}>{formatMessage(intl, 'with.check', '', [])}</label>
        </RadioField>
        <RadioField id={'input-match_type-ach'} name={'withdrawalType'} value={'ACH'} onClick={() => setWithdrawalType('ACH')}>
          <label htmlFor={'input-match_type-ach'}
                  className={'text-sm'}>{formatMessage(intl, 'with.ach', '', [])}</label>
        </RadioField>
      </Form>
    )}
    </Formik>

  <h3 className={'text-lg mt-8 pl-4 font-bold '}><FormattedMessage id={'with.bank.title'}/></h3>
    { withdrawalType === 'ACH' ?
        <>
    <div class="flex justify-start border-b border-gray-200 relative hide-print">
      <Link onClick={showPlaid ? () => {} : toggleButton} 
      className={`block relative top-1.5 text-center text-sm py-4 my-1 sm:px-8 px-1 ${showPlaid === true ? 'font-bold border-b-3 border-solid border-primary' : ''}`}><FormattedMessage id={'with.plaid'}/></Link>
      <Link onClick={showPlaid ? toggleButton : () => {}} 
      className={`block relative top-1.5 text-center text-sm py-4 my-1 sm:px-8 px-1 ${showPlaid === false ? 'font-bold border-b-3 border-solid border-primary' : ''}`}><FormattedMessage id={'with.manual'}/></Link>
    </div>
    
    <div>
      <>
        {showPlaid ? 
          <PlaidBankForm errors={rootStore.formErrors.toJSON()} isCompany={false} rootStore={rootStore} banks={banks} setBanks={loadBanks} email={employee.email} loadStep={loadStep}/>
          : 
          <EmployeeBankForm errors={rootStore.formErrors.toJSON()} employee={employee} rootStore={rootStore} loadStep={loadStep}/>
        }
        </>
      </div>    
        </> : ''
  }
  <CardSettings  className={'py-4 pl-4'}/>
  </div>;
});

export default EmployeeBankSettings
