import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import Loading from "./Loading";
import RouterLink from "./RouterLink";
import { Link } from "@material-ui/core";
import PrimaryButton from "./PrimaryButton";

export default function PolicyTable({policies, archive}) {
  let {  url } = useRouteMatch();
  if (!policies) {
    return <Loading/>;
  }

  return <div>
    <table className={'w-full table-auto'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 py-3"}>Name</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>URL</th>
        {/* <th className={"text-left text-sm text-gray-400 py-3"}>Archived</th> */}
        {/* <th className={"text-left text-sm text-gray-400 py-3"}>SSN</th> */}
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {policies.map((policy) => <tr key={policy.id}>
        <td className={`text-left text-sm p-3 `}>{policy.name}</td>
        <td className={`text-left text-sm p-3 `}>
          <Link href={policy.url} target="_blank" className={"cursor-pointer"}>Review</Link>
        </td>
        {/* <td className={`text-left text-sm p-3 `}>{policy.active ? 'Active' : 'Archived'}</td> */}
        {/* <td className={`text-left text-sm p-3  ${policy.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{policy.ssn}</td> */}
        <td className={""}>
        {policy.active ?
          <Link onClick={() => {archive(policy.id)}} className={"cursor-pointer"}>Archive</Link>
        : ''}
        </td>
        <td className={""}>
          <RouterLink to={`${url}/${policy.id}`} className={"MuiTypography-root MuiLink-root MuiLink-underlineHover cursor-pointer MuiTypography-colorPrimary"}>Edit</RouterLink>&nbsp;
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
