import React from "react";

const Loading = ({className, size = '50px', ...props}) => {
  return <svg width={size} height={size}
       viewBox="0 0 30 30" preserveAspectRatio="xMidYMid" className={"animate-spin"}>
    <circle cx="15" cy="15" fill="none" stroke="#3b7ffb" strokeWidth="6" r="8"
            strokeDasharray="37.69911184307752 14.566370614359172" transform="matrix(1,0,0,1,0,0)"
            />
  </svg>;
};

export default Loading;
