import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistInLocalStorage} from "mobx-state-tree-localstorage";

import AppRouter from "./router";
import StoreContext from "./StoreContext";
import RootStore from "./models/RootStore";
import AdminStore from "./models/AdminStore";
import AuthStore from "./models/AuthStore";
import TokenStore from "./models/TokenStore";


require('./bootstrap');
require('./postcss.config');
require('./tailwind.config');

const rootStore = RootStore.create({adminStore: AdminStore.create(), authStore: AuthStore.create(
        {tokenStore: persistInLocalStorage({tree: TokenStore,
                id: '@ms/TokenStore',
                initialState: {
                    token: ""
                }})}
    )});
const stores = {
    rootStore,
    adminStore: rootStore.adminStore,
    authStore: rootStore.authStore
};

ReactDOM.render(      <StoreContext.Provider value={stores}><App /></StoreContext.Provider>, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

