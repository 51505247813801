import React, { useContext,useEffect,useState} from "react";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/SidebarItem";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import EmployeeDashboard from "../pages/employee/Dashboard";
import Withdraw from "../pages/employer/Withdraw";
import EmployeeTransactions from "../pages/employee/EmployeeTransactions";
import EmployeeSettings from "../pages/employee/EmployeeSettings";
import Support from "../pages/Support";
import ErrorBoundary from "../components/ErrorBoundary";
import {Route, Switch, useRouteMatch, useHistory} from "react-router-dom";
import ReportList from "../pages/employee/reports/ReportList";
import StoreContext from "../StoreContext";
import {Link} from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import AppLogout from "../components/AppLogout";
import LogoutWarningDialog from "../components/LogoutWarningDialog";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { apiGet } from "../utils";
import OutlineBox from "../components/OutlineBox";
import { useIntl} from 'react-intl';
import {formatMessage} from '../utils';
import ComplaintReport from "../pages/admin/ComplaintReport";
import { UnusualActivity } from "../pages/admin/UnusualActivity";
import ChangePassword from "../pages/employee/settings/ChangePassword";
import { EmailLogReport } from "../pages/employee/EmailLogReport";
import EmployeePolicies from "../pages/employee/EmployeePolicies";
import DataRequestReport from "../pages/admin/DataRequestReport";
import { EmailLog } from "../pages/employee/EmailLog";

export default function EmployeeLayout({children, employee, userId, locales, locale, changeLanguage, fmt}) {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [idv, setIdv] = useState(undefined);
    const stepDone = 6;

    const intl = useIntl();

    const onChange = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0,0);
    }

    const {rootStore} = useContext(StoreContext);
    const adminOpen = rootStore.impersonatingEmployee;
    const history = useHistory();
    let {path, url} = useRouteMatch();
    const stopImpersonatingEmployee = () => {
        rootStore.stopImpersonatingEmployee();
        history.push(`${path}/employees`);    
    };

    const steps = [
        formatMessage(intl, 'checklist.contact', '', []),
        formatMessage(intl, 'checklist.address', '', []),
        formatMessage(intl, 'checklist.beneficiary', '', []),
        formatMessage(intl, 'checklist.inquiry', '', []),
        formatMessage(intl, 'checklist.plan', '', []),
        formatMessage(intl, 'checklist.withdraw', '', []),
    ];

    const loadStep = async () => {
        const response = await apiGet(`/api/v1/dashboard/user/step/${employee.id}`, rootStore.authStore);
        const resp = await response.json();
        setStep(resp - 1);
    }

    const loadIdv = async () => {
        const response = await apiGet(`/api/v1/dashboard/idv/${employee.id}`, rootStore.authStore);
        const resp = await response.json();
        setIdv(resp);
    }
    
    useEffect(() => {
        if (employee) {
            loadStep();
            loadIdv();
        }
    }, [employee])

    

    return <AppLogout>
    <LogoutWarningDialog />
    <div id={"container"} className={'container max-w-7.5xl mx-auto px-4'}>
            {rootStore.impersonatingEmployee && <div className={'bg-primary p-4'}>
            <Link className={'text-white font-bold'} to='employees' onClick={() => stopImpersonatingEmployee()}>
                <FormattedMessage id={'login.admin'}/>
            </Link>
        </div>}
        <Header company={employee.company} isEmployee={true} locale={locale} 
            locales={locales} changeLanguage={changeLanguage} fmt={fmt}>{employee && employee.name}</Header>
        <div className={"flex flex-row"}>
            <Sidebar isOpen={isOpen} onChange={onChange} >
                <SidebarItem onChange={onChange} to={`${url}`}
                             highlightExclusions={['transactions', 'settings', 'support', 'reports', 'complaints', 'uar', 'acks']}><FormattedMessage id={'sidebar.dashboard'}/></SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/transactions`}><FormattedMessage id={'sidebar.transactions'}/></SidebarItem>
                <SidebarItem onChange={onChange} to ={`${url}/settings/account`} root={`${url}/settings`} step={step} stepDone={stepDone}><FormattedMessage id={'sidebar.settings'} /></SidebarItem>
                <SidebarItem onChange={onChange} to={`${url}/reports/quarterly`}><FormattedMessage id={'sidebar.reports'}/></SidebarItem>
                <SidebarItem onChange={onChange} to={`${url}/support`}><FormattedMessage id={'sidebar.support'}/></SidebarItem>
                <SidebarItem onChange={onChange} to={`${url}/messageCenter`}><FormattedMessage id={'message.center.title'}/></SidebarItem>
                {adminOpen ?
                <>
                    <SidebarItem onChange={onChange} to={`${url}/acks`}><FormattedMessage id={'acks.title'}/></SidebarItem>
                    <SidebarItem onChange={onChange}  to={`${url}/complaints/legal`} root={`${url}/complaints`}>Complaints</SidebarItem>
                    <SidebarItem onChange={onChange} to={`${url}/uar`}>UAR</SidebarItem>
                    <SidebarItem onChange={onChange}  to={`${url}/data-requests/not-delete`} root={`${url}/data-requests`}>Data Requests</SidebarItem>
                </>
                : '' }
            </Sidebar>
            <div className={'flex-1'}>
                <div className={"py-8 print:pt-4"}>
                {idv && idv.solidIdvUrl ?
                <OutlineBox style={{width: '100%', alignContent: 'center'}} >
                    <p className="ml-4 mt-4 mb-4 text-red-400"><FormattedMessage id={'idv.message'}/></p>
                    <a href={idv.solidIdvUrl} target={'_blank'} rel="noreferrer" className={'underline'} 
                        style={{marginLeft: '1em', marginBottom: '2rem'}}><FormattedMessage id={'idv.link'}/></a>
                </OutlineBox>
                : ''}
                    {step > 0 && step < stepDone ?
                <OutlineBox style={{width: '100%'}} >
                    <p className="ml-4 mt-4 text-red-400"><FormattedMessage id={'checklist.message'}/></p>
                    <Stepper activeStep={step} alternativeLabel style={{paddingLeft: '2px', paddingRight: '2px'}}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </OutlineBox>
                : ''}
                    <div className={"min-h-threequarters"}>
                        <Switch>
                            <Route exact path={[path,`${path}/employees/employeeDashboard`]}>
                                <ErrorBoundary key ={"employeeDash"}>
                                    <EmployeeDashboard/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/password`}>
                                <ErrorBoundary key ={"changePassword"}>
                                    <ChangePassword adminOpen={adminOpen}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/withdraw`}>
                                <ErrorBoundary key ={"withdrawl"}>
                                    <Withdraw userId={userId}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/close`}>
                                <ErrorBoundary key ={"close"}>
                                    <Withdraw userId={userId} close={true}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/transactions`}>
                                <ErrorBoundary key ={"employeeTransaction"}>
                                    <EmployeeTransactions/>
                                </ErrorBoundary>
                            </Route>
                            <Route onClick={() => alert('I will close settings')} path={`${path}/settings`}>
                                 <EmployeeSettings userId={userId} loadStep={loadStep}/>
                            </Route>
                            <Route path={`${path}/messageCenter`}>
                                <ErrorBoundary key ={"messageCenter"}>
                                    <EmailLogReport />
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/support`}>
                                <ErrorBoundary key ={"support"}>
                                    <EmailLog userId={employee.id}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/reports`}>
                                <ReportList employee={employee}/>
                            </Route>
                            {adminOpen ?
                            <>
                            <Route path={`${path}/acks`}>
                                <EmployeePolicies />
                            </Route>
                            <Route path={`${path}/complaints`}>
                                <ComplaintReport accountHolderId={employee.id}/>
                            </Route>
                            <Route path={`${path}/uar`}>
                                <ErrorBoundary key ={"uar"}>
                                    <UnusualActivity accountHolderId={employee.id}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/data-requests`}>
                                <DataRequestReport accountHolderId={employee.id}/>
                            </Route>
                            </>
                            : ''}
                        </Switch>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
    </AppLogout>
}
