import React, { useEffect, useCallback, useState } from "react";
import { PlaidLink,usePlaidLink } from 'react-plaid-link';
import _ from "lodash";
import { apiDelete, apiPost } from "../utils";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';

const PlaidBankForm = ({rootStore, isCompany, increment, setIncrement, banks, setBanks, email, loadStep}) => {
  const intl = useIntl();
  // const [banks, setBanks] = useState([]);
  const contactURI = '/api/v1/bank/contacts/new/';
  const URI = isCompany 
      ? `/api/v1/bankConnection/company/${rootStore.currentCompany.id}/plaid`
      : `/api/v1/bankConnection/accountHolder/${rootStore.currentEmployeeRecord.id}/plaid`;

  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const [config, setConfig] = useState({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: null,
    //required for OAuth; if not using OAuth, set to null or omit:
    receivedRedirectUri: window.location.href,
  });
  const [exchangeUrl, setExchangeUrl] = useState(isCompany 
    ? `/api/v1/plaid/exchange_public_token/company/${rootStore.currentCompany.id}` 
    : `/api/v1/plaid/exchange_public_token/accountholder/${rootStore.currentEmployeeRecord.id}` );

  const { open, exit, ready } = usePlaidLink(config);
  const [company, setCompany] = useState();

  useEffect(() => {
    rootStore.setLoading(true);
    getToken();
    if (rootStore.currentCompany) {
      setCompany(rootStore.currentCompany);
    }
  }, [rootStore.currentCompany]);

  const onRemove = async (id) => {
    rootStore.setLoading(true);
    await apiDelete(`/api/v1/bankConnection/delete/${id}`, rootStore.authStore, formatMessage(intl, 'plaid.bank', '', []), formatMessage(intl, 'plaid.bank.acct', '', []));

    
    setBanks();
    // setBanks(banks.filter(bank =>bank.id!==id));
    setIncrement(1);
  };

  // const submit = async (values) => {
    
  //   await _.forEach(values.banks, (bank) => {
  //     if (isCompany) {
  //       bank.companyId = rootStore.currentCompany.id;
  //     } else {
  //       bank.accountHolder = rootStore.currentEmployee.id;
  //     }
  //   })
  //   const response = await apiPost(URI, values, rootStore.authStore, 'Bank Account Added');
  //   const resp = await response.json();

  //   setBanks(_.filter(resp, {plaidVerified: true}));

  // }

  const getToken = async () => {
    const response = await apiPost('/api/v1/plaid/create_link_token', {email: email }, rootStore.authStore);
    await setConfig({
      onSuccess: onSuccess,
      onExit: onExit,
      onEvent: onEvent,
      token: response.linkToken,
      //required for OAuth; if not using OAuth, set to null or omit:
      receivedRedirectUri: window.location.href,
    });
    rootStore.setLoading(false);
    open();
  }

  const onSuccess = useCallback(
    async (public_token, metadata) => {
      rootStore.setLoading(true);
      // log and save metadata
      // exchange public token
      metadata.public_token = public_token;
      const response = await apiPost(exchangeUrl, metadata, rootStore.authStore);

      // const contResp = await apiPost(contactURI, response, rootStore.authStore);

      // if (contResp.status === 200) {
      //   submit({banks: [contResp]});
      // }
      rootStore.setLoading(false);
      setBanks();
      setIncrement(1); 
      loadStep();
    },
    [],
  );

  const onExit = useCallback(
    (error, metadata) => {
      // log and save error and metadata
      // handle invalid link token
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        // generate new link token
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
      
    },
    [],
  );

  const onEvent = useCallback(
    (
      eventName,
      metadata,
    ) => {
      // log eventName and metadata
    },
    [],
  );

  return (
    <>
    {rootStore.loading ? <Loading/> : ''}
    <br/>
      {!isCompany && banks && banks.length > 0 ?
      <p className="mt-5 text-blue-500"><FormattedMessage id={'plaid.exists'}/></p>
      :
      <div className={'flex row'}>
      <PlaidLink
        className="CustomButton uppercase mr-5"
        style={{ padding: '20px', fontSize: '16px', cursor: 'pointer', backgroundColor: 'rgba(37, 35, 105)', color: 'white' }}
        token={config.token ? config.token : ''}
        onExit={onExit}
        onSuccess={onSuccess}
        onEvent={onEvent}
      >
        Add Your Bank Using Plaid
      </PlaidLink>
      <p className={'text-red-500 mt-5'}><FormattedMessage id={'plaid.nobank'}/></p>
      </div>
      }
      <div className={`flex-1 lg:overflow-auto`}>
      {banks && banks.length > 0 ? 
      <div className={"flex row"}>
        <div className={`flex row card-div-header`}>
          <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left"}><FormattedMessage id={'plaid.name.account'}/></div>
        </div>
        <div className={`flex row card-div-header`}>
          <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left"}><FormattedMessage id={'plaid.name'}/></div>
        </div>
        <div className={`flex row card-div-header`}>
          <div className={"text-right text-sm text-gray-400 py-3 px-2 float-left"}><FormattedMessage id={'plaid.acct'}/></div>
        </div>
        <div className={`flex row card-div-header`}>
          <div className={"text-right text-sm text-gray-400 py-3 px-2 float-left"}><FormattedMessage id={'plaid.type'}/></div>
        </div>
      </div> : <></>}
    {banks ? banks.map((bank, index) => 
    <>
    <div className={`flex row float-left card-div-card`} style={{backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
      <div className={`flex row card-div-column float-left`}>
        <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left  card-div-label"}><FormattedMessage id={'plaid.name.account'}/></div>
        <Link className={'text-left text-sm text-gray-400 py-3 px-2 underline float-left'} onClick={() => onRemove(bank.id) }><FormattedMessage id={'plaid.remove'}/></Link>
        <div className={"text-left text-sm px-2 py-3 float-left"}>{bank.nameOnAccount}</div>
      </div>
      <div className={`flex row card-div-column float-left`}>
        <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left  card-div-label"}><FormattedMessage id={'plaid.name'}/></div>
        <div className={"text-left text-sm px-2 py-3 float-left"}>{bank.name}</div>
      </div>
      <div className={`flex row card-div-column float-left`}>
        <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left  card-div-label"}><FormattedMessage id={'plaid.acct'}/></div>
        <div className={"text-left text-sm px-2 py-3 float-left"}>{bank.accountNumber}</div>
      </div>
      <div className={`flex row card-div-column float-left`}>
        <div className={"text-left text-sm text-gray-400 py-3 px-2 float-left  card-div-label"}><FormattedMessage id={'plaid.type'}/></div>
        <div className={"text-left text-sm px-2 py-3 float-left"}>{bank.type}</div>
      </div>
    </div>
    </>)
    : ''  
    }
    </div>
    </>
  );

};

export default PlaidBankForm;
