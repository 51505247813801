import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import CompanyInfoForm from "../../../forms/CompanyInfoForm";
import EmployerMap from "../../../components/EmployerMap";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import PrimaryButton from "../../../components/PrimaryButton";
import { apiGet, apiPost } from "../../../utils";

const CompanySettings = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating;
  const [showKybButton, setShowKybButton] = useState(false);

  const loadKybStatus = async() => {
    const resp = await apiGet(`/api/v1/company/sendKyb/${currentCompany.id}`,rootStore.authStore);

    const response = await resp.json();
    setShowKybButton(response.kybSend);

  }
  
  useEffect(() => {
    currentCompany.loadAddress();
    currentCompany.loadAffiliates();
    setShowKybButton(currentCompany.kybSend);
    loadKybStatus();
  }, []);


  if (!currentCompany) {
    return <Loading />;
  }

  const sendToKyb = async () => {
    apiPost(`/api/v1/company/sendKyb/${currentCompany.id}`, {}, rootStore.authStore, 'Sent to KYB ');
    setShowKybButton(true);
  }

  return <div>
    {/* <EmployerMap company={currentCompany} /> */}
    <h3 className={'text-lg mt-8'}>Update Company Information</h3>
    <AlertProvider/>
    <p className={'py-4'}>
      Complete all sections of requested company profile.
    </p>
    {/* {showKybButton === false ?
    <PrimaryButton className={"mb-4"} onClick={sendToKyb}>Send To Kyb</PrimaryButton>
    : ''
    } */}
    {currentCompany.address && <CompanyInfoForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      company={currentCompany} doSubmit={(values) => {
        currentCompany.update(values);
        loadStep();
      }
      } />
    }
  </div>;
});

export default CompanySettings
