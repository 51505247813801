import React, {useEffect, useState} from "react";
import AlertProvider from "../../AlertProvider";
import LeadAdminAccountForm from "../../forms/LeadAdminAccountForm";
import NewLeadAdminAccountForm from "../../forms/NewLeadAdminAccountForm";
import { apiGet, apiPost, scrollToTop } from "../../utils";

const LeadAdmin = ({rootStore, currentCompany}) => {
  const [employee, setEmployee] = useState(undefined);
  const [found, setFound] = useState(false);

  const loadLead = async () => {
    const response = await apiGet(`/api/v1/accountHolder/company/lead/${currentCompany.id}`, rootStore.authStore);
    const result = await response.json();

    if (result.id) {
      setEmployee(result);
      setFound(true);
    }
  }

  useEffect(() => {
    rootStore.setLoading(true);
    loadLead();
  }, [rootStore.currentCompany]);

  return <div>
      <AlertProvider/>
      <p className="text-500-green mt-8">In order to submit KYB (Know Your Business), banking regulations require that a member of the company be validated. 
        This process is called KYC (Know Your Customer). Please fill out the information for MatchSavings to validate you and your business!</p>
      {found === true ?
      <LeadAdminAccountForm doSubmit={async (values) => {
          scrollToTop();
          await apiPost(`/api/v1/user/update/${values.id}`, values, rootStore.authStore, 'Lead Administrator Updated');
        }} 
        user={employee.user}
        employee={employee}
        errors={rootStore.formErrors.toJSON()}
        submitting={rootStore.submitting}
        address={employee.address}
      />
      :
      <NewLeadAdminAccountForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting} newAdmin={true}
        doSubmit={async (values) => {
        await apiPost(`/api/v1/accountHolder/newLead`, values, rootStore.authStore, 'Lead Administrator Added');

        }} 
        currentCompany={currentCompany}/>
    }
  </div>;
};

export default LeadAdmin;
