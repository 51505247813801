import React, {useEffect} from "react";
import {useFormikContext} from "formik";

const FormErrors = ({children, errors}) => {
  const formikContext = useFormikContext();
  useEffect(() => {
    formikContext.setErrors(errors);
  }, [errors]);
  return children
};

export default FormErrors;
