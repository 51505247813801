import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import { useForm } from 'react-hook-form';
import { apiDelete, apiGet, apiPost } from '../utils';
import StateInput from "../components/StateInput";
import _ from "lodash";
import * as Yup from "yup";
import RadioSelect from '../components/RadioSelect';
import { FormattedMessage } from 'react-intl';

const EmployeeBankForm = ({errors, employee, rootStore, loadStep}) => {
  const [enabled, setEnabled] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [banks, setBanks] = useState(employee.banks || []);
  const [allBanks, setAllBanks] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const {register} = useForm();

  useEffect(() => {
    rootStore.setLoading(false);
    loadBanks();
  }, [employee]);

  const loadBanks = async () => {

    let bankCount = 0;

      const response = await apiGet(`/api/v1/bankConnection/accountHolder/${employee.id}`, rootStore.authStore);//company
      const resp = await response.json();
      setBanks(resp);

    //   if (!resp || resp.length ===0){
          setEnabled({[0] : true, ...enabled});
    //   }

      const response2 = await apiGet(`/api/v1/bankConnection/accountHolder/${employee.id}/plaid`, rootStore.authStore);//company
      const resp2 = await response2.json();
      setAllBanks(resp2);

      setShowForm(resp.length < 2 && resp2.length < 1);
  }

  const submit = async (values) => {
      await _.forEach(values.banks, (bank) => {
          bank.accountHolderId = employee.id;
      })
      setSubmitting (true);
      const resp = await apiPost(`/api/v1/bankConnection/accountHolder/${employee.id}`, values, rootStore.authStore, 'Account Holder Added');

      setBanks(resp);
      setSubmitting(false);

      loadStep();
  }
  const onRemove = async (id) => {
    
    await apiDelete(`/api/v1/bankConnection/delete/${id}`, rootStore.authStore, 'Bank', 'Bank Account');

    setBanks(banks.filter(bank =>bank.id!==id));
  };

  return <>
    {!showForm ?
      <p className="mt-5 text-blue-500">A bank exists already for this account. Please delete the existing one to be able to add another.</p>
      :
      <>
  <p className="mt-5 text-red-500"><FormattedMessage id={'bank.manual.user'}/></p><Formik
      enableReinitialize={true}
      initialValues={{
          banks: banks.length > 0 ? banks : [{
            name: '',
            accountNumber: '',
            routingNumber: '',
            address1: '',
            address2: '',
            city: '',
            state:'',
            zip: '',
            version: null,
            active: true,
            id: null,
            type: 'CHECKING',
            nameOnAccount: null ? '' : '',
          }]
      }}
      onSubmit={(values) => {
          submit(values)
      }}
      validationSchema={Yup.object().shape({
          banks: Yup.array().of(Yup.object().shape({
              name: Yup.string()
                  .required('Bank Name is Required'),
              nameOnAccount: Yup.string()
                  .required("Name On Account is Required"),
              accountNumber: Yup.string()
                  .required("Account Number is Required"),
              routingNumber: Yup.string()
                  .required("Routing number is required")
                  .test('len', 'Must be 9 characters', val => val.length === 9),
              address1: Yup.string()
                  .required("Address 1 is required"),
              city: Yup.string()
                  .required("City is required"),
              state: Yup.string()
                  .required("State is required"),
              zip: Yup.string()
                  .required("Zip is required")
          })) //end array
      })
      }
  >{({setFieldValue, values}) =>
      <Form>
          <FormErrors errors={errors}>
              <FieldArray
                  name="banks"
                  render={arrayHelpers => (<>
                          {values.banks.map((bank, index) => <div className={'p-4 bg-subtle-blue mb-4'}
                                                                        key={index}>
                              <p className={'font-bold'}>My Bank Account</p>
                              <input type={'hidden'} value={bank.id}/>
                                  <LabeledField
                                      id={'name'}
                                      required
                                      className={"flex-1"}
                                      label={'Bank Name'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.name`}
                                      value={values.banks[index].name}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.name`, e.target.value);
                                      }}
                                      register={`banks.${index}.name`}
                                  />
                                  <LabeledField
                                      id={'nameOnAccount'}
                                      required
                                      className={"flex-1"}
                                      label={'Name on Account'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.nameOnAccount`}
                                      value={values.banks[index].nameOnAccount}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.nameOnAccount`, e.target.value);
                                      }}
                                      register={`banks.${index}.nameOnAccount`}
                                  />
                                  <LabeledField
                                    id={'type'}
                                    required
                                    className={"flex-1"}
                                    label={'Bank Account Type'}
                                    name={`banks.${index}.type`}
                                    value={values.banks[index].type}
                                    choices={[
                                        {label: 'Checking', value: 'CHECKING'},
                                        {label: 'Savings', value: 'SAVINGS'},
                                    ]}
                                    component={RadioSelect}
                                    />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Account Number'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.accountNumber`}
                                      value={values.banks[index].accountNumber}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.accountNumber`, e.target.value);
                                      }}
                                      register={`banks.${index}.accountNumber`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      required
                                      label={'Routing Number'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.routingNumber`}
                                      value={values.banks[index].routingNumber}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.routingNumber`, e.target.value);
                                      }}
                                      register={`banks.${index}.routingNumber`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Address 1'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.address1`}
                                      value={values.banks[index].address1}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.address1`, e.target.value);
                                      }}
                                      register={`banks.${index}.address1`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Address 2'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.address2`}
                                      value={values.banks[index].address2}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.address2`, e.target.value);
                                      }}
                                      register={`banks.${index}.address2`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'City'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.city`}
                                      value={values.banks[index].city}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.city`, e.target.value);
                                      }}
                                      register={`banks.${index}.city`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'State'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.state`}
                                      value={values.banks[index].state}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.state`, e.target.value);
                                      }}
                                      register={`banks.${index}.state`}
                                      component={StateInput}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Zip'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.zip`}
                                      value={values.banks[index].zip}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.zip`, e.target.value);
                                      }}
                                      register={`banks.${index}.zip`}
                                  />
                                  <div className={"flex-1 pt-10 pb-3 flex"}>
                                      {/* <PrimaryButton type={'button'} className={' mr-4'}
                                                     onClick={() => setEnabled({[index] : true, ...enabled})}>Edit</PrimaryButton> */}
                                      <PrimaryButton type={'button'} className={'mx-2 mr-4'}
                                                     onClick={() => onRemove(bank.id) }>Remove</PrimaryButton>
                                      {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
                                  </div>
                          </div>)}
                          {/* <FieldGroup>
                              {}
                              {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
                          </FieldGroup> */}
                      </>
                  )}
              />
          </FormErrors>
      </Form>}
  </Formik>
  </>}
  </>;
};

export default EmployeeBankForm;
