import React, {useRef, useEffect} from "react";
import Chart from "chart.js";
import {formatMoney} from "../utils";

export default function DonutChart({data, legendClass,marginClass ,legendExtraTop, IsFixedWidth,...props}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = new Chart(chartRef.current.getContext("2d"), {
      type: "doughnut",
      data: {
        //Bring in data
        labels: data.map(e => e.label),
        datasets: [
          {
            data: data.map(e => e.value),
            backgroundColor: data.map(e => e.color)
          }
        ]
      },
      options: {
        cutoutPercentage: 0,
        animation: {
          duration: 0
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            beforeLabel: function (tooltipItem, data) {
              const index = tooltipItem.index;
              return data.labels[index];
            },
            label: function (tooltipItem, data) {
              const index = tooltipItem.index;
              let value = data.datasets[0].data[index];
              if (typeof props.formatValue === 'function') {
                value = props.formatValue(value);
              }

              return value;
            }
          }
        }
      }
    });
  }, [data, props]);

  return (
    <div className={`md:flex flex-1 p-0 ${marginClass} sm:p-2 table w-full print:flex`}>
      <div className="text-center">
        <canvas ref={chartRef} height={160} width={160} className={IsFixedWidth === true? "mt-2 donutchart": "mt-2"} />
      </div>
      <div className="flex-1 flex-column xl:max-w-lg md:ml-4 p-2 print:flex-row">
          {legendExtraTop}
          <h4 className="text-md border-b border-gray-400 pb-1 mb-4">{props.legendTitle}</h4>
          <div>
            <ul>
              {data.map(({label, value, color}) =>
                <li className="text-black-50 text-sm flex my-2 items-center" key={label}>
                  <span className={"w-3 h-3 rounded-lg inline-block mr-2"} style={{backgroundColor: color}}/>
                  <span className={'flex-1 mr-8'}>{label}</span>
                  <span>{formatMoney(value)}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
    </div>
  );
}
