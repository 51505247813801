import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import KycTable from "../../components/KycTable";
import { apiGet } from "../../utils";
import PrimaryButton from "../../components/PrimaryButton";

const KycLog = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('Not Approved');
  

  useEffect(() => {
    getKycLogs(false);    
  }, []);
  
  const getKycLogs = async (approved) => {
    let url = `/api/v1/kyc_kyb/all`;
    if (approved === false) {
      url = `/api/v1/kyc_kyb/notApproved`;
      setTitle('Not Approved');
    } else {
      setTitle('All');
    }
    const response = await apiGet(url, rootStore.authStore);
    const resp = await response.json();

    setLogs(resp);
    setLoading(false);
  }
    
  return <div>
    <h3 className={"text-xl"}>KYC/KYB Status {title}</h3>
    <br />
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}
    <PrimaryButton type="button" large onClick={() => getKycLogs(true)} >
      Show All
    </PrimaryButton>
    &nbsp;&nbsp;
    <PrimaryButton type="button" large onClick={() => getKycLogs(false)} >
      Show Not Approved
    </PrimaryButton>
    {logs ? <div>
      <KycTable logs={logs}/>
    </div> : loading ? <Loading/> : <p className={'text-red-500'}>There are no records found.</p>}
  </div>;
});

export default KycLog;
