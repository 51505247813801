import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import PrimaryButton from "../../../components/PrimaryButton";
import { FormattedMessage, useIntl } from "react-intl";
import { apiGet, apiPost } from "../../../utils";
import Loading from "../../../components/Loading";

const EmployeePersonaVerification = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentUser, currentEmployeeRecord}} = useContext(StoreContext);
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [inquiryId, setInquiryId] = useState();
  const [sessionId, setSessionId] = useState();
  const [environment, setEnvironment] = useState();

  const checkForSubmissionStatus = async () => {
    const response = await apiGet(`/api/v1/persona/uisubmission/${currentUser.id}/${intl.defaultLocale}/status`, rootStore.authStore);
    const resp = await response.json();
    setData(resp);
    setInquiryId(resp.inquiryId);
    setSessionId(resp.sessionId);
    setLoading(false);
    // if (resp.inquiryId) {
    //   setShowButton(false);
    // }
  }

  const loadEnvironment = async () => {
    const response = await apiGet(`/api/v1/persona/uisubmission/environmentId`, rootStore.authStore);
    const resp = await response.json();
    setEnvironment(resp.environmentId);
  }

  const recordSubmission = async (fields, inquiryId) => {
    fields.inquiryId = inquiryId;
    fields.userId = currentUser.id;
    setInquiryId(fields.inquiryId);
    await apiPost('/api/v1/persona/uisubmission/new', fields, rootStore.authStore, 'Submission ');
  }



  const openPersona = async () => {
    //only import at time of use or will make unwanted calls up front
    const persona = await import ('persona');
    let client = new persona.Client({
      templateId: 'itmpl_p1L6a5tn7DQaBLuq3vvEpvGz',
      environmentId: environment,
      referenceId: currentUser.id,
      fields: {
        nameFirst: currentUser.firstName,
        nameLast: currentUser.lastName,
        birthdate: currentUser.dateOfBirth,
        addressStreet1: currentEmployeeRecord.address1,
        addressStreet2: currentEmployeeRecord.address2,
        addressCity: currentEmployeeRecord.city,
        addressSubdivision: currentEmployeeRecord.state,
        addressPostalCode: currentEmployeeRecord.zip,
        addressCountryCode: 'US',
        phoneNumber: currentUser.phone,
        emailAddress: currentUser.email,
      },
      // onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        // Inquiry completed. Optionally tell your server about it.
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        recordSubmission(fields, inquiryId);
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        console.log('onCancel');
      },
      onError: (error) => console.log(error),
    });
    client.open();
  }
  
  const resumePersona = async () => {
    //only import at time of use or will make unwanted calls up front
    const persona = await import ('persona');
    let client  = new persona.Client({
      templateId: 'itmpl_p1L6a5tn7DQaBLuq3vvEpvGz',
      environmentId: environment,
      referenceId: currentUser.id,
      sessionToken: sessionId,
      inquiryId: inquiryId,
      fields: {
        nameFirst: currentUser.firstName,
        nameLast: currentUser.lastName,
        birthdate: currentUser.dateOfBirth,
        addressStreet1: currentEmployeeRecord.address1,
        addressStreet2: currentEmployeeRecord.address2,
        addressCity: currentEmployeeRecord.city,
        addressSubdivision: currentEmployeeRecord.state,
        addressPostalCode: currentEmployeeRecord.zip,
        addressCountryCode: 'US',
        phoneNumber: currentUser.phone,
        emailAddress: currentUser.email,
      },
      // onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        // Inquiry completed. Optionally tell your server about it.
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        recordSubmission(fields, inquiryId);
        loadStep();
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        console.log('onCancel');
      },
      onError: (error) => console.log(error),
    });
    client.open();
  }

  useEffect(() => {
    checkForSubmissionStatus();
    loadEnvironment();
 }, []);

 return <>
 {loading === true ?
   <Loading/>
     :
   <div>
   <div className={'md:p-8 p-4'}>
     {data.passed === false ?
     <>
     <p className={'text-red-500 mb-4'}><FormattedMessage id={'persona.message'}/></p>
       
       {data.actions_required === true ?
       <PrimaryButton onClick={resumePersona}><FormattedMessage id={'persona.resume'}/></PrimaryButton>
       :
       <>
       <PrimaryButton onClick={openPersona}><FormattedMessage id={'persona.open'}/></PrimaryButton>
       </>
       }
       </>
         : data.passed === true ? 
         <p className={'text-red-500 mb-4'}><FormattedMessage id={'persona.approved'}/></p> 
         : <p className={'text-red-500 mb-4'}><FormattedMessage id={'persona.reviewed'}/></p>
     }
   </div>
   <div className={'md:p-8 p-4'}>
   {!data.governmentIdVerifiedPassed && data.governmentIdVerifiedActions && data.governmentIdVerifiedActions.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.governmentIdActions'}/></p>
       {data.governmentIdVerifiedActions.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>
   <div className={'md:p-8 p-4'}>
   {!data.selfieVerifiedPassed && data.selfieVerifiedActions && data.selfieVerifiedActions.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.selfieIdActions'}/></p>
       {data.selfieVerifiedActions.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>
   <div className={'md:p-8 p-4'}>
   {!data.dbVerifiedPassed && data.dbVerifiedActions && data.dbVerifiedActions.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.databaseIdActions'}/></p>
       {data.dbVerifiedActions.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>

   <div className={'md:p-8 p-4'}>
   {!data.governmentIdVerifiedPassed && data.governmentIdVerifiedErrors && data.governmentIdVerifiedErrors.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.governmentIdErrors'}/></p>
       {data.governmentIdVerifiedErrors.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>
   <div className={'md:p-8 p-4'}>
   {!data.selfieVerifiedPassed && data.selfieVerifiedErrors && data.selfieVerifiedErrors.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.selfieIdErrors'}/></p>
       {data.selfieVerifiedErrors.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>
   <div className={'md:p-8 p-4'}>
   {!data.dbVerifiedPassed && data.dbVerifiedErrors && data.dbVerifiedErrors.length > 0 ?
       <>
       <p className={'text-xl mb-4'}><FormattedMessage id={'persona.databaseIdErrors'}/></p>
       {data.dbVerifiedErrors.map(item => <ul className={'text-red-500 mb-4'}>{item}</ul>)}
       </>
     : ''
     }

   </div>
 </div>
 }
 </>;
});

export default EmployeePersonaVerification;
