import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import { Link } from "@material-ui/core";
import dayjs from "dayjs";
import OutboundClearingSheet from "../employer/settings/OutboundClearingSheet";
import {formatMoney} from "../../utils";
import Loading from "../../components/Loading";

export const OutboundClearing = (props) => {

    const {authStore} = useContext(StoreContext);
    const [activities, setActivities] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [outboundClearingId, setOutboundClearingId] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getOutbounds();
      }, []);

    const openShowForm = () => {
        window.scrollTo(0,0);
        setShowForm(true);        
    }

    const closeShowForm = () => {
        setLoading(true);
        window.scrollTo(0,0);
        setSelectedOutboundClearingId(null);
        getOutbounds();
        setShowForm(false);
    }

    const setSelectedOutboundClearingId = (id) => {
        setOutboundClearingId(id);
        openShowForm(true);
    }

    const getOutbounds = async () => {
        let url = `/api/v1/clearing/withdrawal/all`;
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setActivities(resp);
        setLoading(false);
    }

    if (loading === true) {
        return <Loading />;
    }
    
    return (
        <>
        {showForm === true ? 
        <OutboundClearingSheet closeShowForm={closeShowForm} outboundClearingId={outboundClearingId}/>
        :
        <div className="container max-w-6xl mx-auto px-4">
        <h1 className="text-lg mb-4">Outstanding Checks Log</h1>
        <MaterialTable 
            components={{}}
            columns={[
                {
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedOutboundClearingId(row.id)}} className={"cursor-pointer"}>
                            Edit
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'Withdrawal Date',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.created) {
                            return (<>{rowData.created ? dayjs(rowData.created).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Amount',
                    field: 'amount',
                    render: (rowData) => {
                        if (rowData.amount) {
                            return (<>{formatMoney(rowData.amount)}</>);
                        } 
                    }
                },
                {
                    title: 'User Name',
                    field: 'userName'
                },
                {
                    title: 'Check Number',
                    field: 'checkNumber'
                },
                {
                    title: 'Check Cleared',
                    field: 'checkCleared', 
                    render: (rowData) => {
                        if (rowData.checkCleared === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: '30-Day Notice',
                    field: 'thirtyDayNotice', 
                    render: (rowData) => {
                        if (rowData.thirtyDayNotice === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: '60-Day Notice',
                    field: 'sixtyDayNotice', 
                    render: (rowData) => {
                        if (rowData.sixtyDayNotice === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Phone or Email Attempt Made',
                    field: 'contactAttemptMade', 
                    render: (rowData) => {
                        if (rowData.contactAttemptMade === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: '90-Day Notice',
                    field: 'ninetyDayNotice', 
                    render: (rowData) => {
                        if (rowData.ninetyDayNotice === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Check Cancelled',
                    field: 'ninetyDayCancel', 
                    render: (rowData) => {
                        if (rowData.ninetyDayCancel === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                }

            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "Outstanding Checks Report",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={activities}
        />
        </div>
        }
        </>
        
    );
}