import React, { useEffect, useState } from 'react';
import { apiGet, apiPost } from '../utils';

const EmployerBankInstructionForm = ({errors, company, rootStore, increment, setIncrement}) => {
  const [banks, setBanks] = useState(company.banks || []);
  const [payrollBank, setPayrollBank] = useState(undefined);
  const [feesBank, setFeesBank] = useState(undefined);
  const [matchBank, setMatchBank] = useState(undefined);
  const [bankInstId, setBankInstId] = useState(undefined);

  useEffect(() => {
    if (increment !== 0) {
      loadBanks();
      loadPayrollBank();
    }
  }, [company, increment]);

  const loadBanks = async () => {
      const response = await apiGet(`/api/v1/bankConnection/company/${company.id}/all`, rootStore.authStore);//company
      const resp = await response.json();

    //   let results = [{key: undefined, label: 'Select an Account'}];
      let results = [{key: '0', label: 'Select an Account'}];
      await resp.forEach(bank => {
        bank.key = bank.id;
        bank.label = `${bank.name} - ${bank.accountNumber}`
        results.push(bank);
      });
      setIncrement(0);
      setBanks(results);
  }

  const loadPayrollBank = async () => {
    const response = await apiGet(`/api/v1/bankInstruction/company/${company.id}`, rootStore.authStore);//company
    const resp = await response.json();
    setPayrollBank(resp.payrollBankConnectionId === null ? '0' : resp.payrollBankConnectionId);
    setFeesBank(resp.feesBankConnectionId === null ? '0' : resp.feesBankConnectionId);
    setMatchBank(resp.matchBankConnectionId === null ? '0' : resp.matchBankConnectionId);
    setBankInstId(resp.id);
  }

  const updateBankInstruction = async (elem) => {
    const target = elem.target;
    const field = target.name;
    const value = target.value;

    let payload = {
        companyId: company.id,
        id: bankInstId,
        payrollBankConnectionId: payrollBank !== '0' ? payrollBank : null,
        feesBankConnectionId: feesBank !== '0' ? feesBank : null,
        matchBankConnectionId: matchBank !== '0' ? matchBank : null
    };
    payload[field]=value === '0' ? null : value;

    const response = await apiPost(`/api/v1/bankInstruction/company/${company.id}`, payload, rootStore.authStore, 'Bank Instructions Added');

    setBankInstId(response.id);
    setIncrement(1);
  }
  
  return <>
  { banks.length > 0 ? <div className='p-4 bg-subtle-blue mb-4'>
    <div className={'py-3 flex-1 mr-8'}>
        <label for="payrollBankConnectionId" className='block mb-3 text-sm '>Tell us which bank account to draft Employee Payroll Deductions.</label>
        <select onChange={updateBankInstruction} value={payrollBank} name="payrollBankConnectionId" className='border border-mid-grey p-3'>
            {banks.map((bank, index) =>
                <option value={bank.key}>{bank.label}</option>
            )}
        </select>
    </div>
    <div className={'py-3 flex-1 mr-8'}>
        <label for="matchBankConnectionId" className='block mb-3 text-sm '>Tell us which bank account to draft Employer Match Contributions.</label>
        <select onChange={updateBankInstruction} value={matchBank} name="matchBankConnectionId" className='border border-mid-grey p-3'>
            {banks.map((bank, index) =>
                <option value={bank.key}>{bank.label}</option>
            )}
        </select>
    </div>
    <div className={'py-3 flex-1 mr-8'}>
        <label for="feesBankConnectionId" className='block mb-3 text-sm '>Tell us which bank account to draft Fees.</label>
        <select onChange={updateBankInstruction} value={feesBank} name="feesBankConnectionId" className='border border-mid-grey p-3'>
            {banks.map((bank, index) =>
                <option value={bank.key}>{bank.label}</option>
            )}
        </select>
    </div>
    </div>
    : ''}
  </>;
};

export default EmployerBankInstructionForm;
