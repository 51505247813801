export async function apiGet(url, authStore) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authStore.getToken()}`,
        },
    });
    try {

        if (response.status && (response.status === 401 || response.status === 404)) {//no longer authed
            authStore.logout("Token Expired");
        } else {
            return response;
        }
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }

}

export async function apiGetNoAuth(url) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',           
        },
    });
    try {
        return response;
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }

}


export async function apiPut(url, params, authStore) {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authStore.getToken()}`,
        },
        body: JSON.stringify(params),
    })
};

export async function apiGetFile(url, authStore) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authStore.getToken()}`,
        },
    });
    try {
        if (response.status && (response.status === 401 || response.status === 404)) {//no longer authed
            authStore.logout("Token Expired");
        } else {
            return response;
        }
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }
};




export async function apiPostNoAuth(url, params, messageName = null) {
    const response = await fetch(url, {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(params),
        // body: params,
    });
    try {
        const json = await response.json();
        sendEvent(`${messageName} Successfully.`)
        return normalizeErrors(json);
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }
}

export async function apiPost(url, params, authStore, messageName = null) {
    const response = await fetch(url, {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authStore ? authStore.getToken() : ""}`,
        },
        body: params ? JSON.stringify(params) : null,
        // body: params,
    });
    try {
        const json = await response.json();
        if (authStore && (response.status && response.status === 401 || response.status === 404)) {//no longer authed
            authStore.logout("Token Expired");
        } else {
            if (messageName) {
                sendEvent(`${messageName} Successfully.`)
            }
            return normalizeErrors(json);
        }
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }
}

export async function apiDelete(url, authStore, messageName = null) {
    const response = await fetch(url, {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${authStore.getToken()}`,
        },
    });
    try {
        if (response.status && (response.status === 401 || response.status === 404)) {//no longer authed
            authStore.logout("Token Expired");
        }
        sendEvent(`${messageName} removed Successfully.`)
    } catch (error) {
        sendEvent(error.message, true)
        throw new Error(error);
    }

    const urlList = url.split('/');
    const size = urlList.length;
    return urlList[size-1];
}

/**
 * Flatten error arrays into something usable by Formick
 *
 * @param json
 */
function normalizeErrors(json) {
    if (json.errors) {
        Object.keys(json.errors).forEach((key) => json.errors[key] = json.errors[key].join(' '));
    }

    return json;
}

export const formatMoney = (value) => {
    if (!value) {
        return '$0.00'
    }

    return '$' + parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const feeTypeToName = (value) => {
    if (!value) {
        return ''
    }

    switch (value) {
        case 'CHECK':
            return 'Paper Check Fee';
        case 'CARD':
            return 'Card Fee';
        case 'NSF':
            return 'Insufficient Funds Fee';
        case 'INDIVIDUAL':
            return 'User Paid Monthly Fee';
        case 'CANCELLED_CHECK':
            return 'Cancelled Check Fee';
        default:
            return ''
    }

    return '$' + parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const formatMoneyWithRangeData = (value) => {
    if (!value) {
        return '';
    }
    if ( typeof value=== "object"){
        value = value.y;
    }
    return '$' + parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}
export const normalizeArray = (array, key) => {
        return array.reduce((obj, item) => {
            obj[item[key]] = item;
            return obj;
        }, {});
    }

export const scrollToTop = () => {
    window.scrollTo(0, 0);
}

const sendEvent = (message, isError) => {
    //Dispatch an Alert event
    var evt = new CustomEvent("AlertEvent", {detail: {message: message, isError: isError}});
    window.dispatchEvent(evt);
}

// export const removeJunkFromAccountHolder = (params) => {
//   // delete params.stats;
//   // delete params.status;
//   // delete params.url;
//   // delete params.urls;
//   // delete params.company;
//   // delete params.user;
//   // delete params.account;
//   // delete params.bankConnection;
//   // delete params.address;
//   // delete params.accountHolderGoals;
//   // delete params.payrollItems;
//   // delete params.beneficiary;

//   return params;
// }
;

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
 export const formatMessage = (intl, id, defaultMessage, values) => {
    const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;
 
    if (id) {
       return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage}, values) : newDefaultMessage;
    } else {
       return '';
    }
 }

//  export async function getCsrf() {
//     if (localStorage.getItem("X-XSRF-TOKEN") === "" || localStorage.getItem("X-XSRF-TOKEN") === null) {
//         await fetch("/csrf")
//         .then(response => response.json())
//             .then(data => {
//                 localStorage.setItem("X-XSRF-TOKEN", data.token);
//             });
//     } else {
//         return localStorage.getItem("X-XSRF-TOKEN");
//     }
//  }

 export const planDescription = (plan, intl) => {
    const baseMatchPercent = parseFloat(plan.matchPercent).toFixed(0)
    const baseMatchMax = parseFloat(plan.matchUpToPercent).toFixed(0)
    const bonusMatchPercent = parseFloat(plan.bonusMatchPercent || 0).toFixed(0)
    const bonusMatchMax = parseFloat(plan.bonusMatchUpToPercent || 0).toFixed(0)
    
    
    if (plan.matchType === 'TRADITIONAL') {
      let values = [baseMatchPercent, baseMatchMax, bonusMatchPercent, bonusMatchMax]
      return formatMessage(intl, 'dashboard.selector.message.traditional', '', values)
    }
  
    if (plan.matchType === 'TIMESTAMPED') {
      let months = formatMessage(intl, 'dashboard.month', '', [])
      let timestamped_period_description = `${plan.timeStampedPeriodMonths} ${months}.`;
      if (plan.timeStampedPeriodMonths === 12) {
        let year = formatMessage(intl, 'dashboard.one.year', '', [])
        timestamped_period_description = year;
      } else if (plan.timeStampedPeriodMonths % 12 === 0 && plan.timeStampedPeriodMonths >= 24) {
        let years = formatMessage(intl, 'dashboard.year', '', [])
        timestamped_period_description = `${plan.timeStampedPeriodMonths / 12} ${years}`;
      }

      let values = [baseMatchPercent, plan.timeStampedIntervalMonths, plan.timeStampedIntervalMonths, timestamped_period_description]
      return formatMessage(intl, 'dashboard.selector.message.timestamp', '', values)
    }
  }