import React, {useEffect, useContext, useState} from "react";
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import DateInput from "../components/DateInput";
import Select from "../components/Select";
import FormErrors from "../components/FormErrors";
import MaskedLabeledField from "../components/MaskedLabeledField";
import StoreContext from "../StoreContext";
import moment from "moment";
import { Redirect, useRouteMatch} from "react-router-dom";
import * as Yup from 'yup';


const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const EmployeeDetailsForm = ({doSubmit, errors,submitting, isSubmitting, employee = {}}) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  let {  url } = useRouteMatch();
  return <Formik
    initialValues={{
      'firstName': employee.user ? employee.user.firstName || '' : '',
      'lastName': employee.user ? employee.user.lastName || '' : '',
      'companyAccountHolderId': employee.user ? employee.companyAccountHolderId || '' : '',
      'email': employee.user ? employee.user.email || '' : '',
      'enrolled': employee.enrolled || false,
      'status': employee.status || 'ACTIVE',
      'hireDate': employee.hireDate ? moment(employee.hireDate).format('YYYY-MM-DD') : '',
      'dateOfBirth': employee.dateOfBirth ? moment(employee.dateOfBirth).format('YYYY-MM-DD') : '',
      'ssn': employee.ssn ? employee.ssn : '',
      'id': employee.id
    }}
    onSubmit={(values) => {
      if (values.enrolled === 'true' || values.enrolled === true) {
        values.enrolled = true;
      } else {
        values.enrolled = false;
      }
      doSubmit(values)
    }}
    validationSchema={Yup.object().shape({
      firstName: Yup.string()
          .required('First Name is required'),
      lastName: Yup.string()
          .required('last Name is required'),
      email: Yup.string()
          .required('Email is required'),
      dateOfBirth: Yup.string()
          .required('Date Of Birth is required'),
      hireDate: Yup.string()
          .required('Hire Date is required'),
      ssn: Yup.string()
          .required('SSN is required')

  })}
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <div className={'bg-subtle-blue max-w-2xl p-8 mt-6 mb-2'}>
          <LabeledField
            label={'First Name'}
            name={'firstName'}
            required
          />
          <LabeledField
            label={'Last Name'}
            name={'lastName'}
            required
          />
          <LabeledField
            label={'Employee ID'}
            name={'companyAccountHolderId'}
          />
          <LabeledField
            label={'Email'}
            name={'email'}
            required
            // onBlur={() =>{currentCompany.VerifyUserEmail(values.email)}}
          />
          <div className={"flex flex-col md:flex-row"}>
            <div className={"flex-1 md:mr-6 mr-0"}>
              <LabeledField
                label={'Plan Enrollment Status'}
                name={'enrolled'}
                className={''}
                choices={[
                  {label: 'Enrolled', value: true},
                  {label: 'Not Enrolled', value: false},
                ]}
                component={Select}
              />
            </div>
            <div className={"flex-1"}>
              <LabeledField
                label={'Employment Status'}
                name={'status'}
                className={''}
                choices={[
                  {label: 'Active', value: 'ACTIVE'},
                  {label: 'Terminated', value: 'TERMINATED'},
                  {label: 'Ineligible', value: 'INELIGIBLE'},
                ]}
                component={Select}
              />
            </div>
          </div>
          
          <LabeledField
            label={'Hire Date'}
            name={'hireDate'}
            component={DateInput}
          />
          <div>
          <LabeledField 
            label={'DOB'}
            name={'dateOfBirth'} 
            component={DateInput} 
          />
          </div>
          <div>
          <MaskedLabeledField required label={'SSN'} name={'ssn'} mask={maskedSsn} placeholder={'___-__-____'} value={values.ssn}
            onChange={e => {
              setFieldValue(`ssn`, e.target.value);
            }}/>
          </div>
          {employee.id && <div>
            <label>Employee has verified affiliation with the company?</label>
            <div>{employee.verified ? 'Verified' : 'Not Verified'}</div>
          </div>}
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton className='bg-primary px-6 py-3 text-center text-white uppercase '>Save</PrimaryButton>}
        </FieldGroup>
      </FormErrors>
    </Form>}
  </Formik>;
};

export default EmployeeDetailsForm;
