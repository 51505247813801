import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import DonutChart from "../../../components/DonutChart";
import OutlineBox from "../../../components/OutlineBox";
import {formatMoney} from "../../../utils";
import BarChartSideLegend from "../../../components/BarChartSideLegend";
import { apiGet } from "../../../utils";
import moment from "moment";
import Loading from "../../../components/Loading";
import TransactionTable from "../../../components/TransactionTable";

const QuarterlyDashboard = observer(() => {
  const {rootStore, authStore} = useContext(StoreContext);
  const currentCompany = rootStore.currentCompany;
  const [quarter, setQuarter] = useState(1);
  const [year, setYear] = useState(undefined);
  const [quarters, setQuarters] = useState([]);
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endDate, setEndDate] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [participatingCount, setParticipatingCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [match, setMatch] = useState(0);
  const [matches, setMatches] = useState(0);
  const [contribution, setContribution] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [startingTotalSavings, setStartingTotalSavings] = useState(0);
  const [totalInvestments, setTotalInvestment] = useState(0);
  const [cont, setCont] = useState(0);
  const [withdrawals, setWithdrawals] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [percent, setPercent] = useState(0);


  useEffect(() => {
    if (currentCompany && year && quarter) {
      getCompanyStats(year, quarter);
      getTransactions();
    }

  }, [quarter, year]);

  useEffect(() => {
    getYears();  
  }, []);
  
  const getTransactions =  async () => {
    const response = await apiGet(`/api/v1/quarterly/get/company/transactions/${currentCompany.id}/${quarter}/${year}`, authStore);

    const result = await response.json();

    setTransactions(result);
  }

  const getCompanyStats = async (year, quarter) => {
    setLoading(true);
    const response = await apiGet(`/api/v1/quarterly/get/company/${currentCompany.id}/${quarter}/${year}`, authStore);

    const result = await response.json();

    setParticipatingCount(result.participatingCount);
    setEmployeeCount(result.employeeCount);
    setTotal(result.total);
    setMatch(result.match);
    setContribution(result.contribution);
    setLoading(false);

    setTotalInvestment(0);
    setTotalSavings(result.total);
    setStartingTotalSavings(result.startingTotal);
    updateEndDate(quarter);
    setCont(result.quarterContributions);
    setWithdrawals(result.quarterWithdrawals);
    setMatches(result.quarterMatches);

    if (result) {
      const employeeCount = result.employeeCount ? result.employeeCount : 0;
      const parCount = result.participatingCount ? result.participatingCount : 0;

      setPercent(parseFloat(parCount > 0 ? (parCount/employeeCount) * 100 : 0).toFixed(1));
    } 
  }

  const updateYear = (el) => {
    setLoading(true);
    setYear(el.target.value);
    populateQuarters(el.target.value);
  }

  const populateQuarters = (year) => {
    let quarters = [];
    let today = moment(new Date());
    if (moment(`03/31/${year}`).isBefore(today) ) {
      quarters.push(1);
    }
    if (moment(`06/30/${year}`).isBefore(today) ) {
      quarters.push(2);
    }
    if (moment(`09/30/${year}`).isBefore(today) ) {
      quarters.push(3);
    }
    if (moment(`12/31/${year}`).isBefore(today) ) {
      quarters.push(4);
    }
    setQuarters(quarters);

    if (!quarters.includes(parseInt(quarter))) {
      setQuarter(1);
    }
  }

  const getYears = async () => {
    const response = await apiGet(`/api/v1/payrollItem/company/interest/years/${currentCompany.id}`, authStore);
    const results = await response.json();

    if (results.length === 0) {
      results.push(moment().year());
    }
    setYears(results);
    if (results.length > 0) {
      setYear(results[0]);
      populateQuarters(results[0]);
    }
  }

  const updateQuarter = (el) => {
    setLoading(true);
    setQuarter(el.target.value);
    updateEndDate(el.target.value);
  }

  const updateEndDate = (paramQ) => {
    switch (parseInt(paramQ)) {
      case 1:
        setEndDate(`03/31/${year}`);
        setStartDate(`01/01/${year}`);
        break;
      case 2:
        setEndDate(`06/30/${year}`);
        setStartDate(`04/01/${year}`);
        break;
      case 3:
        setEndDate(`09/30/${year}`);
        setStartDate(`07/01/${year}`);
        break;
      case 4:
        setEndDate(`12/31/${year}`);
        setStartDate(`10/01/${year}`);
        break;
      default:
        break;
    }
  }
  

  return <div>
    <br/>
    {loading ? 
    <Loading/>  
    : ''
    }
    <div className={'flex'}>
    <h2 className={"text-xl mb-10"}>Welcome back {currentCompany && currentCompany.name}</h2>
    <div className={'mx-4 float-right'}>
          <label className={'mx-4'}>Quarter:</label>
        <select onChange={updateQuarter} value={quarter} name="payrollBankConnectionId" className='border border-mid-grey p-3'>
            {quarters.map((quarter) =>
                <option key={quarter} value={quarter}>{quarter}</option>
            )}
        </select>
        <label className={'mx-4'}>Year:</label>
        <select onChange={updateYear} value={year} name="payrollBankConnectionId" className='border border-mid-grey p-3'>
            {years.map((year) =>
                <option key={year} value={year}>{year}</option>
            )}
        </select>
        </div>
        </div>
    <OutlineBox className={"w-100"}>
    
      <div className={'flex border-solid border-gray-200 rounded border-b-2 p-8'}>
        <div className={'flex-1 pt-4'}>
          <DonutChart
            data={[
              {
                label: "Employee",
                value: contribution,
                color: "#252369",
              },
              {
                label: "Employer",
                value: match,
                color: "#81c1ff",
              },
              {
                label: "Other",
                value: 0,
                color: "#0057ff",
              }
            ]}
            formatValue={formatMoney}
            legendTitle={'Account Balance By Source'}
            legendExtraTop={<div className={'flex text-lg font-bold'}>
              <span className={'flex-1 mb-2'}>Plan Assets as of {endDate}</span> <span>{formatMoney(total)}</span>
            </div>}
            legendClass={'-mt-8'}
          />
        </div>
        <div className={'flex-1'}>
          <BarChartSideLegend
            displayLegend={false}
            height={200}
            width={200}
            data={{
              datasets:
                [
                  {
                    label: "Eligible Employees",
                    data: [employeeCount],
                    color: '#ff8700',
                    backgroundColor: '#ff8700',
                  },
                  {
                    label: "Contributing Employees",
                    data: [participatingCount],
                    color: '#ffc380',
                    backgroundColor: '#ffc380',
                  }
                ]
            }}
            barPercentage={.8}
            legendTitle={'Employee Participation Rate'}
            legendClass={'mt-4'}
            percent={percent}
          />
        </div>
      </div>
      <div className={"mt-6"}>
        <OutlineBox className={"flex-1 p-4 max-w-screen-sm"}>
          <h3 className={'flex-1 text-lg'}>Summary</h3>
          <div className={'flex'}>
            <p className={'flex-1'}>Starting Account Balance {startDate}</p>
            <p className={'text-lg'}>{formatMoney(startingTotalSavings + totalInvestments)}</p>
          </div>
          <div className={'flex'}>
            <p className={'flex-1'}>Contributions</p>
            <p className={'text-lg'}>{formatMoney(cont)}</p>
          </div>
          <div className={'flex'}>
            <p className={'flex-1'}>Matches</p>
            <p className={'text-lg'}>{formatMoney(matches)}</p>
          </div>
          <div className={'flex'}>
            <p className={'flex-1'}>Withdrawals</p>
            <p className={'text-lg'}>{formatMoney(withdrawals)}</p>
          </div>
          <div className={'flex'}>
            <p className={'flex-1'}>Ending Account Balance {endDate}</p>
            <p className={'text-lg'}>{formatMoney(totalSavings + totalInvestments)}</p>
          </div>
        </OutlineBox>
      </div>
      
      {/* <div className={'sm:flex p-5'}>
        <div className={'flex-1 sm:border-r border-gray-300 px-5'}>
          <h3 className={'mb-2'}>Total Contributed To-Date</h3>
          <span className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.totalSavingsToDate : '0')}</span>
        </div>
        <div className={'flex-1 sm:border-r border-gray-300 pr-5 pl-12'}>
          <h3 className={'mb-2'}>Employee Contributions</h3>
          <span
            className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.employeeContributionToDate : '0')}</span>
        </div>
        <div className={'flex-1 pr-5 pl-12'}>
          <h3 className={'mb-2'}>Employer Contributions</h3>
          <span className={"text-4xl font-thin"}>{formatMoney(currentCompany.stats ? currentCompany.stats.matchToDate : '0')}</span>
        </div>
      </div> */}
    </OutlineBox>
    <div className={"mt-8"}>
      <div className={"flex flex-row border-solid border-gray-200 rounded border-b-2 pb-4 mb-4"}>
        <span className={"text-xl flex-1"}>This Quarter's Summary Transactions</span>
      </div>
      {transactions && <TransactionTable transactions={transactions}/>}
    </div>
  </div>;
});

export default QuarterlyDashboard;
