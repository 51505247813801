import React from "react";

const TabButton = ({children, selected, className, onClick, type = 'submit', ...props}) => {
  const colors = selected ? 'bg-primary text-white' : 'bg-white text-primary';
  return <button
    onClick={onClick}
    className={`${colors} border-2 border-solid border-primary px-6 py-3 mr-2 text-center text-white uppercase ${className || ''}`}
    type={type} {...props}>{children}</button>;
};

export default TabButton;
