import React, {useEffect, useContext} from "react";
import SettingsMenu from "../../components/SettingsMenu";
import SettingsMenuItem from "../../components/SettingsMenuItem";
import AccountSettings from "./settings/AccountSettings";
import EmployerSettings from "./settings/EmployerSettings";
import ChangePassword from "./settings/ChangePassword";
import SavingsPlanSettings from "./settings/SavingsPlanSettings";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import EmployeeBankSettings from "./settings/EmployeeBankSettings";
import ErrorBoundary from "../../components/ErrorBoundary";
import CardSettings from "./settings/CardSettings";
import {FormattedMessage} from 'react-intl';
import EmployeePersonaVerification from "./settings/EmployeePersonaVerification";
import { Complaint } from "../../pages/admin/Complaint";

export default function EmployeeSettings({children, userId, loadStep}) {
    let {path, url} = useRouteMatch();
    return <div>
        <h2 className={'text-xl'}><FormattedMessage id={'settings.personal.title'}/></h2>
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/account`}><FormattedMessage id={'settings.personal.info'}/></SettingsMenuItem>
            <SettingsMenuItem to={`${url}/verification`}><FormattedMessage id={'settings.id.verification'}/></SettingsMenuItem>
            {/* <SettingsMenuItem to={`${url}/employer`}><FormattedMessage id={'settings.employer.info'}/></SettingsMenuItem> */}
            {/* <SettingsMenuItem to={`${url}/password`}><FormattedMessage id={'settings.password'}/></SettingsMenuItem> */}
            <SettingsMenuItem to={`${url}/plan`}><FormattedMessage id={'settings.plan'}/></SettingsMenuItem>
            <SettingsMenuItem to={`${url}/bank`}><FormattedMessage id={'settings.withdrawal'}/></SettingsMenuItem>
            {/* <SettingsMenuItem to={`${url}/card`}>Card</SettingsMenuItem> */}
        </SettingsMenu>
        <Switch>
            <Route path={`${path}/account`}>
                <ErrorBoundary key ={"accountSettings"}>
                    <AccountSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/verification`}>
                <ErrorBoundary key ={"accountSettings"}>
                    <EmployeePersonaVerification loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/employer`}>
                <ErrorBoundary key ={"employerSettings"}>
                    <EmployerSettings/>
                </ErrorBoundary>
            </Route>
            {/* <Route path={`${path}/password`}>
                <ErrorBoundary key ={"changePassword"}>
                    <ChangePassword/>
                </ErrorBoundary>
            </Route> */}
            <Route path={`${path}/plan`}>
                <ErrorBoundary key ={"savingsPlanSettings"}>
                    <SavingsPlanSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/bank`}>
                <ErrorBoundary key ={"employeeBankSettings"}>
                    <EmployeeBankSettings loadStep={loadStep}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/card`}>
                <ErrorBoundary key ={"employeeCardSetting"}>
                    <CardSettings loadStep={loadStep} />
                </ErrorBoundary>
            </Route>
            <Route path="/settings">
                <Redirect to={`${path}/account`}/>
            </Route>
        </Switch>

    </div>;
}
