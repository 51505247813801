import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
  }


  render() {
    if (this.state.hasError) {
      return <div className={"p-4 bg-red-500 text-white"}>An error occurred. Please contact support if this issue persists.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
