import React from 'react';
import { Link } from 'react-router-dom';

const PlanRequirementModal = ({wantsToClose}) => {
  return (
    <>
      <div
        className="items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={() => wantsToClose()}
      >
        <div className="w-auto my-6 mx-auto max-w-3xl">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-3xl font-semibold">
                Company Plan Design Required
              </h3>
            </div>

            <div className="relative p-6 flex-auto">
              <p className="my-4 text-gray-600 text-lg leading-relaxed">
                Before you can use this feature a company plan design is required. <Link
                className={'text-primary'}
                to={'settings/plan'}
              >Click here</Link> to create your company plan design now.
              </p>
            </div>

            <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{transition: "all .15s ease"}}
                onClick={() => wantsToClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PlanRequirementModal;
