import React, {useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import ChangePasswordForm from "../../../forms/ChangePasswordForm";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";

const ChangePassword = observer((adminOpen) => {
  const {rootStore, authStore} = useContext(StoreContext);

  const setMessage = (message) => {
    rootStore.setMessage(message);
  }

  return <div>
    {adminOpen.adminOpen === true ? 'Password Reset is disabled as Admin! Please have the user follow the "Forgot Password" flow.'
    :
    <div className={'md:p-8 p-4'}>
      <AlertProvider/>
      <ChangePasswordForm authStore={authStore} doSubmit={rootStore.updateCurrentUser} errors={rootStore.formErrors.toJSON()}
                          submitting={rootStore.submitting} setMessage={setMessage}/>
    </div>}
  </div>;
});

export default ChangePassword;
