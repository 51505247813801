import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet, apiPost } from "../../utils";
import Loading from "../../components/Loading";
import EmailLogForm from "../../forms/EmailLogForm";
import AlertProvider from "../../AlertProvider";

export const EmailLog = (props) => {

    const {authStore} = useContext(StoreContext);
    const [companies, setCompanies] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCompanies();
      }, []);

    const getCompanies = async () => {
        let url = `/api/v1/company/all`;
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setCompanies(resp);
        setLoading(false);
    }

    const sendEmail = async (values) => {
        let url = `/api/v1/email/new`;
        const response = await apiPost(url, values, authStore, 'Mass Email created');        

        setLoading(false);
    }

    if (loading === true) {
        return <Loading />;
    }
    
    return (
        <>
        <AlertProvider/>
        <EmailLogForm values={companies} doSubmit={sendEmail} submitting={loading} 
        setLoading={setLoading} />
        </>
        
    );
}