import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import MyTextArea from '../components/MyTextArea';
import { Autocomplete } from '@material-ui/lab';
import { apiGetNoAuth, formatMessage } from '../utils';
import { useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import StoreContext from '../StoreContext';


const EmailLogFields = ({setFieldValue, intl, getCompanies, name, companies, setName, showCompanies, selectTo, values}) => <div>
    <>
    <div className={'flex mt-4 mb-4'}>

    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>Email Type: </h3>
      <LabeledField
        label={''}
        name={'type'}
        choices={[
          {label: 'Terms Update', value: 'TERMS_UPDATE'},
          {label: 'Outage', value: 'OUTAGE'},
          {label: 'News', value: 'NEWS'},
          {label: 'Company Communication', value: 'COMPANY_COMMUNICATION'},
          {label: 'Other', value: 'OTHER'},
        ]}
        component={Select}
      />
    </div>
  </div>
  <div className={'flex'}>
      
  
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>To: </h3>
      <LabeledField
        label={''}
        name={'emailSendingType'}
        choices={[
          {label: 'All', value: 'ALL'},
          {label: 'All - Active', value: 'ALL_ACTIVE'},
          {label: 'Company Only (Leads/Admins) - All', value: 'COMPANY_ONLY'},
          {label: 'Company Only (Leads/Admins) - Active', value: 'COMPANY_ONLY_ACTIVE'},
          {label: 'Company and Users', value: 'COMPANY_AND_USERS'},
          {label: 'Company and Users - Active', value: 'COMPANY_AND_USERS_ACTIVE'},
          {label: 'Users Only', value: 'USERS_ONLY'},
          {label: 'Users Only - Active', value: 'USERS_ONLY_ACTIVE'},
        ]}
        component={Select}
        onChange={e => {
            setFieldValue(`emailSendingType`, e.target.value);
            selectTo(e)
        }}
      />
    </div>

  </div>

  { showCompanies ?
  <Autocomplete
      className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
      name="companyId" 
      noOptionsText={formatMessage(intl, 'login.no.options', '', [])}
      value={name} 
      required
      autoComplete="companyId"
      options={companies.map((option) => option.label)}
      getOptionLabel={(option) => option}
      onChange={(e) => {
          setName(e.target.textContent);
          }
      }
      onInputChange={(event, newInputValue) => {
          getCompanies(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={formatMessage(intl, 'login.company', '', [])} required/>}
  /> : ''}
  <h3 className={'text-lg my-2 mr-2'}>Subject:</h3>
    <LabeledField
      name={'subject'}
      style={{border: "solid", width: "100%"}}
    />
    <h3 className={'text-lg my-2'}>Body:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="body"
      rows="10"
      style={{border: "solid", width: "100%"}}
    />
  </div>
  
  </>
</div>;

const EmailLogForm = ({doSubmit, errors, submitting, setLoading}) => {
  const { authStore } = useContext(StoreContext);
  const intl = useIntl();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState('');
  const [showCompanies, setShowCompanies] = useState(false);
  const [companyId, setCompanyId] = useState();

  useEffect(() => {
        
    let found = companies.filter(filterCompany);

    handleCompanySelect(found && found.length > 0 ? found[0].value : undefined)

 }, [name]);

 const filterCompany = (company) => {
    if (company.label === name) {
        return company;
    }
  }
  const handleCompanySelect = e => {
    if (e) {
        setCompanyId(e);
    }
  }

  const getCompanies = async (value) => {

    if (value.length > 4) {
        const response = await apiGetNoAuth(`/api/v1/utility/companySelect?search=${value}`);
        const companiesLocal = await response.json();
        setCompanies(companiesLocal);
    }
  }

  const selectTo = (to) => {
    if (to.currentTarget.value === 'COMPANY_ONLY' || to.currentTarget.value === 'COMPANY_AND_USERS'
        || to.currentTarget.value === 'COMPANY_AND_USERS_ACTIVE' ||  to.currentTarget.value === 'COMPANY_ONLY_ACTIVE') {
      setShowCompanies(true);
    } else {
      setShowCompanies(false);
    }
  }

  return <Formik
    initialValues={
      {
      subject: '',
      type: 'TERMS_UPDATE',
      body: '',
      emailSendingType: 'ALL',
      companyId: null
    }}
    onSubmit={(values) => {
      setLoading(true);
      if (values.emailSendingType === 'COMPANY_ONLY' || values.emailSendingType === 'COMPANY_AND_USERS'
        || values.emailSendingType  === 'COMPANY_AND_USERS_ACTIVE' ||  values.emailSendingType  === 'COMPANY_ONLY_ACTIVE') {
        values.companyId = companyId
      }
      doSubmit(values);
      values = {
        subject: '',
        type: 'TERMS_UPDATE',
        body: '',
        emailSendingType: 'ALL',
        companyId: null  
      }
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <EmailLogFields setFieldValue={setFieldValue} values={values} intl={intl} companies={companies} getCompanies={getCompanies}
        name={name} setName={setName} showCompanies={showCompanies} selectTo={selectTo} companyId={companyId}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Send</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default EmailLogForm;
