import React from 'react';
import { useState } from "react";
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import { apiPost } from '../utils';
import PasswordVerifyField from '../components/PasswordVerifyField';
import * as Yup from "yup";
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';

const ChangePasswordForm = ({authStore, doSubmit, errors, submitting, setMessage}) => {
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [oldPwMatch, setOldPwMatch] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [pwPass, setPwPass] = useState(false);
  const [matchPass, setMatchPass] = useState(false);
  const [messages, setMessages] = useState("");
  const [error, setError] =  useState("");
  const [pwStrength, setPwStrength] = useState('');
  const [pwInstr, setPwInstr] = useState(formatMessage(intl, 'password.fail', '', []));
  let formValues = {
    old_password: '',
    password: '',
    password_confirmation: '',
  };

  const resetPassword = async (params) => {
    let values = {user: 
                {
                  userId: authStore.getUserId(),
                  password: password,
                  validatePassword: oldPwMatch,
                  userName: authStore.getEmail()
                }
              };
    const response = await apiPost(`/reset/changePasswordMaint`, values, authStore, null);
    
    if (!response.error) {
      setMessage('Password reset successful!');
      setOldPwMatch('');
      setPassword('');
      setPasswordMatch('');
      setPwInstr('');
    }else {
      setError(response.error);
    }
  } 

  const handleChangePw = (event) => {
    let value = event.target.value.trim() || '';
    if (value === passwordMatch && value !== '') {
      setMessages('');
      setIsDisabled(false);
    } else {
      setMessages(formatMessage(intl, 'password.mismatch', '', []));
      setIsDisabled(true);
    }
    setPassword(value);
    checkPwStrength(value);
  }

  const handleChangePwMatch = (event) => {
    let value = event.target.value.trim() || '';
    if (value === password && value !== '') {
      setMessages('');
      setIsDisabled(false);
      setMatchPass(true);
    } else {
      setMessages(formatMessage(intl, 'password.mismatch', '', []));
      setIsDisabled(true);
      setMatchPass(false);
    }
    setPasswordMatch(value);
  }

  const handleOldPwChange = (event) => {
    let value = event.target.value.trim() || '';
    setOldPwMatch(value);
  }


  const checkPwStrength = (password) => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{14,})");
    // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if(strongRegex.test(password)) {
      setPwPass(true);
      setPwStrength(formatMessage(intl, 'password.success', '', []));
      setPwInstr('');
    // } else if(mediumRegex.test(value)) {
      // setPwStrength({"background-color":"orange"});
    } else {
      setPwPass(false);
      setPwInstr(formatMessage(intl, 'password.fail', '', []));
      setPwStrength('');
    }
  }

  return <Formik
    initialValues={formValues}
    onSubmit={(values) => {
      resetPassword(values)
    }}
    validationSchema={Yup.object().shape({
      // old_password: Yup.string()
      //     .test('len', 'Must be 8 characters', val => val.length === 8)
      //     .required('Current Password is required'),
      // password: Yup.string()
      //     .required('New Password is required'),
      // password_confirmation: Yup.string()
      //     .required('Please Confirm Password'),
  })}
  >
    <Form onBlur={() => {setError('')}}>
      <FormErrors errors={errors}>
        <h3 className={'text-lg '}><FormattedMessage id={'password.title'}/></h3>
        <div className={''}>
          <PasswordVerifyField
            label={formatMessage(intl, 'password.current', '', [])}
            name={'old_password'}
            type={'password'}
            onChange={handleOldPwChange}
            required
            style={{borderColor: error !== '' ? 'red' : ''}}
            value={oldPwMatch}
          />
        </div>
        <div style={{color: 'red'}}>{error}</div>
        <div className={''}>
          <PasswordVerifyField
            label={formatMessage(intl, 'password.new', '', [])}
            name={'password'}
            type={'password'}
            value={password}
            onChange={handleChangePw}
            pass={pwPass}
            required
          />
        </div>
        <div className='text-red-500'>{pwInstr}</div>
        <div className='text-green-500'>{pwStrength}</div>
        <div className={''}>
          <PasswordVerifyField
            label={formatMessage(intl, 'password.confirm', '', [])}
            name={'password_confirmation'}
            type={'password'}
            value={passwordMatch}
            onChange={handleChangePwMatch}
            pass={matchPass}
            required
          />
        </div>
        <div className='text-red-500' style={{color: 'red'}}>{messages}</div>
        <div style={{color: 'red'}}>{error}</div>
        {isDisabled ? '' :
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton ><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
        }
      </FormErrors>
    </Form>
  </Formik>;
};

export default ChangePasswordForm;