import React, {useContext, useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from "react-router-dom";
import MatchSavingsLogo from "../images/match-savings-logo-full-color.png";
import {observer} from "mobx-react";
import StoreContext from "../StoreContext";
import { Link } from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {apiGetNoAuth, formatMessage} from '../utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

const Login = observer(() => {
    const {authStore} = useContext(StoreContext);
    const {role} = useParams();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const reset = params.get('reset');
    authStore.setRole(role);
    const intl = useIntl();
    const [companies, setCompanies] = useState([]);
    const [name, setName] = useState('');
    const normalCss = `rounded-sm p-3 border border-mid-grey border-solid my-4 w-full`;
    const errorCss = `rounded-sm p-3 border border-red-600 border-solid my-4 w-full`;
    const [individual,setIndividual] = useState(undefined);
    const [newCompany, setNewCompany] = useState(undefined);
    const [showCompany, setShowCompany] = useState('');
    
    useEffect(() => {
        getIndividualCompany();
        getNewCompany();
     }, []);

    useEffect(() => {
        let found = companies.filter(filterCompany);
        handleCompanySelect(found && found.length > 0 ? found[0].value : undefined)

     }, [name]);

    const filterCompany = (company) => {
        if (company.label === name) {
            return company;
        }
    }
    
    if (reset) {
        return <Redirect to={`/reset?reset=${reset}`}/>
    }

    const handleEmailChange = e => {
        authStore.setEmail(e.target.value);
    };
    const handlePasswordChange = e => {
        authStore.setPassword(e.target.value);
    };
    const handleCompanySelect = e => {
        if (e) {
            authStore.setCompanyId(e);
        } else {
            authStore.setCompanyId(undefined);
        }
    }
    const  handleSubmitForm = async e =>{
        authStore.setMessage('');
        e.preventDefault();//otherwise the button submit counts as an action and async goes nuts

      const userId= await authStore.login();
      if (userId){
          history.push('/approuter/'+userId+'/dashboard');
      }
    };

    const navigate = (location) => {
        history.push(location);
    }

    const getCompanies = async (value) => {

        if (value.length > 4) {
            const response = await apiGetNoAuth(`/api/v1/utility/companySelect?search=${value}&role=${role}`);
            const companiesLocal = await response.json();
            setCompanies(companiesLocal);
        }
    }

    const getIndividualCompany = async () => {
        const response = await apiGetNoAuth(`/api/v1/utility/companySelect?search=Individual&role=${role}`);
        const companiesLocal = await response.json();
        setIndividual(companiesLocal[0]);
    }

    const getNewCompany = async () => {
        const response = await apiGetNoAuth(`/api/v1/utility/companySelect?search=New Company&role=${role}`);
        const companiesLocal = await response.json();
        setNewCompany(companiesLocal[0]);
    }

    const setDefaultCompany = async(value) => {

        setShowCompany(value);

        if (value === '') {
            handleCompanySelect(undefined);
            return;
        }
        if (role === 'employer') {
            handleCompanySelect(newCompany.id);
        } else {
            handleCompanySelect(individual.id);
        }

    }

    return (

        <div id="login" className="flex md:h-screen flex-col-reverse md:flex-row sm:flex-233">
            <div className="bg-half-splash flex-1 bg-cover p-16 flex justify-center items-center">
                {role === 'employer' ?
                    (
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">
                            <FormattedMessage id={'login.employer.splash'}/></p>)
                                
                    : role === 'employee' ?(
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">
                            <FormattedMessage id={'login.employee.splash'}/></p>
                    ) : role === 'bsa' ?(
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">
                            <FormattedMessage id={'login.bsa.splash'}/></p>
                    ) : role === 'admin' ?(
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">
                            <FormattedMessage id={'login.admin.splash'}/></p>
                    ) : ''}
            </div>
            
            <form className='overflow-y-auto' onSubmit={handleSubmitForm}>
                <div className="pt-6 p-0 lg:pb-0 md:p-24 lg:p-28 md:pb-0 flex flex-col justify-center items-center">
                    <div className="w-4/5 md:w-72 lg:w-96 flex flex-col items-center">
                        <h1 className="text-4xl mb-8 text-center text-primary font-bold">
                            <img className="mx-auto max-w-100" src={MatchSavingsLogo}/>
                        </h1>
                        {authStore.message && <p className="text-green-500" >{authStore.message}</p>}
                        {role === 'employer' ?
                            (
                                <h2 className="text-xl"><FormattedMessage id={'login.employer.title'}/></h2>
                            )
                            : role === 'employee' ?
                            (
                                <h2 className="text-xl"><FormattedMessage id={'login.employee.title'}/></h2>
                            ) : role === 'bsa' ?
                            (
                                <h2 className="text-xl"><FormattedMessage id={'login.bsa.title'}/></h2>
                            )  : role === 'admin' ?
                            (
                                <h2 className="text-xl"><FormattedMessage id={'login.admin.title'}/></h2>
                            ) : ''}

                        { role === 'bsa' || role === 'admin' ? '' :
                        <p className="mt-2 mb-2 max-w-2xl text-body text-md text-center"><FormattedMessage id={'login.noaccount'}/>
                            </p>
                        }

                        <input id="email"
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
                            placeholder={formatMessage(intl, 'login.email', '', [])}
                            name="email" value={authStore.email} required
                            autoComplete="email"
                            autoFocus onChange={handleEmailChange}
                        />

                        <input id="password" type="password"
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('password') border-red-600 @enderror"
                            placeholder={formatMessage(intl, 'login.password', '', [])}
                            name="password"  value = {authStore.password} required
                            onChange={handlePasswordChange}
                        autoComplete="current-password"
                        />  

                        {/* {role === 'employer' ?
                            <p className={'py-4'}>If you just signed up, use "New Company" as your company.</p>
                            : ''
                        }                      */}

                        {/* <div className='row flex'>
                            <label for='newLogin' className='mr-4'>{role === 'employer' ? 'First time? Click Here.' : formatMessage(intl, 'login.individual', '', [])}</label>
                            <input type={'checkbox'} value={showCompany} name='newLogin'
                                onClick={() => showCompany === '' ? setDefaultCompany("NEW") : setDefaultCompany('')}/>
                        </div> */}
                        {role === 'employee' ?
                        <div className={'mb-4'}>
                            <a className={''} data-tip='Simply type in "Individual".' class='text-primary underline' style={{float: 'right', marginTop: '10px'}}>Not associated to a company?</a>
                            <ReactTooltip place="left" type="dark" effect="float"/>                        
                        </div>
                        :''
                        }

                        {role === 'bsa' || role === 'admin' ? '' : 

                        <Autocomplete
                            className={authStore.error && authStore.error === 'Company is required!' ? errorCss : normalCss}
                            name="company" 
                            noOptionsText={formatMessage(intl, 'login.no.options', '', [])}
                            value={name} 
                            autoComplete="company"
                            options={companies.map((option) => option.label)}
                            getOptionLabel={(option) => option}
                            onChange={(e) => {
                                setName(e.target.textContent);
                                }
                            }
                            onInputChange={(event, newInputValue) => {
                                getCompanies(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label={formatMessage(intl, 'login.company', '', [])} required={role === 'employee'}/>}
                        />
                        }

                        <input id="role" type="hidden" value={role} />

                        {authStore.error && <p className="text-red-500" >{authStore.error}</p>}
                        <button type="submit"
                                className="bg-primary px-10 py-2 m-2 w-full text-center text-white uppercase"
                                ><FormattedMessage id={'login.button'}/></button>

                        { role === 'bsa' || role === 'admin' || role === 'admin' ? '' :
                            <Link className="bg-primary px-10 py-2 m-2 w-full text-center text-white uppercase"  to="#" onClick={() => navigate(`/register/${role}`)}>
                                <FormattedMessage id={'login.signup.button'}/>
                            </Link>
                        }
                        

                        <Link className="uppercase text-primary font-bold text-sm mt-4"  to="#" onClick={() => navigate(`/forgotPassword/${role}`)}>
                            <FormattedMessage id={'login.forgot'}/>
                        </Link>
                        <p className="my-8 text-center"><FormattedMessage id={'login.agreement'}/></p>

                    </div>
                </div>
            </form>
            
        </div>
    );
});

export default Login;

