import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useRouteMatch} from "react-router-dom";
import { apiGet } from "../../utils";
import EmployeePolicyTable from "../../components/EmployeePolicyTable";
import { FormattedMessage } from "react-intl";

const EmployeePolicies = observer(function () {
  const {rootStore, rootStore: {currentUser}} = useContext(StoreContext);
  const [policies, setPolicies] = useState([]);

  const loadPolicies = async () => {
    const response = await apiGet(`/api/v1/acks/${currentUser.id}/get`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  };

  useEffect(() => {
    loadPolicies();
  }, []);

  return <div>
    <h3 className={"text-xl"}><FormattedMessage id={'acks.title'}/></h3>
    <br />
    <div>
      <EmployeePolicyTable policies={policies}/>
    </div>
  </div>;
});

export default EmployeePolicies;
