import React, { useEffect, useRef } from 'react';
import Chart from "chart.js";
import {formatMoney} from "../utils";
import BarChart from "./BarChart";

export default function BarChartSideLegend({data, legendTitle, legendClass, IsFixedLength, percent, formatValue = v => v, ...props}) {
  return (
    <div className={'md:flex flex-1 p-0 sm:p-2 table w-full print:flex'}>
      <div className={'text-center'}>
        <BarChart data={data} {...props} IsFixedLength={true} />
      </div>
      <div className="flex-1 flex-column xl:max-w-lg md:ml-4 p-2 print:">
          <h4 className="text-md border-b border-gray-400 pb-1 mb-4">{legendTitle} {percent}%</h4>
          <div>
            <ul>
              {data.datasets.map(({label, data, color}) =>
                <li key={label} className="text-black-50 text-sm flex my-2 items-center">
                  <span className={"w-3 h-3 rounded-lg inline-block mr-2"} style={{backgroundColor: color}}/>
                  <span className={'flex-1 mr-8'}>{label}</span>
                  <span>{formatValue(data.reduce((acc, value) => value + acc, 0))}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
    </div>
  );
}
