import React, {useEffect, useContext} from "react";

import { Atomic, Product } from "@atomicfi/transact-javascript"
import {observer} from "mobx-react";
import {apiPost} from "../../../utils";

import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import StoreContext from "../../../StoreContext";
import AlertProvider from "../../../AlertProvider";

const EmployerPayrollLink = observer(() => {
    const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
    const employer = rootStore.currentUser;
    useEffect(() => {
    }, []);
    const getToken = async () => {
        const response = await apiPost('/api/v1/atomic/create_link_token', {email: employer.email, companyId: rootStore.currentCompany.id }, rootStore.authStore);
        if (response && response.data && response.data.publicToken){
            return getAtomicTransact(response.data.publicToken);
        }

    }
    const getAtomicTransact = async (publicToken) => {
        const transact_events = [];

        return  Atomic.transact({
            config: {
                publicToken: publicToken,
                tasks: [{ product: "enroll" }],//the given Product values in lib are incorrect
                theme: {
                    brandColor: "#252369",
                 },
            },
            onInteraction: interaction => {
                transact_events.push(interaction);

            },
            onFinish:  data => {
                transact_events.push(data);
                const response = apiPost('/api/v1/atomic/log_transactions', {
                    email: employer.email,
                    transact_events: JSON.stringify(transact_events),
                    companyId: rootStore.currentCompany.id
                }, rootStore.authStore, ' Your data will be available in 24-48 hrs. Payroll Linked');



            },
            onClose: data => {
                transact_events.push(data);
                const response = apiPost('/api/v1/atomic/log_transactions', {
                    email: employer.email,
                    transact_events: JSON.stringify(transact_events),
                    companyId: rootStore.currentCompany.id
                }, rootStore.authStore);
            }

        });
    }


    return <div>
        <AlertProvider/>
        <div className={'md:p-8 p-4'}>

            <Link onClick={getToken} to="#"
                  className={`bg-primary px-6 py-3 mr-2 text-center text-white uppercase cursor-pointer`}>

                <FormattedMessage id={'link.payroll'}/>
            </Link>

        </div>
    </div>;
});

export default EmployerPayrollLink;
