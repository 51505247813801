import React from 'react';
import {Formik, getIn, Form, ErrorMessage} from 'formik';
import FieldGroup from "../components/FieldGroup";
import StateInput from "../components/StateInput";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import * as Yup from 'yup';
import {phoneNumberMask} from "../formikUtils";
import MaskedLabeledField from "../components/MaskedLabeledField";
import DateInput from '../components/DateInput';
import moment from 'moment';

const EmployerCompanyForm = ({doSubmit, errors, submitting}) => {
  return <Formik
    initialValues={{
      name: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },

      phone: '',
      taxId: '',
      fiscalYearStartMonth: '1',
    }}

    validationSchema={Yup.object().shape({
        name: Yup.string()
            .required('Company Name is Required'),
        address: Yup.object().shape({
            address1: Yup.string()
                .required("Address is required"),
            city: Yup.string()
                .required("City is required"),
            state:  Yup.string()
                .required("State is required"),
            zip:  Yup.string()
                .required("Zip Code is required"),
        }),
        phone:  Yup.string()
            .required("Phone is required"),
        taxId:  Yup.string()
            .test('len', 'Must be 9 characters', val => val.length === 9)
            .required("Tax Id is required")
    })}
    onSubmit={(values) => {
      doSubmit(values)
    }}
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <div>
          <LabeledField
            label={'Company Name'}
            name={'name'}
            required
          />
        </div>
        <div>
          <LabeledField
            label={'Address'}
            name={'address.address1'}
            required

            />
        </div>
        <div>
          <LabeledField
            label={'Address'}
            name={'address.address2'}
          />
        </div>
        <div className={"flex"}>
          <LabeledField
            className={"flex-1"}
            label={'City'}
            name={'address.city'}
            required
          />
          <LabeledField
            className={"mx-8"}
            label={'State'}
            name={'address.state'}
            component={StateInput}
            required
          />
          <LabeledField
            className={"min-w-24"}
            label={'Zip Code'}
            name={'address.zip'}
            required
          />
        </div>
        <div className={"flex"}>
          <MaskedLabeledField
            className={"flex-1"}
            label={'Phone'}
            name={'phone'}
            required
            mask={phoneNumberMask}
            value={values.phone}
            onChange={e => {
                setFieldValue(`phone`, e.target.value);
            }}
          />
          <LabeledField
            className={"flex-1 mx-8"}
            label={'Tax ID'}
            name={'taxId'}
            required
          />
          <LabeledField
            label={'Fiscal Year Start'}
            name={'fiscalYearStartMonth'}
            choices={[
              {label: 'January', value: 1},
              {label: 'February', value: 2},
              {label: 'March', value: 3},
              {label: 'April', value: 4},
              {label: 'May', value: 5},
              {label: 'June', value: 6},
              {label: 'July', value: 7},
              {label: 'August', value: 8},
              {label: 'September', value: 9},
              {label: 'October', value: 10},
              {label: 'November', value: 11},
              {label: 'December', value: 12},
            ]}
            placeholder={"Fiscal Year Start"}
            component={Select}
          />
        </div>
        <div className={"flex"}>
        <LabeledField
            label={'Tax ID Type'}
            name={'idType'}
            className={"mr-8"}
            choices={[
              {label: 'SSN', value: 'ssn'},
              {label: 'EIN', value: 'ein'},
              {label: 'ITIN', value: 'itin'}
            ]}
            placeholder={"Tax ID Type"}
            component={Select}
          />
          <LabeledField
            label={'Entity Type'}
            name={'entityType'}
            className={"mr-8"}
            choices={[
              {label: 'Sole Proprietorship', value: 'SoleProprietorship'},
              {label: 'Limit Liability Partnership', value: 'LimitLiabilityPartnership'},
              {label: 'Limit Liability C-Corp', value: 'LimitLiabilityC-Corp'},
              {label: 'Limit Liability S-Corp', value: 'LimitLiabilityS-Corp'},
              {label: 'Trust Estate', value: 'Trust_Estate'},
              {label: 'Partnership', value: 'Partnership'},
              {label: 'S-Corporation', value: 'S_Corporation'},
              {label: 'C-Corporation', value: 'C_Corporation'},
              {label: 'Corporate', value: 'Corporate'},
              {label: 'Unknown', value: 'Unknown'}
            ]}
            placeholder={"Entity Type"}
            component={Select}
          />
          <LabeledField
            label={'Formation Date'}
            name={'formationDate'}
            component={DateInput}
            className={"mr-8"}
            value={moment(values.formationDate).format("YYYY-MM-DD")}
            onChange={e => {
                setFieldValue(`formationDate`, e.target.value);
            }}
          />
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton >Save</PrimaryButton>}
        </FieldGroup>
      </FormErrors>
    </Form>}
  </Formik>;
};

export default EmployerCompanyForm;
