import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useParams, useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import PrimaryButton from "../../components/PrimaryButton";
import moment from "moment";
import DateInput from "../../components/DateInput";
import {formatMoney} from "../../utils";
import Dropzone from "react-dropzone";
import CheckboxInput from "../../components/CheckboxInput";
import TabButton from "../../components/TabButton";
import MatchTableTimeStamped from "../../components/MatchTableTimeStamped";
import papa from "papaparse";
import GoBack from '../GoBack';
import MatchTableTraditional from "../../components/MatchTableTraditional";
import AlertProvider from "../../AlertProvider";

const ReviewTables = observer(function ({payroll}) {
  const [reviewTab, setReviewTab] = useState('payroll');
  const sums = payroll && payroll.payrollReportItems ? payroll.payrollReportItems.reduce((sums, item) => {
//    ['baseWages', 'bonusWages', 'matchDue', 'baseContribution', 'bonusContribution', 'matchAmount'].forEach((key) => {
//      sums[key] = parseFloat(item[key]) + (sums[key] || 0);
//    });
    ['baseWages', 'matchDue', 'baseContribution', 'matchAmount'].forEach((key) => {
      sums[key] = parseFloat(item[key]) + (sums[key] || 0);
    });

    return sums;
  }, {}) : [];

  return <div>
    <div className={'mb-2'}>
      <TabButton selected={reviewTab === 'payroll'} onClick={() => setReviewTab('payroll')}>Payroll Overview</TabButton>
      <TabButton selected={reviewTab === 'match'} onClick={() => setReviewTab('match')}>Match Detail</TabButton>
    </div>
    {reviewTab === 'match' && payroll.matchType === 'TIMESTAMPED' ? <MatchTableTimeStamped payroll={payroll}/> : ''}
    {reviewTab === 'match' && payroll.matchType === 'TRADITIONAL' ? <MatchTableTraditional payroll={payroll}/> : ''}
    {reviewTab === 'payroll' && <table className={"w-full table-auto"}>
      <thead>
      <tr>
        <th className={'p-3'}>Name</th>
        <th className={'p-3'}>Employee ID</th>
        <th className={'p-3'}>EE Compensation</th>
        {/* <th className={'p-3'}>EE Bonus Compensation</th> */}
        <th className={'p-3'}>EE Contribution</th>
        {/* <th className={'p-3'}>EE Bonus Contribution</th> */}
        <th className={'p-3'}>ER Match Amount</th>
      </tr>
      </thead>
      <tbody>
      {payroll && payroll.payrollReportItems ? payroll.payrollReportItems.map(item =>
        <tr key={item.id}>
          <td className={"p-3 text-left"}>{item.name}</td>
          <td className={"p-3 text-left"}>{item.employeeId}</td>
          <td className={"p-3 text-left"}>{formatMoney(item.baseWages)}</td>
          {/* <td className={"p-3 text-left"}>{formatMoney(item.bonusWages)}</td> */}
          <td className={"p-3 text-left"}>{formatMoney(item.baseContribution)}</td>
          {/* <td className={"p-3 text-left"}>{formatMoney(item.bonusContribution)}</td> */}
          <td className={"p-3 text-left"}>{formatMoney(item.matchDue)}</td>
        </tr>
      ) : ''}
      <tr>
        <td className={"p-3 text-left"}/>
        <td className={"p-3 text-left"}/>
        <td className={"p-3 text-left font-bold"}>{formatMoney(sums.baseWages)}</td>
        {/* <td className={"p-3 text-left font-bold"}>{formatMoney(sums.bonusWages)}</td> */}
        <td className={"p-3 text-left font-bold"}>{formatMoney(sums.baseContribution)}</td>
        {/* <td className={"p-3 text-left font-bold"}>{formatMoney(sums.bonusContribution)}</td> */}
        <td className={"p-3 text-left font-bold"}>{formatMoney(sums.matchDue)}</td>
      </tr>
      </tbody>
    </table>}
  </div>
})
const EditPayroll = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const payroll = currentCompany.currentPayroll;
  const [payPeriodDate, setPayPeriodDate] = useState(payroll ? payroll.payPeriodDate : new Date());
  const [noMatch, setNoMatch] = useState(false);
  const {id} = useParams();
  const history = useHistory();

  const setPayroll = async (id) => {
    await currentCompany.setCurrentPayrollId(id);
    setPayPeriodDate(currentCompany.currentPayroll.payPeriodDate);
    setNoMatch(currentCompany.currentPayroll.noMatch);
  }

  useEffect(() => {
    setPayroll(id);
  }, [id]);

  if (rootStore.submitting) {
    return <Loading/>;
  }

  const storePayroll = async () => {
    await currentCompany.createPayroll({payPeriodDate: payPeriodDate, noMatch: noMatch})
    history.push(`payrolls/${currentCompany.currentPayroll.id}`);
  };

  const update = async () => {
    await currentCompany.updatePayroll(currentCompany.currentPayroll.id, {
      payPeriodDate: payPeriodDate,
      noMatch: noMatch
    });
    await setPayroll(currentCompany.currentPayroll.id);
  };

  const download = async () => {
    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(payroll.csv)], { type: "text/csv" }));
    a.setAttribute("download", `Payroll ${moment(payroll.payPeriodDate).format("YYYY-MM-DD")}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

      // currentCompany.downloadPayroll({id: payroll.id, payPeriodDate: payroll.payPeriodDate});
  }

  const downloadPercent = async () => {
    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(payroll.csvPercent)], { type: "text/csv" }));
    a.setAttribute("download", `Payroll ${moment(payroll.payPeriodDate).format("YYYY-MM-DD")}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

      // currentCompany.downloadPayroll({id: payroll.id, payPeriodDate: payroll.payPeriodDate});
  }
  
  const upload = async (file) => {
    currentCompany.uploadPayroll(file, payroll);
  }

  const finalize = async () => {
    currentCompany.finalizePayroll(payroll);
  }

  return <div>
    <AlertProvider />
    <GoBack />
    {id && payroll ?
      <h3 className={"text-xl"}>Payroll for {moment(payroll.payPeriodDate).utc().format('L')}</h3>
      : <h3 className={"text-xl"}>Process Payroll</h3>}
    <div className={"my-4 p-4 bg-subtle-blue "}>
      <p className={'font-bold mb-2'}>1. Select payroll pay period date</p>
      <div className={'flex flex-col md:flex-row items-start md:items-center'}>
        <div className={"mr-4 w-full md:w-48"}>
          { payPeriodDate ? <DateInput value={moment(payPeriodDate).utc().format('YYYY-MM-DD')}
                     onChange={(e) => setPayPeriodDate(e.target.value)}/>
          : <DateInput
          onChange={(e) => setPayPeriodDate(e.target.value)}/>}
        </div>
        <div className={"mr-4 py-3"}>
          <CheckboxInput id={'payroll-no-match'} field={{value: noMatch}}
                         onChange={(e) => setNoMatch(e.target.checked)}/>
          <label className={'pl-2 mt-2'} htmlFor={'payroll-no-match'}>Exempt this payroll from employer match</label>
        </div>
        <div>
          {currentCompany.currentPayroll && currentCompany.currentPayroll.id ? <PrimaryButton onClick={update}>Save</PrimaryButton>
          : <PrimaryButton onClick={storePayroll}>Save</PrimaryButton>
          }
        </div>
      </div>
    </div>
    <div className={'bg-subtle-blue p-4 mb-4'}>
      <p className={'font-bold'}>2. Upload payroll data</p>
      {id && payroll && 
      // <div onClick={
      //   () => download()
      // }
      //           className={'inline-block bg-primary px-6 py-3 mb-4 mr-4 text-center text-white uppercase cursor-pointer'}>
      //   Download Template (CSV)</div>
      <>
      <PrimaryButton onClick={download}
          className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
          Download Template (CSV)
      </PrimaryButton>
      {/* <br/> */}
      {/* <PrimaryButton onClick={downloadPercent}
          className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
          Download Template (CSV)
      </PrimaryButton> */}
      </>
        }
      {id && <div><Dropzone onDrop={(files) => upload(files[0])} accept={['.csv']}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <PrimaryButton type={'submit'}>Upload Payroll Data (CSV)</PrimaryButton>
          </div>
        )}
      </Dropzone></div>}
    </div>
    <div className={'mb-4 flex items-stretch'}>
      <div className={'bg-subtle-blue p-4 mr-4 flex-1'}>
        <p className={'font-bold'}>3. Review</p>
        Review match amount
      </div>
      <div className={'bg-subtle-blue p-4 flex-1'}>
        <h4 className={"text-lg font-bold"}>Pay Period Summary</h4>
        <p>Total Contributions: <span className={'font-bold'}>{formatMoney(payroll ? payroll.totalContributions : 0)}</span></p>
        <p>Total Match: <span className={'font-bold'}>{formatMoney(payroll ? payroll.totalMatch : 0)}</span></p>
      </div>
    </div>
    {id ?
    <>
    {currentCompany.currentPayroll && currentCompany.currentPayroll.messages ? 
            <ul>
            {currentCompany.currentPayroll.messages.map((index) =>
              <li className="text-blue-500 text-sm flex my-2 items-center">
                <span className={""} >{index}</span>
              </li>
            )}
          </ul> 
          : ''
             }
     <ReviewTables payroll={payroll}/>
     </>
    :''}
    <div className={'my-4 p-4 bg-subtle-blue'}>
      <p className={'font-bold'}>4. Finish<br/><br/>Clicking finish authorizes MatchSavings to auto draft contributions from designated company bank account(s).</p><br/>
      {id && <PrimaryButton onClick={async () => {
        await finalize();
        history.push('/approuter/'+ rootStore.currentUser.id +'/dashboard/payrolls');
      }}>Finish</PrimaryButton>}
    </div>
  </div>;
});

export default EditPayroll;
