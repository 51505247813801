import React from 'react';
import { Formik, Form, useField } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import MaskedLabeledField from "../components/MaskedLabeledField";
import * as Yup from 'yup';
import dayjs from "dayjs";
import DateInputRight from '../components/DateInputRight';
import { formatMessage } from '../utils';
import StateInput from '../components/StateInput';

const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="" {...field} {...props} />
          {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
          ) : null}
      </>
  );
};

const DataRequestInfoFields = ({setFieldValue,  values, adminOpen}) => <div>
    <>
    <div className={'flex mt-4 mb-4'}>
      
    {/* <label class="block mb-2 text-sm my-4 mr-2"></label> */}
    <h3 className={'text-lg my-2 mr-3'}>Date of Data Request</h3>
    <DateInputRight 
      name={'requestDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.requestDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`requestDate`, e.target.value);
      }}
    />

    {/* <LabeledField
      label={'Date of DataRequest'}
      name={'dataRequestDate'}
      component={DateInputRight}
      className={"mr-8 container"}
      value={dayjs(values.dataRequestDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`dataRequestDate`, e.target.value);
      }}
    /> */}
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2 mr-2'}>Type</h3>
      <LabeledField
        label={''}
        name={'requestType'}
        choices={[
          {label: 'Consent', value: 'CONSENT'},
          {label: 'Withdrawal', value: 'WITHDRAWAL'},
          {label: 'Access', value: 'ACCESS'},
          {label: 'Correct PII', value: 'CORRECT_PII'},
          {label: 'Erase PII', value: 'ERASE_PII'},
          {label: 'Restrict PII', value: 'RESTRICT_PII'},
          {label: 'PII Portability', value: 'PII_PORTABILITY'},
          {label: 'Object PII Processing', value: 'OBJECT_PII_PROCESSING'},
          {label: 'Automated Decisions', value: 'AUTOMATED_DECISIONING'},
          {label: 'Delete Account', value: 'DELETE'},
          {label: 'Other', value: 'OTHER'},
        ]}
        component={Select}
      />
    </div>  
    <div className={'flex justify-between ml-4'}>
      <h3 className={'text-lg my-2'}>Case No:</h3>
      <span className='mt-2 ml-2'><strong>{values.caseNumber}</strong></span>
    </div>
  </div>
  <div className={'flex'}>
    <div className={'card-div-column mt-2 mb-2'}>
      Company Name: <u><strong>{values.companyName}</strong></u>
    </div>
  </div>
  {values.userEmpty ?
  <div className={'flex'}>
    <div className={'card-div-column mt-2 mb-2'}>
      Last Name: <u><strong>{values.lastName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      First Name: <u><strong>{values.firstName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      SSN or EIN: <u><strong>{values.ssnOrEin}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      
    </div>
  </div>
  : 
  <>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Last Name:</h3>
    <LabeledField
      name={'lastName'}
      style={{width:"75%"}}
    />
    <h3 className={'text-lg my-2 mr-2'}>First Name:</h3>
    <LabeledField
      name={'firstName'}
      style={{width:"75%"}}
    />
    <h3 className={'text-lg my-2 mr-2'}>State:</h3>
    <LabeledField
            label={'State'}
            className={"mr-2 md:mr-2"}
            name={'residentState'}
            component={StateInput}
          />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>SSN or EIN:</h3>
    <MaskedLabeledField name={'ssnOrEin'} mask={maskedSsn} 
          placeholder={'___-__-____'} value={values.ssnOrEin} style={{width:"15%"}}
          className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
              setFieldValue(`ssnOrEin`, e.target.value);
          }}/>
  </div>
  </>
  }
  <h3 className={'text-lg my-2'}>PII Involved:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="piiInvolved"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.piiInvolved}
    />
  </div>
  <h3 className={'text-lg my-2'}>Request Details:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="requestDetails"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.requestDetails}
    />
  </div>
  <h3 className={'text-lg my-2'}>Reason:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="reason"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.reason}
    />
  </div>
  <div className={'flex mt-4 mb-4'}>
    <h3 className={'text-lg my-2 mr-3'}>Date of Acknowledgment</h3>
    <DateInputRight 
      name={'ackEmailDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.ackEmailDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`ackEmailDate`, e.target.value);
      }}
    />
    <h3 className={'text-lg my-2 mr-3'}>Date of Chief Compliance Office Notified</h3>
    <DateInputRight 
      name={'cooNotifiedDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.cooNotifiedDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`cooNotifiedDate`, e.target.value);
      }}
    />
  </div>
  <div className={'flex mt-4 mb-4'}>
    <h3 className={'text-lg my-2 mr-3'}>VP of Tech Notified</h3>
    <DateInputRight 
      name={'vpTechNotifiedDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.vpTechNotifiedDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`vpTechNotifiedDate`, e.target.value);
      }}
    />
  </div>
  <h3 className={'text-lg my-2'}>Document List:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="documentList"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.documentList}
    />
  </div>
  <h3 className={'text-lg my-2'}>Involved Legal Or Expert:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="involvedLegalOrExpert"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.involvedLegalOrExpert}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Conflict with MS Partners?</h3>
    <LabeledField
        name={'msConflict'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Sponsor Bank Escalation?</h3>
    <LabeledField
        name={'bankEscalation'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <div className={'flex mt-4 mb-4'}>
    <h3 className={'text-lg my-2 mr-3'}>Bank Escalation Date:</h3>
    <DateInputRight 
      name={'bankEscalationDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.bankEscalationDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`bankEscalationDate`, e.target.value);
      }}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Conflict Resolvable?</h3>
    <LabeledField
        name={'conflictResolvable'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <h3 className={'text-lg my-2'}>Compliance Officer and VP Discussion:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="complianceOfficerAndVpDiscussion"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.complianceOfficerAndVpDiscussion}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Approved?</h3>
    <LabeledField
        name={'approved'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Action Required?</h3>
    <LabeledField
        name={'actionOnAccountRequired'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <h3 className={'text-lg my-2'}>Actions Taken:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="actionsTaken"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.actionsTaken}
    />
  </div>
  <div className={'flex mt-4 mb-4'}>
    <h3 className={'text-lg my-2 mr-3'}>Action Completion Date:</h3>
    <DateInputRight 
      name={'actionCompletionDate'}
      className={"mr-8 container max-w-xxs"}
      value={dayjs(values.actionCompletionDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`actionCompletionDate`, e.target.value);
      }}
    />
  </div>

  <input value={values.accountHolderId} name={'accountHolderId'} type={'hidden'}/>
  <input value={values.companyId} name={'companyId'} type={'hidden'}/>
  {/* <input value={values.ssnOrEin} name={'ssnOrEin'} type={'hidden'}/> */}
  <input value={values.caseNumber} name={'caseNumber'} type={'hidden'}/>
  <input value={values.id} name={'id'} type={'hidden'}/>
  </>
</div>;

const DataRequestInfoForm = ({doSubmit, errors, submitting, dataRequest, adminOpen, company, user, closeShowForm}) => {

  // const dataRequestDate = dataRequest.formationDate ? dayjs(dataRequest.dataRequestDate) : dayjs();
  // const escalationDate = dataRequest.escalationDate ? dayjs(dataRequest.escalationDate) : dayjs();
  if (dataRequest) {
    dataRequest = {...dataRequest};
    // dataRequest.dataRequestDate = dataRequestDate.format("YYYY-MM-DD");
    // dataRequest.escalationDate = escalationDate.format("YYYY-MM-DD");
  }

  let ssnOrEin = dataRequest ? dataRequest.ssnOrEin : null;
  if (ssnOrEin === undefined) {
    ssnOrEin = user ? user.ssn : null;
  }
  if (ssnOrEin === undefined) {
    ssnOrEin = company ? company.ein : null;
  }

  return <Formik
    initialValues={
      dataRequest.id ? {
        companyName: dataRequest ? dataRequest.companyName : company ? company.name : '',
        id: company ? dataRequest.id : null,
        requestDate: dayjs(dataRequest.requestDate).format("YYYY-MM-DD"),
        requestType: dataRequest && dataRequest.requestType? dataRequest.requestType : 'CONSENT',
        accountHolderId: user ? user.id : null,
        companyId: dataRequest ? dataRequest.companyId : company ? company.id : null,
        userEmpty: (user && user.user) || !dataRequest.id ? true : false,
        firstName: dataRequest ? dataRequest.firstName : user && user.user ? user.user.firstName : "",
        lastName: dataRequest ? dataRequest.lastName : user && user.user ? user.user.lastName : "",
        ssnOrEin: ssnOrEin,
        residentState: dataRequest ? dataRequest.residentState : "",
        piiInvolved: dataRequest ? dataRequest.piiInvolved : "",
        requestDetails: dataRequest ? dataRequest.requestDetails : "",
        reason: dataRequest ? dataRequest.reason : "",
        ackEmailDate: dayjs(dataRequest.ackEmailDate).format("YYYY-MM-DD"),
        cooNotifiedDate: dayjs(dataRequest.cooNotifiedDate).format("YYYY-MM-DD"),
        vpTechNotifiedDate: dayjs(dataRequest.vpTechNotifiedDate).format("YYYY-MM-DD"),
        documentList: dataRequest ? dataRequest.documentList : "",
        involvedLegalOrExpert: dataRequest ? dataRequest.involvedLegalOrExpert : "",
        msConflict: dataRequest ? dataRequest.msConflict : false,
        bankEscalation: dataRequest ? dataRequest.bankEscalation : false,
        bankEscalationDate: dayjs(dataRequest.bankEscalationDate).format("YYYY-MM-DD"),
        conflictResolvable: dataRequest ? dataRequest.conflictResolvable : false,
        complianceOfficerAndVpDiscussion: dataRequest ? dataRequest.complianceOfficerAndVpDiscussion : "",
        approved: dataRequest ? dataRequest.approved : false,
        actionOnAccountRequired: dataRequest ? dataRequest.actionOnAccountRequired : false,
        actionsTaken: dataRequest ? dataRequest.actionsTaken : "",
        actionCompletionDate: dayjs(dataRequest.actionCompletionDate).format("YYYY-MM-DD"),
        caseNumber: dataRequest ? dataRequest.caseNumber : null,
      } : {
        companyName: '',
        id: null,
        requestDate: dayjs(dataRequest.requestDate).format("YYYY-MM-DD"),
        requestType: 'CONSENT',
        accountHolderId: user ? user.id : null,
        companyId: company ? company.id : null,
        userEmpty: (user && user.user) ? true : false,
        firstName: user && user.user ? user.user.firstName : "",
        lastName: user && user.user ? user.user.lastName : "",
        ssnOrEin: ssnOrEin,
        residentState: "",
        piiInvolved: "",
        requestDetails: "",
        reason: "",
        ackEmailDate: null,
        cooNotifiedDate: null,
        vpTechNotifiedDate: null,
        documentList: "",
        involvedLegalOrExpert: "",
        msConflict: false,
        bankEscalation: false,
        bankEscalationDate: null,
        conflictResolvable: false,
        complianceOfficerAndVpDiscussion: "",
        approved: false,
        actionOnAccountRequired: false,
        actionsTaken: "",
        actionCompletionDate: null,
        caseNumber: null,

    }}
    onSubmit={(values) => {
      values.requestDate = values.requestDate ? `${values.requestDate}T12:00:00.000000Z` : null;
      values.ackEmailDate = values.ackEmailDate ? `${values.ackEmailDate}T12:00:00.000000Z` : null;
      values.cooNotifiedDate = values.cooNotifiedDate ? `${values.cooNotifiedDate}T12:00:00.000000Z` : null;
      values.vpTechNotifiedDate = values.vpTechNotifiedDate ? `${values.vpTechNotifiedDate}T12:00:00.000000Z` : null;
      values.bankEscalationDate = values.bankEscalationDate ? `${values.bankEscalationDate}T12:00:00.000000Z` : null;
      values.actionCompletionDate = values.actionCompletionDate ? `${values.actionCompletionDate}T12:00:00.000000Z` : null;
      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <DataRequestInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Save</PrimaryButton><PrimaryButton onClick={closeShowForm}>Cancel</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default DataRequestInfoForm;
