import React, {Component} from "react";
import { FormattedMessage} from 'react-intl';
import { Link, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

export default function Footer() {
  let {  url } = useRouteMatch();

  return <div className={"p-12 bg-subtle-blue hide-print"}>
    <div className={"text-2xl text-gray-500 font-black"}>{"MatchSavings"}</div>
    <div className={"py-8"}>
      <a className={"text-sm uppercase mr-4"} href="https://matchsavings.com/about" target="_blank" rel="noreferrer"><FormattedMessage id={'about'}/></a>
      <a className={"text-sm uppercase mr-4"} href="https://matchsavings.com/legal" target="_blank" rel="noreferrer"><FormattedMessage id={'terms'}/></a>
      <a className={"text-sm uppercase mr-4"} href="https://matchsavings.com/legal" target="_blank" rel="noreferrer"><FormattedMessage id={'privacy'}/></a>
      <a className={"text-sm uppercase mr-4"} href="https://matchsavings.com/legal" target="_blank" rel="noreferrer"><FormattedMessage id={'faq'}/></a>
      <Link to={`${url}/support`} className={'text-sm uppercase mr-4'}>
            <FormattedMessage id={'contact'}/>
          </Link>
      <a className={"text-sm uppercase mr-4"} 
        href="https://uploads-ssl.webflow.com/605b705d7c6cb31adad64603/6691727a025186a8cb603561_Data%20Subject%20Request%20Form.docx" target="_blank" rel="noreferrer">
          <FormattedMessage id={'data.request.form'}/>
      </a>
    </div>
    <div className={"text-xs"}><FormattedMessage id={'copyright'}/></div>
  </div>;
}
