import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import StoreContext from "../../StoreContext";
import EmployeeTable from "../../components/EmployeeTable";
import Pagination from "../../components/Pagination";
import LinkButton from "../../components/LinkButton";
import Dropzone from "react-dropzone";
import PrimaryButton from "../../components/PrimaryButton";
import PlanRequirementModal from '../../components/PlanRequirementModal';
import papa from "papaparse";
import { useRouteMatch} from 'react-router-dom';
import AlertProvider from "../../AlertProvider";
import Loading from "../../components/Loading";
import ReactTooltip from 'react-tooltip';
import Input from "../../components/Input";

const EmployeeList = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [page, setPage] = useState(1);
  const [planRequiredModal, showPlanRequiredModal] = useState(false);
  let {url} = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [employeePages, setEmployeePages] = useState(undefined);
  const [name, setName] = useState('');
  const [originalPages, setOriginalPages] = useState();

  useEffect(() => {
    loadPage();
  }, [page, name]);

  const loadPage = async () => {
    console.log('called ' + name);
    await currentCompany.loadEmployees(page, name);
    setEmployeePages(currentCompany.employeePages);
    if (!originalPages) {
      setOriginalPages(currentCompany.employeePageCount);
    }
    await currentCompany.loadCompanyPlans(currentCompany.id);
    setLoading(false);
  }

  function canProceed(event) {
    if (!currentCompany.companyPlans.length) {
      showPlanRequiredModal(true);

      event &&  event.preventDefault();

      return false;
    }

    return true;
  }

  const downloadTemplate = async () => {setLoading(true);
    const csv = [{"first_name": '', "last_name": '', "email": '', "hire_date (01/01/2000)": '', "ssn (XXX-XX-XXXX)": '', "dob (01/01/2000)": '', "IMPORTANT": 'Please leave these columns in this order when submitting.'}];

    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([papa.unparse(csv)], { type: "text/csv" }));
    a.setAttribute("download", `employee_template.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setLoading(false);
  }

  const updateNameSearch = async (value) => {
    setName(value);
  }

  return <div>
    <AlertProvider/>
    <div className={"flex flex-col xl:flex-row"}>
    <h3 className={"text-xl flex-1"}>Employees</h3>
    <div className={"flex flex-col sm:flex-row py-4"}>
      
      {planRequiredModal && <PlanRequirementModal wantsToClose={() => showPlanRequiredModal(false)}/>}
      <PrimaryButton onClick={downloadTemplate}
         className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
        Download CSV Template
      </PrimaryButton>
      <div className="sm:mx-2 mb-3"><Dropzone onDrop={async (files) => {
        	setLoading(true);
        if (!canProceed()) {
          return;
        }

        await currentCompany.uploadEmployeeData(files[0]);

        loadPage();
      }} accept={['.csv']}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input {...getInputProps({
              onClick: event => canProceed(event)
            })} />
            <PrimaryButton type={'submit'} className={'w-full'} >
              Upload Employee CSV Data
            </PrimaryButton>
          </div>
        )}
      </Dropzone></div>
      <LinkButton className={'mb-3'} to={`create-employee`} onClick={event => canProceed(event)}>
        New Employee
      </LinkButton>
    </div>
    <div className={'md:mx-4 mb-2'}>
        {/* <label>First or Last Name</label> */}
        {originalPages && originalPages > 1 ?
        <Input
          onChange={(e) => {
            updateNameSearch(e.target.value)//, setPage(1)
          }}
          value={name}
          placeholder={"Search Employee By First or Last Name (use 3 or more characters)"}
        />
        : ''}
      </div>
    {/* <div className={'mb-4'}>
          <a className={''} data-tip="Visit the Employees page and add an Employee account using a personal email." class='text-primary underline' style={{float: 'right', marginTop: '10px'}}>Don't see yourself in the list?</a>
          <ReactTooltip place="left" type="dark" effect="float"/>
          
        </div> */}
    {loading ?
    <Loading/>
    :
    employeePages ? <EmployeeTable employees={employeePages.get(page)}/> : ''
    }
    <Pagination
      page={page}
      totalPages={currentCompany.employeePageCount}
      setPage={setPage}
    />
    </div>
  </div>
    ;
});

export default EmployeeList;
