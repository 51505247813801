import React, {useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import Loading from "./Loading";
import LinkButton from "./LinkButton";

export default function UserTable({users}) {
  let {  url } = useRouteMatch();
  if (!users) {
    return <Loading/>;
  }

  const showName = (code) => {
    if ('CSA' === code) {
      return "CUSTOMER SERVICE REP";
    }
    if ('BSA' === code) {
      return "BANK ANALYST";
    }
    return code;
  }

  return <div>
    <table className={'w-full table-auto'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 py-3"}>Name</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>Email</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>Role</th>
        {/* <th className={"text-left text-sm text-gray-400 py-3"}>SSN</th> */}
        <th></th>
      </tr>
      </thead>
      <tbody>
      {users.map((user) => <tr key={user.id}>
        <td className={`text-left text-sm p-3 ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.firstName} {user.lastName}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.email}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{showName(user.role)}</td>
        {/* <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.ssn}</td> */}
        <td className={"text-right text-sm p-3"}>
          <LinkButton to={`${url}/${user.id}`}>Edit</LinkButton>
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
