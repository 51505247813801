
import React from 'react';
import MatchSavingsLogo from "../images/match-savings-logo-full-color.png";
import { Redirect, useHistory } from 'react-router-dom';

import { Link } from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import RouterLink from '../components/RouterLink';

export default function Home({locales, locale, changeLanguage, fmt}) {
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const reset = params.get('reset');
    const loggedout = params.get('loggedout');
    const inactive = params.get('inactive');

    if (reset) {
        return <Redirect to={`/reset?reset=${reset}`}/>
    }

    const navigateEmployee = () => {
        history.push("/login/employee");
    }

    const navigateEmployer = () => {
        history.push("/login/employer");
    }

    const navigateBsa = () => {
        history.push("/login/bsa");
    }

    const navigateAdmin = () => {
        history.push("/login/admin");
    }

    return (
      <div id="welcome" className="bg-full-splash h-screen bg-cover flex flex-col justify-between items-center overflow-auto">
          <div className='row mx-auto'>

            {/* <label for="locale" className='block mb-3 text-sm '>{fmt({id: 'selectalanguage'})}</label> */}
            <select
                name="locale"
                defaultValue={locale}
                id="locale"
                onChange={(event) => changeLanguage(event.target.value)}
                className={'float-right border border-mid-grey p-3'}
            >
                {Object.keys(locales).map(locale => (
                <option key={locale} value={locale}>
                    {fmt({id: `${locale}`})}
                </option>
                ))}
            </select>
        <div className="bg-white px-4 md:px-16 pt-12 pb-16 rounded-sm shadow-sm mt-32 mx-4 lg:mx-0">
            <h1 className="text-4xl mb-8 text-center text-primary font-bold">
                <img className="mx-auto max-w-100" src={MatchSavingsLogo}/>
            </h1>
            <div className='my-2 max-w-2xl text-lg text-center ml-auto mr-auto text-red-500'>{loggedout === 'true' ? 'We\'re sorry, you were logged out due to an error.' : ''}</div>
            <div className='my-2 max-w-2xl text-lg text-center ml-auto mr-auto text-red-500'>{inactive === 'true' ? 'You were logged out due to inactivity.' : ''}</div>
            <p className="my-2 max-w-2xl text-body text-lg text-center ml-auto mr-auto"><FormattedMessage id={'home.paragraph1'}/></p>
            <p className="my-2 max-w-2xl text-body text-lg text-center ml-auto mr-auto"><FormattedMessage id={'home.paragraph2'}/></p>
            <p className="mt-2 max-w-2xl text-body text-lg text-center ml-auto mr-auto"><FormattedMessage id={'home.paragraph3'}/>
            <br/><FormattedMessage id={'home.paragraph4'}/></p>
            <p className="mt-8 mb-10 max-w-2xl text-body text-lg text-center ml-auto mr-auto"><FormattedMessage id={'home.paragraph5'}/></p>
            <div className="flex justify-between content-center flex-col lg:flex-row">
                <div className='m-2 lg:mr-8'>
                    <RouterLink onClick={navigateEmployee} to="#"
                   className="mx-auto block custom-width bg-primary text-white text-xl bg-employee-icon bg-no-repeat bg-left-2 pr-12 pl-20 p-9 uppercase hover:bg-primary-dark">
                    <FormattedMessage id={'employee.button'}/>
                </RouterLink>
                </div>
                <div className='m-2' >
                <RouterLink onClick={navigateEmployer} to="#"
                   className="mx-auto custom-width block bg-primary text-white text-xl bg-employer-icon bg-no-repeat bg-left-2 pr-12 pl-20 p-9 uppercase hover:bg-primary-dark">
                    <FormattedMessage id={'employer.button'}/>
                </RouterLink>
                </div>
            </div>
            <p className="ml-2 mt-8 mb-5 max-w-2xl text-body text-md mr-auto"><FormattedMessage id={'home.paragraph6'}/></p>
            <div className="flex content-center flex-col lg:flex-row ">
                <div className='m-2 lg:mr-8'>
                    <Link onClick={navigateBsa} to="#"
                   className="cursor-pointer">
                    <FormattedMessage id={'bsa.button'}/>
                </Link>
                </div>
                <div className='m-2'>
                    <Link onClick={navigateAdmin} to="#"
                   className="cursor-pointer">
                    Admin
                </Link>
                </div>
            </div> 
        </div>
        <p className="text-white opacity-50 text-xs mb-10 mx-4 lg:mx-0"><FormattedMessage id={'home.copyright'}/></p>
        </div>
    </div>
);
}

