import React, {Component,useContext,useState} from "react";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/SidebarItem";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import AdminCompanies from "../pages/admin/AdminCompanies";
import AdminUserDetail from "../pages/admin/AdminUserDetail";
import AdminUsers from "../pages/admin/AdminUsers";
import ErrorBoundary from "../components/ErrorBoundary";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Dashboard from "../pages/employer/Dashboard";
import AdminEmployees from "../pages/admin/AdminEmployees";
import EmployeeDashboard from "../pages/employee/Dashboard"
import KycLog from "../pages/admin/KycLog";
import { Invoice } from "../pages/admin/Invoice";
import { Fees } from "../pages/admin/Fees";
import AppLogout from "../components/AppLogout";
import LogoutWarningDialog from "../components/LogoutWarningDialog";
import { Guides } from "../pages/admin/Guides";
import { Complaint } from "../pages/admin/Complaint";
import ComplaintReport from "../pages/admin/ComplaintReport";
import { UnusualActivity } from "../pages/admin/UnusualActivity";
import StoreContext from "../StoreContext";
import ACHFileLog from "../pages/admin/ACHFileLog";
import { InboundClearing } from "../pages/admin/InboundClearing";
import { OutboundClearing } from "../pages/admin/OutboundClearing";
import { EmailLog } from "../pages/admin/EmailLog";
import ChangePassword from "../pages/employee/settings/ChangePassword";
import { EmailLogReport } from "../pages/admin/EmailLogReport";
import Policies from "../pages/admin/Policies";
import PolicyDetail from "../pages/admin/PolicyDetail";
import DataRequestReport from "../pages/admin/DataRequestReport";

export default function AdminLayout({children, user, locales, locale, changeLanguage, fmt}) {
    const [isOpen, setIsOpen] = useState(false);
    const {rootStore} = useContext(StoreContext);
    const adminOpen = rootStore.isAdmin;

    const onChange = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0,0);
    }
    let {path, url} = useRouteMatch();
    return <AppLogout>
    <LogoutWarningDialog />
    <div className={'container max-w-7.5xl mx-auto px-4'}>
        <Header locale={locale} 
            locales={locales} changeLanguage={changeLanguage} fmt={fmt}>{user.full_name}</Header>
        <div className={"flex flex-row max-w-7.5xl"}>
            <Sidebar isOpen={isOpen} onChange={onChange}>
                <SidebarItem onChange={onChange} to={`${url}/companies`}>Companies</SidebarItem>
                <SidebarItem onChange={onChange} to={`${url}/employees`}>Employees</SidebarItem>
                {/* <SidebarItem onChange={onChange} to={`${url}/kyc`}>Kyc/Kyb Log</SidebarItem> */}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/invoices`}>Invoices</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/fees`}>Fees</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/guides`}>Guides</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/ach`}>ACH files</SidebarItem>
                : ''}
                <SidebarItem onChange={onChange} to={`${url}/kyc`}>Kyc/Kyb</SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/complaints/legal`} root={`${url}/complaints`}>Complaints</SidebarItem> 
                <SidebarItem onChange={onChange} to={`${url}/uar`}>UAR</SidebarItem>
                <SidebarItem onChange={onChange} to={`${url}/policies`}>Document Acknowledgments</SidebarItem>
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/users`}>Admin Users</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/inbound`}>Inbound Clearing Account</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/outbound`}>Outstanding Checks</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/email`}>Email</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/eLog`}>Email Log</SidebarItem>
                : ''}
                {adminOpen === true ?
                <SidebarItem onChange={onChange} to={`${url}/data-requests/not-delete`} root={`${url}/data-requests`}>Data Request</SidebarItem>
                : ''}
            </Sidebar>
            <div className={'flex-1'}>
                <div className={"p-8 pr-0"}>
                    <div className={"min-h-threequarters"}>
                        <Switch>
                            <Route path={`${path}/companies`}>
                                <ErrorBoundary key ={"adminCompanies"}>
                                    <AdminCompanies/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/password`}>
                                <ErrorBoundary key ={"changePassword"}>
                                    <ChangePassword adminOpen={false}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/employees`}>
                                <ErrorBoundary key ={"adminEmployees"}>
                                    <AdminEmployees/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={`${path}/companies/companydashboard`}>
                                <ErrorBoundary key ={"companydashboard"}>
                                     <Dashboard/>
                                </ErrorBoundary>
                            </Route>
                            <Route exact path={`${path}/employees/employeeDashboard`}>
                                <ErrorBoundary key ={"employeeDashboard"}>
                                     <EmployeeDashboard/>
                                </ErrorBoundary>
                            </Route>
                            {adminOpen === true ?
                            <Route path={`${path}/users/:id`}>
                                <ErrorBoundary key ={"adminUserDetail"}>
                                    <AdminUserDetail/>
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            <Route path={`${path}/policies/:id`}>
                                <ErrorBoundary key ={"policyDetail"}>
                                    <PolicyDetail/>
                                </ErrorBoundary>
                            </Route>
                            {adminOpen === true ?
                            <Route path={`${path}/inbound`}>
                                <ErrorBoundary key ={"inbound"}>
                                    <InboundClearing/>
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            {adminOpen === true ?
                            <Route path={`${path}/outbound`}>
                                <ErrorBoundary key ={"outbound"}>
                                    <OutboundClearing/>
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            {adminOpen === true ?
                            <Route path={`${path}/email`}>
                                <ErrorBoundary key ={"email"}>
                                    <EmailLog />
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            {adminOpen === true ?
                            <Route path={`${path}/eLog`}>
                                <ErrorBoundary key ={"eLog"}>
                                    <EmailLogReport />
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            <Route path={`${path}/create-user`}>
                                <ErrorBoundary key ={"createUser"}>
                                    <AdminUserDetail/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/create-policy`}>
                                <ErrorBoundary key ={"createPolicy"}>
                                    <PolicyDetail/>
                                </ErrorBoundary>
                            </Route>
                            {adminOpen === true ?
                            <Route path={`${path}/users`}>
                                <ErrorBoundary key ={"adminaUsers"}>
                                    <AdminUsers/>
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            <Route path={`${path}/policies`}>
                                <ErrorBoundary key ={"policies"}>
                                    <Policies/>
                                </ErrorBoundary>
                            </Route>
                            {adminOpen === true ? 
                                <Route path={`${path}/ach`}>
                                <ErrorBoundary key ={"ach"}>
                                    <ACHFileLog />
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            <Route path={`${path}/kyc`}>
                                <ErrorBoundary key ={"kyc"}>
                                    <KycLog/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/invoices`}>
                                <ErrorBoundary key ={"invoices"}>
                                    <Invoice/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/fees`}>
                                <ErrorBoundary key ={"fees"}>
                                    <Fees/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/guides`}>
                                <ErrorBoundary key ={"guides"}>
                                    <Guides/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/complaints`}>
                                <ComplaintReport />
                            </Route>
                            <Route path={`${path}/data-requests`}>
                                <DataRequestReport />
                            </Route>
                            <Route path={`${path}/uar`}>
                                <ErrorBoundary key ={"uar"}>
                                    <UnusualActivity/>
                                </ErrorBoundary>
                            </Route>
                            <Route>
                                <Redirect to={`${path}/employees`}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
    </AppLogout>
}
