import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Input, InputLabel, Link, MenuItem, Select } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";
import ComplaintSheet from "../employer/settings/ComplaintSheet";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import moment from "moment";
import { FormattedMessage } from "react-intl";

export const EmailLogReport = (props) => {

    const {authStore, rootStore} = useContext(StoreContext);
    const [invoices, setInvoices] = useState([]);
    // const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    // const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    // const [endDate, setEndDate] = useState(endDateDay);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // const [accountHolderName, setAccountHolderName] = useState('');
    // const [companyName, setCompanyName] = useState('');
    // const [subject, setSubject] = useState('');
    // const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        setLoading(true)
        getInvoices();
    }, [page]);

    const typeToString =  (val) => {

        let stringVal = '';

        switch (val) {
            case 'NEWS':
                val = 'News';
                break;
            case 'TERMS_UPDATE':
                val = 'Terms Update';
                break;
            case 'OUTAGE':
                val = 'Outage';
                break;
            case 'COMPANY_COMMUNICATION':
                val = 'Company Communication';
                break;
            case 'OTHER':
                val = 'Other';
                break;
            default:
                break;
        }

        return val;
    }

    const getInvoices = async () => {
        // let url = `/api/v1/email/search?startDate=${dayjs(startDate).format("YYYY-MM-DD")}&endDate=${dayjs(endDate).format("YYYY-MM-DD")}`;
        // url = url + `&accountHolderName=${accountHolderName}&companyName=${companyName}`;
        // url = url + `&body=${body}&subject=${subject}`;
        // url = url + `&pageNumber=${page}&pageSize=${pageSize}`;
        let url = `/api/v1/email/${rootStore.currentUser.id}/byAccountHolder`;
        url = url + `?pageNumber=${page}&pageSize=${pageSize}`;
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setInvoices(resp.content);
        setTotalPages(resp.totalPages);
        setLoading(false);
    }
   
    // const handleStartDateChange = (date) => {
    //     if (date) {
    //         setStartDate(date);
    //     }
    // };

    // const handleEndDateChange = (date) => {
    //     if (date) {
    //         setEndDate(date);
    //     }
    // };
    
    return (
        <>
        {/* <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                <InputLabel style={{marginTop: "15px"}} id="selectPage">Page Size</InputLabel>
                <Select
                    labelId="selectPage"
                    id="selectPageSize"
                    value={pageSize}
                    label="Page Size"
                    onChange={(e) => {
                        setPageSize(e.target.value)
                    }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                </Select>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <Input 
                        label="Account Holder Name"
                        placeholder="Enter Name"
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item>
                    <Input 
                        label="Company Name"
                        placeholder="Enter Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item>
                    <Input 
                        label="Subject"
                        placeholder="Enter subject"
                        value={subject}
                        onChange={(e) => setSubject(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item>
                    <Input 
                        label="Body"
                        placeholder="Enter body"
                        value={body}
                        onChange={(e) => setBody(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={getInvoices} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid> */}

        <div className="container max-w-6xl mx-auto px-4">
        {!loading && invoices ? 
        <div className={"overflow-auto w-screenn table-cuswidth md:w-full"}>
            <h1 className="mb-5 text-xl"><FormattedMessage id={'message.center.title'}/></h1>
            <h1 className="mb-5"><FormattedMessage id={'message.center.info'}/></h1>
            <table className={"w-full table-auto whitespace-nowrap"}>
            <thead>
            <tr>
            {/* <th className={"text-left text-sm text-gray-400 py-3 px-2 text-normal"}>Name</th> */}
            <th className={"text-left text-sm text-gray-400 py-3 px-2 text-normal"}><FormattedMessage id={'message.center.sent'}/></th>
            <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}><FormattedMessage id={'message.center.company'}/></th>
            <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}><FormattedMessage id={'message.center.type'}/></th>
            <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}><FormattedMessage id={'message.center.subject'}/></th>
            <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}><FormattedMessage id={'message.center.body'}/></th>
            </tr>
            </thead>
            <tbody>
            {invoices ? invoices.map(transaction => {
            return <tr key={transaction.id}>
                {/* <td className={"text-left text-sm px-2 py-3"}>{transaction.accountHolderName}</td> */}
                <td className={"text-left text-sm px-2 py-3"}>{moment(transaction.created).utc().format('L')}</td>
                <td className={"text-right text-sm px-2 py-3"}>{transaction.companyName}</td>
                <td className={"text-right text-sm px-2 py-3"}>{typeToString(transaction.type)}</td>
                <td className={"text-right text-sm px-2 py-3"}>{transaction.subject}</td>
                <td className={"text-right text-sm px-2 py-3"}>{transaction.body}</td>
            </tr>
            }) : ''}
            </tbody>
        </table>
        </div> : <Loading /> }
        <Pagination
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            />
        </div>
        </>
        
    );
}