import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import StoreContext from "../../StoreContext";
import OwnerTable from "../../components/OwnerTable";
import Pagination from "../../components/Pagination";
import LinkButton from "../../components/LinkButton";
import { useRouteMatch} from 'react-router-dom';
import AlertProvider from "../../AlertProvider";
import Loading from "../../components/Loading";
import ReactTooltip from 'react-tooltip';

const OwnerList = observer(function ({loadStep}) {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [page, setPage] = useState(1);
  let {url} = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [ownerPages, setOwnerPages] = useState(undefined);
  useEffect(() => {
    loadPage();
    loadStep();
  }, [page]);

  const loadPage = async () => {
    await currentCompany.loadOwners(page);
    setOwnerPages(currentCompany.ownerPages);
    setLoading(false);
  }

  function canProceed(event) {
    // if (!currentCompany.companyPlans.length) {
    //   showPlanRequiredModal(true);

    //   event &&  event.preventDefault();

    //   return false;
    // }

    return true;
  }
  
  return <div>
    <AlertProvider/>
    <div className={"flex flex-col xl:flex-row"}>
    <h3 className={"text-xl flex-1"}>Owners</h3>
    <div className={"flex flex-col sm:flex-row py-4"}>
      
      <LinkButton className={'mb-3'} to={`createOwner`} onClick={event => canProceed(event)}>
        Add Owner
      </LinkButton>
    </div>

    {loading ?
    <Loading/>
    :
    ownerPages ? <OwnerTable owners={ownerPages.get(page)}/> : ''
    }
    <Pagination
      page={page}
      totalPages={currentCompany.ownerPageCount}
      setPage={setPage}
    />
    </div>
  </div>
    ;
});

export default OwnerList;
