import React from 'react';
import { Formik, Field, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import StateInput from "../components/StateInput";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import ImageUpload from '../components/ImageUpload';
import MaskedLabeledField from "../components/MaskedLabeledField";
import {phoneNumberMask} from "../formikUtils";
import * as Yup from 'yup';
import DateInput from '../components/DateInput';
import moment from 'moment';

const CompanyInfoFields = ({setFieldValue,  values, adminOpen}) => <div>
  {adminOpen ?
    <>Waive Fee Date
    <div className={'flex'}>
      
    {/* <LabeledField
      label={'Beginning Date (Must be first of the month.)'}
      name={'waiveDateStart'}
      component={DateInput}
      className={"mr-8"}
      value={moment(values.waiveDateStart).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`waiveDateStart`, e.target.value);
      }}
    />
    
    <LabeledField
      label={'End Date (Must be first of the month.)'}
      name={'waiveDateEnd'}
      component={DateInput}
      className={"mr-8"}
      value={moment(values.waiveDateEnd).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`waiveDateEnd`, e.target.value);
      }}
    /> */}
    
  </div>
  </>  
    : ''}
  <div className={''}>
    <LabeledField
      label={'Company Name'}
      name={'name'}
      required
    />
  </div>
  <div className={''}>
    <LabeledField
      label={'Address 1'}
      name={'address.address1'}
      required
    />
  </div>
  <div className={''}>
    <LabeledField
      label={'Address 2'}
      name={'address.address2'}
    />
  </div>
  <div className={"flex "}>
    <LabeledField
      className={"flex-1"}
      label={'City'}
      name={'address.city'}
      required
    />
    <LabeledField
      className={'mx-8'}
      label={'State'}
      name={'address.state'}
      component={StateInput}
      required
    />
    <LabeledField
      className={"min-w-24  mr-8"}
      label={'Zip Code'}
      name={'address.zip'}
      required
    />
    <MaskedLabeledField
      className={"flex-1"}
      label={'Phone'}
      name={'phone'}
      required
      mask={phoneNumberMask}
      value={values.phone}
      onChange={e => {
          setFieldValue(`phone`, e.target.value);
      }}
    />
  </div>
  <div className={"flex "}>
    
    <LabeledField
      className={"flex-1 mr-8"}
      label={'Tax ID'}
      name={'taxId'}
      required
    />
    <LabeledField
      label={'Tax ID Type'}
      name={'idType'}
      className={"mr-8"}
      choices={[
        {label: 'SSN', value: 'ssn'},
        {label: 'EIN', value: 'ein'},
        {label: 'ITIN', value: 'itin'},
        {label: 'OTHER', value: 'otherId'}
      ]}
      placeholder={"Tax ID Type"}
      component={Select}
    />
    <LabeledField
      label={'Entity Type'}
      name={'entityType'}
      className={"mr-8"}
      choices={[
        {label: 'Sole Proprietorship', value: 'soleProprietorship'},
        {label: 'Limit Liability Partnership', value: 'limitLiabilityPartnership'},
        {label: 'Limit Liability C-Corp', value: 'limitLiabilityC-Corp'},
        {label: 'Limit Liability S-Corp', value: 'limitLiabilityS-Corp'},
        {label: 'Trust Estate', value: 'trust_Estate'},
        {label: 'Partnership', value: 'partnership'},
        {label: 'S-Corporation', value: 'sCorporation'},
        {label: 'C-Corporation', value: 'cCorporation'},
        {label: 'Corporate', value: 'corporate'},
        {label: 'Unknown', value: 'unknown'}
      ]}
      placeholder={"Entity Type"}
      component={Select}
    />
    <LabeledField
      label={'Formation Date'}
      name={'formationDate'}
      component={DateInput}
      className={"mr-8"}
      value={moment(values.formationDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`formationDate`, e.target.value);
      }}
    />
  </div>
  <div className={"flex "}>
    <LabeledField
      label={'Fiscal Year Start'}
      name={'fiscalYearStartMonth'}
      choices={[
        {label: 'January', value: 1},
        {label: 'February', value: 2},
        {label: 'March', value: 3},
        {label: 'April', value: 4},
        {label: 'May', value: 5},
        {label: 'June', value: 6},
        {label: 'July', value: 7},
        {label: 'August', value: 8},
        {label: 'September', value: 9},
        {label: 'October', value: 10},
        {label: 'November', value: 11},
        {label: 'December', value: 12}
      ]}
      placeholder={"Fiscal Year Start"}
      component={Select}
    />
  </div>
</div>;

const CompanyInfoForm = ({doSubmit, errors, submitting, company, adminOpen}) => {

  const loadLogo = company.uploadLogo;

  const formationDate = company.formationDate ? moment(company.formationDate) : moment();
  const waiveDateStart = company.waiveDateStart ? moment(company.waiveDateStart) : moment();
  const waiveDateEnd = company.waiveDateEnd ? moment(company.waiveDateEnd) : moment();
  if (company) {
    company = {...company};
    company.timeStampedMatchPercent = company.matchPercent;
    company.formationDate = formationDate.format("YYYY-MM-DD");
    company.waiveDateStart = waiveDateStart.format("YYYY-MM-DD");
    company.waiveDateEnd = waiveDateEnd.format("YYYY-MM-DD");
  }

  return <Formik
    initialValues={{
      name: company.name,
      address: {
        address1: company.address ? company.address.address1 : '',
        address2: company.address ? company.address.address2 : '',
        city: company.address ? company.address.city : '',
        state: company.address ? company.address.state : '',
        zip: company.address ? company.address.zip : '',
        id: company.address ? company.address.id : ''
      },
      // affiliates:  company.affiliates ? company.affiliates.length ? company.affiliates : [] : [],
      phone: company.phone,
      taxId: company.taxId,
      idType: company.idType,
      entityType: company.entityType,
      fiscalYearStartMonth: company.fiscalYearStartMonth,
      id: company.id,
      formationDate: moment(company.formationDate).format("YYYY-MM-DD"),
      waiveDateStart: moment(company.waiveDateStart).format("YYYY-MM-DD"),
      waiveDateEnd: moment(company.waiveDateEnd).format("YYYY-MM-DD"),
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
          .required('Name is Required'),
      address: Yup.object().shape({
          address1: Yup.string().nullable()
              .required("Address is required"),
          city: Yup.string().nullable()
              .required("City is required"),
          state:  Yup.string().nullable()
              .required("State is required"),
          zip:  Yup.string().nullable()
              .required("Zip is required"),
      }),
      phone:  Yup.string()
          .required("Phone is required"),
      taxId:  Yup.string()
          .test('len', 'Must be 9 characters', val => val.length === 9)
          .required("Tax Id is required")
  })}
    onSubmit={(values) => {
      values.formationDate = `${values.formationDate}T12:00:00.000000Z`;
      values.waiveDateStart = `${values.waiveDateStart}T12:00:00.000000Z`;
      values.waiveDateEnd = `${values.waiveDateEnd}T12:00:00.000000Z`;
      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <CompanyInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
      </FieldGroup>
      <h3 className={'text-lg mt-8 mb-3'}>Company Logo</h3>
      <div className={'text-red-500 text-sm mt-4 mb-10'}>You may need to refresh your page to see the changes you make.</div>
      <div className={"flex "}>
      <Field name={'logoUrl'} imageSource={company.logoUrl} onSelect={loadLogo} company={company} component={ImageUpload} />
      
      </div>
      {/* <h3 className={'text-lg mt-8'}>Affiliate Businesses/Locations</h3>
      <FieldArray
        name={'affiliates'}
        render={arrayHelpers => <div>
          {values.affiliates.map((affiliate, index) => <div className={'p-4 bg-subtle-blue mb-4'} key={index}>
            <Field type={'hidden'} name={`affiliates.${index}.id`} value={affiliate.id}/>
            {<CompanyInfoFields prefix={`affiliates.${index}.`}/>}
            <CompanyInfoFields prefix={``}/>
          </div>)}
          <FieldGroup>
            {values.affiliates.length ? (submitting ? <Loading size={30}/> :
              <PrimaryButton>Save</PrimaryButton>) : null}
            <PrimaryButton onClick={(event) => {
              arrayHelpers.push({
                name: '',
                address: {
                  address1: '',
                  address2: '',
                  city: '',
                  state: '',
                  zip: '',
                  id: ''
                },
                phone: '',
                taxId: '',
                fiscalYearStartMonth: "1",
                id: ''
              });
              event.preventDefault();
            }}>Add Affiliate</PrimaryButton>
          </FieldGroup>
        </div>
        }/> */}
    </FormErrors>
  </Form>}
  </Formik>;
};

export default CompanyInfoForm;
