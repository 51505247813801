import React, { useEffect, useContext } from "react";
import StoreContext from "../../../StoreContext";
import EmployerPlanForm from "../../../forms/EmployerPlanForm";
import { observer } from "mobx-react";
import AlertProvider from "../../../AlertProvider";

const SavingsPlanSettings = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const plan = currentCompany && currentCompany.companyPlans.length > 0 ? currentCompany.companyPlans[0] : undefined;
  return <div className={'max-w-2xl'}>
    <AlertProvider/>
    <EmployerPlanForm
      plan={plan}
      doSubmit={(values) => {
        plan
        ? currentCompany.updateCompanyPlan(plan, values)
        : currentCompany.createCompanyPlan(values);
        loadStep();
        }
      }
      submitting={rootStore.submitting}
      errors={rootStore.formErrors}
      rootStore={rootStore}
    />
  </div>;
});
export default SavingsPlanSettings;
