import React from 'react';
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import StateInput from "../components/StateInput";
import MaskedLabeledField from "../components/MaskedLabeledField";
import {phoneNumberMask} from "../formikUtils";
// import LicenseImageUpload from '../components/LicenseImageUpload';
import ReactTooltip from 'react-tooltip';
import DateInput from '../components/DateInput';
import moment from 'moment';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';
import GoBack from '../pages/GoBack';

const AdminAccountForm = ({doSubmit, errors, submitting, user, employee, newAdmin, address, rootStore}) => {
  const intl = useIntl();
  const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  // const upload = async (file) => {
  //   const formData = new FormData();
  //     formData.append('file', file);

  //     const response = await fetch(`/api/v1/accountHolder/addLicenseImage/${employee.id}`, {
  //         method: 'POST',
  //         body: formData,
  //         headers: {
  //             Authorization: `Bearer ${rootStore.authStore.getToken()}`
  //         },
  //     });

  //     return response;
  // }

return <>
  <GoBack/>
    <br/>
    {newAdmin || (user && address && employee) ?
  <Formik
    initialValues={newAdmin === true ? 
      {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          id: undefined,
          version: undefined,
        },
        license : '',
        licenseImage : undefined,
        dateOfBirth: '',
        ssn: '',
        owner: true,
        ownership: ''
      }
      :
      {
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      address: {
        address1: address ? address.address1 : '',
        address2: address ? address.address2 : '',
        city: address ? address.city : '',
        state: address ? address.state : '',
        zip: address ? address.zip : '',
        id: address ? address.id : '',
        version: address ? address.version : '',
      },
      license : employee.license,
      licenseImage : employee.licenseImage,
      dateOfBirth: employee.dateOfBirth ? moment(employee.dateOfBirth).format('YYYY-MM-DD') : '',
      ssn: employee.ssn,
      owner: true,
      userVersion : user.version,
      accountHolderVersion: employee.version,
      ownership: employee.ownership
    }}
    onSubmit={(values) => {
      doSubmit(values)
    }}
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <h3 className={'text-lg '}><FormattedMessage id={'user.owner'}/></h3>
        {/* {employee && employee.id !== '' ?
        <Field className={'mr-4 mt-4'} name={'licenseUrl'} imageSource={employee.licenseUrl} onSelect={upload} employee={employee} component={LicenseImageUpload} />
        : ''
          } */}
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.first.name', '', [])}
            name={'firstName'}
          />
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.last.name', '', [])}
            name={'lastName'}
          />          
        </div>
        <div className={'flex flex-col md:flex-row'}>
        <LabeledField 
            label={formatMessage(intl, 'user.dob', '', [])}
            name={'dateOfBirth'}
            component={DateInput} 
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            />
          <LabeledField 
            label={'Ownership %'}
            name={'ownership'}
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
          />
          <MaskedLabeledField label={'SSN'} name={'ssn'} mask={maskedSsn} 
          placeholder={'___-__-____'} value={values.ssn} className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
              setFieldValue(`ssn`, e.target.value);
          }}/>
        </div>
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.email', '', [])}
            name={'email'}
          />
          <MaskedLabeledField
            label={formatMessage(intl, 'user.phone', '', [])}
            name={'phone'}
            mask={phoneNumberMask}
            value={values.phone}
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
                setFieldValue(`phone`, e.target.value);
            }}
          />
          <LabeledField
            label={formatMessage(intl, 'user.license', '', [])} 
            name={'license'}
            className={'md:mr-4 sm:mr-0 sm:flex-1'}
            />
          <div className={'text-md mt-10'}>
            <a data-tip={formatMessage(intl, 'user.license.help', '', [])}  className='text-primary underline' style={{float: 'right', marginTop: '10px'}}><FormattedMessage id={'user.license.hover'}/></a>
            <ReactTooltip place="right" type="dark" effect="float"/>
          </div>
        </div>          
        
        {/* <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup> */}
        <h3 className={'text-lg mt-4'}><FormattedMessage id={'address.title'}/></h3>
        <div className={"flex flex-col md:flex-row"}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address1', '', [])}
            name={'address.address1'}
          />
          <LabeledField
          className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address2', '', [])}
            name={'address.address2'}
          />
          <LabeledField
            className={"flex-1"}
            label={formatMessage(intl, 'address.city', '', [])}
            name={'address.city'}
            required
          />
        </div>
        <div className={''}>
          
        </div>
        <div className={"flex flex-col md:flex-row"}>
          
          <LabeledField
            label={formatMessage(intl, 'address.state', '', [])}
            className={"mr-2 md:mr-2"}
            name={'address.state'}
            component={StateInput}
          />
          <LabeledField
            className={"min-w-24"}
            label={formatMessage(intl, 'address.zip', '', [])}
            name={'address.zip'}
          />
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
        
        <div className={''}>
          
        </div>
        
      </FormErrors>

    </Form>}
  </Formik>
  : ''
      }
  </>;
};

export default AdminAccountForm;
