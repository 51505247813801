import {useState, useEffect, useContext} from 'react';
import StoreContext from "./StoreContext";
import {useHistory} from "react-router-dom";

export function useAuthStatus() {
    const {authStore} = useContext(StoreContext);


    return  authStore.hasAuthError();

}