import React from "react";

export default function HorizontalRadioSelect({field, form, className, choices, ...props}) {
  return <div className={'flex'}>
    {choices.map(({label, value}) => <div key={value} className={'mr-8'}>
    <span className={'pt-0.5 mr-2'}>
      <input id={`input-${field.name}-${value}`}
             className={className}
             type={"radio"}
             {...field} {...props}
             checked={field.value === value}
             value={value} />
    </span>
      <label htmlFor={`input-${field.name}-${value}`} className={'text-sm'}>{label}</label>
    </div>)}</div>;
}
