import React from 'react';
import { Formik, Field, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import DateInput from '../components/DateInput';
import moment from 'moment';
import SectionHeading from '../components/SectionHeading';
import RadioSelect from '../components/RadioSelect';
import Input from "../components/Input";

const FeeInfoFields = ({setFieldValue,  values, adminOpen}) => <div>
    <div className={'bg-subtle-blue p-8 my-8'}>
    <SectionHeading>Waive Fee Date (Beta companies)</SectionHeading>
      <div className={'flex'}>      
        <LabeledField
          label={'Beginning Date (Must be first of the month.)'}
          name={'waiveDateStart'}
          component={DateInput}
          className={"mr-8"}
          value={moment(values.waiveDateStart).format("YYYY-MM-DD")}
          onChange={e => {
              setFieldValue(`waiveDateStart`, e.target.value);
          }}
        />
        
        <LabeledField
          label={'End Date (Must be first of the month.)'}
          name={'waiveDateEnd'}
          component={DateInput}
          className={"mr-8"}
          value={moment(values.waiveDateEnd).format("YYYY-MM-DD")}
          onChange={e => {
              setFieldValue(`waiveDateEnd`, e.target.value);
          }}
      />    
      </div>
      Waive Platform Fees Date
      <div className={'flex'}>      
        <LabeledField
          label={'Beginning Date (Must be first of the month.)'}
          name={'waivePlatformDateStart'}
          component={DateInput}
          className={"mr-8"}
          value={moment(values.waivePlatformDateStart).format("YYYY-MM-DD")}
          onChange={e => {
              setFieldValue(`waivePlatformDateStart`, e.target.value);
          }}
        />
        
        <LabeledField
          label={'End Date (Must be first of the month.)'}
          name={'waivePlatformDateEnd'}
          component={DateInput}
          className={"mr-8"}
          value={moment(values.waivePlatformDateEnd).format("YYYY-MM-DD")}
          onChange={e => {
              setFieldValue(`waivePlatformDateEnd`, e.target.value);
          }}
      />    
      </div>
    </div>
    <div className={'bg-subtle-blue p-8 my-8'}>
      <SectionHeading>Platform Fees based on company size. This drives a monthly platform fee.</SectionHeading>
      <LabeledField
        label={'Company Size:'}
        name={'platformFeeType'}
        choices={[
          {label: '< 1000', value: '_lessThan1000'},
          {label: '1000 - 4999', value: '_1000to4999'},
          {label: '5000+', value: '_5000orMore'}
        ]}
        component={RadioSelect}
      />
    </div>
    <div className={'bg-subtle-blue p-8 my-8'}>
      <SectionHeading>Plan setup fee based on company size. This will not be billed until a selection is made.</SectionHeading>
      <LabeledField
        label={'Company Size:'}
        name={'planSetupFeeType'}
        choices={[
          {label: '< 1000', value: '_lessThan1000'},
          {label: '1000 - 4999', value: '_1000to4999'},
          {label: '5000+', value: '_5000orMore'}
        ]}
        component={RadioSelect}
      />
    </div>
  </div>

const FeeInfoForm = ({doSubmit, errors, submitting, company, adminOpen}) => {

  const waiveDateStart = company.waiveDateStart ? moment(company.waiveDateStart) : moment();
  const waiveDateEnd = company.waiveDateEnd ? moment(company.waiveDateEnd) : moment();
  const waivePlatformDateStart = company.waivePlatformDateStart ? moment(company.waivePlatformDateStart) : moment();
  const waivePlatformDateEnd = company.waivePlatformDateEnd ? moment(company.waivePlatformDateEnd) : moment();
  if (company) {
    company = {...company};
    company.waiveDateStart = waiveDateStart.format("YYYY-MM-DD");
    company.waiveDateEnd = waiveDateEnd.format("YYYY-MM-DD");
    company.waivePlatformDateStart = waivePlatformDateStart.format("YYYY-MM-DD");
    company.waivePlatformDateEnd = waivePlatformDateEnd.format("YYYY-MM-DD");
  }

  return <Formik
    initialValues={{
      name: company.name,
      id: company.id,
      waiveDateStart: moment(company.waiveDateStart).format("YYYY-MM-DD"),
      waiveDateEnd: moment(company.waiveDateEnd).format("YYYY-MM-DD"),
      waivePlatformDateStart: moment(company.waivePlatformDateStart).format("YYYY-MM-DD"),
      waivePlatformDateEnd: moment(company.waivePlatformDateEnd).format("YYYY-MM-DD"),
      platformFeeType: company.platformFeeType,
      planSetupFeeType: company.planSetupFeeType,
    }}
    onSubmit={(values) => {
      values.waiveDateStart = `${values.waiveDateStart}T12:00:00.000000Z`;
      values.waiveDateEnd = `${values.waiveDateEnd}T12:00:00.000000Z`;
      values.waivePlatformDateStart = `${values.waivePlatformDateStart}T12:00:00.000000Z`;
      values.waivePlatformDateEnd = `${values.waivePlatformDateEnd}T12:00:00.000000Z`;
      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <FeeInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default FeeInfoForm;
