import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import OutlineBox from "../../components/OutlineBox";
import {formatMoney} from "../../utils";
import moment from "moment";
import {useParams} from "react-router-dom";
import DonutChart from "../../components/DonutChart";
import Loading from "../../components/Loading";
import MatchTableTimeStamped from "../../components/MatchTableTimeStamped";
import papa from "papaparse";
import MatchTableTraditional from "../../components/MatchTableTraditional";
import PrimaryButton from "../../components/PrimaryButton";

const MatchingReport = observer(function () {
  const {rootStore: {currentCompany}} = useContext(StoreContext);
  const payroll = currentCompany.currentPayroll;
  const {id} = useParams();
  const plan = currentCompany.companyPlans[0];

  useEffect(() => {
    (async () => {
      await currentCompany.setCurrentPayrollId(id);
    })();
  }, [id]);

  if (!payroll || payroll.id !== id) {
    return <Loading/>;
  }

  const download = async () => {
    let csv = [];
    payroll.payrollReportItems.forEach(item => {

      let newItem = {};
      newItem.name = item.name;
      newItem.employeeId = item.employeeId;
      newItem.payPeriodDate = item.payPeriodDate;
      newItem.totalContribution = parseFloat(item.baseContribution) + parseFloat(item.bonusContribution);
      newItem.withdrawals = item.withdrawals;
      newItem.matchAmount = ((parseFloat(item.baseContribution) + parseFloat(item.bonusContribution)) - (item.withdrawals || 0)) * (plan.matchPercent / 100)
      newItem.totalMatch = item.matchDue

      csv.push(newItem)
    })


    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(csv)], { type: "text/csv" }));
    a.setAttribute("download", `Match Report ${moment(payroll.payPeriodDate).format("YYYY-MM-DD")}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

  const employeeContribution = payroll.payrollReportItems.reduce((sum, item) => sum + parseFloat(item.baseContribution)
    + parseFloat(item.bonusContribution), 0);
  const employerContribution = payroll.payrollReportItems.reduce((sum, item) => sum + parseFloat(item.matchDue), 0);
  const withdrawals = payroll.payrollReportItems.reduce((sum, item) => sum + parseFloat(item.withdrawals), 0);

  return <div>
    <h2 className={'text-xl my-2'}>Match report for {moment(payroll.payPeriodDate).utc().format('L')}</h2>
    <div className={"flex flex-col xl:flex-row"}>
      <OutlineBox className={"mr-0 xl:mr-8 p-6 "}>
        <h3>Amount Contributed This Pay Period</h3>
        <span
          className={"text-primary text-4xl font-thin"}>{formatMoney(employeeContribution + employerContribution)}</span>
        <div className={'mx-auto'}>
          <DonutChart
            data={[
            {
              label: "Employee Contribution",
              value: employeeContribution,
              color: "#3b7ffb",
            },
            {
              label: "Employer Contribution",
              value: employerContribution,
              color: "#a2c3ff",
            },
            {
              label: "Other",
              value: 0,
              color: "#0057ff",
            }
          ]}
            formatValue={formatMoney}
            legendTitle={'Plan Contributions'}
            IsFixedWidth={true}
          />
        </div>
      </OutlineBox>
      <OutlineBox className={"flex-1 p-6"}>
        <h3 className={'text-lg mb-4'}>Pay Period Match Summary</h3>
        <p>Total Contributions Eligible for Match: {formatMoney(employeeContribution - (withdrawals || 0) )}</p>
        <p>Total Match Due this period: {formatMoney(employerContribution)}</p>
      </OutlineBox>
    </div>
    {payroll.matchType === 'TIMESTAMPED' ? <MatchTableTimeStamped payroll={payroll} /> : <MatchTableTraditional payroll={payroll} />}
    <div className={"mt-4"}>
      <PrimaryButton onClick={download} className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
        Download
      </PrimaryButton>
    </div>
  </div>;
});

export default MatchingReport;
