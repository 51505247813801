import {types, flow, getRoot} from "mobx-state-tree"
import {apiDelete,  apiPost} from "../utils";


const Contact = types.model("Contact", {
  id: types.identifier,
  name: types.string,
  email: types.string,
  phone: types.string,
  userName: types.maybeNull(types.string),
  title: types.maybe(types.string),
}).actions(self => ({
  delete: flow(function* () {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiDelete(self.url, getRoot(self).authStore, 'Administrator');
    if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    }
    yield getRoot(self).setSubmitting(false);
  }),
  update: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(self.url, data, getRoot(self).authStore, 'Administrator Updated');
    if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    }
    yield getRoot(self).setSubmitting(false);
  }),
}));

export default Contact;
