import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Link } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";
import UnusualActivitySheet from "../employer/settings/UnusualActivitySheet";

export const UnusualActivity = (props) => {

    const {rootStore, rootStore: {currentCompany, currentUser}} = useContext(StoreContext);
    const { companyId, accountHolderId, reportType } = props;
    const {authStore} = useContext(StoreContext);
    const [activities, setActivities] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    const [endDate, setEndDate] = useState(endDateDay);
    const [showForm, setShowForm] = useState(false);
    const [unusualActivityId, setUnusualActivityId] = useState();

    useEffect(() => {
        getUnusualActivities();
      }, []);

    const openShowForm = () => {
        window.scrollTo(0,0);
        setShowForm(true);        
    }

    const closeShowForm = () => {
        window.scrollTo(0,0);
        setSelectedUnusualActivityId(null);
        getUnusualActivities();
        setShowForm(false);
    }

    const setSelectedUnusualActivityId = (id) => {
        setUnusualActivityId(id);
        openShowForm(true);
    }

    const getUnusualActivities = async () => {
        let url = `/api/v1/uar/all/${dayjs(startDate).format("MMDDYYYY")}/${dayjs(endDate).format("MMDDYYYY")}`;
        if (companyId && !accountHolderId) {
            url = `/api/v1/uar/by/${companyId}/company`
        }
        if (!companyId && accountHolderId) {
            url = `/api/v1/uar/by/${accountHolderId}/user`
        }
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setActivities(resp);
    }
   
    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };
    
    return (
        <>
        {companyId === undefined && accountHolderId === undefined && showForm === false ?
        <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <PrimaryButton style={{marginTop: "20px", marginRight: "10px"}} onClick={getUnusualActivities} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid>
        : showForm !== true ?
        <Grid item>
            <PrimaryButton style={{marginTop: "20px"}} className={'mb-4'} onClick={openShowForm} variant="contained" >New Unusual Activity</PrimaryButton>
        </Grid> : ''
        }
        {showForm === true ? 
        <UnusualActivitySheet companyId={companyId} accountHolderId={accountHolderId} closeShowForm={closeShowForm} unusualActivityId={unusualActivityId}/>
        :
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'UAR No',
                    field: 'caseNumber',
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedUnusualActivityId(row.id)}} className={"cursor-pointer"}>
                            {row.caseNumber}
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'Date of Report',
                    field: 'uarDate', 
                    render: (rowData) => {
                        if (rowData.uarDate) {
                            return (<>{rowData.uarDate ? dayjs(rowData.uarDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Company Name',
                    field: 'companyName'
                },
                {
                    title: 'User Last Name',
                    field: 'lastName'
                },
                {
                    title: 'User First Name',
                    field: 'firstName'
                },
                {
                    title: 'Account Number',
                    field: 'ssnOrEin'
                },
                {
                    title: 'Unusual Activity Type',
                    field: 'uarType'
                },
                {
                    title: 'Detailed Description of the Unusual Activity',
                    field: 'description', 
                    render: (rowData) => {
                        if (rowData.description) {
                            return (<>{rowData.description.length > 100 ?  rowData.description.substring(0, 100) + '...': rowData.description}</>);
                        } 
                    }
                },
                {
                    title: 'Employee Name',
                    field: 'employeeName'
                },
                {
                    title: 'Date of UA',
                    field: 'dateOfActivity', 
                    render: (rowData) => {
                        if (rowData.dateOfActivity) {
                            return (<>{rowData.dateOfActivity ? dayjs(rowData.dateOfActivity).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Date UA Discovered',
                    field: 'dateOfDiscovery', 
                    render: (rowData) => {
                        if (rowData.dateOfDiscovery) {
                            return (<>{rowData.dateOfDiscovery ? dayjs(rowData.dateOfDiscovery).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Law Enforcement Involved',
                    field: 'lawEnforcement', 
                    render: (rowData) => {
                        if (rowData.lawEnforcement === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Other Referrals Involved',
                    field: 'referralsInvolved', 
                    render: (rowData) => {
                        if (rowData.referralsInvolved === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Date of Escalated to BSA in writing',
                    field: 'escalationDateToBsa', 
                    render: (rowData) => {
                        if (rowData.escalationDateToBsa) {
                            return (<>{rowData.escalationDateToBsa ? dayjs(rowData.escalationDateToBsa).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Documents',
                    field: 'documents', 
                    render: (rowData) => {
                        if (rowData.documents) {
                            return (<>{rowData.documents.length > 100 ?  rowData.documents.substring(0, 100) + '...': rowData.documents}</>);
                        } 
                    }
                },
                {
                    title: 'Employees Involved',
                    field: 'employeesInvolved', 
                    render: (rowData) => {
                        if (rowData.employeesInvolved) {
                            return (<>{rowData.employeesInvolved.length > 100 ?  rowData.employeesInvolved.substring(0, 100) + '...': rowData.employeesInvolved}</>);
                        } 
                    }
                },
                {
                    title: 'Date of BSA Reviewed',
                    field: 'bsaReviewDate', 
                    render: (rowData) => {
                        if (rowData.escalationDateToBsa) {
                            return (<>{rowData.bsaReviewDate ? dayjs(rowData.bsaReviewDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'File contains additional info indicating illegal activity?',
                    field: 'disguises', 
                    render: (rowData) => {
                        if (rowData.disguises) {
                            return (<>{rowData.disguises.length > 0 ?  'Yes': 'No'}</>);
                        } 
                    }
                },
                {
                    title: 'Escalated to Sponsor Bank?',
                    field: 'bsaEscalation', 
                    render: (rowData) => {
                        if (rowData.bsaEscalation === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Date Referred to Sponsor Bank',
                    field: 'bsaReviewDate', 
                    render: (rowData) => {
                        if (rowData.escalationDate) {
                            return (<>{rowData.escalationDate ? dayjs(rowData.escalationDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Date UAR referral to bank was reported to MS BOD',
                    field: 'escalationDateToBoard', 
                    render: (rowData) => {
                        if (rowData.escalationDateToBoard) {
                            return (<>{rowData.escalationDateToBoard ? dayjs(rowData.escalationDateToBoard).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Does SAR ned to be filed?',
                    field: 'sarRequired', 
                    render: (rowData) => {
                        if (rowData.sarRequired === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Date SAR was filed',
                    field: 'sarFileDate', 
                    render: (rowData) => {
                        if (rowData.sarFileDate) {
                            return (<>{rowData.sarFileDate ? dayjs(rowData.sarFileDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Reasons to file or not',
                    field: 'sarReason', 
                    render: (rowData) => {
                        if (rowData.sarReason) {
                            return (<>{rowData.sarReason.length > 100 ?  rowData.sarReason.substring(0, 100) + '...': rowData.sarReason}</>);
                        } 
                    }
                },
                {
                    title: 'Action on Account Required?',
                    field: 'accountActionRequired', 
                    render: (rowData) => {
                        if (rowData.accountActionRequired === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Actions Taken',
                    field: 'accountAction', 
                    render: (rowData) => {
                        if (rowData.accountAction) {
                            return (<>{rowData.accountAction.length > 100 ?  rowData.accountAction.substring(0, 100) + '...': rowData.accountAction}</>);
                        } 
                    }
                }

            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "activities",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={activities}
        />
        </div>
        }
        </>
        
    );
}