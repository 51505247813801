import React from "react";
import {Link} from "react-router-dom";

const LinkButton = ({children, className, ...props}) => {
  return <Link className={`bg-primary px-6 py-3 mb-3 text-center text-white uppercase ${className || ''}`} {...props}>
    {children}
  </Link>;
};

export default LinkButton;
