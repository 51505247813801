import {observer} from "mobx-react";
import React, {useContext} from "react";
import StoreContext from "./StoreContext";
import Alert from "./components/Alert";

const AlertProvider = observer(() => {
    const {rootStore} = useContext(StoreContext);

    //Listen for the alert event
    window.addEventListener("AlertEvent", function(evt) {
        const evtData = evt.detail;
        if (evtData){
            if(evtData.isError){
                rootStore.setErrorMessage(evtData.message);
                setTimeout(() => {
                    rootStore.setErrorMessage('');
                },5000)
            }else{
                rootStore.setMessage(evtData.message)
                setTimeout(() => {
                    rootStore.setMessage('');
                },5000)
            }
        }
        
    }, false);

    return <React.Fragment>
        {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
        {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}

    </React.Fragment>


});

export default AlertProvider;