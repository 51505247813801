import React, { useContext } from "react";
import StoreContext from "../../../StoreContext";
import CompanyContactForm from "../../../forms/CompanyContactForm";
import {observer} from "mobx-react";
import Loading from "../../../components/Loading";
import AlertProvider from "../../../AlertProvider";
import LeadAdmin from "../LeadAdmin";

const CompanyContactSettings = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentCompany}, rootStore: {currentEmployee}} = useContext(StoreContext);

  if (rootStore.submitting) {
    return <Loading />;
  }

  return <div className={'max-w-2xl'}>
    <h3 className={'text-lg mt-8'}>Lead Administrator</h3>

    <LeadAdmin rootStore={rootStore} currentCompany={currentCompany}/>
    
    <h3 className={'text-lg mt-8'}>Authorized Administrators</h3>
    <AlertProvider/>
    <p className={'py-4'}>Please select the authorized plan administrator(s) for the MatchSavings plan and provide other requested administrators for the company.</p>
    <CompanyContactForm errors={rootStore.formErrors.toJSON()} currentEmployee={currentEmployee} company={currentCompany} rootStore={rootStore} 
    doSubmit={(values) => {
      currentCompany.updateContact(values);
      loadStep();
    } 
    }/>
  </div>;
});

export default CompanyContactSettings;
