import React from 'react';
import { Formik, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import moment from 'moment';
import {formatMoney} from "../utils";
import DateInput from '../components/DateInput';


const OutboundInfoFields = ({setFieldValue,  values, adminOpen, clearingTransactions, contactDateDisplay}) => <div>
    <>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Name:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.userName}</h3>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Check Number:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.checkNumber}</h3>
      {/* <LabeledField
          className={'mr-2 clearing-min-width'}
          label={''}
          name={'checkNumber'}
          required
        /> */}
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Amount:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{formatMoney(values.amount)}</h3>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Check Date:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.checkDate ? moment(values.checkDate).format("MM-DD-YYYY") : ''}</h3>
      
    </div>
    <div className='flex row'>
    
    </div>
    <div className='flex row'>
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>30 day notice:</h3>
    <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.thirtyDayNotice ? 'Sent' : ''}</h3>
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>60 day notice:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.sixtyDayNotice ? 'Sent' : ''}</h3>
    </div>
    <div className='flex row'>
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Manually Contacted User:</h3>
    <LabeledField
        name={'contactType'}
        className={"mr-2 clearing-min-width"}
        choices={[
          {label: 'Select One', value: ''},
          {label: 'Phone', value: 'Phone'},
          {label: 'Email', value: 'Email'},          
        ]}
        component={Select}
      />
      {values.contactType && values.contactType !== '' ?
      <LabeledField
        name={'contactDate'}
        component={DateInput}
      />: ''}
      
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>90 day notice:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.ninetyDayNotice ? 'Sent' : ''}</h3>
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Cancel Check:</h3>
      <LabeledField
          name={'ninetyDayCancel'}
          className={"mr-2 clearing-min-width"}
          choices={[
            {label: 'No', value: false},
            {label: 'Yes', value: true},          
          ]}
          component={Select}
        />
      {values.ninetyDayCancel === true ||  values.ninetyDayCancel === 'true'?
        <LabeledField
          name={'cancelReasonEnum'}
          className={"mr-2 clearing-min-width"}
          choices={[
            {label: 'User did not receive check and requests cancellation.', value: 0},
            {label: 'NO FEE - User did not receive check and requests cancellation.', value: 4},
            {label: 'User requests cancellation - no longer wants withdrawal.', value: 1},
            {label: 'Check has not cleared in 95 days and all notices were given', value: 2},
            {label: 'Other', value: 3},
          ]}
          component={Select}
        />
        : ''}
    </div>
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Matching Transaction:</h3>
    <LabeledField
        name={'matchingTransaction'}
        className={"mr-2 clearing-min-width"}
        choices={clearingTransactions}
        component={Select}
      />
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Account is Closing After This Check Clears:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.closing ? 'Yes' : 'No'}</h3>
    </div>
    <p className={"mt-8 text-red-600"}>If cancelling a check, be aware that a fee is associated to this action. Also, a check must be cancelled through CNB. It is 
    recommended to do the actual cancelling before carrying out the MatchSaving's cancellation process here. Please call 1-785-336-6143.</p>
    </>
</div>;

const OutboundClearingForm = ({doSubmit, errors, submitting, outboundClearing, adminOpen, 
  company, user, closeShowForm, clearingTransactions}) => {

  const contactDateDisplay = moment(outboundClearing.contactDate);

  if (outboundClearing) {
    outboundClearing = {...outboundClearing};
    outboundClearing.contactDate = contactDateDisplay.format("YYYY-MM-DD");
  }

  let ssnOrEin = outboundClearing ? outboundClearing.ssnOrEin : null;
  if (ssnOrEin === undefined) {
    ssnOrEin = user ? user.ssn : null;
  }
  if (ssnOrEin === undefined) {
    ssnOrEin = company ? company.ein : null;
  }

  return <Formik
    initialValues={
      {
        name: outboundClearing ? outboundClearing.name : '',
        created: outboundClearing ? outboundClearing.created : '',
        amount: outboundClearing ? outboundClearing.amount : '',
        closing: outboundClearing ? outboundClearing.closing : '',
        checkCleared: outboundClearing ? outboundClearing.checkCleared : false,
        thirtyDayNotice: outboundClearing ? outboundClearing.thirtyDayNotice : false,
        sixtyDayNotice: outboundClearing ? outboundClearing.sixtyDayNotice : false,
        ninetyDayNotice: outboundClearing ? outboundClearing.ninetyDayNotice : false,
        processed: outboundClearing ? outboundClearing.processed : false,
        balanced: outboundClearing ? outboundClearing.balanced : false,
        fundsReturned: outboundClearing ? outboundClearing.fundsReturned : false,
        matchingTransaction: outboundClearing ? outboundClearing.matchingTransaction : null,
        checkNumber: outboundClearing ? outboundClearing.checkNumber : null,
        matchType: outboundClearing ? outboundClearing.matchType : '',
        version: outboundClearing ? outboundClearing.version : null,
        id: outboundClearing ? outboundClearing.id : null,
        userName: outboundClearing ? outboundClearing.userName : '',
        checkDate: outboundClearing ? outboundClearing.checkDate : '',
        contactDate: outboundClearing ? contactDateDisplay.format("YYYY-MM-DD") : '',
        contactType: outboundClearing ? outboundClearing.contactType : '',
        ninetyDayCancel: outboundClearing ? outboundClearing.ninetyDayCancel : false,
        canceledCheckFee: outboundClearing ? outboundClearing.canceledCheckFee : false,
        cancelReasonEnum: outboundClearing ? outboundClearing.cancelReasonEnum : null,
      }}
    onSubmit={(values) => {
      const date = values.contactDate;
      values.contactDate = values.contactDate ? `${values.contactDate}T12:00:00.000000Z` : null;
      if (values.ninetyDayCancel === 'true' && values.cancelReasonEnum === null) {
        values.cancelReasonEnum = 0;
      } else if (values.ninetyDayCancel !== 'true') {
        values.cancelReasonEnum = null;
      }
      doSubmit(values);
      values.contactDate =  moment(date).format("YYYY-MM-DD");
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <OutboundInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen} clearingTransactions={clearingTransactions} contactDateDisplay={contactDateDisplay}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Save</PrimaryButton><PrimaryButton onClick={closeShowForm}>Cancel</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default OutboundClearingForm;

