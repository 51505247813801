import {types, flow, getRoot} from "mobx-state-tree"
import {apiGet, apiPost} from "../utils";
import {IsoDate} from "../types/isoDate";
import moment from "moment";

const Payroll = types.model("Payroll", {
  id: types.identifier,
  paid: types.boolean,
  scheduled: types.boolean,
  pending: types.boolean,
  noMatch: types.boolean,
  payPeriodDate: IsoDate,
  totalContributions: types.maybeNull(types.number),
  totalMatch: types.maybeNull(types.number),
  payrollItems: types.maybeNull(types.array(types.frozen())),
  payrollReportItems: types.maybeNull(types.array(types.frozen())),
  csv: types.maybeNull(types.array(types.frozen())),
  csvPercent: types.maybeNull(types.array(types.frozen())),
  matchType: types.maybeNull(types.string),
  // url: types.string,
  // urls: types.map(types.string),
}).views(self => ({
  get status() {

    const today = moment();
    const payPeriod = moment(self.payPeriodDate);

    if (self.paid) {
      return 'Finished';
    }

    if (self.pending && today.isSameOrAfter(payPeriod, 'day')) {
      return "In Progress";
    }

    if (self.scheduled && self.pending) {
      return 'Scheduled';
    }

    if (self.pending) {
      return 'Not Finished';
    }    

    return 'Incomplete';
  }
})).actions(self => ({
  update: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(self.url, data, getRoot(self).authStore, 'Payroll Updated');
    if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.payroll_items = response.data.payroll_items;
      self.url = response.data.url;
      self.payPeriodDate = response.data.payPeriodDate;
    }
    yield getRoot(self).setMessage(response.message);
    yield getRoot(self).setSubmitting(false);
  }),
}));

export default Payroll;
