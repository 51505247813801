import React from "react";
import FieldGroup from "./FieldGroup";
import Label from "./Label";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Input from "./Input";
import RadioInput from "./RadioInput";

const Error = ({children}) => <div className={"text-red-500 text-sm"}>{children}</div>;

export default function RadioField({className, children, label, name, component=RadioInput, ...props}) {
  const formikContext = useFormikContext();
  return <FieldGroup className={className}>
    <Field
      name={name}
      placeholder={label}
      component={component}
      className={'mr-2'}
      {...props}
    />
    {children}
    {formikContext.errors && formikContext.errors[name] && <Error>{formikContext.errors[name]}</Error>}
  </FieldGroup>
}
