import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import TransactionTable from "../../components/TransactionTable";
import Pagination from "../../components/Pagination";
import Input from "../../components/Input";
import DateInput from "../../components/DateInput";
import { apiGet } from "../../utils";
import moment from "moment";
import papa from "papaparse";
import Loading from "../../components/Loading";
import PrimaryButton from "../../components/PrimaryButton";
import TransactionDivTable from "../../components/TransactionDivTable";

const EmployerTransactions = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [queryTimer, setQueryTimer] = useState(null);
  const [pendingQuery, setPendingQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    currentCompany.loadTransactions({page, startDate, endDate, searchQuery});
  }, [page, startDate, endDate, searchQuery]);

  function updateQuery (query) {
    setPendingQuery(query);
    if (queryTimer) {
      clearTimeout(queryTimer);
    }

    setQueryTimer(setTimeout(() => {
      setSearchQuery(query);
    }, 500));
  }

  const download = async () => {
    setLoading(true);
    const result = await apiGet(`/api/v1/transaction/company/${currentCompany.id}?pageNumber=1&pageSize=10000&startDate=${startDate ? startDate: ''}&endDate=${endDate ? endDate : ''}&search=${searchQuery ? searchQuery : ''}`, rootStore.authStore);

    let csv = await result.json();

    csv.content.forEach(item => {
      item.name = item.userName;
      item.transaction_date = item.created;
      item.employer_contribution = item.withdrawalAmountD > 0 ? -item.withdrawalAmountD : item.depositAmount;
      item.employer_match = item.withdrawalAmountM > 0 ? -item.withdrawalAmountM : item.matchAmount;
      delete item.userName;
      delete item.created;
      delete item.withdrawalAmountD;
      delete item.withdrawalAmountM;
      delete item.depositAmount;
      delete item.matchAmount;
      delete item.account;
      delete item.payrollItem;
      delete item.updated;  
      delete item.updatedBy;
      delete item.createdBy;
      delete item.active;
      delete item.version;
      delete item.id;
      delete item.companyId;
      delete item.accountId;
    });

    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(csv.content)], { type: "text/csv" }));
    a.setAttribute("download", `Company Transactions Report ${moment().format("YYYY-MM-DD")}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setLoading(false);
  }

  return <div>
    <h3 className={"text-xl"}>All Transactions</h3>
    <br/>
    <div className={'flex flex-col md:flex-row mb-2'}>
      <div className={'flex-1 mb-2'}>
        <label>Search by Name</label>
        <Input
          onChange={(e) => {
            updateQuery(e.target.value)//, setPage(1)
          }}
          value={pendingQuery}
          placeholder={'Search transactions by name'}
        />
      </div>
      <div className={'md:mx-4 mb-2'}>
        <label>From</label>
        <DateInput
          onChange={(e) => {
            setStartDate(e.target.value)//, setPage(1)
          }}
          placeholder={"From"}
        />
      </div>
      <div className={''}>
        <label>To</label>
        <DateInput
          onChange={(e) => {
            setEndDate(e.target.value)//, setPage(1)
          }}
          placeholder={"To"}
        />
      </div>
      <div className={"mt-6 md:ml-6"}>
      {loading ? <Loading/> : 
      <PrimaryButton onClick={download} className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
        Download
      </PrimaryButton>
          }
      </div>
    </div>
    {/* {currentCompany.transactionPages && <TransactionTable transactions={currentCompany.transactionPages.get(page)}/>} */}
    {currentCompany.transactionPages && <TransactionDivTable transactions={currentCompany.transactionPages.get(page)}/>}
    <Pagination
      page={page}
      totalPages={currentCompany.transactionPageCount}
      setPage={setPage}
    />
  </div>;
});

export default EmployerTransactions;
