import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import UserTable from "../../components/UserTable";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import {useRouteMatch} from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import { apiGet } from "../../utils";

const AdminUsers = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  let {  url } = useRouteMatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDOB] = useState(null);
  const [role, setRole] = useState('');
  const [ssn, setSsn] = useState('');
  const [users, setUsers] = useState([]);

  const loadAdminUsers = async () => {
    const response = await apiGet(`/api/v1/admin/user/all/${rootStore.currentUser.id}`, rootStore.authStore);
    const users = await response.json();
    setUsers(users);
  };

  useEffect(() => {
    loadAdminUsers();
  }, []);

  useEffect(() => {
    loadAdminUsers();
  }, [firstName, lastName, email, dob, role, ssn, adminStore.userSearch]);

  function  EmployeeFilter(user,firstName,lastName,email, role){    
    return  user && (user.firstName === null? false: firstName === ''? true: user.firstName.toLowerCase().includes(firstName.toLowerCase())) 
    && (user.lastName === null? false: lastName === ''? true: user.lastName.toLowerCase().includes(lastName.toLowerCase()))
    && (user.email === null? false: email === ''? true: user.email.toLowerCase().includes(email.toLowerCase()))
    // && (user.ssn === null? false: ssn === ''? true: user.ssn.toLowerCase().includes(ssn.toLowerCase()))
    && (user.role === null? false: role === ''? true: user.role.toLowerCase().includes(role.toLowerCase()));
}

function setDOBState(date){
  const ToDate = new Date();
  const FromDate = new Date(date);
  if (FromDate > ToDate) {      
      rootStore.setErrorMessage("The Date must be lower or equal to today date");
      return false;
  
    }else{
    setDOB(date)
    rootStore.setErrorMessage("");
  }

}

  // let t = null;
  // function updateQuery (q) {
  //   setQuery(q);
  //   if (queryTimer) {
  //     clearTimeout(queryTimer);
  //   }

  //   setQueryTimer(setTimeout(() => {
  //     adminStore.setUserSearch(query);
  //   }, 500));
  // }

  function getTodayDateFormatted(){
    var dtToday = new Date();
  
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
  
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
  
    return year + '-' + month + '-' + day;
  }

  return <div>
    <h3 className={"text-xl"}>Users</h3>
    <br />
    <div className={'flex flex-col md:flex-row mb-2'}>
      <div className={'mb-2'}>
        <label>First Name</label>
        <Input
          onChange={(e) => {
            setFirstName(e.target.value)//, setPage(1)
          }}
          value={firstName}
          placeholder={'Search Employee by First Name'}
        />
      </div>
      <div className={'md:mx-4 mb-2'}>
        <label>Last Name</label>
        <Input
          onChange={(e) => {
            setLastName(e.target.value)//, setPage(1)
          }}
          value={lastName}
          placeholder={"Search Employee By Last Name"}
        />
      </div>
      <div className={'md:mr-4 mb-2'}>
        <label>Email</label>
        <Input
          onChange={(e) => {
            setEmail(e.target.value)//, setPage(1)
          }}
          value={email}
          placeholder={"Search Employee By Email"}
        />
      </div>
      <div className={'md:mr-4 mb-2'}>
        <label>Role</label>
        <Input
          onChange={(e) => {
            setRole(e.target.value)//, setPage(1)
          }}
          value={role}
          placeholder={"Search Employee By Role"}
        />
      </div>
      {/* <div className={'md:mr-4 mb-2'}>
        <label>SSN</label>
        <Input
          onChange={(e) => {
            setSsn(e.target.value)//, setPage(1)
          }}
          value={ssn}
          placeholder={"Search SSN"}
        />
      </div> */}
      {/* <div style={{marginTop: "-1px"}} className={''}>
        <label>DOB</label>
        <DateInput 
          //value= {dob}
          max= { getTodayDateFormatted()}
          onChange={(evt) => setDOBState(evt.target.value)} 
        />
      </div> */}
    </div>
    <LinkButton to={`${url}/create-user`}>Create User</LinkButton>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}
    <div>
      <UserTable users={users.filter((user) => EmployeeFilter(user,firstName,lastName,email, role))}/>
    </div>
  </div>;
});

export default AdminUsers;
