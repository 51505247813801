import React, { useEffect, useState } from 'react';
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import RadioField from "../components/RadioField";
import * as Yup from 'yup';
import RadioSelect from '../components/RadioSelect';
import {formatMoney, formatMessage} from "../utils";
import { FormattedMessage, useIntl} from 'react-intl';

const WithdrawForm = ({doSubmit, errors, submitting, close, amount, solidContactId, employee, preAmount, impersonatingEmployee}) => {
    const [withdrawType, setWithdrawType] = useState(employee ? employee.withdrawalType : 'ACH');
    const [sameDay, setSameDay] = useState("nextDay");
    const [summary, setSummary] = useState("");
    const [adjAmt, setAdjAmt] = useState(0);
    const intl = useIntl();
    
    useEffect(() => {
        if (employee) {
            setWithdrawType(employee.withdrawalType);
        }
      },[employee])

    useEffect(() => {
        calcValue(adjAmt);
      },[sameDay, withdrawType])

    const calcValue = (passAmount) => {
        passAmount = parseFloat(passAmount);

        if (employee.totalSavings < passAmount) {
            setSummary(`${formatMessage(intl, 'withdrawal.warning1', '', [])}  ${formatMoney(passAmount)} ${formatMessage(intl, 'withdrawal.warning2', '', [])}`);
        } else {
            if (withdrawType === 'ACH') {
                // let values = [passAmount ? formatMoney(passAmount) : formatMoney(close ? employee.totalSavings : 0),sameDay === 'sameDay' ? formatMessage(intl, 'withdrawal.sameday', '', []) : formatMessage(intl, 'withdrawal.nextday', '', []) ]
                // setSummary(formatMessage(intl, 'withdrawal.ach', '', values));
                setSummary(formatMessage(intl, 'withdrawal.nextday'));
            } else if (withdrawType === 'CHECK') {
                setSummary(``);
                let values = [passAmount ? formatMoney(passAmount) : formatMoney(close ? employee.totalSavings : 0)]
                setSummary(formatMessage(intl, 'withdrawal.check', '', values));
            }
        }

        setAdjAmt(parseFloat(passAmount));
    }

    return <Formik
        initialValues={{
            amount: preAmount,
            withdrawType: withdrawType,
            sameDay: 'nextDay'
        }}
        onSubmit={(values) => {
            values.totalSavings = employee.totalSavings;
            doSubmit(values);            
        }}
        validationSchema={Yup.object().shape({
            amount: Yup.number(formatMessage(intl, 'withdrawal.numeric', '', []))
                .positive(formatMessage(intl, 'withdrawal.positive', '', []))
                .required(formatMessage(intl, 'withdrawal.req', '', []))
        })
        }
        validate={(values) => {
            setWithdrawType(values.withdrawType);
            setSameDay(values.sameDay);
        }}
    >
        <Form>
            <FormErrors errors={errors}>
                
                <div className={'flex-row'}>
                    <input type={'hidden'} value={solidContactId} />
                    <input type={'hidden'} value={employee.totalSavings} />
                </div>

                {!close ? 
                    <div className={'mb-4'}>
                        <LabeledField
                            label={formatMessage(intl, 'withdrawal.amount', '', [])}
                            placeholder={'0.00'}
                            name={'amount'}
                            required
                            onKeyUp={(e) => calcValue(e.target.value)}
                        />
                    </div>
                    
                   :
                   <div className={'mb-4'}>
                        <LabeledField
                            label={formatMessage(intl, 'withdrawal.warning2', '', [])}
                            placeholder={'0.00'}
                            name={'amount'}
                            required
                            disabled={close}
                        />
                        {/* <LabeledField
                        name={'sameDay'}
                        choices={[
                            {label: 'Next Day (free)', value: 'nextDay'},
                            // {label: 'Full-time Employee who regularly works at least 30+ hrs/week', value: 'FULLTIME'},
                            {label: 'Same Day ($1)', value: 'sameDay'}
                        ]}
                        component={RadioSelect}
                        onClick={(e) => {
                            setSameDay(e.target.value)
                            calcValue(amount);                            
                            }
                        }
                        /> */}
                    </div>
                }
                {summary !== '' ?
                    <p className={'text-red-500'}>{summary}</p> : ''
                    }
                { !close ? 
                !impersonatingEmployee ?
                <FieldGroup>
                    {submitting ? <Loading size={30}/> : <PrimaryButton type={'submit'}><FormattedMessage id={'withdrawal.button'}/></PrimaryButton>}
                </FieldGroup>
                : ''
                :

                    <>
                <FieldGroup>
                    {submitting ? <Loading size={30}/> : <PrimaryButton type={'submit'}><FormattedMessage id={'withdrawal.button.close'}/></PrimaryButton>}
                </FieldGroup>
                <p><FormattedMessage id={'withdrawal.going'}/><br/><br/><b><i><FormattedMessage id={'withdrawal.aware'}/> </i></b></p>
                </>
                
                }
                {/* <div className={'flex'}>
                    <RadioField id={'input-match_type-default'} name={'withdrawType'} value={'ACH'}>
                    <label htmlFor={'input-match_type-default'}
                        className={'text-sm mr-4'}>{'Default'}</label>
                    </RadioField>
                    <RadioField name={'withdrawType'} value={'CHECK'} id={'input-match_type-check'}>
                    <label htmlFor={'input-match_type-check'}
                            className={'text-sm mr-4'}>{'Check'}</label>
                    </RadioField>
                </div> */}
              {withdrawType === 'ACH' ? 
              <>
              <p><FormattedMessage id={'withdrawal.notice.ach'}/></p>
                {/* <div className={'flex'}>
                    <RadioField id={'input-match_type-nextDay'} name={'sameDay'} value={'nextDay'}>
                    <label htmlFor={'input-match_type-nextDay'}
                        className={'text-sm mr-4'}>{'Next Day (free)'}</label>
                    </RadioField>
                    <RadioField name={'sameDay'} value={'sameDay'} id={'input-match_type-sameDay'}>
                    <label htmlFor={'input-match_type-sameDay'}
                            className={'text-sm'}>{'Same Day ($1)'}</label>
                    </RadioField>
                </div> */}
                </>
               : ''}

               {withdrawType === 'CARD' ?  <>
               {/* <p className='text-red-500 mb-4'>Please be aware that there is a $1.00 charge that will be debited from your MatchSavings account.</p> */}
               <p className='text-red-500 mb-4'>NOT IMPLEMENTED</p>
               <p>We will push money to your debit card.</p>
               </> : ''}
            </FormErrors>
        </Form>
    </Formik>;
};

export default WithdrawForm;
