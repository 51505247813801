import React from "react";
import FieldGroup from "./FieldGroup";
import Label from "./Label";
import {Field, useFormikContext} from "formik";
import Input from "./Input";
import * as formikUtils from "../formikUtils";

const Error = ({message}) => <div className={"text-red-500 text-sm"}>{message}</div>;

export default function PasswordVerifyField({className, label, placeholder, name, pass, component = Input, ...props}) {
    const formikContext = useFormikContext();
     let error = formikUtils.formikErrorHandler (formikContext, name);


    return <div className="flex row">
        <div className="float-left">
        <FieldGroup className={className}>
        {label && <><Label>{label}{props.required && <span className={'text-red-500 ml-1'}>*</span>}</Label> </> }
            <Field
                name={name}
                placeholder={placeholder || label}
                component={component}
                {...props}
                className={'custom-pw-width'}
            />
            {error ? <Error message={error}/> : null}
            
        </FieldGroup>
        </div>
        <div className="float-right mt-12 green-500">
        {pass ?
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-right text-green-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
        : ''}
        </div>
        
    </div>
}
