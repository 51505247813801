import React from "react";
import FieldGroup from "./FieldGroup";
import Label from "./Label";
import {ErrorMessage, Field, useFormikContext} from "formik";
import Input from "./Input";

export default function CheckboxSelect({field, form, className, choices, ...props}) {
  return choices.map(({label, value, disabled}) => <div key={value} className={'flex'}>
      <span className={'mr-2 block'}>
        <input id={`input-${field.name}-${value}`}
               className={className}
               type={"checkbox"}
               {...field} {...props}
               disabled={disabled}
               checked={form.values[field.name] ? form.values[field.name].includes(value) : false}
               value={value} />
      </span>
    <label htmlFor={`input-${field.name}-${value}`} className={'text-sm'}>{label}</label>
  </div>);
}
