import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import ComplaintInfoForm from "../../../forms/ComplaintInfoForm";
import { apiGet, apiPost } from "../../../utils";
import GoBack from "../../GoBack";
import PrimaryButton from "../../../components/PrimaryButton";

const ComplaintSheet = observer((props) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating || rootStore.impersonatingEmployee;
  const [complaint, setComplaint] = useState({});
  const { accountHolderId, companyId, complaintId, closeShowForm } = props;
  const {authStore} = useContext(StoreContext);
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);

  const loadComplaint = async () => {
    if (complaintId) {
      const response = await apiGet(`/api/v1/complaint/get/${complaintId}`, authStore);
      const complaint = await response.json();
      setComplaint(complaint);
      loadAccount(complaint.accountHolderId)
      loadCompany(complaint.companyId);
    }
    setLoad1(true);
  }

  const loadAccount = async (accountHolderId) => {
    if (accountHolderId) {
      const response = await apiGet(`/api/v1/accountHolder/get/${accountHolderId}`, authStore);
      const accountHolder = await response.json();
      setUser(accountHolder);
      setCompany(accountHolder.company);
    }
    setLoad2(true);
  }

  const loadCompany = async (companyId) => {
    if (companyId) {
      const response = await apiGet(`/api/v1/company/get/${companyId}`, authStore);
      const company = await response.json();
      setCompany(company);      
    }
    setLoad3(true);

  }

  const updateComplaint = async (values) => {
    const response = await apiPost(`/api/v1/complaint/save/complaint`, values, authStore, 'Added Complaint');
    setComplaint(response);   
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    loadComplaint();
    loadAccount(accountHolderId);
    loadCompany(companyId);
  }, []);


  if (!(load1 === true && load2 === true && load3 === true)) {
    return <Loading />;
  }

  return <div>
    <PrimaryButton onClick={window.print} className={'float-right'}>Print</PrimaryButton>
    <PrimaryButton onClick={closeShowForm} className={'float-right mr-4'}>Cancel</PrimaryButton>
    <h3 className={'text-2xl mt-8'}>Customer Complaint Tracking Worksheet</h3>
    <AlertProvider/>    
    {load1 === true && load2 === true && load3 === true ?
    <div className="mt-10">
    <ComplaintInfoForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      company={company} complaint={complaint} user={user} closeShowForm={closeShowForm}
      doSubmit={(values) => {
        updateComplaint(values);
      }
      } />
    </div>
     : ''}
  </div>;
});

export default ComplaintSheet
