import React, {useState} from "react";
import LineChart from "./LineChart";
import moment from "moment";
import {formatMoneyWithRangeData} from "../utils";
import Select from "./Select";

const EmployerBalanceChart = ({balanceData}) => {
  const [chartInterval, setChartInterval] = useState('12m');
  if (chartInterval === '6m') {
    balanceData = balanceData['months'].slice(-7);
  } else if (chartInterval === '12m') {
    balanceData = balanceData['months'].slice(-13);
  } else if (chartInterval === '24m') {
    balanceData = balanceData['months'].slice(-25);
  } else if (chartInterval === '3y') {
    balanceData = balanceData['years'].slice(-4);
  } else if (chartInterval === '5y') {
    balanceData = balanceData['years'].slice(-6);
  } else {
    balanceData = balanceData['years'];
  }

  let labels = [];
  if (chartInterval.includes('m')) {
    labels = balanceData.map(e => {
        if (chartInterval === '24m') {
            return moment().month(e.month - 1).year(e.year).format('MM/YY');
        } else {
            return moment().month(e.month - 1).year(e.year).format('MMM');
        }
    });
  } else {
      labels = balanceData.map(e => {
          let d = new Date();
          if (d.getFullYear() !== e.year){//other years
              return moment().month(0).year(e.year).format("MM/YY");
         }else { //current year
              return moment().month(d.getMonth()).year(e.year).format("MM/YY");
          }
      });
  }

  return <div className={'p-8 flex-1'}>
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2'}>Employee Savings Balance</h3>
      <div>
        <Select
          choices={[
            {label: '6 months', value: '6m'},
            {label: '1 year', value: '12m'},
            {label: '2 years', value: '24m'},
            {label: '3 years', value: '3y'},
            {label: '5 years', value: '5y'},
          ]}
          field={{value: chartInterval}}
          onChange={(e) => {
            setChartInterval(e.target.value);
          }}
        />
      </div>
    </div>
    <LineChart
      labels={labels}
      formatValue={formatMoneyWithRangeData}
      datasets={[
        {
          fill: false,
          label: "Average",
          data: balanceData.map(e => parseFloat(e.mean)),
          borderColor: '#ec7800',
          backgroundColor: '#ec7800',
        },
        {
          fill: false,
          label: "Median",
          data: balanceData.map(e => parseFloat(e.median)),
          borderColor: '#23bf88',
          backgroundColor: '#23bf88',
        },
        {
          fill: false,
          label: "Range",
          data: balanceData.flatMap((e, index) => [
            {x: labels[index], y: parseFloat(e.range[0])},
            {x: labels[index], y: parseFloat(e.range[1])},
            {x: labels[index], y: null}
          ]),
          borderColor: '#b7b7b7',
          backgroundColor: '#b7b7b7',
        }
      ]}
    />
  </div>

}

export default EmployerBalanceChart;
