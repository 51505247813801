import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import UserTable from "../../components/UserTable";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import {useRouteMatch} from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import { apiGet, apiPost } from "../../utils";
import PolicyTable from "../../components/PolicyTable";
import PrimaryButton from "../../components/PrimaryButton";

const Policies = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  let {  url } = useRouteMatch();
  const [policies, setPolicies] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const toggleShow = () => {
    if (showAll === true) {
      setShowAll(false);
    } else {
      setShowAll(true);
    }
  }

  const loadPolicies = async () => {
    const response = await apiGet(`/api/v1/policy/active`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  };

  const loadAllPolicies = async () => {
    const response = await apiGet(`/api/v1/policy/all`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  };

  const deletePolicy = async(values) => {
    await apiPost(`/api/v1/policy/${values}/archive`, values, rootStore.authStore, 'Policy Archived');
    loadPolicies();
  }

  useEffect(() => {
    if (showAll === false) {
      loadPolicies();
    } else {
      loadAllPolicies();
    }
    
  }, [showAll]);

  return <div>
    <h3 className={"text-xl"}>Policies</h3>
    <br />
    <p>
      If a new document needs to be accepted by employees/employers, please add a new policy record. The same is true of an update to an existing policy.
      Add a new policy, then archive the previous one. This ensures that the new document is acknowledged.
    </p>
    <br />
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}
    <LinkButton to={`${url}/create-policy`}>Create Policy</LinkButton>
    <br />
    <br />
    <PrimaryButton onClick={() => toggleShow(false)} className={'mr-5'}>Show Current</PrimaryButton>
    <PrimaryButton onClick={() => toggleShow(true)}>Show All</PrimaryButton>
    <div>
      <PolicyTable policies={policies} archive={deletePolicy}/>
    </div>
  </div>;
});

export default Policies;
