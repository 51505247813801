import React from 'react';
import { Formik, Form, useField } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import moment from 'moment';
import {formatMoney} from "../utils";

const InboundInfoFields = ({setFieldValue,  values, adminOpen, clearingTransactions}) => <div>
    <>
    <h1 className='text-2xl mt-8'><u>Payroll File:</u></h1>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Company Name:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.name}</h3>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Pay Period:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{moment(values.payPeriodDate).format("MM-DD-YYYY")}</h3>
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Contribution Amount:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{formatMoney(values.totalContributions)}</h3>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Atomic:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{values.atomic ? 'Yes' : 'No'}</h3>
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Match Amount:</h3>
      <h3 className={'text-lg my-2 mr-2 clearing-min-width'}>{formatMoney(values.totalMatch)}</h3>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Transfers Made:</h3>
      <h3 className={'text-lg my-2 mr-2'}>{values.transfersMade ? 'Yes' : 'No'}</h3>
    </div>
    <h1 className='text-2xl mt-8'><u>Inbound Clearing Account:</u></h1>
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>EE Contribution Bank Transaction:</h3>
    <LabeledField
        name={'matchingTransaction'}
        className={"mr-2 clearing-min-width"}
        choices={clearingTransactions}
        component={Select}
      />
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>ER Matching Bank Transaction:</h3>
    <LabeledField
        name={'matchMatchingTransaction'}
        className={"mr-2 clearing-min-width"}
        choices={clearingTransactions}
        component={Select}
      />
    <div className='flex row'>
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>EE Contribution Settled:</h3>
    <LabeledField
        name={'settled'}
        className={"mr-2 clearing-min-width"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
    <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>ER Match Settled:</h3>
    <LabeledField
        name={'matchSettled'}
        className={"mr-2 clearing-min-width"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
    </div>
    <div className='flex row'>
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width'}>Payroll File Balanced:</h3>
      <LabeledField
          name={'balanced'}
          className={"mr-2 clearing-min-width"}
          choices={[
            {label: 'No', value: 'false'},
            {label: 'Yes', value: 'true'},          
          ]}
          component={Select}
        />
      <h3 className={'text-lg my-2 mr-2 font-bold clearing-min-width text-red-600'}>Manually Transfer to FBO accounts*:</h3>
      <LabeledField
          name={'paid'}
          className={"mr-2 clearing-min-width"}
          choices={[
            {label: 'No', value: 'false'},
            {label: 'Yes', value: 'true'},          
          ]}
          component={Select}
        />
    </div>
    <div className='flex row'>
      <p className={"mt-8 text-red-600"}>*Do not mark it "yes" if money is not received and both EE and ER amounts agree with Payroll File. The process requires "settled", "match settled", and "Manually Transfer to FBO accounts" to be set to "Yes".</p>
    </div>
    
      <input type={'hidden'} value={values.id} name='id'/>
      <input type={'hidden'} value={values.version} name='version'/>
    </>
</div>;

const InboundClearingForm = ({doSubmit, errors, submitting, inboundClearing, adminOpen, 
  company, user, closeShowForm, clearingTransactions}) => {
  if (inboundClearing) {
    inboundClearing = {...inboundClearing};
  }

  let ssnOrEin = inboundClearing ? inboundClearing.ssnOrEin : null;
  if (ssnOrEin === undefined) {
    ssnOrEin = user ? user.ssn : null;
  }
  if (ssnOrEin === undefined) {
    ssnOrEin = company ? company.ein : null;
  }

  return <Formik
    initialValues={
      {
        name: inboundClearing && inboundClearing.company ? inboundClearing.company.name : '',
        payPeriodDate: inboundClearing ? inboundClearing.payPeriodDate : '',
        totalContributions: inboundClearing ? inboundClearing.totalContributions : '',
        totalMatch: inboundClearing ? inboundClearing.totalMatch : '',
        settled: inboundClearing ? inboundClearing.settled : false,
        matchSettled: inboundClearing ? inboundClearing.matchSettled : false,
        atomic: inboundClearing ? inboundClearing.atomic : false,
        statementsAdded: inboundClearing ? inboundClearing.statementsAdded : false,
        matchMade: inboundClearing ? inboundClearing.matchMade : false,
        balanced: inboundClearing ? inboundClearing.balanced : false,
        transfersMade: inboundClearing ? inboundClearing.transfersMade : false,
        matchingTransaction: inboundClearing ? inboundClearing.matchingTransaction : null,
        matchMatchingTransaction: inboundClearing ? inboundClearing.matchMatchingTransaction : null,
        matchType: inboundClearing ? inboundClearing.matchType : '',
        version: inboundClearing ? inboundClearing.version : null,
        id: inboundClearing ? inboundClearing.id : null,
        companyId: inboundClearing && inboundClearing.company ? inboundClearing.company.id : null,
        paid: inboundClearing ? inboundClearing.paid : false,
      }}
    onSubmit={(values) => {
      values.inboundClearingDate = values.inboundClearingDate ? `${values.inboundClearingDate}T12:00:00.000000Z` : null;
      values.escalationDate = values.escalationDate ? `${values.escalationDate}T12:00:00.000000Z` : null;
      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <InboundInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen} clearingTransactions={clearingTransactions}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Save</PrimaryButton><PrimaryButton onClick={closeShowForm}>Cancel</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default InboundClearingForm;

