import React, { useState } from 'react';
import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';

const PDFUpload = ({field, form, ...props}) => {
  const [company, setCompany] = useState(props.company);

  async function handleSelect(event) {
    const response = await props.onSelect(event.target.files[0]);

    // setImageSource(response);
    // setCompany(company);
  }

  return (
    <div>
      <Link onClick={() => {props.close()}} to={'#'} className="text-primary underline block pb-3 cursor-pointer">&lt; Back</Link>

      <h1>{props.cName}</h1>
            <br/>

      <label className="bg-primary px-6 py-3 mr-4 text-center text-white uppercase">
        <FormattedMessage id='image.upload.button'/><input type={'file'}
                      style={{display: 'none'}}
                      {...field}
                      onChange={event => handleSelect(event)} accept={['.pdf']}/>
      </label>
      {form.errors?.guideUrl &&
      <div className={'text-red-500 text-sm mt-4'}>{form.errors.guideUrl}</div>
      }
      {company.guide ?
        company.guideName : 'None of file' }
    </div>
  );
};

export default PDFUpload;
