import {types} from "mobx-state-tree"

const Balances = types.model("User", {
  contribution: types.maybeNull(types.number),
  match: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
  companyId: types.identifier,
});

export default Balances;
