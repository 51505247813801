import React, {useContext} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import EmployeeGoalForm from "../../../forms/EmployeeGoalForm";
import {observer} from "mobx-react";
import SavingsAmountSelector from "../../../components/SavingsAmountSelector";
import {FormattedMessage} from 'react-intl';

const SavingsPlanSettings = observer(({loadStep}) => {
  const {rootStore} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;

  if (rootStore.submitting) {
    return <Loading />;
  }

  return <div className={'md:p-8 p-4'}>
    {rootStore.currentUser.role === 'INDIVIDUAL' || employee.company ? <>
    <h3 className={'text-lg'}><FormattedMessage id={'settings.plan'}/></h3>
      <SavingsAmountSelector employee={employee} loadStep={loadStep}/></>
      : <></>
    }
    <h3 className={'text-lg mt-12 sm:mb-0 mb-2'}><FormattedMessage id={'plan.employee.goals'}/></h3>
    <EmployeeGoalForm doSubmit={employee.updateSavingsGoals}
                      errors={rootStore.formErrors.toJSON()}
                      employee={employee}
                      submitting={rootStore.submitting} loadStep={loadStep} />
  </div>;
});

export default SavingsPlanSettings;
