import React, {useContext} from "react";
import {observer} from "mobx-react";
import {formatMoney} from "../utils";
import moment from "moment";
import StoreContext from "../StoreContext";

const MatchTableTraditional = observer(function ({payroll}) {
  const {rootStore: {currentCompany}} = useContext(StoreContext);
  const plan = currentCompany.companyPlans[0];
  const sums = payroll ? payroll.payrollItems.reduce((sums, item) => {
    ['withdrawals', 'matchDue'].forEach((key) => {
      sums[key] = parseFloat(item[key]) + (sums[key] || 0);
    });

    return sums;
  }, {}) : '';


  const lookbackSums = payroll ? payroll.payrollReportItems.reduce((sums, item) => {
    if (item.lookback && item.lookback.length > 0) {
      return item.lookback.reduce((sums, lookback) => {
        ['baseContribution', 'bonusContribution'].forEach((key) => {
          sums[key] = parseFloat(lookback[key]) + (sums[key] || 0);
        });

        return sums;
      }, sums);
    } else if (item.tradLookBack && item.tradLookBack.length > 0) {
        return item.tradLookBack.reduce((sums, lookback) => {
          ['baseContribution', 'bonusContribution'].forEach((key) => {
            sums[key] = parseFloat(lookback[key]) + (sums[key] || 0);
          });
  
          return sums;
        }, sums);
      }
    }, {}) : '';

  // const lookbackSums = //payroll.payrollItems.reduce((sums, item) => {
  //   payroll.payrollReportItems.reduce((sums, lookback) => {
  //     ['baseContribution', 'bonusContribution'].forEach((key) => {
  //       sums[key] = parseFloat(lookback[key]) + (sums[key] || 0);
  //     });

  //     return sums;
  //   }, sums);
  // }, {});

  const renderLookback = function (item) {
    const total_contribution = parseFloat(item.baseContribution) + parseFloat(item.bonusContribution);
    return <tr key={item.id}>
      <td className={"p-3 text-left"}>{item.userName}</td>
      <td className={"p-3 text-left"}>{item.employeeid}</td>
      <td className={"p-3 text-left"}>{moment(item.matchperiod).utc().format('L')}</td>
      <td
        className={"p-3 text-left"}>{formatMoney(item.eligibleContribution)}</td>
      <td className={"p-3 text-left"}>{formatMoney(item.withdrawalAmount)}</td>
      <td className={"p-3 text-left"}>{formatMoney((item.eligibleContribution - (item.withdrawalAmount || 0)) * (plan.matchPercent / 100))}</td>
      <td className={"p-3 text-left"}></td>
    </tr>
  };

  return <div className={""}>
  <table className={"w-full table-auto"}>
    <thead>
    <tr>
      <th className={"p-3 text-left border"}>Name</th>
      <th className={"p-3 text-left border"}>Employee ID</th>
      <th className={"p-3 text-left border"}>Eligible Match Pay Periods</th>
      <th className={"p-3 text-left border"}>Eligible Match Employee Contributions</th>
      <th className={"p-3 text-left border"}>Withdrawals</th>
      <th className={"p-3 text-left border"}>Match Amount</th>
      <th className={"p-3 text-left border"}>Total Match</th>
    </tr>
    </thead>
    <tbody>
    {payroll ? payroll.matchType === 'TIMESTAMPED' ?
    
    payroll.payrollReportItems.map(item => 
    item.lookback ? item.lookback.map(item => renderLookback(item)) : ''
    .concat([
        <tr key={item.id}>
          <td className={"p-3 font-bold text-left"}>{item.name}</td>
          <td className={"p-3 font-bold text-left"}>{item.employeeId}</td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}>{formatMoney(item.matchAmount)}</td>
        </tr>
      ])
    ) :
    payroll.payrollReportItems.map(item => 
        <tr key={item.id}>
          <td className={"p-3 font-bold text-left"}>{item.name}</td>
          <td className={"p-3 font-bold text-left"}>{item.employeeId}</td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}></td>
          <td className={"p-3 font-bold text-left"}>{formatMoney(item.matchDue)}</td>
        </tr>
      )
    : ''}
    <tr>
      <td className={"p-3 text-left font-bold"}>Totals</td>
      <td className={"p-3 text-left"}/>
      <td className={"p-3 text-left"}/>
      <td
        className={"p-3 text-left font-bold"}>{lookbackSums ? formatMoney(lookbackSums.baseContribution + lookbackSums.bonusContribution) : ''}</td>
      <td className={"p-3 text-left font-bold"}>{formatMoney(sums.withdrawals)}</td>
      <td className={"p-3 text-left"}/>
      <td className={"p-3 text-left font-bold"}>{formatMoney(sums.matchDue)}</td>
    </tr>
    </tbody>
  </table>
  </div>
});

export default MatchTableTraditional;
