import React from "react";
import { FormattedMessage} from 'react-intl';

export default function Pagination({page, totalPages, setPage}) {
  return <div className={"text-center p-4 max-w-3xl mx-auto"}>
    {page > 1 && <span className={"text-primary cursor-pointer mr-2"}
                                onClick={() => setPage(1)}>&lt;&lt; First</span>
                                }
    {page > 1 && <span className={"text-primary cursor-pointer"}
                       onClick={() => setPage(page - 1)}>&lt; Back</span>}
    {totalPages && Array(totalPages).fill().map((e, n) =>
      (Math.abs(page - n) > 10 && ((n + 1) % 10)) ? null :
        <span key={n}
              className={page === n + 1 ? 'font-bold m-2 cursor-pointer' : 'm-2 cursor-pointer'}
              onClick={() => setPage(n + 1)}>{n + 1}</span>).reduce((a, b) => [a, ' ', b])}
    {page < totalPages && <span className={"text-primary cursor-pointer"}
                                onClick={() => setPage(page + 1)}><FormattedMessage id={'next'}/> &gt;</span>
                                }
    {page < totalPages && <span className={"text-primary cursor-pointer ml-2"}
                                onClick={() => setPage(totalPages)}><FormattedMessage id={'last'}/> &gt;&gt;</span>
                                }
  </div>
}
