import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Link } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";
import DataRequestSheet from "../employer/settings/DataRequestSheet";

export const DataRequest = (props) => {

    const {rootStore, rootStore: {currentCompany, currentUser}} = useContext(StoreContext);
    const { companyId, accountHolderId, reportType, showForm, setShowForm } = props;
    const {authStore} = useContext(StoreContext);
    const [invoices, setInvoices] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    const [endDate, setEndDate] = useState(endDateDay);
    
    const [dataRequestId, setDataRequestId] = useState();

    useEffect(() => {
        getInvoices();
      }, [reportType]);

    const openShowForm = () => {
        window.scrollTo(0,0);
        setShowForm(true);        
    }

    const closeShowForm = () => {
        window.scrollTo(0,0);
        setSelectedDataRequestId(null);
        getInvoices();
        setShowForm(false);
    }

    const setSelectedDataRequestId = (id) => {
        setDataRequestId(id);
        openShowForm(true);
    }

    const getInvoices = async () => {
        let url = `/api/v1/dataRequest/all/${dayjs(startDate).format("MMDDYYYY")}/${dayjs(endDate).format("MMDDYYYY")}/${reportType}`;
        if (companyId && !accountHolderId) {
            url = `/api/v1/dataRequest/by/${companyId}/company/${reportType}`
        }
        if (!companyId && accountHolderId) {
            url = `/api/v1/dataRequest/by/${accountHolderId}/user/${reportType}`
        }
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setInvoices(resp);
    }
   
    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };
    
    return (
        <>
        {companyId === undefined && accountHolderId === undefined && showForm === false ?
        <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <PrimaryButton style={{marginTop: "20px", marginRight: "10px"}} onClick={getInvoices} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid>
        : showForm !== true ?
        <Grid item>
            <PrimaryButton style={{marginTop: "20px"}} className={'mb-4'} onClick={openShowForm} variant="contained" >New Data Request</PrimaryButton>
        </Grid> : ''
        }
        {showForm === true ? 
        <DataRequestSheet companyId={companyId} accountHolderId={accountHolderId} closeShowForm={closeShowForm} dataRequestId={dataRequestId}/>
        :
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'Case No',
                    field: 'caseNumber',
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedDataRequestId(row.id)}} className={"cursor-pointer"}>
                            {row.caseNumber}
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'Date of Data Request',
                    field: 'dataRequestDate', 
                    render: (rowData) => {
                        if (rowData.dataRequestDate) {
                            return (<>{rowData.requestDate ? dayjs(rowData.requestDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Last Name',
                    field: 'lastName'
                },
                {
                    title: 'First Name',
                    field: 'firstName'
                },
                {
                    title: 'Resident State',
                    field: 'residentState'
                },
                {
                    title: 'Customer SSN/EIN',
                    field: 'ssnOrEin'
                },
                {
                    title: 'Company Name',
                    field: 'companyName'
                },
                {
                    title: 'Type',
                    field: 'requestType'
                },
                {
                    title: 'PII involved',
                    field: 'piiInvolved', 
                    render: (rowData) => {
                        if (rowData.piiInvolved) {
                            return (<>{rowData.piiInvolved.length > 100 ?  rowData.piiInvolved.substring(0, 100) + '...': rowData.piiInvolved}</>);
                        } 
                    }
                },
                {
                    title: 'Request Details',
                    field: 'requestDetails', 
                    render: (rowData) => {
                        if (rowData.requestDetails) {
                            return (<>{rowData.requestDetails.length > 100 ?  rowData.requestDetails.substring(0, 100) + '...': rowData.requestDetails}</>);
                        } 
                    }
                },
                {
                    title: 'Reason',
                    field: 'reason', 
                    render: (rowData) => {
                        if (rowData.reason) {
                            return (<>{rowData.reason.length > 100 ?  rowData.reason.substring(0, 100) + '...': rowData.reason}</>);
                        } 
                    }
                },
                {
                    title: 'Date of Email Ack Sent to User',
                    field: 'ackEmailDate', 
                    render: (rowData) => {
                        if (rowData.ackEmailDate) {
                            return (<>{rowData.ackEmailDate ? dayjs(rowData.ackEmailDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Date of Chief Compliance Officer Notified',
                    field: 'cooNotifiedDate', 
                    render: (rowData) => {
                        if (rowData.cooNotifiedDate) {
                            return (<>{rowData.cooNotifiedDate ? dayjs(rowData.cooNotifiedDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Date of VP of Tech Notified',
                    field: 'vpTechNotifiedDate', 
                    render: (rowData) => {
                        if (rowData.vpTechNotifiedDate) {
                            return (<>{rowData.vpTechNotifiedDate ? dayjs(rowData.vpTechNotifiedDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Document List',
                    field: 'documentList', 
                    render: (rowData) => {
                        if (rowData.documentList) {
                            return (<>{rowData.documentList.length > 100 ?  rowData.documentList.substring(0, 100) + '...': rowData.documentList}</>);
                        } 
                    }
                },
                {
                    title: 'Involved',
                    field: 'involvedLegalOrExpert', 
                    render: (rowData) => {
                        if (rowData.involvedLegalOrExpert) {
                            return (<>{rowData.involvedLegalOrExpert.length > 100 ?  rowData.involvedLegalOrExpert.substring(0, 100) + '...': rowData.involvedLegalOrExpert}</>);
                        } 
                    }
                },
                {
                    title: 'Conflict with MS partners?',
                    field: 'msConflict', 
                    render: (rowData) => {
                        if (rowData.msConflict === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Escalated to Sponsor Bank?',
                    field: 'bankEscalation', 
                    render: (rowData) => {
                        if (rowData.bankEscalation === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Date Referred to Sponsor Bank',
                    field: 'bankEscalationDate', 
                    render: (rowData) => {
                        if (rowData.bankEscalationDate) {
                            return (<>{rowData.bankEscalationDate ? dayjs(rowData.bankEscalationDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Can Conflict be Resolved?',
                    field: 'conflictResolvable', 
                    render: (rowData) => {
                        if (rowData.conflictResolvable === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Discussion',
                    field: 'complianceOfficerAndVpDiscussion', 
                    render: (rowData) => {
                        if (rowData.complianceOfficerAndVpDiscussion) {
                            return (<>{rowData.complianceOfficerAndVpDiscussion.length > 100 ?  rowData.complianceOfficerAndVpDiscussion.substring(0, 100) + '...': rowData.complianceOfficerAndVpDiscussion}</>);
                        } 
                    }
                },
                {
                    title: 'Approved?',
                    field: 'approved', 
                    render: (rowData) => {
                        if (rowData.approved === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Action Required?',
                    field: 'actionOnAccountRequired', 
                    render: (rowData) => {
                        if (rowData.actionOnAccountRequired === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Actions Taken',
                    field: 'actionsTaken', 
                    render: (rowData) => {
                        if (rowData.actionsTaken) {
                            return (<>{rowData.actionsTaken.length > 100 ?  rowData.actionsTaken.substring(0, 100) + '...': rowData.actionsTaken}</>);
                        } 
                    }
                },
                {
                    title: 'Action Completed/Resolved Date',
                    field: 'actionCompletionDate', 
                    render: (rowData) => {
                        if (rowData.actionCompletionDate) {
                            return (<>{rowData.actionCompletionDate ? dayjs(rowData.actionCompletionDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "invoices",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={invoices}
        />
        </div>
        }
        </>
        
    );
}