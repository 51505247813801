import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import EmployerMap from "../../../components/EmployerMap";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import PrimaryButton from "../../../components/PrimaryButton";
import { apiGet, apiPost } from "../../../utils";
import FeeInfoForm from "../../../forms/FeeInfoForm";

const FeeSettings = observer(() => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [company, setCompany] = useState({}); 
  const adminOpen = rootStore.adminStore.impersonating;

  const loadCompany = async() => {
    const resp = await apiGet(`/api/v1/company/get/${currentCompany.id}`,rootStore.authStore);

    const response = await resp.json();
    setCompany(response);

  }

  const updateCompany = async(values) => {
    const resp = await apiPost(`/api/v1/company/update/companySettings/${values.id}`, values, rootStore.authStore, 'Company Settings Updated');
    setCompany(resp);
  }
  
  useEffect(() => {
    loadCompany();
  }, []);


  if (!currentCompany) {
    return <Loading />;
  }

  return <div>
    <h3 className={'text-lg mt-8'}>Update Company Fee Information</h3>
    <AlertProvider/>
    <p className={'py-4'}>
      Complete all sections of requested company fees. All dates default to today's date if not set.
    </p>
    {company && company.id ? <FeeInfoForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      company={company} doSubmit={(values) => {
        updateCompany(values);
      }
      } />
    : '' }
  </div>;
});

export default FeeSettings
