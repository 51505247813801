import React, {Component, useRef, useEffect} from "react";
import Chart from "chart.js";

export default function LineChart({datasets, labels=null, displayLegend=true, ...props}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = new Chart(chartRef.current.getContext("2d"), {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        legend: {
          align: 'start',
          labels: {
            usePointStyle: true,
          },
          display: displayLegend,
        },
        tooltips: {
          callbacks: {
            title: function (items, data) {
              const item = items[0];
              const value = data.datasets[item.datasetIndex].data[item.index];
              if (value.x) {
                return value.x;
              } else {
                return item.label;
              }
            },
            label: function (tooltipItem, data) {
              const index = tooltipItem.index;
              let label = data.datasets[tooltipItem.datasetIndex].label;
              let value = data.datasets[tooltipItem.datasetIndex].data[index];
              if (value.x && value.y) {
                value = value.y;
              }

              if (typeof props.formatValue === 'function') {
                value = props.formatValue(value);
              }

              return `${label}: ${value}`;
            }
          }
        }
      },
      plugins: [{
        beforeInit: function(chart, options) {
          chart.legend.afterFit = function() {
            this.height = this.height + 20;
          };
        }
      }]
    });
    return () => chart.destroy();
  }, [datasets, labels, displayLegend]);

  return <div>
    <canvas ref={chartRef} height={200} width={500} className={"linechart"} />
  </div>;
}
