import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import EmployerBankForm from "../../../forms/EmployerBankForm";
import Alert from "../../../components/Alert";
import EmployerBankInstructionForm from "../../../forms/EmployerBankInstructionForm";
import PlaidBankForm from "../../../forms/PlaidBankForm";
import { Link } from "react-router-dom";
import { apiGet } from "../../../utils";


const CompanyBankSettings = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [increment, setIncrement] = useState(1);
  const [showPlaid, setShowPlaid] = useState(true);
  const [banks, setBanks] = useState([]);

  const URI = `/api/v1/bankConnection/company/${rootStore.currentCompany.id}/all`;

  useEffect(() => {
    rootStore.setLoading(true);
    loadBanks();
  }, []);
  
  const loadBanks = async () => {    
    const response = await apiGet(URI, rootStore.authStore);//company
    const resp = await response.json();
    
    // setBanks(_.filter(resp, {plaidVerified: true}));
    setBanks(resp);
    rootStore.setLoading(false);
  }
  
  // useEffect(() => {
  //   currentCompany.loadBanks();
  // }, []);


  if (!currentCompany) {
    return <Loading />;
  }

  const toggleButton = () => {
    if (showPlaid === true) {
      setShowPlaid(false);
    } else {
      loadBanks();
      setShowPlaid(true);
    }
  }

  return <div className={'max-w-2xl'}>
    
    <EmployerBankInstructionForm rootStore={rootStore} company={currentCompany} increment={increment} setIncrement={setIncrement}/>
    <h3 className={'text-lg mt-8'}>Bank Accounts</h3>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    <p className={'py-4'}>Please enter the bank accounts that you wish to associate with your company's ISA plan.</p>
    <div class="flex justify-start border-b border-gray-200 relative hide-print">
      <Link onClick={showPlaid ? () => {} : toggleButton} 
      className={`block relative top-1.5 text-center text-sm py-4 my-1 sm:px-8 px-1 ${showPlaid === true ? 'font-bold border-b-3 border-solid border-primary' : ''}`}>Plaid</Link>
      <Link onClick={showPlaid ? toggleButton : () => {}} 
      className={`block relative top-1.5 text-center text-sm py-4 my-1 sm:px-8 px-1 ${showPlaid === false ? 'font-bold border-b-3 border-solid border-primary' : ''}`}>Manual</Link>
    </div>
    {showPlaid ? 
      <PlaidBankForm errors={rootStore.formErrors.toJSON()} email={rootStore.currentUser.email} isCompany={true} rootStore={rootStore} 
      doSubmit={(values) => {
        currentCompany.updateContact(values);
        loadStep();
      }
      } 
      increment={increment} setIncrement={setIncrement}  banks={banks} setBanks={loadBanks}/>
      : 
      <EmployerBankForm errors={rootStore.formErrors.toJSON()} company={currentCompany} rootStore={rootStore} 
      doSubmit={(values) => {
        currentCompany.updateContact(values);
        loadStep();  
      }
      }  
      increment={increment} setIncrement={setIncrement}/>
    }
    
    
  </div>;
});

export default CompanyBankSettings
