import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useHistory} from "react-router-dom";
import EmployeeDetailsForm from "../../forms/EmployeeDetailsForm";
import AlertProvider from "../../AlertProvider";
import AdminAccountForm from "../../forms/AdminAccountForm";
import GoBack from "../GoBack";
import { scrollToTop } from "../../utils";

const NewOwner = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const history = useHistory();

  return <div>
      <AlertProvider/>
    <AdminAccountForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting} newAdmin={true}
                         doSubmit={async (values) => {
                          scrollToTop();
                           if (await currentCompany.createOwner(values)) {
                             await currentCompany.loadOwners(1);
                             history.push('ownerList');
                             loadStep();
                           }
                         }} />
  </div>;
});

export default NewOwner;
