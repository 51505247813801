import React, {useContext} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import Loading from "./Loading";
import PrimaryButton from "./PrimaryButton";
import StoreContext from "../StoreContext";
import moment from "moment";

export default function CompanyTable({companies}) {
  const {adminStore} = useContext(StoreContext);
  const history = useHistory();
  let {  url } = useRouteMatch();

  if (!companies) {
    return <Loading/>;
  }

  function viewDashboard(company) {
    adminStore.startImpersonating(company);
    history.push(`${url}/companydashboard`);
  }

  return <div>
    <table className={'w-full table-auto'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 py-3"}>Company Name</th>
        <th className={"text-left text-sm text-gray-400"}>KYB Status</th>
        <th className={"text-left text-sm text-gray-400"}>KYB Date</th>
      </tr>
      </thead>
      <tbody>
      {companies.map((company) => <tr key={company.id}>
        <td className={"text-left text-sm p-3"}>{company.name}</td>
        <td className={"text-left text-sm"}>{company.kybSend && company.kybApproved === false ? 'Pending' : company.kybApproved ? 'Approved' : ''}</td>
        <td className={"text-left text-sm"}>{company.kybStatusDate ? moment(company.kybStatusDate).utc().format('L') : ''}</td>
        <td className={"text-right text-sm p-3"}>
          <PrimaryButton onClick={() => viewDashboard(company)}>View Dashboard</PrimaryButton>
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
