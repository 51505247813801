
import React from 'react';
import { useHistory, Link } from 'react-router-dom';

export default function GoBack() {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    return (
        <>
        <Link onClick={goBack} to="#" className="text-primary underline block pb-3 cursor-pointer">&lt; Back</Link>
        </>

);
}

