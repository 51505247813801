import {observer} from "mobx-react";
import Loading from "./Loading";
import PrimaryButton from "./PrimaryButton";
import React, {useContext, useState} from "react";
import StoreContext from "../StoreContext";
import Input from "./Input";
import { FormattedMessage, useIntl} from 'react-intl';
import {planDescription} from '../utils';
import { useRouteMatch, Link } from "react-router-dom";

const DashboardSavingsAmountSelector = observer(({employee}) => {
  const {rootStore} = useContext(StoreContext);
  const [matchPercent, setMatchPercent] = useState(parseFloat(employee.matchPercent).toFixed(2));
  const [bonusMatchPercent, setBonusMatchPercent] = useState(parseFloat(employee.bonusMatchPercent).toFixed(2));
  const intl = useIntl();
  let {  url } = useRouteMatch();

  if (rootStore.currentUser.role === 'EMPLOYEE' && employee.company.companyPlans.length === 0) {
    return <div><FormattedMessage id={'dashboard.selector.incomplete'}/></div>
  }

  const plan = employee.company ? employee.company.companyPlans[0] : undefined;

  return <div className={'flex flex-col lg:flex-row'}>
    <div className={'text-sm flex-1'}>
      <div className="float-right withdrawLink-sm">
        <Link to={`${url}/withdraw`} className={"text-primary underline"}><FormattedMessage id={'dashboard.withdrawal'}/></Link>
      </div>
      <h3 className={'text-lg mt-8'}><FormattedMessage id={'dashboard.selector.title'}/></h3>
      {/* <p className={'text-sm'}>
      {employee.company ?
        planDescription(plan, intl)
        : ''
      }
      </p> */}
    </div>
    <div className={'flex-1 flex flex-col sm:flex-row justify-start lg:justify-end sm:items-end'}>
    <div className={"w-full sm:w-40 sm:mr-4 mt-2"}>
          <p className={'text-sm'}><FormattedMessage id={'dashboard.selector.regular'}/></p>
          <div className={'percent-input'}>
            <Input
                type={"number"}
                field={{value: matchPercent}}
                onChange={(e) => setMatchPercent(e.target.value)}
            />
          </div>
        </div>
        {/* <div className={"w-full sm:w-40 sm:mr-4 mt-2"}>
          <p className={'text-sm'}><FormattedMessage id={'dashboard.selector.bonus'}/></p>
          <div className={'percent-input'}>
            <Input
                type={"number"}
              field={{value: bonusMatchPercent}}
              onChange={(e) => setBonusMatchPercent(e.target.value)}
            />
          </div>
        </div> */}
      {/* <div className={"flex w-full my-4 items-center"}>
        
      </div> */}

      <div className={'mt-4'}>
        {rootStore.submitting ? <Loading/> :
          <PrimaryButton 
          className={''}
          onClick={() => employee.updateSavings({
            matchPercent: matchPercent === 'NaN' || matchPercent === '' ? '0':matchPercent,
            bonusMatchPercent: bonusMatchPercent === 'NaN' || bonusMatchPercent === '' ? '0':bonusMatchPercent,
          })}><FormattedMessage id={'save.button'}/></PrimaryButton>}
      </div>
  </div>
  </div>;
});

export default DashboardSavingsAmountSelector;
