import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useHistory} from "react-router-dom";
import EmployeeDetailsForm from "../../forms/EmployeeDetailsForm";
import AlertProvider from "../../AlertProvider";

const NewEmployee = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const history = useHistory();

  return <div>
    <h3 className={"text-xl mb-3"}>New Employee</h3>
      <AlertProvider/>
    <EmployeeDetailsForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
                         doSubmit={async (values) => {
                           if (await currentCompany.createEmployee(values)) {
                             await currentCompany.loadEmployees(1, '');
                             history.push('employees');
                           }
                         }} />
  </div>;
});

export default NewEmployee;
