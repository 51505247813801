import React from "react";
import moment from "moment";
import Loading from "./Loading";
import {formatMoney} from "../utils";

export default function TransactionDivTable({transactions}) {
  return transactions ? <>
  <div className={"flex row"}>
      <div className={`flex row transaction-div-header`}>
        <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left"}>Name</div>
      </div>
      <div className={`flex row transaction-div-header`}>
        <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left"}>Transaction Date</div>
      </div>
      <div className={`flex row transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}>Employee Contribution</div>
      </div>
      <div className={`flex row transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}>Employer Match</div>
      </div>
      <div className={`flex row transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}>Status</div>
      </div>
  </div> 
    {transactions.map((transaction, index) => {
      return <div className={`flex row float-left transaction-div-card`} style={{backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
        <div className={`flex row transaction-div-column float-left`}>
          <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"} >Name</div>
          <div className={"text-left text-sm px-2 py-3-over float-left"}>{transaction.withdrawalAmountD > 0 || transaction.withdrawalAmountM > 0 ? 'Employee Withdrawal' : transaction.userName}</div>
        </div>
        <div className={`flex row transaction-div-column float-left`}>
          <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}>Transaction Date</div>
          <div className={"text-left text-sm px-2 py-3-over float-left"}>{moment(transaction.created).utc().format('L')}</div>
        </div>
        <div className={`flex row transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}>Employee Contribution</div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.withdrawalAmountD > 0 ? `-$${parseFloat(transaction.withdrawalAmountD).toFixed(2)}` : transaction.depositAmount > 0 ? formatMoney(transaction.depositAmount) : ''}</div>
        </div>
        <div className={`flex row transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}>Employer Match</div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.withdrawalAmountM > 0 ? `-$${parseFloat(transaction.withdrawalAmountM).toFixed(2)}` : transaction.matchAmount > 0 ? formatMoney(transaction.matchAmount) : ''}</div>
        </div>
        <div className={`flex row transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}>Status</div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.status || 'Paid'}</div>
        </div>
      </div>
    })}
   
  </>
  : <Loading />;
}
