import React from "react";
import moment from "moment";
import Loading from "./Loading";
import {formatMoney} from "../utils";

export default function TransactionTable({transactions}) {
  return transactions ? 
  <div className={"overflow-auto w-screenn table-cuswidth md:w-full"}>
    <table className={"w-full table-auto whitespace-nowrap"}>
    <thead>
    <tr>
      <th className={"text-left text-sm text-gray-400 py-3 px-2 text-normal"}>Name</th>
      <th className={"text-left text-sm text-gray-400 py-3 px-2 text-normal"}>Transaction Date</th>
      <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}>Employee Contributions</th>
      <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}>Employer Match</th>
      <th className={"text-right text-sm text-gray-400 py-3 px-2 text-normal"}>Status</th>
    </tr>
    </thead>
    <tbody>
    {transactions.map(transaction => {
      return <tr key={transaction.id}>
        <td className={"text-left text-sm px-2 py-3"}>{transaction.withdrawalAmountD > 0 || transaction.withdrawalAmountM > 0 ? 'Employee Withdrawal' : transaction.userName}</td>
        <td className={"text-left text-sm px-2 py-3"}>{moment(transaction.created).utc().format('L')}</td>
        <td className={"text-right text-sm px-2 py-3"}>{transaction.withdrawalAmountD > 0 ? `-$${parseFloat(transaction.withdrawalAmountD).toFixed(2)}` : transaction.depositAmount > 0 ? formatMoney(transaction.depositAmount) : ''}</td>
        <td className={"text-right text-sm px-2 py-3"}>{transaction.withdrawalAmountM > 0 ? `-$${parseFloat(transaction.withdrawalAmountM).toFixed(2)}` : transaction.matchAmount > 0 ? formatMoney(transaction.matchAmount) : ''}</td>
        <td className={"text-right text-sm px-2 py-3"}>{transaction.status || 'Paid'}</td>
      </tr>
    })}
    </tbody>
  </table>
  </div> : <Loading />;
}
