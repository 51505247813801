import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import InboundClearingForm from "../../../forms/InboundClearingForm";
import { apiGet, apiPost } from "../../../utils";
import PrimaryButton from "../../../components/PrimaryButton";
import _ from "lodash";
import dayjs from "dayjs";
import { formatMoney } from "../../../utils";
import { Link } from "@material-ui/core";

const InboundClearingSheet = observer((props) => {
  const {rootStore} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating || rootStore.impersonatingEmployee;
  const [inboundClearing, setInboundClearing] = useState({});
  const [clearingTransactions, setClearingTransactions] = useState([]);
  const { inboundClearingId, closeShowForm } = props;
  const {authStore} = useContext(StoreContext);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  // const [load3, setLoad3] = useState(false);

  const loadInboundClearing = async () => {
    if (inboundClearingId) {
      const response = await apiGet(`/api/v1/clearing/get/${inboundClearingId}`, authStore);
      const inboundClearing = await response.json();
      setInboundClearing(inboundClearing);
      loadClearingAccount(inboundClearing.company.id)
      // loadCompany(inboundClearing.companyId);
    }
    setLoad1(true);
  }

  const loadClearingAccount = async (id) => {
    const response = await apiGet(`/api/v1/clearing/get/clearing/${id}/available`, authStore);
    const transactions = await response.json();

    let filtered = [];
    filtered.push({label: 'Please Choose One', value: null});

    await _.forEach(transactions, (tx) => {
      tx.label = `Posted: ${dayjs(tx.postedDt).format('MM-DD-YYYY')}${formatMoney(tx.amount).padStart(15, '.')} ..... Desc: ${tx.descList}`;
      tx.value = tx.id;
      filtered.push(tx);
    });
    setClearingTransactions(filtered);
    setLoad2(true);
  }

  const updateInboundClearing = async (values) => {
    const response = await apiPost(`/api/v1/clearing/${values.id}/update`, values, authStore, 'Update Inbound Clearing');
    setInboundClearing(response);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    loadInboundClearing();
    // loadClearingAccount();
  }, []);


  if (!(load1 === true && load2 === true)) {
    return <Loading />;
  }

  return <div>
    <Link onClick={closeShowForm} className="text-primary underline block pb-3 cursor-pointer">&lt; Back</Link>
    <PrimaryButton onClick={window.print} className={'float-right'}>Print</PrimaryButton>
    {/* <PrimaryButton onClick={closeShowForm} className={'float-right mr-4'}>Cancel</PrimaryButton> */}
    <h3 className={'text-2xl mt-8'}>Inbound Clearing</h3>
    <p className={"mt-8 text-blue-600"}>Use this screen to match up transactions with payrolls. If the match or matches on transaction suffice for payment, then mark it "paid".
      The regularly running job in the background will then pick it up for transfer to user sub accounts.
      To clear an item from the list, both "settled" and "match settled" must be set to true. </p>
    <AlertProvider/>
    {load1 === true && load2 === true ?
    <div className="mt-10">
    <InboundClearingForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      inboundClearing={inboundClearing} closeShowForm={closeShowForm} clearingTransactions={clearingTransactions}
      doSubmit={(values) => {
        updateInboundClearing(values);
      }
      } />
    </div>
     : ''}
  </div>;
});

export default InboundClearingSheet
