import React, {Component, useContext, useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch, useParams,
} from "react-router-dom";
import EmployerLayout from "./layouts/EmployerLayout";
import StoreContext from "./StoreContext";
import {observer} from "mobx-react";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import StoreStateManager from "./StoreStateManager";
import EmployeeLayout from "./layouts/EmployeeLayout";
import AdminLayout from "./layouts/AdminLayout";
import ValidateEmployee from "./pages/employee/ValidateEmployee";
import RootStore from "./models/RootStore";
import NewCompany from "./pages/employer/NewCompany";

const AppRouter = observer(({locales, locale, changeLanguage, fmt}) => {
  const {rootStore, adminStore} = useContext(StoreContext);
  const {userId} = useParams();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setCurrentUser(await rootStore.loadCurrentUser(userId));
      setLoading(false)
    }
    fetchData();

  }, []);


  if (loading || !currentUser || !rootStore) {
    return <div className={"flex justify-center items-center h-screen"}><Loading/></div>;
  }

  
 if (currentUser?.role === 'EMPLOYER' || adminStore.impersonating) {
  return <Router>
      <StoreStateManager>
        <ErrorBoundary>
          <Switch>
            <Route path={`/approuter/${userId}/dashboard/create-company`}>
              <NewCompany/>
            </Route>
            <Route path={`/approuter/${userId}/dashboard`}>
              <EmployerLayout company={rootStore.currentCompany} userId={userId} locale={locale} 
                                locales={locales} changeLanguage={changeLanguage} fmt={fmt}/>
            </Route>
          </Switch>
        </ErrorBoundary>
      </StoreStateManager>
    </Router>;
  } else if (currentUser?.role === 'EMPLOYEE' || currentUser?.role === "INDIVIDUAL" || rootStore.impersonatingEmployee) {//TODO - Should this be both
    return <Router>
      <StoreStateManager>
          <Switch>
            <Route path="/verify">
              <ErrorBoundary key = "validateEmployee">
                 <ValidateEmployee/>
              </ErrorBoundary>
            </Route>
            <Route path={`/approuter/${userId}/dashboard`}>
              <ErrorBoundary key = "Employee Layout">
                <EmployeeLayout employee={rootStore.currentEmployeeRecord} userId={userId} locale={locale} 
                                locales={locales} changeLanguage={changeLanguage} fmt={fmt}/>
              </ErrorBoundary>
            </Route>
          </Switch>
      </StoreStateManager>
    </Router>;
  } else if (currentUser?.role === 'ADMIN' || currentUser?.role === 'BSA' || currentUser?.role === 'CSA') {
    return <Router>
      <StoreStateManager>
        <ErrorBoundary>
          <Switch>
            <Route path={`/approuter/${userId}/dashboard`}>
              <AdminLayout user={rootStore.currentUser} locale={locale} 
                                locales={locales} changeLanguage={changeLanguage} fmt={fmt}/>
            </Route>
          </Switch>
        </ErrorBoundary>
      </StoreStateManager>
    </Router>;
  }
});

export default AppRouter;
