import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import { useForm } from 'react-hook-form';
import { apiDelete, apiGet, apiPost } from '../utils';
import StateInput from "../components/StateInput";
import _ from "lodash";
import * as Yup from "yup";
import { scrollToTop } from '../utils';
import RadioSelect from '../components/RadioSelect';
import { usePlaidLink,PlaidLinkOptions,PlaidLinkOnSuccess} from 'react-plaid-link';
import { FormattedMessage } from 'react-intl';

const EmployerBankForm = ({errors,  company, rootStore, increment, setIncrement}) => {
  const [enabled, setEnabled] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [banks, setBanks] = useState(company.banks || []);

  const config = {
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    // token: 'GENERATED_LINK_TOKEN',
    token: null,
    //required for OAuth; if not using OAuth, set to null or omit:
    receivedRedirectUri: window.location.href,
  };
  const { open, exit, ready } = usePlaidLink(config);

  const {register} = useForm();

  useEffect(() => {
      loadBanks();
  }, [company, increment]);

  const loadBanks = async () => {
      const response = await apiGet(`/api/v1/bankConnection/company/${company.id}`, rootStore.authStore);//company
      const resp = await response.json();
      setBanks(resp);
  }

  const submit = async (values) => {
      await _.forEach(values.banks, (bank) => {
          bank.companyId = company.id;
      })
      setSubmitting (true);
      const response = await apiPost(`/api/v1/bankConnection/company/${company.id}`, values, rootStore.authStore, 'Bank Account Added');

      setBanks(response);
      setSubmitting (false);

      setIncrement(1);
  }
  const onRemove = async (id) => {
    
    await apiDelete(`/api/v1/bankConnection/delete/${id}`, rootStore.authStore, 'Bank Account');

    setBanks(banks.filter(bank =>bank.id!==id));
    setIncrement(1);
  };

  return <><p className="mt-5 text-red-500"><FormattedMessage id={'bank.manual'}/></p><Formik
      enableReinitialize={true}
      initialValues={{
          banks: banks.length > 0 ? banks : [{
            name: '',
            accountNumber: '',
            routingNumber: '',
            address1: '',
            address2: '',
            city: '',
            state:'',
            zip: '',
            version: null,
            active: true,
            id: null,
            type: 'CHECKING',
            nameOnAccount: '',
          }]
      }}
      onSubmit={(values) => {
          scrollToTop();
          submit(values)
      }}
      validationSchema={Yup.object().shape({
          banks: Yup.array().of(Yup.object().shape({
              name: Yup.string()
                  .required('Bank Name is Required'),
              accountNumber: Yup.string()
                  .required("Account Number is Required"),
              routingNumber: Yup.string()
                  .required("Routing number is required")
                  .test('len', 'Must be 9 characters', val => val.length === 9),
              address1: Yup.string()
                  .required("Address 1 is required"),
              city: Yup.string()
                  .required("City is required"),
              state: Yup.string()
                  .required("State is required"),
              zip: Yup.string()
                  .required("Zip is required"),
              nameOnAccount: Yup.string()
                  .required("Account Name is required")
          })) //end array
      })
      }
  >{({setFieldValue, values}) =>
      <Form>
          <FormErrors errors={errors}>
              <FieldArray
                  name="banks"
                  render={arrayHelpers => (<>
                          {values.banks.map((bank, index) => <div className={'p-4 bg-subtle-blue mb-4'}
                                                                        key={index}>
                              <p className={'font-bold'}>Account {index + 1}</p>
                              <input type={'hidden'} value={bank.id}/>
                                  <LabeledField
                                      id={'name'}
                                      className={"flex-1"}
                                      label={'Bank Name'}
                                      disabled={!enabled[index]}
                                      required
                                      name={`banks.${index}.name`}
                                      value={values.banks[index].name}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.name`, e.target.value);
                                      }}
                                      register={`banks.${index}.name`}
                                  />
                                  <LabeledField
                                      id={'nameOnAccount'}
                                      className={"flex-1"}
                                      label={'Name on Account'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.nameOnAccount`}
                                      value={values.banks[index].nameOnAccount}
                                      required
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.nameOnAccount`, e.target.value);
                                      }}
                                      register={`banks.${index}.nameOnAccount`}
                                  />
                                   <LabeledField
                                    id={'type'}
                                    className={"flex-1"}
                                    required
                                    label={'Bank Account Type'}
                                    name={`banks.${index}.type`}
                                    value={values.banks[index].type}
                                    choices={[
                                        {label: 'Checking', value: 'BUSINESSCHECKING'},
                                        {label: 'Savings', value: 'BUSINESSSAVINGS'},
                                    ]}
                                    component={RadioSelect}
                                    />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Account Number'}
                                      disabled={!enabled[index]}
                                      required
                                      name={`banks.${index}.accountNumber`}
                                      value={values.banks[index].accountNumber}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.accountNumber`, e.target.value);
                                      }}
                                      register={`banks.${index}.accountNumber`}
                                  />
                                  <LabeledField
                                      className={"flex-1"}
                                      label={'Routing Number'}
                                      disabled={!enabled[index]}
                                      required
                                      name={`banks.${index}.routingNumber`}
                                      value={values.banks[index].routingNumber}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.routingNumber`, e.target.value);
                                      }}
                                      register={`banks.${index}.routingNumber`}
                                  />
                                  <LabeledField
                                      className={"flex-1 mr-8"}
                                      label={'Address 1'}
                                      disabled={!enabled[index]}
                                      required
                                      name={`banks.${index}.address1`}
                                      value={values.banks[index].address1}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.address1`, e.target.value);
                                      }}
                                      register={`banks.${index}.address1`}
                                  />
                                  <LabeledField
                                      className={"flex-1 mr-8"}
                                      label={'Address 2'}
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.address2`}
                                      value={values.banks[index].address2}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.address2`, e.target.value);
                                      }}
                                      register={`banks.${index}.address2`}
                                  />
                                  <LabeledField
                                      className={"flex-1 mr-8"}
                                      label={'City'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.city`}
                                      value={values.banks[index].city}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.city`, e.target.value);
                                      }}
                                      register={`banks.${index}.city`}
                                  />
                                  <LabeledField
                                      className={"flex-1 mr-8"}
                                      label={'State'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.state`}
                                      value={values.banks[index].state}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.state`, e.target.value);
                                      }}
                                      register={`banks.${index}.state`}
                                      component={StateInput}
                                  />
                                  <LabeledField
                                      className={"flex-1 mr-8"}
                                      label={'Zip'}
                                      required
                                      disabled={!enabled[index]}
                                      name={`banks.${index}.zip`}
                                      value={values.banks[index].zip}
                                      onChange={e => {
                                          setFieldValue(`banks.${index}.zip`, e.target.value);
                                      }}
                                      register={`banks.${index}.zip`}
                                  />
                                  <div className={"flex-1 pt-10 pb-3 flex"}>
                                      <PrimaryButton type={'button'} className={'pr-4 mr-4'}
                                                     onClick={() => {setEnabled({[index] : true, ...enabled}); scrollToTop();} }>Edit</PrimaryButton>
                                      <PrimaryButton type={'button'} className={'mr-4'}
                                                     onClick={() => {onRemove(bank.id); arrayHelpers.remove(index); scrollToTop();}}>Remove</PrimaryButton>
                                      {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
                                  </div>
                          </div>)}
                          <FieldGroup>
                              <PrimaryButton type={'button'} onClick={() => {
                                  arrayHelpers.push({
                                    name: '',
                                    accountNumber: '',
                                    routingNumber: '',
                                    address1: '',
                                    address2: '',
                                    city: '',
                                    state:'',
                                    zip: '',
                                    version: null,
                                    active: true,
                                    id: null,
                                  });
                                  setEnabled({[arrayHelpers.form.values? arrayHelpers.form.values.banks.length: 0] : true, ...enabled});
                              }}>Add Bank</PrimaryButton>
                          </FieldGroup>
                      </>
                  )}
              />
          </FormErrors>
      </Form>}
  </Formik>
  </>;
};

export default EmployerBankForm;
