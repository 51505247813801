import React, {useContext,useEffect,useState} from "react";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/SidebarItem";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import ErrorBoundary from "../components/ErrorBoundary";
import Dashboard from "../pages/employer/Dashboard";
import EmployerTransactions from "../pages/employer/EmployerTransactions";
import PayrollReport from "../pages/employer/PayrollReport";
import MatchingReport from "../pages/employer/MatchingReport";
import EditPayroll from "../pages/employer/EditPayroll";
import PayrollList from "../pages/employer/PayrollList";
import EmployeeDetails from "../pages/employer/EmployeeDetails";
import NewEmployee from "../pages/employer/NewEmployee";
import EmployeeList from "../pages/employer/EmployeeList";
import EmployerSettings from "../pages/employer/EmployerSettings";
import Support from "../pages/Support";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import StoreContext from "../StoreContext";
import {observer} from "mobx-react";
import {Redirect} from "react-router-dom";
import {Link} from 'react-router-dom';
import ReportList from "../pages/employer/reports/ReportList";
import { FormattedMessage } from "react-intl";
import AppLogout from "../components/AppLogout";
import LogoutWarningDialog from "../components/LogoutWarningDialog";
import { useIntl} from 'react-intl';
import {apiGet, formatMessage} from '../utils';
import OutlineBox from "../components/OutlineBox";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import FeeSettings from "../pages/employer/settings/FeeSettings";
import ComplaintReport from "../pages/admin/ComplaintReport";
import { UnusualActivity } from "../pages/admin/UnusualActivity";
import ChangePassword from "../pages/employee/settings/ChangePassword";
import FinchLink from "../forms/FinchLink";
import EmployerPolicies from "../pages/employer/settings/EmployerPolicies";
import { EmailLog } from "../pages/employer/EmailLog";

const EmployerLayout = observer(({children, company, locales, locale, changeLanguage, fmt}) => {
    const [isOpen, setIsOpen] = useState(false);
    const stepDone = 6;
    const intl = useIntl();

    const onChange = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0,0);
    }
    const {adminStore, rootStore} = useContext(StoreContext);
    const adminOpen = rootStore.adminStore.impersonating;
    const fullAdmin = rootStore.isAdmin;
    const history = useHistory();
    let {path, url} = useRouteMatch();
    const stopImpersonating = () => {
        adminStore.stopImpersonating();
        history.push(`${path}/companies`);
    };

    const [step, setStep] = useState(0);

    const steps = [
        formatMessage(intl, 'er.checklist.company', '', []),
        formatMessage(intl, 'er.checklist.owner', '', []),
        formatMessage(intl, 'checklist.inquiry', '', []),
        formatMessage(intl, 'er.checklist.admin', '', []),
        formatMessage(intl, 'er.checklist.plan', '', []),
        formatMessage(intl, 'er.checklist.bank', '', []),
    ];

    const loadStep = async () => {
        const response = await apiGet(`/api/v1/dashboard/company/step/${company.id}`, rootStore.authStore);
        const resp = await response.json();
        setStep(resp - 1);
    }

    useEffect(() => {
    if (company) {
        loadStep();
    }

    }, [company])

    const currentCompany = rootStore.currentCompany;

    if (!currentCompany) {
        return <Redirect to={`${url}/create-company`}/>;
    }


    return <AppLogout>
        <LogoutWarningDialog />
    <div id={"container"} className={'container max-w-7.5xl mx-auto px-4'}>
        {adminStore.impersonating && <div className={'bg-primary p-4'}>
            <Link className={'text-white font-bold'} to={'#'} href={'#'} onClick={() => stopImpersonating()}>
                <FormattedMessage id={'login.admin'}/></Link>
        </div>}
        <Header companyLogo={company && company.logoUrl} company={company} locale={locale}
            locales={locales} changeLanguage={changeLanguage} fmt={fmt}>{company && company.name}</Header>
        <div className={"flex flex-row"}>
            <Sidebar isOpen={isOpen} onChange={onChange}>
                <SidebarItem onChange={onChange}  to={`${url}`} highlightExclusions={['payrolls', 'transactions', 'employees', 'settings', 'support', 'reports', 'provider', 'complaints', 'uar', 'acks']}>
                <FormattedMessage id={'sidebar.dashboard'}/></SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/payrolls`}><FormattedMessage id={'sidebar.payroll'}/></SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/transactions`}><FormattedMessage id={'sidebar.transactions'}/></SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/employees`}><FormattedMessage id={'sidebar.employees'}/></SidebarItem>
                <SidebarItem  onChange={onChange} to={`${url}/settings/company/profile`} root={`${url}/settings`} step={step} stepDone={stepDone}><FormattedMessage id={'sidebar.settings'}/></SidebarItem>
                <SidebarItem onChange={onChange}  to={`${url}/reports/quarterly`}><FormattedMessage id={'sidebar.reports'}/></SidebarItem>
                <SidebarItem  onChange={onChange} to={`${url}/support`}><FormattedMessage id={'sidebar.support'}/></SidebarItem>
                {fullAdmin ?
                <>
                    <SidebarItem onChange={onChange} to={`${url}/acks`}><FormattedMessage id={'acks.title'}/></SidebarItem>
                    <SidebarItem onChange={onChange}  to={`${url}/fees`}><FormattedMessage id={'Fees'}/></SidebarItem>
                </>
                : '' }
                {adminOpen ?
                <>
                    <SidebarItem onChange={onChange}  to={`${url}/complaints/legal`} root={`${url}/complaints`}>Complaints</SidebarItem>
                    <SidebarItem onChange={onChange} to={`${url}/uar`}>UAR</SidebarItem>
                </>
                : '' }
            </Sidebar>
            <div className={'flex-1 lg:overflow-auto'}>
                <div className={"py-8 ppr-0"}>
                {step > 0 && step < stepDone ?
                <OutlineBox style={{width: '100%'}} >
                    <p className="ml-4 mt-4 text-red-400"><FormattedMessage id={'checklist.message'}/></p>
                    <Stepper activeStep={step} alternativeLabel style={{paddingLeft: '2px', paddingRight: '2px'}}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </OutlineBox>
                : ''}
                    <div className={"min-h-threequarters"}>
                        <Switch>
                            <Route exact path={[path, `${path}/companies/companydashboard`]}>
                                <ErrorBoundary key ={"dashboard"}>
                                    <Dashboard/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/transactions`}>
                                <ErrorBoundary key ={"employerTransactions"}>
                                    <EmployerTransactions/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/password`}>
                                <ErrorBoundary key ={"changePassword"}>
                                    <ChangePassword adminOpen={adminOpen}/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/reports/payroll/:id`}>
                                <ErrorBoundary key ={"payrollReport"}>
                                    <PayrollReport/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/reports/match/:id`}>
                                <ErrorBoundary key ={"matchingReport"}>
                                    <MatchingReport/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/payrolls/:id`}>
                                <ErrorBoundary key ={"editPayroll"}>
                                    <EditPayroll/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/create-payroll`}>
                                <ErrorBoundary key ={"createPayroll"}>
                                    <EditPayroll/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/payrolls`}>
                                <ErrorBoundary key ={"payrollList"}>
                                    <PayrollList/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/employees/:id`}>
                                <ErrorBoundary key ={"employeeDetails"}>
                                    <EmployeeDetails/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/create-employee`}>
                                <ErrorBoundary key ={"create-employee"}>
                                    <NewEmployee/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/employees`}>
                                <ErrorBoundary key ={"employeeList"}>
                                    <EmployeeList/>
                                </ErrorBoundary>
                            </Route>
                            <Route path={`${path}/settings/company`}>
                                    <EmployerSettings loadStep={loadStep}/>
                            </Route>
                            <Route path={`${path}/reports`}>
                                <ReportList/>
                            </Route>
                            <Route path={`${path}/support`}>
                                <ErrorBoundary key ={"support"}>
                                    <EmailLog companyId={currentCompany.id}/>
                                </ErrorBoundary>
                            </Route>
                            {fullAdmin ?
                            <Route path={`${path}/acks`}>
                                <EmployerPolicies/>
                            </Route>
                            : ''}
                            {fullAdmin ?
                            <Route path={`${path}/fees`}>
                                <ErrorBoundary key ={"fees"}>
                                    <FeeSettings/>
                                </ErrorBoundary>
                            </Route>
                            : ''}
                            {adminOpen ?
                            <>
                            <Route path={`${path}/complaints`}>
                                <ComplaintReport companyId={currentCompany.id}/>
                            </Route>
                            <Route path={`${path}/uar`}>
                                <ErrorBoundary key ={"uar"}>
                                    <UnusualActivity companyId={currentCompany.id}/>
                                </ErrorBoundary>
                            </Route>
                            </>
                            : ''}
                        </Switch>
                    </div>
                </div>
            <Footer/>
        </div>
    </div>
</div>
</AppLogout>
});

export default EmployerLayout;
