import React, {useEffect, useContext} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useParams} from "react-router-dom";
import Loading from "../../components/Loading";
import moment from "moment";
import {formatMoney} from "../../utils";
import papa from "papaparse";
import PrimaryButton from "../../components/PrimaryButton";

const PayrollReport = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const payroll = currentCompany.currentPayroll;
  const {id} = useParams();

  useEffect(() => {
    (async () => {
      await currentCompany.setCurrentPayrollId(id);
    })();
  },[id]);

  if (!payroll || payroll.id !== id) {
    return <Loading />;
  }

  const sums = payroll.payrollReportItems.reduce((sums, item) => {
    ['ytdBaseWages', 'ytdBonusWages', 'baseWages',
      'bonusWages', 'baseContribution', 'bonusContribution', 'matchDue'].forEach((key) => {
        sums[key] = parseFloat(item[key]) + (sums[key] || 0);
    });

    return sums;
  }, {});

  const download = async () => {
    let csv = [];

    payroll.payrollReportItems.forEach(item => {

      let newItem = {};
      newItem.name = item.name;
      newItem.employeeId = item.employeeId;
      newItem.ytd_base_wages = item.ytdBaseWages;
      newItem.ytd_bonus_wages = item.ytdBonusWages;
      newItem.baseWages = item.baseWages;
      newItem.bonusWages = item.bonusWages;
      newItem.totalPay = parseFloat(item.bonusWages) + parseFloat(item.baseWages);
      newItem.baseContribution = item.baseContribution;
      newItem.bonusContribution = item.bonusContribution;
      newItem.totalContribution = parseFloat(item.baseContribution) + parseFloat(item.bonusContribution);
      newItem.matchDue = item.matchDue;

      csv.push(newItem)
    })


    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(csv)], { type: "text/csv" }));
    a.setAttribute("download", `Payroll Report ${moment(payroll.payPeriodDate).format("YYYY-MM-DD")}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

return <div>
<h2 className={'text-xl my-2'}>Payroll report for {moment(payroll.payPeriodDate).utc().format('L')}</h2>
  <div className="overflow-auto w-screenn table-cuswidth md:w-full">
    <table className={"w-full table-auto"}>
      <tbody>
      <tr>
        <th className={"p-2 text-left border"}>Name</th>
        <th className={"p-2 text-left border"}>Employee ID</th>
        <th className={"p-2 text-left border"}>YTD Base Wages</th>
        {/* <th className={"p-2 text-left border"}>YTD Bonus Wages</th> */}
        <th className={"p-2 text-left border"}>Base Pay This Pay Period</th>
        {/* <th className={"p-2 text-left border"}>Bonus Pay This Pay Period</th> */}
        <th className={"p-2 text-left border"}>Total Pay This Pay Period</th>
        <th className={"p-2 text-left border"}>Employee contribution for base pay</th>
        {/* <th className={"p-2 text-left border"}>Employee contribution for bonus pay</th> */}
        <th className={"p-2 text-left border"}>Total Employee Contribution</th>
        <th className={"p-2 text-left border"}>Match Due This Pay Period</th>
      </tr>
      {payroll.payrollReportItems.map(item =>
        <tr key={item.id}>
          <td className={"p-2 text-left"}>{item.name}</td>
          <td className={"p-2 text-left"}>{item.employeeId}</td>
          <td className={"p-2 text-left"}>{formatMoney(item.ytdBaseWages)}</td>
          {/* <td className={"p-2 text-left"}>{formatMoney(item.ytdBonusWages)}</td> */}
          <td className={"p-2 text-left"}>{formatMoney(item.baseWages)}</td>
          {/* <td className={"p-2 text-left"}>{formatMoney(item.bonusWages)}</td> */}
          <td className={"p-2 text-left"}>{formatMoney(parseFloat(item.bonusWages) + parseFloat(item.baseWages))}</td>
          <td className={"p-2 text-left"}>{formatMoney(item.baseContribution)}</td>
          {/* <td className={"p-2 text-left"}>{formatMoney(item.bonusContribution)}</td> */}
          <td className={"p-2 text-left"}>{formatMoney(parseFloat(item.baseContribution) + parseFloat(item.bonusContribution))}</td>
          <td className={"p-2 text-left"}>{formatMoney(item.matchDue)}</td>
        </tr>
      )}

      <tr>
        <td className={"p-2 text-left"}></td>
        <td className={"p-2 text-left"}></td>
        <td className={"p-2 text-left font-bold"}>{formatMoney(sums.ytdBaseWages)}</td>
        {/* <td className={"p-2 text-left font-bold"}>{formatMoney(sums.ytdBonusWages)}</td> */}
        <td className={"p-2 text-left font-bold"}>{formatMoney(sums.baseWages)}</td>
        {/* <td className={"p-2 text-left font-bold"}>{formatMoney(sums.bonusWages)}</td> */}
        <td className={"p-2 text-left font-bold"}>{formatMoney(parseFloat(sums.bonusWages) + parseFloat(sums.baseWages))}</td>
        <td className={"p-2 text-left font-bold"}>{formatMoney(sums.baseContribution)}</td>
        {/* <td className={"p-2 text-left font-bold"}>{formatMoney(sums.bonusContribution)}</td> */}
        <td className={"p-2 text-left font-bold"}>{formatMoney(parseFloat(sums.baseContribution) + parseFloat(sums.bonusContribution))}</td>
        <td className={"p-2 text-left font-bold"}>{formatMoney(sums.matchDue)}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div className={"mt-4"}>
    <PrimaryButton onClick={download} className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
        Download
    </PrimaryButton>
  </div>
</div>;
});

export default PayrollReport;
