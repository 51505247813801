import React from "react";
import Loading from "./Loading";
import dayjs from "dayjs";
import MaterialTable from "material-table";
import { Link } from "@material-ui/core";

export default function KycTable({logs}) {
  if (!logs) {
    return <Loading/>;
  }

  return <div>
    <MaterialTable 
            components={{}}
            columns={[
                {
                    title: '',
                    field: 'inquiryId',
                    render: (rowData) => {
                        if (rowData.inquiryId) {
                            return (
                            <Link target="_new" href={`https://app.withpersona.com/dashboard/inquiries/${rowData.inquiryId}`}>
                              View on Persona
                            </Link>);
                        } 
                    }
                },
                {
                    title: 'Status',
                    field: 'status',
                    render: (rowData) => {
                        if (rowData.status) {
                            return (<>{rowData.status}</>);
                        } 
                    }
                },
                {
                  title: 'Company Name',
                  field: 'companyName', 
                  render: (rowData) => {
                      if (rowData.companyName) {
                          return (<>{rowData.companyName}</>);
                      } 
                  }
                },
                {
                  title: 'Full Name',
                  field: 'fullName', 
                  render: (rowData) => {
                      if (rowData.fullName) {
                          return (<>{rowData.fullName}</>);
                      } 
                  }
                },
                {
                    title: 'SSN',
                    field: 'ssn', 
                    render: (rowData) => {
                        if (rowData.ssn) {
                            return (<>{rowData.ssn}</>);
                        } 
                    }
                  },
                {
                    title: 'Created',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.created) {
                            return (<>{rowData.created ? dayjs(rowData.created).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "fees",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={logs}
        />
  </div>;
}
