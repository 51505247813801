export const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export  function formikErrorHandler (formikContext, name) {
    let error = "";
    if (formikContext.errors && Object.entries(formikContext.errors).length !== 0) {
        if (formikContext.errors[name]) {
            error = formikContext.errors[name]; //single field error
        } else {
            const nameArr = name.split(".");
            if (nameArr && nameArr.length === 2 && formikContext.errors[nameArr[0]]) {
                error = formikContext.errors[nameArr[0]][nameArr[1]];//single nested obj
            } else if (nameArr && nameArr.length === 3 && formikContext.errors[nameArr[0]][nameArr[1]]) {
                error = formikContext.errors[nameArr[0]][nameArr[1]][nameArr[2]]; //field array
            }
        }
    }
    return error;
}