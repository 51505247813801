import React, {useContext} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import ResetPasswordForm from "../../../forms/ResetPasswordForm";

const ResetPassword = observer(() => {
  const {rootStore, authStore} = useContext(StoreContext);


  return <div>
    <div className={'max-w-xl p-8'}>
      <ResetPasswordForm authStore={authStore} errors={rootStore.formErrors.toJSON()}
                          submitting={rootStore.submitting} />
    </div>
  </div>;
});

export default ResetPassword;
