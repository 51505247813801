import React, {useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiPost } from "../../utils";
import Loading from "../../components/Loading";
import AlertProvider from "../../AlertProvider";
import SupportEmailLogForm from "../../forms/SupportEmailLogForm";

export const EmailLog = ({userId}) => {

    const {authStore,currentUser} = useContext(StoreContext);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const sendEmail = async (values) => {
        let url = `/api/v1/email/${userId}/contactUs`;
        const response = await apiPost(url, values, authStore, 'Your Support Email has been sent');        

        setLoading(false);
        setSent(true);
    }

    if (loading === true) {
        return <Loading />;
    }
    
    return (
        <>
        <AlertProvider/>
        <p className={"text-red-500"}></p>
        {sent === false ? 
        <SupportEmailLogForm doSubmit={sendEmail} submitting={loading} 
        setLoading={setLoading} />
        : ''
        }
        </>
        
    );
}