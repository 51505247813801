import React from "react";
import Loading from "./Loading";
import { Link } from "@material-ui/core";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

export default function EmployeePolicyTable({policies}) {
  if (!policies) {
    return <Loading/>;
  }

  return <div>
    <table className={'w-full table-auto'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 py-3"}><FormattedMessage id={'acks.title'}/></th>
        <th className={"text-left text-sm text-gray-400 py-3"}><FormattedMessage id={'acks.url'}/></th>
        <th className={"text-left text-sm text-gray-400 py-3"}><FormattedMessage id={'acks.status'}/></th>
        <th className={"text-left text-sm text-gray-400 py-3"}><FormattedMessage id={'acks.date'}/></th>
      </tr>
      </thead>
      <tbody>
      {policies.map((policy) => <tr key={policy.id}>
        <td className={`text-left text-sm p-3 `}>{policy.policyName}</td>
        <td className={`text-left text-sm p-3 `}>
          <Link href={policy.url} target="_blank" className={"cursor-pointer"}><FormattedMessage id={'acks.review'}/></Link>
        </td>
        <td className={`text-left text-sm p-3 `}>{policy.active ? <FormattedMessage id={'acks.active'}/> : <FormattedMessage id={'acks.archived'}/>}</td>
        <td className={`text-left text-sm p-3 `}>{dayjs(policy.created).format("MM/DD/YYYY")}</td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
