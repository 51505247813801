import React from "react";

const Select = ({field, form, choices, ...props}) => {

  return <div className={"border border-mid-grey bg-white rounded pr-4 w-full inline-block"}>
    <select {...field} {...props} value={field.value} className={'w-full p-3 rounded'}>
      {choices.map(choice => <option key={choice.value} value={choice.value} disabled={choice.disabled}>{choice.label}</option>)}
    </select></div>;
};

export default Select;
