import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import RadioSelect from "../components/RadioSelect";
import HorizontalRadioSelect from "../components/HorizontalRadioSelect";
import CheckboxSelect from "../components/CheckboxSelect";
import DateInput from "../components/DateInput";
import Input from "../components/Input";
import SectionHeading from "../components/SectionHeading";
import RadioField from "../components/RadioField";
import Select from "../components/Select";
import FormErrors from "../components/FormErrors";
import moment from 'moment';
import * as Yup from 'yup';

const EmployerPlanForm = ({doSubmit, errors, submitting, plan = null, rootStore}) => {
  const [matchType, setMatchType] = useState(plan && plan.matchType);
  const [servicePeriodChoice, setServicePeriodChoice] = useState(plan && plan.servicePeriodMonths || 1);
  const today = moment();
  const launchDate = moment(plan.launchDate);
  const lockedDate = moment(plan.lockedDate);
  const locked = rootStore.adminStore.impersonating ? false : lockedDate.isBefore(today);
  const adminOpen = rootStore.adminStore.impersonating;

  const checkLaunchDate = Yup.object().shape({
    launchDate: Yup.string()
        .test("Invalid Date", "Launch Date required.", async value => (await value) !== "Invalid Date")
  });

  if (plan) {
    plan = {...plan};
    plan.timeStampedMatchPercent = plan.matchPercent;
    plan.launchDate = launchDate.format("YYYY-MM-DD");
    plan.lockedDate = lockedDate.format("YYYY-MM-DD");
  }

  return <Formik
    initialValues={plan || {
      'yearType': '',
      'launchDate': '',
      'eligibleEmployees': '',
      'eligibleDate': '',
      'eligibleMatchDate': '',
      'matchType': '',
      'matchPercent': '0',
      'bonusMatchPercent': '0',
      'timeStampedMatchPercent': '0',
      'matchUpToPercent': '0',
      'bonusMatchUpToPercent': '0',
      'timeStampedIntervalMonths': '',
      'timeStampedPeriodMonths': '',
      'nonDiscretionaryRate': '0',
      'nonDiscretionaryBonusRate': '0',
      'servicePeriodMonths': '',
      'minimumContributionType': '',
      'minumumContributionAmount': '',
      'matchUpToDollar': '0',
      'bonusMatchUpToDollar': '0',
      'feeType': '',
      'exclusions': [],
      'platforms': [],
      'depositoryOfferings': [],
      'benefits': [],
      'lockedDate': ''
    }}
    onSubmit={(values) => {
      if (launchDate.isBefore(today) && values.lockedDate === 'Invalid date' && !rootStore.adminStore.impersonating) {
        values.lockedDate = values.launchDate ;
      } else if (values.lockedDate === 'Invalid date') {
        values.lockedDate = values.launchDate ;
      }
      if (values.matchType === 'TIMESTAMPED') {
        values.matchPercent = values.timeStampedMatchPercent;
      }

      if (values.matchType === 'TIMESTAMPED' && !values.timeStampedPeriodMonths) {
        values.timeStampedPeriodMonths = 6;
      }

      doSubmit(values);
    }}
    validate={async (values) => {
      // Set match type to enable/disable subfields
      console.log(values.launchDate)
      await checkLaunchDate.validate({launchDate: values.launchDate});
      setMatchType(values.matchType);
    }}
  >
    {({values}) => (
      <Form>
        <FormErrors errors={errors}>
          <p className={'p-8 text-center'}>The benefits that you offer must add value to your employee`s lives and they must add value to your bottom line.  We want to help you achieve the optimal results.  A MatchSavings ISA Plan is customizable for each company's unique situation.  Our goal is to help you create a highly effective and sustainable benefit program that will improve the financial stability of your workforce.  
            <br/><br/>
             <br/><strong><a className={`bg-primary px-6 py-3 mr-2 text-center text-white uppercase`} href={'https://calendly.com/matchsavings-tona'} target="_blank" rel="noreferrer">
             Schedule a free 30 min Plan Design consultation</a></strong>.
             {/* <button onClick={onClick} className={`bg-primary px-6 py-3 mr-2 text-center text-white uppercase ${className || ''}`}
                 type={type} {...props}>{children}</button> */}
          </p>
          {locked ? <p className='text-red-500'>You have launced your program. Changes are restricted. Please reach out to MatchSavings adminstrators about options to change this.</p>: '' }
          <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>1. Timeframe</SectionHeading>
            <div className={'flex'}>
            <LabeledField
              label={'Define Plan Year'}
              name={'yearType'}
              choices={[
                {label: 'Calendar year', value: 'CALENDAR'}
                // {label: 'Fiscal year', value: 'FISCAL'}
              ]}
              component={HorizontalRadioSelect}
              disabled={locked}
            />
            
            {/* <LabeledField
            label={'Fiscal Year Start'}
            name={'fiscalYearStartMonth'}
            choices={[
              {label: 'January', value: 1},
              {label: 'February', value: 2},
              {label: 'March', value: 3},
              {label: 'April', value: 4},
              {label: 'May', value: 5},
              {label: 'June', value: 6},
              {label: 'July', value: 7},
              {label: 'August', value: 8},
              {label: 'September', value: 9},
              {label: 'October', value: 10},
              {label: 'November', value: 11},
              {label: 'December', value: 12},
            ]}
            placeholder={"Fiscal Year Start"}
            component={Select}
            disabled={locked}
          /> */}
          </div>
            <div className={'w-48'}>
              <LabeledField
                label={'Launch Date'}
                name={'launchDate'}
                component={DateInput}
                disabled={locked}
              />
            </div>
            {adminOpen ?
            <div className={'w-48'}>
            <LabeledField
              label={'Locked Date'}
              name={'lockedDate'}
              component={DateInput}
            />
          </div>
            : ''}
          </div>
          <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>2. Eligibility classification and exclusions</SectionHeading>
            <LabeledField
              label={'Employees eligible to participate in a MatchSavings ISA will include:'}
              name={'eligibilityType'}
              choices={[
                {label: 'All common law employees, as defined by Employer ("Employees")', value: 'ALL'},
                // {label: 'Full-time Employee who regularly works at least 30+ hrs/week', value: 'FULLTIME'},
                {label: 'Full-time employees only', value: 'FULLTIME'},
                {label: 'Hourly employees only', value: 'HOURLY'},
                {label: 'Employees classified as:', value: 'OTHER'}
              ]}
              component={RadioSelect}
              disabled={locked}
            />
            <div className={''}>
                  <Field name={'employeeClassification'} component={Input} placeholder={'Employee Classification'} disabled={locked}/>
                </div>
            <p className={'text-gray-400 text-xs mb-3'}>
              Note: MatchSavings assumes no responsibility for identification of, or monitoring eligibility of specific employees per this section.  The sponsoring employer bares this responsibility.
            </p>
            <LabeledField
              label={'Excluded categories of employees, regardless of common law status'}
              name={'exclusions'}
              choices={[
                {label: 'Lease Employees', value: 'LEASE', disabled: locked ? 'locked' : ''},
                {label: 'Seasonal Employees', value: 'SEASONAL', disabled: locked ? 'locked' : ''},
                {label: 'Interns', value: 'INTERNS', disabled: locked ? 'locked' : ''}
              ]}
              component={CheckboxSelect}
              disabled={locked}
            />
          </div>
          <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>3. Eligibility Date for Employee to participte in the ISA Plan:</SectionHeading>
            <LabeledField
              label={'Employees who meet the classification eligibility criteria above are eligible to make employee ISA contributions and received Employer Matching or Discretionary contributions beginning:'}
              name={'eligibleDate'}
              choices={[
                {label: "Date of Hire and Employee's election into the plan.", value: 'HIRE'},
                // {
                //   label: "The first day of the month coinciding with or immediately following the Employee's election in the plan",
                //   value: 'FIRST_DAY_OF_MONTH'
                // },
                {
                  label: "After completion of one year of continuous service and Employee's election in the plan.",
                  value: 'AFTER_YEAR'
                },
                {
                  label: "After completion of 6 months of continuous service and Employee's election in the plan.",
                  value: 'SIX_MONTH'
                },
                {
                  label: "On the first of the month after completion of 1 month of continuous service and Employee's election in the plan.",
                  value: 'ONE_MONTH_FIRST'
                }
              ]}
              component={RadioSelect}
              disabled={locked}
            />
            <p className={'text-gray-400 text-xs'}>
            Note: ISA Contributions shall be withheld and transmitted each pay period.  No deferrals of contributions will be allowed; all contributions will be made on an after-tax basis in the year in which they are earned.  Employer Discretionary Contributions will be made to the extent an eligible employee has an open ISA.
            </p>
          </div>
          <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>4. Benefits available under the Plan include (Select all that apply)</SectionHeading>
            <LabeledField
              label={''}
              name={'benefits'}
              choices={[
                {label: 'Employee Payroll-Deducted ISA Contributions', value: 'PAYROLL', disabled: locked ? 'locked' : ''},
                {label: 'Employer Matching Contributions', value: 'MATCH', disabled: locked ? 'locked' : ''},
                {
                  label: 'Employer Discretionary Contributions',
                  value: 'NON_DISCRETIONARY', disabled: locked ? 'locked' : ''
                },
                {
                  label: 'Non-payroll deducted Employee Deposits - employees may make deposits into their ISA account from external sources, but will not be matched.  Only employee payroll-deducted ISA contributions will be eligible for employer matching.',
                  value: 'DEPOSITS', disabled: locked ? 'locked' : ''
                }
              ]}
              component={CheckboxSelect}
              disabled={locked}
            />
          </div>
          {/* <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>5. Minimum ISA Contributions (Select one; if none selected, default to Not Applicable.)</SectionHeading>
            <div>
              <RadioField name={'minimumContributionType'} value={'PAY_PERIOD'} className={'flex items-center'} disabled={locked}>
                <label className={'text-sm mr-4'}>At least</label>
                <div className={'w-24 mr-4'}>
                <Field
                  name={'minumumContributionAmount'}
                  choices={[
                    {label: '$10', value: '10'},
                    {label: '$20', value: '20'},
                    {label: '$30', value: '30'},
                    {label: '$40', value: '40'},
                    {label: '$50', value: '50'},
                    {label: '$60', value: '60'},
                    {label: '$70', value: '70'},
                    {label: '$80', value: '80'},
                    {label: '$90', value: '90'},
                    {label: '$100', value: '100'},
                  ]}
                  component={Select}
                  disabled={locked}
                />
              </div>
                <label className={'text-sm'}>per pay period.</label>
              </RadioField>
            </div>
            <div>
              <RadioField name={'minimumContributionType'} value={'NONE'} disabled={locked}>
                <label className={'text-sm'}>No minimum contribution.</label>
              </RadioField>
            </div>
          </div> */}
          {/* <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>Eligibility to receive ISA Matching and/or Non-Discretionary Contribution.</SectionHeading>
            <LabeledField
              label={'Each Eligible Employee becomes eligible to receive ISA Matching and/or ISA Non-Discretionary Contributions on'}
              name={'eligibleMatchDate'}
              choices={[
                {label: "Date of Hire and Employee's election into the plan.", value: 'HIRE'},
                {
                  label: "The first day of the month coinciding with or immediately following the Employee's election in the plan.",
                  value: 'FIRST_DAY_OF_MONTH'
                },
                {
                  label: "After completion of one year of service and Employee's election in the plan.",
                  value: 'AFTER_YEAR'
                }
              ]}
              component={RadioSelect}
            />
            <p className={'text-gray-400 text-xs'}>
              NOTE: If elected, ISA Non-Discretionary Contributions will be made, as elected above, to the
              extent an eligible employee has an open ISA and remains eligible to make ISA Contributions,
              regardless of whether the employee elects and makes ISA Contributions for the applicable period.
            </p>
          </div> */}
          <div className={'bg-subtle-blue p-8 my-8'}>
            <SectionHeading>5. Matching Method, Amount and Limits</SectionHeading>
            <p className={'text-sm my-2'}>
            If Employer Matching Contributions are allowed as selected in #4 above, the match will be based on the following method, rates and limits.
            </p>

            <p className={'text-sm'}><strong>Choose Match Method.</strong> (Choose either Traditional Match or Time-Stamped Incentive Match):</p>

            <RadioField id={'input-match_type-traditional'} name={'matchType'} value={'TRADITIONAL'} disabled={locked}>
              <label htmlFor={'input-match_type-traditional'}
                     className={'text-sm'}>{'Traditional Match formula'}</label>
            </RadioField>
            {matchType !== 'TIMESTAMPED' ? 
            <div className={'pl-8'}>
              <p className={'text-sm'}>Select regular pay-period matching formula</p>
              <div className={"flex"}>
                <LabeledField
                  label={'Employer match %'}
                  name={'matchPercent'}
                  disabled={!locked ? matchType !== 'TRADITIONAL' : true}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '5%', value: '5'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'},
                    {label: '33%', value: '33'},
                    {label: '50%', value: '50'},
                    {label: '75%', value: '75'},
                    {label: '100%', value: '100'}
                  ]}
                  component={Select}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Select maximum % of pay)'}
                  disabled={!locked ? matchType !== 'TRADITIONAL' : locked}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                  name={'matchUpToPercent'}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Dollar Limit to be matched)'}
                  disabled={!locked ? matchType !== 'TRADITIONAL' : true}
                  placeholder={'0'}
                  choices={[
                    {label: '0', value: '0'},
                    {label: '$100', value: '100'},
                    {label: '$250', value: '250'},
                    {label: '$500', value: '500'},
                    {label: '$1,000', value: '1000'},
                    {label: '$1,500', value: '1500'},
                    {label: '$2,000', value: '2000'},
                    {label: '$2,500', value: '2500'},
                    {label: '$3,000', value: '3000'},
                    {label: '$3,500', value: '3500'},
                    {label: '$4,000', value: '4000'},
                    {label: '$4,500', value: '4500'},
                    {label: '$5,000', value: '5000'}
                  ]}
                  component={Select}
                  name={'matchUpToDollar'}
                  className={'mr-4'}
                />
              </div>
              
              {/* <p className={'text-sm'}>Select special pay-period matching formula</p>
              <div className={"flex"}>
                <LabeledField
                  label={'Employer match %'}
                  name={'bonusMatchPercent'}
                  disabled={matchType !== 'TRADITIONAL'}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '5%', value: '5'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'},
                    {label: '33%', value: '33'},
                    {label: '50%', value: '50'},
                    {label: '75%', value: '75'},
                    {label: '100%', value: '100'}
                  ]}
                  component={Select}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Select maximum % of pay)'}
                  disabled={matchType !== 'TRADITIONAL'}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                  name={'bonusMatchUpToPercent'}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Dollar Limit to be matched)'}
                  disabled={matchType !== 'TRADITIONAL'}
                  placeholder={'0'}
                  choices={[
                    {label: '0', value: '0'},
                    {label: '$500', value: '500'},
                    {label: '$1,000', value: '1000'},
                    {label: '$1,500', value: '1500'},
                    {label: '$2,000', value: '2000'},
                    {label: '$2,500', value: '2500'},
                    {label: '$3,000', value: '3000'},
                    {label: '$3,500', value: '3500'},
                    {label: '$4,000', value: '4000'},
                    {label: '$4,500', value: '4500'},
                    {label: '$5,000', value: '5000'}
                  ]}
                  component={Select}
                  name={'bonusMatchUpToDollar'}
                  className={'mr-4'}
                />
              </div> */}
              
            </div>
            : <></>
            }
            <RadioField name={'matchType'} value={'TIMESTAMPED'} id={'input-match_type-TIMESTAMPED'} disabled={locked}>
              <label htmlFor={'input-match_type-TIMESTAMPED'}
                     className={'text-sm'}>{'Time-Stamped Incentive Match'}</label>
            </RadioField>
            {matchType !== 'TRADITIONAL' ? 
            <div className={'pl-8'}>
              <p className={'text-sm'}>
              Select the Match Rate, Maximum Amount, Match Intervals, and Maximum Time Limit that Employer will match employee payroll deducted ISA contributions:
              </p>
              <div className={'flex'}>
                <LabeledField
                  label={'Employer match %'}
                  disabled={!locked ? matchType !== 'TIMESTAMPED' : true}
                  placeholder={'10.0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '5%', value: '5'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'},
                    {label: '33%', value: '33'},
                    {label: '50%', value: '50'},
                    {label: '75%', value: '75'},
                    {label: '100%', value: '100'}
                  ]}
                  component={Select}
                  name={'timeStampedMatchPercent'}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Select maximum % of pay)'}
                  disabled={!locked ? matchType !== 'TIMESTAMPED' : true}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                  name={'matchUpToPercent'}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Dollar Limit to be matched)'}
                  disabled={!locked ? matchType !== 'TIMESTAMPED' : true}
                  placeholder={'0'}
                  choices={[
                    {label: '0', value: '0'},
                    {label: '$100', value: '100'},
                    {label: '$250', value: '250'},
                    {label: '$500', value: '500'},
                    {label: '$1,000', value: '1000'},
                    {label: '$1,500', value: '1500'},
                    {label: '$2,000', value: '2000'},
                    {label: '$2,500', value: '2500'},
                    {label: '$3,000', value: '3000'},
                    {label: '$3,500', value: '3500'},
                    {label: '$4,000', value: '4000'},
                    {label: '$4,500', value: '4500'},
                    {label: '$5,000', value: '5000'}
                  ]}
                  component={Select}
                  name={'matchUpToDollar'}
                  className={'mr-4'}
                />
              </div>
              {/* <p className={'text-sm'}>Select special pay-period matching formula</p>
              <div className={"flex"}>
                <LabeledField
                  label={'Employer match %'}
                  name={'bonusMatchPercent'}
                  disabled={matchType !== 'TIMESTAMPED'}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '5%', value: '5'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'},
                    {label: '33%', value: '33'},
                    {label: '50%', value: '50'},
                    {label: '75%', value: '75'},
                    {label: '100%', value: '100'}
                  ]}
                  component={Select}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Select maximum % of pay)'}
                  disabled={matchType !== 'TIMESTAMPED'}
                  placeholder={'0'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                  name={'bonusMatchUpToPercent'}
                  className={'mr-4'}
                />
                <LabeledField
                  label={'Up To: (Dollar Limit to be matched)'}
                  disabled={matchType !== 'TIMESTAMPED'}
                  placeholder={'0'}
                  choices={[
                    {label: '0', value: '0'},
                    {label: '$500', value: '500'},
                    {label: '$1,000', value: '1000'},
                    {label: '$1,500', value: '1500'},
                    {label: '$2,000', value: '2000'},
                    {label: '$2,500', value: '2500'},
                    {label: '$3,000', value: '3000'},
                    {label: '$3,500', value: '3500'},
                    {label: '$4,000', value: '4000'},
                    {label: '$4,500', value: '4500'},
                    {label: '$5,000', value: '5000'}
                  ]}
                  component={Select}
                  name={'bonusMatchUpToDollar'}
                  className={'mr-4'}
                />
              </div> */}
              <p className={'text-sm mb-1'}>Select intervals in which time-stamped match rate will apply (select one):</p>
              <LabeledField
                label={''}
                name={'timeStampedIntervalMonths'}
                disabled={!locked ? matchType !== 'TIMESTAMPED' : true}
                choices={[
                  {
                    label: '3 Month Intervals  - When AND if the ISA Employee Contribution remains in the ISA at 3 months from the date of deposit and every 3 month interval after, up to the maximum time period selected.',
                    value: 3
                  },
                  {
                    label: '6 Month Intervals - When AND if the ISA Employee Contribution remains in the ISA at 6 months from the date of deposit and every 6 months interval after, up to the maximum time period selected.',
                    value: 6
                  },
                  {
                    label: '12 Month Intervals - When and if the ISA Employee Contribution remains in the ISA at 12 months from the date of deposit and every 12 months interval after, up to the maximum time period selected.',
                    value: 12
                  }
                ]}
                component={RadioSelect}
              />
              <p className={'text-sm mb-1'}>
              Select the Maximum Time Period the ISA Contributions will be matched over Time-Stamped Intervals (select one): 
              </p>
              <div className={'w-60'}>
                <LabeledField
                  label={''}
                  name={'timeStampedPeriodMonths'}
                  disabled={!locked ? matchType !== 'TIMESTAMPED' : true}
                  choices={[
                    {label: '6 months', value: 6},
                    {label: '1 year', value: 12},
                    {label: '2 years', value: 24}
                  ]}
                  component={Select}
                />
              </div>
              <p className={'text-sm'}>Note: Eligible matching contribution must be paid each pay period, along with employee payroll-deducted ISA contributions. </p>
            </div>
            : <></>
            }
          </div>
          {/* <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>7. Employer Discretionary Contributions (non-matching) will be made at the following rate:</SectionHeading>
            <div className={'flex items-center mb-4'}>
              <label className={'text-sm mr-4'}>At </label>
              <div className={'w-24 mr-4'}>
                <Field
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                  name={'nonDiscretionaryRate'}
                />
              </div>
              <label className={'text-sm'}>of compensation per each regular (base) pay period.</label>
            </div>
            <div className={'flex items-center'}>
              <label className={'text-sm mr-4'}>At </label>
              <div className={'w-24 mr-4'}>
                <Field
                  name={'nonDiscretionaryBonusRate'}
                  choices={[
                    {label: '0%', value: '0'},
                    {label: '1%', value: '1'},
                    {label: '2%', value: '2'},
                    {label: '3%', value: '3'},
                    {label: '4%', value: '4'},
                    {label: '5%', value: '5'},
                    {label: '6%', value: '6'},
                    {label: '7%', value: '7'},
                    {label: '8%', value: '8'},
                    {label: '9%', value: '9'},
                    {label: '10%', value: '10'},
                    {label: '15%', value: '15'},
                    {label: '20%', value: '20'},
                    {label: '25%', value: '25'}
                  ]}
                  component={Select}
                />
              </div>
              <label className={'text-sm'}>of each special/bonus pay period.</label>
            </div>
          </div> */}
          {/* <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>Service Period</SectionHeading>
            <p className={'text-sm'}>
              “Service Period” means completion of the following period of continuous services with
              the Employer (select one, if service period is applicable for either ISA Matching Contributions
              and/or ISA Non-Discretionary Contributions)
            </p>
            <div>
              <RadioField name={'servicePeriodMonths'} value={0}>
                <label className={'text-sm'}>Not Applicable. Date of hire satisfies Service Period</label>
              </RadioField>
            </div>
            <div>
              <RadioField name={'servicePeriodMonths'} value={12}>
                <label className={'text-sm'}>One year of employment</label>
              </RadioField>
            </div>
            <div>
              <RadioField name={'servicePeriodMonths'} value={servicePeriodChoice} className={'flex items-center'}>
                <div className={'w-30 mr-4'}>
                  <Select choices={[
                    {label: '1 month', value: 1},
                    {label: '2 months', value: 2},
                    {label: '3 months', value: 3},
                    {label: '6 months', value: 6},
                    {label: '1 year', value: 12},
                    {label: '2 years', value: 24}
                  ]}
                          field={{value: servicePeriodChoice}}
                          onChange={(e) => {
                            setServicePeriodChoice(e.target.value);
                            // Also set the value for formik itself else the value remains unchanged
                            values.servicePeriodMonths = e.target.value;
                          }}
                  />
                </div>
                <label className={'text-sm'}>of employment</label>
              </RadioField>
            </div>
          </div> */}
          
          <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>6. Administration (including service and account maintenance) fees will be paid by (select one):</SectionHeading>

            <LabeledField
              label={''}
              name={'feeType'}
              choices={[
                {label: 'The Employer (company)', value: 'EMPLOYER'},
                {label: 'Charged to the eligible employee’s account', value: 'EMPLOYEE'}
              ]}
              component={RadioSelect}
              disabled={locked}
            />
            <p className={'text-gray-400 text-xs'}>
              Note: Paper statement fees and certain transaction fees will be charged to the respective employee's account.
            </p>
            <p className={'text-gray-400 text-xs'}>Note:  All fees will be charged directly to the employee account upon termination of employment relationship with the Employer.</p>
          </div>
          {/* <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>Depository Offering</SectionHeading>
            <LabeledField
              label={''}
              name={'depositoryOfferings'}
              choices={[
                {label: 'Cash Account', value: 'CASH'},
                {label: 'Money Market Account*', value: 'MONEY_MARKET', disabled: true},
                {label: 'Investment Account*', value: 'INVESTMENT', disabled: true},
                {label: 'Other*', value: 'OTHER', disabled: true}
              ]}
              component={CheckboxSelect}
            />
            <p className={'text-xs'}>* Future options</p>
          </div>
          <div className={'bg-gray-50 p-8 my-8'}>
            <SectionHeading>Online Platform Offerings</SectionHeading>
            <LabeledField
              label={''}
              name={'platforms'}
              choices={[
                {label: 'Online Enrollment', value: 'ENROLLMENT'},
                {label: 'Online and Mobile Account Access', value: 'ACCOUNT_ACCESS'},
                {label: 'Online and Mobile Contribution / Distribution', value: 'CONTRIBTUION_DISTRIBUTION'},
                {label: 'Other', value: 'OTHER'}
              ]}
              component={CheckboxSelect}
            />
          </div> */}

          <FieldGroup>
            {locked ? ''
            : <>
             {submitting ? <Loading size={30}/> : <PrimaryButton>Save Plan</PrimaryButton>}
            </>
            }
          </FieldGroup>
        </FormErrors>
      </Form>
    )}
  </Formik>;
};

export default EmployerPlanForm;
