import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import MyTextArea from '../components/MyTextArea';
import { FormattedMessage, useIntl } from 'react-intl';
import StoreContext from '../StoreContext';


const EmailLogFields = ({setFieldValue, intl, getCompanies, name, companies, setName, showCompanies, selectTo, values}) => <div>
    <>
    <p>
    <FormattedMessage id={'support.form.msg'}/>
    <FormattedMessage id={'phone'} />.
    </p>
  <div className={'flex mt-10'}>
    <MyTextArea
      label=""
      name="body"
      rows="10"
      style={{border: "solid", width: "100%"}}
    />
  </div>
  
  </>
</div>;

const SupportEmailLogForm = ({doSubmit, errors, submitting, setLoading}) => {
  const { authStore } = useContext(StoreContext);
  const intl = useIntl();
  const [name, setName] = useState('');

  return <Formik
    initialValues={
      {
      subject: '',
      type: 'TERMS_UPDATE',
      body: '',
      emailSendingType: 'ALL',
      companyId: null
    }}
    onSubmit={(values) => {
      setLoading(true);
      doSubmit(values);
      values = {
        subject: '',
        type: 'TERMS_UPDATE',
        body: '',
        emailSendingType: 'ALL',
        companyId: null  
      }
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <EmailLogFields setFieldValue={setFieldValue} values={values} intl={intl} name={name} setName={setName} />
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Send</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default SupportEmailLogForm;
