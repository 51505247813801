import React, { useState } from "react";
import SettingsMenu from "../../components/SettingsMenu";
import SettingsMenuItem from "../../components/SettingsMenuItem";
import {Route, Switch, useRouteMatch, Redirect} from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import { DataRequest } from "./DataRequest";

export default function DataRequestReport({accountHolderId, companyId}) {
    let {path, url} = useRouteMatch();
    const [showForm, setShowForm] = useState(false);

    return <div>
        {showForm === false ?
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/not-delete`}>Data Request</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/delete`}>Delete Request</SettingsMenuItem>
        </SettingsMenu>
        : ''}
        <Switch>
            <Route path={`${path}/delete`}>
                <ErrorBoundary key ={"dataRequestReport"}>
                    <DataRequest reportType={'delete'} accountHolderId={accountHolderId} companyId={companyId} showForm={showForm} setShowForm={setShowForm}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/not-delete`}>
                <ErrorBoundary key ={"dataRequestReport"}>
                    <DataRequest reportType={'not-delete'} accountHolderId={accountHolderId} companyId={companyId} showForm={showForm} setShowForm={setShowForm}/>
                </ErrorBoundary>
            </Route>
            <Route path="/data-requests">
                <Redirect to={`${path}/delete`}/>
            </Route>
        </Switch>

    </div>;
}
