import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import StoreContext from "../../StoreContext";
import moment from "moment";
import LinkButton from "../../components/LinkButton";
import Alert from "../../components/Alert";
import PlanRequirementModal from '../../components/PlanRequirementModal';
import {useRouteMatch} from "react-router-dom";
import Loading from "../../components/Loading";
import AlertProvider from "../../AlertProvider";

const PayrollList = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [planRequiredModal, showPlanRequiredModal] = useState(false);
  let {url} = useRouteMatch();
  useEffect(() => {
    currentCompany.loadPayrolls();
    currentCompany.loadCompanyPlans(currentCompany.id);
  }, []);

  if (rootStore.submitting) {
    return <Loading/>;
  }

  return <div>
    <AlertProvider/>
    <div className={"flex py-4"}>
      <h3 className={"text-xl flex-1"}>Manage Payroll</h3>
      {planRequiredModal && <PlanRequirementModal wantsToClose={() => showPlanRequiredModal(false)}/>}
      <LinkButton to={`create-payroll`} onClick={event => {
        if (!currentCompany.companyPlans.length) {
          event.preventDefault();

          showPlanRequiredModal(true);
        }
      }}>New Pay Period</LinkButton>
    </div>
    <div className={"overflow-auto w-screenn table-cuswidth md:w-full"}>
      <table className={"w-full table-auto whitespace-nowrap"}>
        <thead>
        <tr>
          <th className={"text-left"}>Pay Period Date</th>
          <th className={"text-left"}>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {currentCompany.payrolls && currentCompany.sortedPayrolls.map(payroll =>
          <tr key={payroll.id}>
            <td className={"p-3 text-left"}>{moment(payroll.payPeriodDate).utc().format('L')}</td>
            <td className={"p-3 text-left"}>{payroll.status}</td>
            <td className={"p-3 text-right"}>{
              payroll.paid ? <>
                <LinkButton className={'mr-2'} to={`reports/payroll/${payroll.id}`}>Payroll Report</LinkButton>
                <LinkButton to={`reports/match/${payroll.id}`}>Match Report</LinkButton>
              </> : <>
                <LinkButton className={'mr-2'} to={`payrolls/${payroll.id}`}>Edit</LinkButton>
                <LinkButton to={'#'} onClick={event => {
                  event.preventDefault();
                  currentCompany.deletePayroll(payroll.id);
                }}>Delete</LinkButton>
              </>}
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  </div>;
});

export default PayrollList;
