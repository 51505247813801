import React, {useState, useEffect, useContext} from "react";
import StoreContext from "./StoreContext";
import {useLocation} from "react-router-dom";

const StoreStateManager = ({children}) => {
  const {pathname} = useLocation();
  const {rootStore} = useContext(StoreContext);
  const [lastMessage, setLastMessage] = useState(null);
  const [lastErrorMessage, setLastErrorMessage] = useState(null);


  // Clear form errors on navigation
  useEffect(() => {
    rootStore.setFormErrors({});
    rootStore.setSubmitting(false);
    if (lastMessage === rootStore.message) {
      rootStore.setMessage('');
    } else {
      setLastMessage(rootStore.message);
    }
    if (lastErrorMessage === rootStore.errorMessage){
      rootStore.setErrorMessage('');
    }else {
      setLastErrorMessage(rootStore.errorMessage);
    }
  }, [
    pathname
  ]);

  return children;
};

export default StoreStateManager
