import { types, flow, getRoot } from "mobx-state-tree";
import { apiGet, apiPost } from "../utils";
import Company from "./Company";
import { IsoDate } from "../types/isoDate";
import { useHistory } from "react-router-dom";

const Employee = types.model("Employee", {
  id: types.identifier,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  companyAccountHolderId: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  verified: types.maybeNull(types.boolean),
  planAdmin: types.maybeNull(types.boolean),
  emailverified: types.maybeNull(types.boolean),
  enrolled: types.maybeNull(types.boolean),
  status: types.maybeNull(types.string),
  address: types.frozen(),
  beneficiary: types.frozen(),
  hireDate: types.maybeNull(IsoDate),
  matchPercent: types.maybeNull(types.number),
  savingsPlanAmount: types.maybeNull(types.number),
  bonusMatchPercent: types.maybeNull(types.number),
  emergencySavingsGoal: types.maybeNull(types.number),
  totalAvailable: types.maybeNull(types.number),
  totalSavings: types.maybeNull(types.number),
  matchSavings: types.maybeNull(types.number),
  payrollSavings: types.maybeNull(types.number),
  stats: types.map(types.frozen()),
  accountHolderGoals: types.maybeNull(types.frozen()),
  url: types.maybeNull(types.string),
  urls: types.map(types.string),
  company: types.maybeNull(types.late(() => Company)),
  accountId: types.maybeNull(types.string),
  transactionPages: types.map(types.frozen([])),
  transactionPageCount: 0,
  account: types.frozen(),
  created: types.maybeNull(IsoDate),
  createdBy: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  resetToken: types.maybeNull(types.string),
  updated: types.maybeNull(IsoDate),
  updatedBy: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  active: types.maybeNull(types.boolean),
  owner: types.maybeNull(types.boolean),
  ownership: types.maybeNull(types.number),
  ssn: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(IsoDate),
  setupItems: types.maybeNull(types.array(types.frozen({}))),
  licenseImage: types.frozen(),
  licenseUrl: types.maybeNull(types.string),
  license: types.maybeNull(types.string),
  withdrawalType: types.maybeNull(types.string),
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zip: types.maybeNull(types.string),
  addressId: types.maybeNull(types.string),
  enrolledStatus: types.maybeNull(types.string),
  csaTiedId: types.maybeNull(types.string),
  locked: types.maybeNull(types.boolean),
  terminationDate: types.maybeNull(types.string),
  newAcks: types.maybeNull(types.boolean),
}).views(self => ({
  get goalGraphData() {
    let data = {
      labels: ['Emergency']
    };
    let savedDataset = {
      label: 'Amount Saved',
      data: [],
      backgroundColor: '#b6e174',
      minBarLength: 2,
    };
    let goalDataset = {
      label: 'Goal',
      data: [],
      backgroundColor: '#252369'
    };

    
    // If we have money stored...
    const totalSavings = self.totalSavings;
    // if (totalSavings) {
      // Determine if emergency goal has been realized;
      const emergencyProgress = totalSavings >= self.emergencySavingsGoal
        ? self.emergencySavingsGoal
        : totalSavings;
      savedDataset.data.push(emergencyProgress);
      goalDataset.data.push(self.emergencySavingsGoal);

      let remainder = Math.max(totalSavings - emergencyProgress, 0);

      let longTermGoals = self.longTermGoals;
      let shortTermGoals = self.shortTermGoals;

      // Short term
      const shortTermGoalCount = shortTermGoals.length;
      if (shortTermGoalCount) {
        let shortTermAmount = 0;
        shortTermGoals.forEach(goal => shortTermAmount += goal.amount);

        // If all short term goals have not been started or already been realized simply map to goal amount
        if (remainder === 0 || remainder >= shortTermAmount) {
          shortTermGoals.map(goal => {
            data.labels.push(goal.name);
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });

          // Determine remainder for long term goals
          remainder = Math.max(remainder - shortTermAmount, 0);
        } else {
          // Calculate even distribution for all goals
          const shortTermDistributed = Math.floor(remainder / shortTermGoalCount);

          shortTermGoals.map((goal, index) => {
            data.labels.push(goal.name);

            let shortGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (shortTermDistributed >= goal.amount) {
              shortGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              shortGoalProgress = index > 0 ? remainder : shortTermDistributed;
            }

            savedDataset.data.push(shortGoalProgress);
            goalDataset.data.push(goal.amount);
          });

          // Zero any remainder since short term goals were not met
          remainder = 0;
        }
      }

      // Long term
      const longTermGoalCount = longTermGoals.length;
      if (longTermGoalCount) {
        let longTermAmount = 0;
        longTermGoals.forEach(goal => longTermAmount += goal.amount);

        // If all long term goals have been realized simply map to goal amount
        if (remainder === 0 || remainder >= longTermAmount) {
          longTermGoals.map(goal => {
            data.labels.push(goal.name);
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });
        } else {
          // Calculate even distribution for all goals
          const longTermDistributed = Math.floor(remainder / longTermGoalCount);

          longTermGoals.map((goal, index) => {
            // Push in the goal amount
            data.labels.push(goal.name);

            let longGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (longTermDistributed >= goal.amount) {
              longGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              longGoalProgress = index > 0 ? remainder : longTermDistributed;
            }

            savedDataset.data.push(longGoalProgress);
            goalDataset.data.push(goal.amount);
          });
        }
      }
    // }

    data.datasets = [savedDataset, goalDataset];

    return data;
  },
  get name() {
    return `${self.firstName} ${self.lastName}`;
  },
  get longTermGoals() {
    // const goals = self.goals.length ? self.goals : []; //TODO
    const goals = self.accountHolderGoals.length ? self.accountHolderGoals : [];

    return goals.map(goal => {
      const amount = parseInt(goal.amount);
      goal = {amount: amount, name: goal.name, term: goal.term};

      return goal;
    })
    .filter(goal => goal.term === 'LONG' && goal.amount > 0)
    .sort(function (a, b) {
      return a.amount - b.amount;
    });
  },
  get shortTermGoals() {
    const goals = self.accountHolderGoals.length ? self.accountHolderGoals : [];

    return goals.map(goal => {
      const amount = parseInt(goal.amount);
      goal = {amount: amount, name: goal.name, term: goal.term};

      return goal;
    })
    .filter(goal => goal.term === 'SHORT' && goal.amount > 0)
    .sort(function (a, b) {
      return a.amount - b.amount;
    });
  }
})).actions(self => ({
    uploadLicense: flow(function* (file) {
      const formData = new FormData();
      formData.append('file', file);

      const response = yield fetch(`/api/v1/accountHolder/addLicenseImage/${self.id}`, {
          method: 'POST',
          body: formData,
          headers: {
              Authorization: `Bearer ${getRoot(self).authStore.getToken()}`
          },
      });
      const json = yield response.json();

      if (json.errors) {
          yield getRoot(self).setFormErrors({logoUrl: json.errors.logoUrl[0]});
      } else {
          self.logoUrl = json.logoUrl;
      }

      return self.logoUrl;
  }),
  checkSetup: flow(function* (id) {
    self.loading = true;
    const resp = yield apiGet(`/api/v1/dashboard/user/${id}`, getRoot(self).authStore);//company
    const response = yield resp.json();
    self.setupItems = response;
    self.loading = false;
  }),
  loadTransactions: flow(function* ({page, startDate, endDate, searchQuery}) {
    // const params = Object.entries({
    //   page: page,
    //   start_date: startDate,
    //   end_date: endDate,
    //   query: searchQuery
    // }).filter(([key, val]) => val).map(([key, val]) => key + '=' + encodeURIComponent(val)).join('&');
    if(self.accountId){
      const response = yield apiGet(`/api/v1/transaction/account/${self.accountId}?pageNumber=${page}&pageSize=15&startDate=${startDate ? startDate: ''}&endDate=${endDate ? endDate : ''}`, getRoot(self).authStore);
      try{
        const json = yield response.json();
        self.transactionPages.set(page, json.content);
        self.transactionPageCount = json.totalPages;
      }catch{}
    }
  }),
  loadQuarterlyTransactions: flow(function* ({page, quarter, year}) {
    if(self.accountId){
      const response = yield apiGet(`/api/v1/transactionQuarterly/account/${self.accountId}?pageNumber=${page}&pageSize=1000&quarter=${quarter ? quarter: ''}&year=${year ? year : ''}`, getRoot(self).authStore);
      const json = yield response.json();
      self.transactionPages.set(page, json.content);
      self.transactionPageCount = json.totalPages;
    }
  }),
  loadStats: flow(function* (page) {
    const response = yield apiGet(self.url, getRoot(self).authStore);
    self.stats = response.data.stats;
  }),
  update: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    // const response = yield apiPost(self.urls.get('update'), data);

    let params = {...self, ...data};
    const response = yield apiPost(`/api/v1/accountHolder/update/${self.id}`, params, getRoot(self).authStore, 'Account Holder Updated');
    if (response.error) {
      yield getRoot(self).setErrorMessage("Error Updating Account Holder")
    } else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.savingsPlanAmount = response.savingsPlanAmount;
      self.bonusMatchPercent = response.bonusMatchPercent;
      self.verified = response.verified;
      self.newAcks = response.newAcks;
      
      yield getRoot(self).setMessage('Profile updated');
    }
    yield getRoot(self).setSubmitting(false);
  }),
  verify: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    yield getRoot(self).setErrorMessage("");
    let params = {...self, ...data};
    params.dateOfBirth = data.values.dateOfBirth;
    params.ssn = data.values.ssn;
    const response = yield apiPost(`/api/v1/accountHolder/verifyEmployee/${self.id}`, params, getRoot(self).authStore, "Employee Verified");
    if (response.error) {
      yield getRoot(self).setErrorMessage(response.error);
    } else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.savingsPlanAmount = response.savingsPlanAmount;
      self.bonusMatchPercent = response.bonusMatchPercent;
      self.verified = response.verified;
      self.newAcks = response.newAcks;

      yield getRoot(self).setMessage('Profile updated');
    }
    yield getRoot(self).setSubmitting(false);
  }),
  updateSavings: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);

    let params = {...self, ...data};
    const response = yield apiPost(`/api/v1/accountHolder/updateSavingsPlan/${self.id}`, params, getRoot(self).authStore, 'SavingPlan Updated');
    if (response.error){
      yield getRoot(self).setErrorMessage(response.error);
    }
    else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.matchPercent = response.matchPercent;
      self.bonusMatchPercent = response.bonusMatchPercent;
      self.verified = response.verified;
      self.newAcks = response.newAcks;
      yield getRoot(self).setMessage('Profile updated');
    }
    yield getRoot(self).setSubmitting(false);
  }),
  updateBeneficiary: flow(function* (beneficiary) {
    yield getRoot(self).setSubmitting(true);

    let apiCall = `/api/v1/beneficiary/new`;
    beneficiary.id = '';

    beneficiary.accountHolder = self.id;
    beneficiary.address.accountHolder = self.id;

    if (self.beneficiary && self.beneficiary.id) {
      apiCall = `/api/v1/beneficiary/update/${self.beneficiary.id}`
      beneficiary.id = self.beneficiary.id;
    }

    const response = yield apiPost(apiCall, beneficiary, getRoot(self).authStore, 'Beneficary Updated');
    if (response.error){
      yield getRoot(self).setErrorMessage("Error Updating Beneficiary");
    } else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.beneficiary = response;
      yield getRoot(self).setMessage('Profile updated');
    }
    yield getRoot(self).setSubmitting(false);
  }),
  updateAddress: flow(function* (address) {
    yield getRoot(self).setSubmitting(true);

    let apiCall = self.address && self.address.id ? `/api/v1/address/update/${self.address.id}`
      : `/api/v1/address/new`

    address.accountHolder = self.id;

    const response = yield apiPost(apiCall, address, getRoot(self).authStore, "Address Updated");
    if(response.error){
      yield getRoot(self).setErrorMessage("Error Updating Address");
    }else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.beneficiary = response;
      yield getRoot(self).setMessage('Profile updated');
    }
    yield getRoot(self).setSubmitting(false);
  }),
  updateSavingsGoals: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/accountHolder/updateSavingsGoals/${self.id}`, data, getRoot(self).authStore);
    if(response.error){
      yield getRoot(self).setErrorMessage("Error Updating Savings Goals");
    } else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      yield getRoot(self).setMessage('Goals updated');
      self.emergencySavingsGoal = response.emergencySavingsGoal;
      self.accountHolderGoals = response.accountHolderGoals;
    }
    yield getRoot(self).setSubmitting(false);
  }),
  withdraw: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/user/withdrawal/${self.id}`, data, getRoot(self).authStore, 'Withdrawal');
    if (response.error || response.errors) {
      yield getRoot(self).setErrorMessage("Withdrawal Error");
      yield getRoot(self).setSubmitting(false);
      return false;
    }else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
      yield getRoot(self).setSubmitting(false);
      return false;
    } else {
      yield getRoot(self).setMessage(response.message);
      yield getRoot(self).setSubmitting(false);
      return true;
    }
  }),
  closeAccount: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/user/closeAccount/${self.id}`, data, getRoot(self).authStore, 'Account Closed');
    if (response.error) {
      yield getRoot(self).setErrorMessage ("Error Closing Account")
      yield getRoot(self).setSubmitting(false);
      return false;
    }else if (response.errors) {
      yield getRoot(self).setFormErrors(response.errors);
      yield getRoot(self).setSubmitting(false);
      return false;
    } else {
      yield getRoot(self).setMessage(response.message);
      yield getRoot(self).setSubmitting(false);
      return true;
    }
  })
}));

export default Employee;

