import React, {useEffect, useContext} from "react";
import StoreContext from "../../../StoreContext";
import ChangePasswordForm from "../../../forms/ChangePasswordForm";
import {observer} from "mobx-react";

const ChangePassword = observer((adminOpen) => {
  const {rootStore} = useContext(StoreContext);

  return <div>
    {adminOpen.adminOpen === true ? 'Password Reset is disabled as Admin! Please have the user follow the "Forgot Password" flow.'
    :
    <div className={'md:p-8 p-4'}>
      <ChangePasswordForm doSubmit={rootStore.updateCurrentUser} errors={rootStore.formErrors.toJSON()}
                          submitting={rootStore.submitting} />
    </div>
    }
  </div>;
});

export default ChangePassword;
