import {types, flow, getRoot} from "mobx-state-tree";
import {apiGet, apiPost, normalizeArray, apiGetFile} from "../utils";
import Employee from "./Employee";
import Payroll from "./Payroll";
import Contact from "./Contact";
import Balances from "./Balances";
import CompanyStats from "./CompanyStats";

const Company = types.model("Company", {
    id: types.identifier,
    name: types.maybeNull(types.string),
    logoUrl: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
    logoType: types.maybeNull(types.string),
    guide: types.maybeNull(types.string),
    guideName: types.maybeNull(types.string),
    fiscalYearStartMonth: types.maybeNull(types.number),
    taxId: types.maybeNull(types.string),
    idType: types.maybeNull(types.string),
    entityType: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    contacts: types.maybeNull(types.array(Contact)),
    address: types.frozen(),
    bankConnection: types.frozen(),
    companyPlans: types.maybeNull(types.array(types.frozen({}))),
    transactionPages: types.map(types.frozen([])),
    transactionPageCount: 0,
    employeePages: types.map(types.array(Employee)),
    employeeList: types.maybeNull(types.array(types.frozen({}))),
    employeePageCount: 0,
    employeeCount: types.maybeNull(types.number),
    ownerPages: types.map(types.array(Employee)),
    ownerPageCount: 0,
    participatingCount: types.maybeNull(types.number),
    balances: types.maybeNull(Balances),
    stats: types.maybeNull(CompanyStats),
    payrolls: types.maybeNull(types.map(Payroll)),
    affiliates: types.maybeNull(types.array(types.late(() => Company))),
    contributionData: types.map(types.frozen([])),
    balanceData:types.map(types.frozen([])),
    setupItems: types.maybeNull(types.array(types.frozen({}))),
    formationDate: types.maybeNull(types.string),
    waiveDate: types.maybeNull(types.string),
    kybSend: types.maybeNull(types.boolean),
    kybApproved: types.maybeNull(types.boolean),
    kybStatusDate: types.maybeNull(types.string),
    newAcks: types.maybeNull(types.boolean),
}).views(self => ({
    get sortedPayrolls() {
        return [...self.payrolls.values()].sort((a, b) => a.payPeriodDate > b.payPeriodDate ? -1 : 1);
    }
})).actions(self => ({
    loadQuarterlyTransactions: flow(function* ({page, quarter, year}) {
        const response = yield apiGet(`/api/v1/transactionQuarterly/company/${self.id}?pageNumber=${page}&pageSize=1000&quarter=${quarter ? quarter: ''}&year=${year ? year : ''}`, getRoot(self).authStore);
        const json = yield response.json();
        self.transactionPages.set(page, json.content);
        self.transactionPageCount = json.totalPages;
    }),
    checkSetup: flow(function* (id) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/dashboard/company/${id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.setupItems = response;
        self.loading = false;
    }),
    loadContributionData: flow(function* (id) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/contributionData/months/company/${id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.contributionData['months'] = response;

        const resp2 = yield apiGet(`/api/v1/contributionData/years/company/${id}`, getRoot(self).authStore);
        const response2 = yield resp2.json();
        self.contributionData['years'] = response2;

        self.loading = false;
    }),
    loadBalanceData: flow(function* (id) {
            self.loading = true;

            const resp1 = yield apiGet(`/api/v1/contributionData/dataBalance/years/company/${id}`, getRoot(self).authStore);
            const response1 = yield resp1.json();
            self.balanceData['years'] = response1;

            const resp2 = yield apiGet(`/api/v1/contributionData/dataBalance/months/company/${id}`, getRoot(self).authStore);
            const response2 = yield resp2.json();
            self.balanceData['months'] = response2;

            self.loading = false;
    }),
    loadCompanyPlans: flow(function* (id) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/companyPlan/company/${id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.companyPlans = [response];
        self.loading = false;
    }),
    loadCompanyContacts: flow(function* () {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/contact/company/${self.id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.contacts = response;
        self.loading = false;
    }),
    loadCompanyStats: flow(function* (id) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/company/stats/${id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.stats = CompanyStats.create(response);
        self.loading = false;
    }),
    loadCompanyTotal: flow(function* (id) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/company/totals/${id}`, getRoot(self).authStore);//company
        const response = yield resp.json();
        self.balances = Balances.create(response);
        self.loading = false;
    }),
    loadTransactions: flow(function* ({page, startDate, endDate, searchQuery}) {
        // const params = Object.entries({
	    //   page: page,
	    //   start_date: startDate,
	    //   end_date: endDate,
	    //   query: searchQuery
	    // }).filter(([key, val]) => val).map(([key, val]) => key + '=' + encodeURIComponent(val)).join('&');
	    const resp = yield apiGet(`/api/v1/transaction/company/${self.id}?pageNumber=${page}&pageSize=15&startDate=${startDate ? startDate: ''}&endDate=${endDate ? endDate : ''}&search=${searchQuery ? searchQuery : ''}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.transactionPages.set(page, response.content);
        self.transactionPageCount = response.totalPages;
    }),
    loadEmployees: flow(function* (page, name) {
        const resp = yield apiGet(`/api/v1/accountHolder/company/${self.id}?pageNumber=${page || 1}&pageSize=15&name=${name}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.employeePages.set(page, response.content);
        self.employeePageCount = response.totalPages;

        const listResp = yield apiGet(`/api/v1/accountHolder/company/employees/${self.id}`, getRoot(self).authStore);
        const listResponse = yield listResp.json();

        self.employeeList = listResponse;
    }),
    loadOwners: flow(function* (page) {
        const resp = yield apiGet(`/api/v1/accountHolder/company/owners/${self.id}?pageNumber=${page || 1}&pageSize=15`, getRoot(self).authStore);
        const response = yield resp.json();
        self.ownerPages.set(page, response.content);
        self.ownerPageCount = response.totalPages;
    }),
    loadPayrolls: flow(function* (page) {
        const resp = yield apiGet(`/api/v1/payroll/company/${self.id}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.payrolls = normalizeArray(response, 'id');
    }),
    loadAddress: flow(function* (page) {
        const resp = yield apiGet(`/api/v1/address/get/company/${self.id}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.address = response;
    }),
    loadAffiliates: flow(function* (page) {
        const resp = yield apiGet(`/api/v1/company/affiliates/${self.id}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.affiliates = response;
    }),
    setCurrentPayrollId: flow(function* (id) {
        if (id) {
            const resp = yield apiGet(`/api/v1/payroll/get/${id}`, getRoot(self).authStore);
            const response = yield resp.json();
            self.currentPayroll = response;
        } else {
            self.currentPayroll = {
                id: '',
                payPeriodDate: new Date(),
                payroll_items: [],
                paid: false,
                noMatch: false,
                scheduled: false,
                url: ''
            };
        }
    }),
    createPayroll: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        data.companyId = self.id;
        const response = yield apiPost(`/api/v1/payroll/new`, data, getRoot(self).authStore, 'Payroll Added');
        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Payroll");
        }

        self.payrolls.put(response);
        yield self.setCurrentPayrollId(response.id);
        yield getRoot(self).setSubmitting(false);
        return response;
    }),
    deletePayroll: flow(function* (id) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/payroll/delete/${id}`, {}, getRoot(self).authStore, 'Payroll Removed');

        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Deleting Payroll");
        }
        self.currentPayroll = undefined;

        self.payrolls = normalizeArray(response, 'id');
        yield getRoot(self).setSubmitting(false);
    }),
    updatePayroll: flow(function* (id, data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield (`/api/v1/payroll/update/${id}`, data, getRoot(self).authStore, 'Payroll Updated');
        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Updating Payroll");
        }
        self.currentPayroll = response;
        yield getRoot(self).setSubmitting(false);
    }),
    setCurrentEmployeeById: flow(function* (id) {
        self.currentEmployee = undefined;
        const resp = yield apiGet(`/api/v1/accountHolder/get/${id}`, getRoot(self).authStore);
        const response = yield resp.json();
        self.currentEmployee = response;
    }),
    updateCompanyPlan: flow(function* (plan, data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/companyPlan/update/${self.id}`, data, getRoot(self).authStore, 'Company Plan Updated');
        if (response.error) {
            yield getRoot(self).setErrorMessage("Error Updating Company Plan");
        } else if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
        } else {
            self.companyPlans.push(response);
            self.loadCompanyPlans(self.id);
            yield getRoot(self).setMessage(response.message);
        }
        yield getRoot(self).setSubmitting(false);
    }),
    createCompanyPlan: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/companyPlan/new`, data, getRoot(self).authStore, 'Company Added');
        if (response.error) {
            yield getRoot(self).setErrorMessage("Error Creating Company Plan");
        } else if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
        } else {
            self.companyPlans.push(response);
            self.loadCompanyPlans(self.id);
            yield getRoot(self).setMessage(response.message);
        }
        yield getRoot(self).setSubmitting(false);
    }),
    createEmployee: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        data.company = self;
        const response = yield apiPost(`/api/v1/accountHolder/newEmployee`, data, getRoot(self).authStore, 'Employee Added');

        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Employee");
            yield getRoot(self).setSubmitting(false);
            return false;
        }
        if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            return false;
        } else {
            yield getRoot(self).setSubmitting(false);
            return true;
        }
    }),
    createOwner: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        data.company = self;
        const response = yield apiPost(`/api/v1/accountHolder/newOwner`, data, getRoot(self).authStore, 'Owner Added');

        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Owner");
            yield getRoot(self).setSubmitting(false);
            return false;
        }
        if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            return false;
        } else {
            yield getRoot(self).setSubmitting(false);
            return true;
        }
    }),
    createLead: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        data.company = self;
        const response = yield apiPost(`/api/v1/accountHolder/newLead`, data, getRoot(self).authStore, 'Lead Administrator Added');

        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Lead Administrator");
            yield getRoot(self).setSubmitting(false);
            return false;
        }
        if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            return false;
        } else {
            yield getRoot(self).setSubmitting(false);
            return true;
        }
    }),
    VerifyUserEmail: flow(function* (address) {
        self.loading = true;
        const resp = yield apiGet(`/api/v1/user/checkEmail/employee/${address}`, getRoot(self).authStore)
        const response = yield resp.json();
        self.email = response;
        self.loading = false;
        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Verifying Email");
            setTimeout(() => {
                getRoot(self).setErrorMessage("");
            }, 3000);
            yield getRoot(self).setSubmitting(false);
            return false;
        }
        if (response.exists == true) {
            yield getRoot(self).setErrorMessage("Email Already Exists");
            setTimeout(() => {
                getRoot(self).setErrorMessage("");
            }, 3000);
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            //console.log(getRoot(self).ErrorMessage)
            return false;
        } else {
            yield getRoot(self).setSubmitting(false);
            //console.log(response.exists)
            return true;
        }
    }),
    update: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/company/update/companySettings/${data.id}`, data, getRoot(self).authStore, 'Company Settings Updated');

        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Updating");
        } else if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
        } else {
            self.loadAddress();
            getRoot(self).loadCurrentUser(getRoot(self).currentUser.id);  
        }
        yield getRoot(self).setSubmitting(false);
    }),
    uploadLogo: flow(function* (file) {
        yield getRoot(self).setSubmitting(true);
        const formData = new FormData();
        formData.append('file', file);

        const response = yield fetch(`/api/v1/company/addLogo/${self.id}`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${getRoot(self).authStore.getToken()}`
            },
        });
        const json = yield response.json();

        if (json.errors) {
            yield getRoot(self).setFormErrors({logoUrl: json.errors.logoUrl[0]});
        } else {
            self.logoUrl = json.logoUrl;
            self.logoType = json.logoType;
            self.logo = json.logo;
        }
        yield getRoot(self).setSubmitting(false);
        return self.logoUrl;
    }),
    downloadPayroll: flow(function* (data) {
        const resp = yield apiGetFile(`/api/v1/payroll/csv/download/${data.id}`, getRoot(self).authStore);
        return yield resp.json();
    }),
    uploadPayroll: flow(function* (file, data) {
        yield getRoot(self).setSubmitting(true);

        if (file === undefined) {
            yield getRoot(self).setErrorMessage("Error Uploading File. Only CSV files are allowed.");
            yield getRoot(self).setSubmitting(false);
            return;       
        }

        const formData = new FormData();
        formData.append("file", file);
        const response = yield fetch(`/api/v1/payroll/csv/upload/${data.id}`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${getRoot(self).authStore.getToken()}`
            },
        });

        if (response.status === 417 || response.error) {
            yield getRoot(self).setErrorMessage("Error Uploading Payroll");
        } else {
            const json = yield response.json();
            self.currentPayroll = json;
        }
        yield getRoot(self).setSubmitting(false);
    }),
    uploadEmployeeData: flow(function* (file) {
        yield getRoot(self).setSubmitting(true);
        if (file === undefined) {
            yield getRoot(self).setErrorMessage("Error Uploading File. Only CSV files are allowed.");
            yield getRoot(self).setSubmitting(false);
            return;       
        }
        
        const formData = new FormData();
        formData.append("file", file);
        const response = yield fetch(`/api/v1/accountHolder/csv/upload/${self.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${getRoot(self).authStore.getToken()}`
            },
            body: formData
        });
        const json = yield response.json();
        if (response.error) {
            yield getRoot(self).setErrorMessage("Error Uploading Employee Data");
        } else if (response.status === 417) {
            yield getRoot(self).setErrorMessage(json.message);
        } else {            
            yield getRoot(self).setMessage(json.message ? json.message : 'Successful Upload');
        }

        yield getRoot(self).setSubmitting(false);
    }),
    finalizePayroll: flow(function* (payroll) {
        yield getRoot(self).setSubmitting(true);

        const response = yield apiPost(`/api/v1/payroll/finalize/${payroll.id}`, payroll, getRoot(self).authStore, 'Payroll Finalized');

        if (response.error) {
            yield getRoot(self).setErrorMessage("Error Processing Payroll");
            yield getRoot(self).setSubmitting(false);
            return false;
        } else if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
            yield getRoot(self).setSubmitting(false);
            return false;
        } else {
            yield getRoot(self).setMessage('Payroll Processed');
            yield getRoot(self).setSubmitting(false);
            return true;
        }
    }),
    companyUpdate: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/accountHolder/update/employee/${data.id}`, data, getRoot(self).authStore, "Employee Updated");
        if (response.error) {
            yield getRoot(self).setErrorMessage("Error Updating Employee");
        }
        if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
        } else {
            yield getRoot(self).setSubmitting(false);
            return true;
        }
    }),
    updateContact: flow(function* (data) {
        yield getRoot(self).setSubmitting(true);
        const response = yield apiPost(`/api/v1/contact/update`, data, getRoot(self).authStore, 'Contact Updated');
        if (response.error) {//general error/500
            yield getRoot(self).setErrorMessage("Error Creating Contact(s)");
        } else if (response.errors) {
            yield getRoot(self).setFormErrors(response.errors);
        }
        yield getRoot(self).setSubmitting(false);
    }),
    hasContributionData: function (){
        return self.contributionData.years && self.contributionData.months;
    },
    hasBalanceData: function (){
        return self.balanceData.years && self.balanceData.months;
    }
}));

export default Company;
