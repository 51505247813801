import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import MatchSavingsLogo from "../images/match-savings-logo-full-color.png";
import {observer} from "mobx-react";
import StoreContext from "../StoreContext";
import { apiPost } from '../utils';

const ForgotPassword = observer(() => {
    const {authStore} = useContext(StoreContext);
    const { role } = useParams();
    const history = useHistory();
    const [message, setMessage] = useState(undefined);    

    const handleEmailChange = e => {
        authStore.setEmail(e.target.value);
        // 
    };

    const  handleSubmitForm = async e =>{

        e.preventDefault();//otherwise the button submit counts as an action and async goes nuts

        let newUser = {
            email: authStore.email 
        }
        apiPost(`/forgot/${role}/${newUser.email}`);
        setMessage(`If an account exists, an email has been sent to ${newUser.email}.`);
    };

    return (

        <div id="forgotPassword" className="flex md:h-screen flex-col-reverse md:flex-row">
            <div className="bg-half-splash flex-1 bg-cover p-10 flex justify-center items-center">
            </div>
            <form onSubmit={handleSubmitForm}>
                <div className="p-28 flex flex-col justify-center items-center">
                    <div className="w-96 flex flex-col items-center">
                        <h1 className="text-4xl mb-8 text-center text-primary font-bold">
                            <img className="mx-auto max-w-100" src={MatchSavingsLogo}/>
                        </h1>
                        <h2 className="text-xl">Forgot Password</h2>

                        <input id="email"
                               className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
                               placeholder="Email"
                               name="email" value={authStore.email} required
                               autoComplete="email"
                               autoFocus onChange={handleEmailChange}
                        />

                        {message && <p className="text-red-500" >{message}</p>}
                        <button type="submit"
                                className="bg-primary px-10 py-2 m-2 w-full text-center text-white uppercase"
                                >
                            Send Email  </button>
                        <Link to={`/login/${role}`}
                            className="bg-primary px-10 py-2 m-2 w-full text-center text-white uppercase"
                            >
                        Back to Login </Link>

                    </div>
                </div>
            </form>
        </div>
    );
});

export default ForgotPassword;

