import React, { useContext, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import MatchSavingsLogo from "../images/match-savings-logo-full-color.png";
import { observer } from "mobx-react";
import StoreContext from "../StoreContext";
import { apiGetNoAuth, apiPostNoAuth, formatMessage } from '../utils';
import { Form, Formik } from 'formik';
import FormErrors from '../components/FormErrors';
import PasswordVerifyField from '../components/PasswordVerifyField';
import FieldGroup from '../components/FieldGroup';
import PrimaryButton from '../components/PrimaryButton';
import { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';

const Register = observer(() => {
    const { authStore } = useContext(StoreContext);
    const [exist, setExist] = useState(false)
    const { role } = useParams();
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(authStore.email);
    const [passwordMatch, setPasswordMatch] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [pwPass, setPwPass] = useState(false);
    const [matchPass, setMatchPass] = useState(false);
    const [messages, setMessages] = useState("");
    const [error, setError] =  useState("");
    const [pwStrength, setPwStrength] = useState('');
    const [pwInstr, setPwInstr] = useState('Password must contain at least 14 characters with one or more uppercase, lowercase, number, and at least 1 special character.');
    let formValues = {
        password: '',
        password_confirmation: '',
      };
    const intl = useIntl();
    const [companies, setCompanies] = useState([]);
    const [name, setName] = useState('');
    const [accepted, setAccepted] = useState(false);
    // const privacy_policy = 'https://drive.google.com/file/d/14U15MRh6aWIwiBv1AvD_vhbca0R2Qcp4/view?usp=sharing';
    // const terms_of_service_company = 'https://drive.google.com/file/d/1K9lWpzA4Zw7YVwUXiDqlx6KgYtBkxc0r/view?usp=sharing';
    // const terms_of_service_participant = '';
    
    useEffect(() => {
        
        let found = companies.filter(filterCompany);

        handleCompanySelect(found && found.length > 0 ? found[0].value : undefined)

     }, [name]);

    const filterCompany = (company) => {
        if (company.label === name) {
            return company;
        }
    }
    const handleCompanySelect = e => {
        if (e) {
            authStore.setCompanyId(e);
        }
    }

    const getCompanies = async (value) => {

        if (value.length > 4) {
            const response = await apiGetNoAuth(`/api/v1/utility/companySelect?search=${value}`);
            const companiesLocal = await response.json();
            setCompanies(companiesLocal);
        }
    }

    const handleEmailChange = e => {
        authStore.setError('');
        authStore.setEmail(e.target.value);
        setEmail(e.target.value);
    };
    // const handlePasswordChange = e => {
    //     authStore.setError('');
    //     authStore.setPassword(e.target.value);
    // };

    const handleChangePw = (event) => {
        let value = event.target.value.trim() || '';
        if (value === passwordMatch && value !== '') {
            setMessages('');
            setIsDisabled(false);
        } else {
            setMessages('Passwords do not match');
            setIsDisabled(true);
        }
        setPassword(value);
        authStore.setPassword(value);
        checkPwStrength(value);
    }
    
    const handleChangePwMatch = (event) => {
        let value = event.target.value.trim() || '';
        if (value === password && value !== '') {
            setMessages('');
            setIsDisabled(false);
            setMatchPass(true);
        } else {
            setMessages('Passwords do not match');
            setIsDisabled(true);
            setMatchPass(false);
        }
        setPasswordMatch(value);
    }

    const toggleAccept = () => {
        if (accepted === true) {
          setAccepted(false);
        } else {
          setAccepted(true);
        }
      }

    const checkPwStrength = (password) => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{14,})");
        // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        if(strongRegex.test(password)) {
            setPwPass(true);
            setPwStrength('Password meets requirements.');
            setPwInstr('');
        // } else if(mediumRegex.test(value)) {
            // setPwStrength({"background-color":"orange"});
        } else {
            setPwPass(false);
            setPwInstr('Password must contain at least 14 characters with one or more uppercase, lowercase, number, and at least 1 special character.');
            setPwStrength('');
        }
    }

    const handleSubmitForm = async (e) => {
        authStore.setMessage("");

        if (exist) {
            setError("Email Already Exists");           
        }
        else {
            let newUser = {
                email: email,
                password: password,
                role: role.toUpperCase(),
                companyId: role === 'employee' ? authStore.companyId : undefined
            }
            const user = await apiPostNoAuth(`/api/v1/register/new/${role}`, newUser, 'Added');


            if (!user.error && user.error !== '') {
                authStore.setPassword('');
                authStore.setMessage(`Successfully Added ${newUser.email}. Please Login`);
                history.push(`/login/${role}`);
            } else {
                authStore.setPassword('');
                if (user.message) {
                    setError(user.message);
                } else {
                    setError(`An error occurred adding ${newUser.email}. This email may already be registered.`);
                }                
            }
        }
    };
    const handleVerifyEmail = async e => {

        e.preventDefault();//otherwise the button submit counts as an action and async goes nuts
        setError("");
        setExist(false);
        let newUser = {
            email: authStore.email
        }
        const user = await apiGetNoAuth(`/api/v1/user/checkEmail/${role}/${newUser.email}`);
        const response = await user.json();
        if (response.exists === true) {
            setExist(true);
            setError("Email Already Exists");
           
        }
    };
    return (

        <div id="register" className="flex md:h-screen flex-col-reverse md:flex-row">
            <div className="bg-half-splash flex-1 bg-cover p-10 flex justify-center items-center">
                {role === 'employer' ?
                    (
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">Welcome
                            to MatchSavings.
                            <br />Our mission is to financially stabilize American workers through an easy and
                            rewarding savings system.</p>)
                    : (
                        <p className="text-3xl lg:text-5xl max-w-2xl text-white text-center font-thin leading-normal lg:leading-relaxed">Welcome
                            to MatchSavings.
                            <br />You need more than a savings account.You need robust savings and peace of mind.</p>
                    )}
            </div>
            {/* <form  className='overflow-y-auto'  onSubmit={handleSubmitForm}>
                <div className="pt-6 p-0 lg:pb-0 md:p-24 lg:p-28 md:pb-0 flex flex-col justify-center items-center">
                    <div className="w-4/5 md:w-72 lg:w-96 flex flex-col items-center">
                        <h1 className="text-4xl mb-8 text-center text-primary font-bold">
                            <img className="mx-auto max-w-100" src={MatchSavingsLogo} />
                        </h1>

                        {role === 'employer' ?
                            (
                                <h2 className="text-xl">Employer Sign Up</h2>
                            )
                            :
                            (
                                <h2 className="text-xl">Employee Sign Up</h2>
                            )}
                        {authStore.message && <p className="text-red-500" >{authStore.message}</p>}
                        <input id="email"
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
                            placeholder="Email"
                            name="email" value={authStore.email} required
                            autoComplete="email"
                            autoFocus onChange={handleEmailChange}
                            // onBlur={handleVerifyEmail}
                        />


                        <input id="password" type="password"
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('password') border-red-600 @enderror"
                            placeholder="Password"
                            name="password" value={authStore.password} required
                            onChange={handleChangePw}
                            autoComplete="current-password"
                        />

                        {authStore.error && <p className="text-red-500" >{authStore.error}</p>}
                        <button type="submit"
                            className="bg-primary px-10 py-2 m-2 w-full text-center text-white uppercase"
                        >
                            Sign Up  </button>

                        <p className="my-8 text-center">By signing in or signing up, I agree to MatchSavings Terms of
                            Service
                            and Privacy
                            Policy.</p>

                    </div>
                </div>
            </form> */}
           <Formik
                initialValues={formValues}
                    onSubmit={(values) => {
                    handleSubmitForm(values)
                }}
            >
                <Form onBlur={() => {setError('')}}>
                <FormErrors >
                    
            <div className="pt-6 p-0 lg:pb-0 md:p-24 lg:p-28 md:pb-0 flex flex-col register-box">
                    <div className="w-4/5 md:w-72 lg:w-96 flex flex-col items-center">
                        <h1 className="text-4xl mb-8 text-center text-primary font-bold">
                            <img className="mx-auto max-w-100" src={MatchSavingsLogo} alt='logo'/>
                        </h1>

                        {role === 'employer' ?
                            (
                                <h2 className="text-xl">Employer Sign Up</h2>
                            )
                            :
                            (
                                <h2 className="text-xl">Employee Sign Up</h2>
                            )}
                        {error && <p className="text-red-500" >{error}</p>}
                        
                        


                        <>
                    <div className={'py-3'}>
                        
                    {/* <input id="email"
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
                            
                            placeholder="Email"
                            name="email" value={authStore.email} required
                            autoComplete="email"
                            autoFocus onChange={handleEmailChange}
                            // onBlur={handleVerifyEmail}
                        /> */}
                    <PasswordVerifyField 
                        placeholder="Email"
                        name="email" value={authStore.email} required
                        autoComplete="email"
                        autoFocus onChange={handleEmailChange}
                        label={"Email"}
                    />
                    {role === 'employee' ?
                    <p className="text-xs text-red-500">
                        <FormattedMessage id={'login.signup.help'}/>
                    </p>
                    : ''}
                    <PasswordVerifyField
                        label={'Password'}
                        name={'password'}
                        type={'password'}
                        value={password}
                        onChange={handleChangePw}
                        pass={pwPass}
                        required
                    />
                    <div className='text-red-500 text-xs'>{pwInstr}</div>
                    <div className='text-green-500 text-xs'>{pwStrength}</div>
                    <div className={''}>
                    <PasswordVerifyField
                        label={'Confirm Password'}
                        name={'password_confirmation'}
                        type={'password'}
                        value={passwordMatch}
                        onChange={handleChangePwMatch}
                        pass={matchPass}
                        required
                    />
                    <div className='text-red-500 text-xs' style={{color: 'red'}}>{messages}</div>
                    </div>
                    </div>
                    
                    </>
                    {role === 'employee' ?
                    <Autocomplete
                            className="rounded-sm p-3 border border-mid-grey border-solid my-4 w-full @error('email') border-red-600 @enderror"
                            name="company" 
                            noOptionsText={formatMessage(intl, 'login.no.options', '', [])}
                            value={name} 
                            required
                            autoComplete="company"
                            options={companies.map((option) => option.label)}
                            getOptionLabel={(option) => option}
                            onChange={(e) => {
                                setName(e.target.textContent);
                                }
                            }
                            onInputChange={(event, newInputValue) => {
                                getCompanies(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label={formatMessage(intl, 'login.company', '', [])} required/>}
                        />
                        : ''}

                        <>
                        <h2 className="text-xl mt-8 mb-2"><FormattedMessage id={'login.signup.title'}/></h2>

                        <p className="text-sm mb-2"><FormattedMessage id={'login.signup.par1'}/></p>

                        <p className="text-sm mb-5">
                            <FormattedMessage id={'login.signup.par2a'}/>
                            <a href="https://matchsavings.com/legal" className='underline' rel="noreferrer" style={{fontWeight: "bold"}} target={'_blank'}>
                                <FormattedMessage id={'login.signup.legal'}/>
                            </a>
                            <FormattedMessage id={'login.signup.par2b'}/>
                        </p>
                        </>

                        <div className='row flex'>

                        <input type={'checkbox'} value={accepted} name='acceptTerms' onClick={toggleAccept} className={'mb-6 mr-4'}/>
                        <label for='acceptTerms' className='mr-4'><FormattedMessage id={'login.signup.agreement1'}/></label>
                        
                        </div>

                    {accepted === true ?
                    <>
                    <FieldGroup>
                       
                        <PrimaryButton disabled={isDisabled}><FormattedMessage id={'login.signup.button'}/></PrimaryButton>
                        
                    </FieldGroup>
                    
                    {/* <p className="my-8 text-center">By signing in or signing up, I agree to MatchSavings Terms of
                            Service
                            and Privacy
                            Policy.</p> */}
                            </>
                            : ''
                        }
                            </div>
                            
        </div>
                </FormErrors>
                </Form>
            </Formik>;
        </div>
        
    );
});

export default Register;


