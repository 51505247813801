import React, { useContext, useEffect, useState } from 'react';
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import Select from "../components/Select";
import {phoneNumberMask} from "../formikUtils";
import MaskedLabeledField from "../components/MaskedLabeledField";
import * as Yup from "yup";
import { apiGet } from '../utils';
import StoreContext from '../StoreContext';
import _ from "lodash";

const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const AdminUserForm = ({doSubmit, errors, submitting, user}) => {

  const {rootStore} = useContext(StoreContext);
  const [users, setUsers] = useState([]);

  const loadAdminUsers = async () => {
    const response = await apiGet(`/api/v1/admin/user/all/${rootStore.currentUser.id}/admin`, rootStore.authStore);
    const users = await response.json();

    let filtered = [];
    filtered.push({label: 'Please Choose One', value: null});

    await _.forEach(users, (user) => {
      user.label =  user.role === 'CSA' ? `${user.firstName} ${user.lastName} (CSA)` : `${user.firstName} ${user.lastName} (EMPLOYEE)`;
      user.value = user.id;
      filtered.push(user);
    });   
    
    setUsers(filtered);
  };

  useEffect(() => {
    loadAdminUsers();
  }, []);

  return <Formik
    initialValues={user ? {
      email: user.email,
      phone: user.phone || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      role: user.role,
      // dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).format('YYYY-MM-DD') : '',
      id: user.id,
      // hireDate: user.hireDate ? moment(user.hireDate).format('YYYY-MM-DD') : '',
      ssn: user.ssn ? user.ssn : '',
      csaTieId: user.csaTieId ? user.csaTieId : '',
      companyId: rootStore.companyId,
      active: user.active,
    } : {
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      role: 'EMPLOYEE',
      with_demo: '',
      // hireDate: '',
      ssn: '',
      csaTieId: '',
      companyId: rootStore.companyId,
      active: false,
    }}
    onSubmit={(values) => {
      if (!values.password) {
        const {password, ...rest} = values;
        doSubmit(rest);
      } else {
        doSubmit(values);
      }
    }}
    validationSchema={
      Yup.object().shape({
        email: Yup.string()
            .required('Email Address is required'),
        // phone: Yup.string()
        //     .required('Phone Number is required'),
        firstName: Yup.string()
            .required('First name is required'),
        lastName: Yup.string()
            .required('Last name is required'),
        // dateOfBirth: Yup.string()
        //     .required('Date Of Birth is required'),
        role: Yup.string()
            .required('Role is required'),
        // ssn: Yup.string()
        //     .required('Ssn is required')

    })
  }
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <div className={'mb-4'}>
          <LabeledField
            label={'Email Address'}
            name={'email'}
          />
        </div>
        <div className={''}>
          <MaskedLabeledField
            label={'Phone'}
            name={'phone'}
            mask={phoneNumberMask}
            value={values.phone}
            onChange={e => {
              setFieldValue(`phone`, e.target.value);
            }}
          />
        </div>
        <div className={'flex'}>
          <LabeledField
            className={'mr-8 flex-1'}
            label={'First Name'}
            name={'firstName'}
            
          />
          <LabeledField
            className={'flex-1'}
            label={'Last Name'}
            name={'lastName'}
          />
        </div>
        <div className={'mb-4'}>
          <LabeledField
            label={'Role'}
            name={'role'}
            choices={[
              {label: 'ADMIN', value: 'ADMIN'},
              {label: 'EMPLOYEE', value: 'EMPLOYEE'},
              {label: 'EMPLOYER', value: 'EMPLOYER'},
              {label: 'OWNER', value: 'OWNER'},
              {label: 'CUSTOMER SERVICE REP', value: 'CSA'},
              {label: 'BANK ANALYST', value: 'BSA'},
              {label: 'LEAD', value: 'LEAD'},
            ]}
            component={Select}
          />
        </div>
        <div className={'mb-4'}>
          <LabeledField
            label={'CSR tied Id (only CSR roles need this)'}
            name={'csaTieId'}
            choices={users}
            component={Select}
          />
        </div>
        <div className={'flex mt-2'}>
          <h3 className={'text-lg my-2 mr-2'}>Disabled:</h3>
          <LabeledField
              name={'active'}
              className={"mr-8"}
              choices={[
                {label: 'No', value: 'true'},
                {label: 'Yes', value: 'false'},          
              ]}
              component={Select}
            />
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
        </FieldGroup>
      </FormErrors>
    </Form>}
  </Formik>;
};

export default AdminUserForm;
