import React, {Component} from "react";
import {Link, useLocation} from "react-router-dom";

export default function SidebarItem({to, highlightExclusions , root, children,onChange, step, stepDone}) {
  const {pathname} = useLocation();

  let inactive = false;
  if (highlightExclusions ){
    highlightExclusions.forEach(exclusion =>{
      if (pathname.includes(exclusion)){
        inactive = true;
      }
    })
  }
  const activeClasses = pathname.startsWith(root? root: to) && !inactive? "bg-transparent-white text-white font-bold" : "text-white";
  return <Link to={to} onClick={onChange} className={"block text-left w-full text-lg py-4 my-1 pl-10 pr-16 " + activeClasses + (step > 0 && step < stepDone ? ' text-red-400' : '')} >{children}</Link>
}
