import React from "react";
import {Link} from "react-router-dom";

const RouterLink = ({children, className, ...props}) => {
  return <Link className={`${className || ''}`} {...props}>
    {children}
  </Link>;
};

export default RouterLink;
