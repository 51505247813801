const defaultTheme = require('tailwindcss/defaultTheme')

export const purge = ['./src/**/*.js']
export const darkMode = false; // or 'media' or 'class'
export const theme = {
  extend: {
    fontFamily: {
      'sans': ['Roboto', ...defaultTheme.fontFamily.sans],
    },
    backgroundImage: theme => ({
      'full-splash': "url('../images/background.png')",
      'half-splash': "url('../images/half-background.png')",
      'employee-icon': "url('../images/employee-icon.svg')",
      'employer-icon': "url('../images/employee-icon.svg')",
    }),
    textColor: theme => ({
      ...theme('colors'),
      'primary': '#252369',
      'primary-dark': '#2a5ebc',
      'body': '#151515',
    }),
    backgroundColor: theme => ({
      ...theme('colors'),
      'primary': '#252369',
      'primary-mid': '#252369',
      'primary-dark': '#2a5ebc',
      'subtle-blue': 'rgba(232, 245, 255, 0.4)',
      'off-white': '#f9f9f9',
      'transparent-white': 'rgba(255, 255, 255, 0.3)'
    }),
    backgroundPosition: {
      'left-3': '3rem center'
    },
    borderColor: theme => ({
      ...theme('colors'),
      'mid-grey': '#6f7c8b',
      'primary': '#252369',
      'primary-mid': '#3f72cd',
      'primary-dark': '#2a5ebc',
    }),
    borderWidth: theme => ({
      '0': '0',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px',
    }),
    minHeight: theme => ({
      'threequarters': '75vh',
    }),
    maxWidth: theme => ({
      '7.5xl': '85rem',
    }),
    boxShadow: theme => ({
      'inset': 'inset 0 -1px 0 0 #acacac',
    }),
  },
}
export const variants = {
  extend: {},
}
export const plugins = []
