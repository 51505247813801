import React, {useContext, useEffect, useRef, useState} from "react";
import companyPng from './company.png';
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import logo from "../images/match-savings-logo-full-color.png";
import StoreContext from "../../StoreContext";
import { FormattedMessage} from 'react-intl';
//TODO - sort out how this was intended to work and fix

const Observer = ({ref, onObserved}) => {

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onObserved();
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  // }, [ref]);

};

export default function Header({children, title, companyLogo, company, isEmployee, locales, locale, changeLanguage, fmt}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const {authStore} = useContext(StoreContext);
  const observerRef = useRef(null);
  Observer({ref: observerRef, onObserved: closeHeader});
  const history = useHistory();
  let { path, url } = useRouteMatch();

  function closeHeader() {
    authStore.setMessage('');
    setDropdownVisible(false);
  }
  function logout () {
    authStore.logout();
  }
  return <div className={"py-6 flex items-center relative overflow-visible shadow-inset print:header-border"}>
    <h1 className={"max-w-xs font-extrabold text-white text-center text-lg"}><img className={'max-h-14 max-w-100'} src={logo} /></h1>
    <h2 className={"flex-1 text-primary font-bold text-lg"}>{title}</h2>
    {locale && locales ? 
    <select
      name="locale"
      defaultValue={locale}
      id="locale"
      onChange={(event) => changeLanguage(event.target.value)}
      className={'float-right border border-mid-grey p-3'}
  >
      {Object.keys(locales).map(locale => (
      <option key={locale} value={locale}>
          {fmt({id: `${locale}`})}
      </option>
      ))}
    </select>
    : '' }
    {company && company.guide ?
    <a href={`/guide/${company.id}`} target={"_blank"} rel="noreferrer" className={'text-red-400 ml-4 underline'}>
        SPD/Quick Start Guide!
    </a>
    : ''}
    <div className={"flex justify-end text-primary relative cursor-pointer text-right ml-3"}  ref={observerRef} onClick={() => setDropdownVisible(!dropdownVisible)}
          style={{zIndex: '9999'}}>
         <div className={"flex items-center leading-normal"}>{children}</div>
        <div><img src={(!company || !company.logo) ? companyPng : `/api/v1/company/logo/${company.id}`} alt={'Company Logo'} className={'inline-block ml-2'} style={{borderRadius: '50%', height: 38, maxWidth: 38}} /></div>
      {/* <img className={'inline-block ml-2'} src={companyLogo ?? companyPng}
           style={{borderRadius: '50%', height: 38, maxWidth: 38}}/> */}
      
      <div
           className={`bg-white border border-solid border-grey-300 absolute right-1 top-12 ${dropdownVisible ? '' : 'hidden'}`}
      >
        <div>
          <Link to={`${url}/password`} onClick={closeHeader}
                className={'text-primary block hover:bg-subtle-blue p-2 px-1 sm:px-8'}>
            <FormattedMessage id={'change.password'}/>
          </Link>
        </div>
        {isEmployee ?
        <div>
          <Link to={`${url}/close`} onClick={closeHeader}
                className={'text-primary block hover:bg-subtle-blue p-2 px-1 sm:px-8'}>
            <FormattedMessage id={'close.acct'}/>
          </Link>
        </div>
        : ''
        }
        <div>
          <form action={"/"} onClick={() => logout()}>
            <button className={"text-primary hover:bg-subtle-blue p-2 px-1 sm:px-8 w-full"}><FormattedMessage id={'logout'}/></button>
          </form>
        </div>
      </div>
    </div>
  </div>;
}
