import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import UserAccountForm from "../../../forms/UserAccountForm";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import {FormattedMessage} from 'react-intl';

const AccountSettings = observer(({loadStep}) => {
  const {rootStore, rootStore: {currentUser, currentEmployeeRecord}} = useContext(StoreContext);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const change = params.get('change');
  const employee = rootStore.currentEmployeeRecord;
  const company = employee.company;
  // const intl = useIntl();

  return <div>
    <div className={'md:p-8 p-4'}>
      <AlertProvider/>
      {change && change === 'true' ?
        <p className={'text-red-500 mb-4'}>Please take a moment to update your profile. Also, it is strongly encouraged that you change your email address to a personal one other than your work email. Then use the updated email to login.</p>
        : ''
      }
      {currentEmployeeRecord && currentUser &&
          <UserAccountForm
        doSubmit={async (values) => {
          const {address, beneficiary, ...userData} = values;
          if (beneficiary.firstName || beneficiary.lastName || beneficiary.phone || beneficiary.relationship) {
            await currentEmployeeRecord.updateBeneficiary(beneficiary);
          }
          if (address.address1 || address.address2 || address.city || address.state || address.zip) {
            await currentEmployeeRecord.updateAddress(address);
          }
          await rootStore.updateCurrentUser(userData);

          loadStep();
        }}
        user={currentUser}
        employee={currentEmployeeRecord}
        errors={rootStore.formErrors.toJSON()}
        submitting={rootStore.submitting}/>}

        <h3 className={'text-xl mt-3 mb-5'}><FormattedMessage id={'settings.employer.info'}/></h3>

        {employee.terminationDate && employee.terminationDate !== '' ?
            <>
              <div className={"mt-2 mb-8 text-red-500"}>Your account will be unaffiliated after {employee.terminationDate}</div>
              {company ? 
              <>
                <div>{company.name}</div>
                <div>{company.address.address1}</div>
                <div>{company.address.address2}</div>
                <div>{company.address.city}, {company.address.state}</div>
                <br/>        
                <div>Phone: {company.phone}</div>
              </>
              : ''}
            </>
            : <>
              <div>{company.name}</div>
              <div>{company.address.address1}</div>
              <div>{company.address.address2}</div>
              <div>{company.address.city}, {company.address.state}</div>
              <br/>
              <div>Phone: {company.phone}</div>
            </>
            }
    </div>
  </div>;
});

export default AccountSettings;
