import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import { FormattedMessage } from "react-intl";
import EmployerPolicyTable from "../../../components/EmployerPolicyTable";
import StoreContext from "../../../StoreContext";
import { apiGet } from "../../../utils";

const EmployerPolicies = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const [policies, setPolicies] = useState([]);

  const loadPolicies = async () => {
    const response = await apiGet(`/api/v1/acks/${currentCompany.id}/company/get`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  };

  useEffect(() => {
    loadPolicies();
  }, []);

  return <div>
    <h3 className={"text-xl"}><FormattedMessage id={'acks.title'}/></h3>
    <br />
    <div>
      <EmployerPolicyTable policies={policies}/>
    </div>
  </div>;
});

export default EmployerPolicies;
