import { types } from "mobx-state-tree";
import moment from "moment";

export const IsoDate = types.custom({
  name: "IsoDate",
  fromSnapshot: string => moment(string),
  toSnapshot: (momentObj) => momentObj.format(),
  isTargetType: maybeMoment => moment.isMoment(maybeMoment),
  getValidationMessage: snapshot => {
    if (snapshot===null || snapshot === undefined) {
      return "";
    }
    const momentObj = moment(snapshot);
    if (!momentObj.isValid()) {
      const message = `"${snapshot}" is not in a valid iso date format`;
      console.error(message);
      return message;
    }

    return "";
  }
});
