import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import { Link } from "@material-ui/core";
import PDFUpload from "../../components/PDFUpload";
import { Field, Formik } from "formik";

export const Guides = (props) => {

    const {authStore} = useContext(StoreContext);
    const [guides, setGuides] = useState([]);
    const [company, setCompany] = useState({company : {guideUrl :'', guideLogo: ''}});
    const [companyId, setCompanyId] = useState(undefined);

    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        getGuides();
      }, []);

    const getGuides = async () => {
        const response = await apiGet(`/api/v1/company/all/actual`, authStore);
        const resp = await response.json();

        setGuides(resp);
    }

    const getCompany = async () => {
        const response = await apiGet(`/api/v1/company/get/${companyId}`, authStore);
        const resp = await response.json();

        setCompany(resp);
        setShowEdit(true);
    }

    const addGuide = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        await fetch(`/api/v1/company/addGuide/${companyId}`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${authStore.getToken()}`
            },
        });

        getGuides();
        setShowEdit(false);
    }

    const setSelectedCompanyId = async (id) => {
        setCompanyId(id);
    }

    const closeForm = () => {
        setShowEdit(false);
    }

    useEffect(() => {
        if (companyId) {
            getCompany();
        }
      }, [companyId]);
    
    return (
        <>
        {!showEdit ?
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'Company',
                    field: 'name',
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedCompanyId(row.id)}} className={"cursor-pointer"}>
                            {row.name}
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'File',
                    field: 'guideName',
                    render: (row) => {
                        return (
                        <>
                        {/* <Link onClick={() => {downloadFile(row)}}>
                            {row.guideName}
                        </Link> */}
                        <a href={`/guide/${row.id}`} target={"_blank"} rel="noreferrer">
                            {row.guideName}
                        </a>
                        </>
                        )
                    }
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "guides",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={guides}
        />
        </div>
        :
        <div>

            <Formik initialValues={company}
            >
            
            <Field name={'guideUrl'} imageSource={company.guideUrl} onSelect={addGuide} company={company} component={PDFUpload} close={closeForm} cName={company.name}/>

            </Formik>
        </div>
        }
        </>
    );
}