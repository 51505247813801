import React from 'react';
import { Formik, Form, useField } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import MaskedLabeledField from "../components/MaskedLabeledField";
import * as Yup from 'yup';
import moment from 'moment';
import DateInputRight from '../components/DateInputRight';

const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="" {...field} {...props} />
          {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
          ) : null}
      </>
  );
};

const UnusualActivityInfoFields = ({setFieldValue,  values, adminOpen, isBSA}) => <div>
    <>

      
    <div className={'flex mt-2 container'}>
        <h3 className={'text-lg my-2'}>UAR No:</h3>
        <span className='mt-2 ml-2'><strong>{values.caseNumber}</strong></span>
    </div>
    <div className={'flex mt-2 container'}>
    <strong><h2 className={'text-lg my-2 mr-2'}>EMPLOYEE REPORT</h2></strong>
    </div>

    <div className={'flex mt-2 container'}>
      <h3 className={'text-lg my-2 mr-3'}>Date of UA Report:</h3>
      <DateInputRight 
        name={'uarDate'}
        className={"mr-8 container max-w-xxs"}
        value={moment(values.uarDate).format("YYYY-MM-DD")}
        onChange={e => {
            setFieldValue(`uarDate`, e.target.value);
        }}
      />
    </div>

    <div className={'flex mt-2 container'}>
      <h3 className={'text-lg my-2 mr-2'}>Type:</h3>
      <LabeledField
        label={''}
        name={'uarType'}
        choices={[
          {label: '$5k or More', value: 'FIVE_THOUSAND'},
          {label: 'Insider Abuse', value: 'INSIDER_ABUSE'},
          {label: 'Criminal violations aggregating $25k or more', value: 'FEDERAL_25K'},
          {label: 'Other', value: 'OTHER'},
        ]}
        component={Select}
      />
    </div>  
    

  <div className={'flex mt-2 container'}>
      <h3 className={'text-lg my-2 mr-2'}>UAR Level:</h3>
      <LabeledField
        label={''}
        name={'uarLevelType'}
        choices={[
          {label: 'Company', value: 'COMPANY'},
          {label: 'User', value: 'USER'},
        ]}
        component={Select}
      />
  </div>

  <div className={'flex mt-2 container'}>
    <div className={'card-div-column mt-2 mb-2'}>
      Company Name: <u><strong>{values.companyName}</strong></u>
    </div>
  </div>
  {values.userEmpty ?
  <div className={'flex mt-2 container'}>
    <div className={'card-div-column mt-2 mb-2'}>
      User Last Name: <u><strong>{values.lastName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      User First Name: <u><strong>{values.firstName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      Customer Account Number (SSN or EIN): <u><strong>{values.ssnOrEin}</strong></u>
    </div>
  </div>
  : 
  <>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>User Last Name:</h3>
    <LabeledField
      name={'lastName'}
      style={{width:"75%"}}
    />
    <h3 className={'text-lg my-2 mr-2'}>User First Name:</h3>
    <LabeledField
      name={'firstName'}
      style={{width:"75%"}}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Customer Account Number (SSN or EIN):</h3>
    <MaskedLabeledField name={'ssnOrEin'} mask={maskedSsn} 
          placeholder={'___-__-____'} value={values.ssnOrEin} style={{width:"15%"}}
          className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
              setFieldValue(`ssnOrEin`, e.target.value);
          }}/>
  </div>
  </>
  }
  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-2'}>Employee Name who Identified UA:</h3>
    <LabeledField
      name={'employeeName'}
      // style={{width:"75%"}}
    />
  </div>

  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>Date of UA:</h3>
    <DateInputRight 
      name={'dateOfActivity'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.dateOfActivity).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`dateOfActivity`, e.target.value);
      }}
    />
  </div>

  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>Date UA Discovered:</h3>
    <DateInputRight 
      name={'dateOfDiscovery'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.dateOfDiscovery).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`dateOfDiscovery`, e.target.value);
      }}
    />
  </div>

  <div className={'mt-2 container'}>
    <h3 className={'text-lg my-2'}>Detailed Description of UA:</h3> 
    <div className={'flex'}>
      <MyTextArea
        label=""
        name="description"
        rows="6"
        style={{border: "solid", width: "100%"}}
        value={values.description}
      />
    </div>
  </div>

  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Any law enforcement inquiries involved?</h3>
    <LabeledField
        name={'lawEnforcement'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>

  <h3 className={'text-lg my-2'}>If yes, provide details:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="lawEnforcementDetails"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.lawEnforcementDetails}
    />
  </div>

  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Other referrals involved?</h3>
    <LabeledField
        name={'referralsInvolved'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>

  <h3 className={'text-lg my-2'}>If yes, provide details:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="referralDetails"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.referralDetails}
    />
  </div>
  {isBSA ?  
  <>
  <strong><h2 className={'text-lg my-2 mr-2'}>BSA OFFICER’S REVIEW</h2></strong>

  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>Date BSA OFFICER Reviewed UAR:</h3>
    <DateInputRight 
      name={'bsaReviewDate'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.bsaReviewDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`bsaReviewDate`, e.target.value);
      }}
    />
  </div>

  <h3 className={'text-lg my-2'}>List all documents gathered/reviewed surrounding the UAR: </h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="documents"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.documents}
    />
  </div>

  <h3 className={'text-lg my-2'}>List Employees Involved/Interviewed & their perspective(s):</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="employeesInvolved"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.employeesInvolved}
    />
  </div>

  <h3 className={'text-lg my-2'}>List anything else in the customer file that indicates attempt to disguise funds from illegal activity:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="disguises"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.disguises}
    />
  </div>

  <div className={' flex container mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Decision By BSA Officer to Escalated to Sponsor Bank:</h3>
    <LabeledField
        name={'bsaEscalation'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>

  <div className={' flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>If yes, Date Referred to Sponsor Bank (CNB) by BSA Officer: </h3>
    <DateInputRight 
      name={'escalationDate'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.bsaReviewDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`escalationDate`, e.target.value);
      }}
    />
  </div>

  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>Date UAR referral to Sponsor Bank was reported to MS Board of Directors (required):</h3>
    <DateInputRight 
      name={'escalationDateToBoard'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.escalationDateToBoard).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`escalationDateToBoard`, e.target.value);
      }}
    />
  </div>

  <strong><h2 className={'text-lg my-2 mr-2'}>SPONSOR BANK’s RESPONSE</h2></strong>

  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Does SAR need to be filed?</h3>
    <LabeledField
        name={'sarRequired'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>

  <div className={'flex mt-2 container'}>
    <h3 className={'text-lg my-2 mr-3'}>If Yes, date SAR was filed:</h3>
    <DateInputRight 
      name={'sarFileDate'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.sarFileDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`sarFileDate`, e.target.value);
      }}
    />
  </div>

  <h3 className={'text-lg my-2'}>Reason Sponsor Bank (CNB) decided to file/not to file.</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="sarReason"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.sarReason}
    />
  </div>

  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Action on Account Required?</h3>
    <LabeledField
        name={'accountActionRequired'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>

  <h3 className={'text-lg my-2'}>If yes, describe action taken:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="accountAction"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.accountAction}
    />
  </div>
  </>
  : ''}

  <input value={values.accountHolderId} name={'accountHolderId'} type={'hidden'}/>
  <input value={values.companyId} name={'companyId'} type={'hidden'}/>
  {/* <input value={values.ssnOrEin} name={'ssnOrEin'} type={'hidden'}/> */}
  <input value={values.caseNumber} name={'caseNumber'} type={'hidden'}/>
  <input value={values.id} name={'id'} type={'hidden'}/>
  </>
</div>;

const UnusualActivityInfoForm = ({doSubmit, errors, submitting, unusualActivity, adminOpen, company, user, closeShowForm, isBSA}) => {

  // const unusualActivityDate = unusualActivity.formationDate ? moment(unusualActivity.unusualActivityDate) : moment();
  // const escalationDate = unusualActivity.escalationDate ? moment(unusualActivity.escalationDate) : moment();
  // const uarDate = unusualActivity.uarDate ? moment(unusualActivity.uarDate) : moment();
  // const dateOfActivity = unusualActivity.dateOfActivity ? moment(unusualActivity.dateOfActivity) : moment();
  // const dateOfDiscovery = unusualActivity.dateOfDiscovery ? moment(unusualActivity.dateOfDiscovery) : moment();
  // const escalationDateToBsa = unusualActivity.escalationDateToBsa ? moment(unusualActivity.escalationDateToBsa) : moment();
  // const bsaReviewDate = unusualActivity.bsaReviewDate ? moment(unusualActivity.bsaReviewDate) : moment();
  // const escalationDateToBoard = unusualActivity.escalationDateToBoard ? moment(unusualActivity.escalationDateToBoard) : moment();
  // const sarFileDate = unusualActivity.sarFileDate ? moment(unusualActivity.sarFileDate) : moment();

  if (unusualActivity) {
    unusualActivity = {...unusualActivity};
    // unusualActivity.unusualActivityDate = unusualActivityDate.format("YYYY-MM-DD");
    // unusualActivity.escalationDate = escalationDate.format("YYYY-MM-DD");
    // unusualActivity.uarDate = uarDate.format("YYYY-MM-DD");
    // unusualActivity.dateOfActivity = dateOfActivity.format("YYYY-MM-DD");
    // unusualActivity.dateOfDiscovery = dateOfDiscovery.format("YYYY-MM-DD");
    // unusualActivity.escalationDateToBsa = escalationDateToBsa.format("YYYY-MM-DD");
    // unusualActivity.bsaReviewDate = bsaReviewDate.format("YYYY-MM-DD");
    // unusualActivity.escalationDateToBoard = escalationDateToBoard.format("YYYY-MM-DD");
    // unusualActivity.sarFileDate = sarFileDate.format("YYYY-MM-DD");
  }

  let ssnOrEin = unusualActivity ? unusualActivity.ssnOrEin : null;
  if (ssnOrEin === undefined) {
    ssnOrEin = user ? user.ssn : null;
  }
  if (ssnOrEin === undefined) {
    ssnOrEin = company ? company.ein : null;
  }

  return <Formik
    initialValues={
      unusualActivity.id ? {
      name: unusualActivity ? unusualActivity.name : '',
      companyName: unusualActivity ? unusualActivity.companyName : company ? company.name : '',
      id: company ? unusualActivity.id : null,
      escalationDate: moment(unusualActivity.escalationDate).format("YYYY-MM-DD"),
      uarDate: moment(unusualActivity.uarDate).format("YYYY-MM-DD"),
      dateOfActivity: moment(unusualActivity.dateOfActivity).format("YYYY-MM-DD"),
      dateOfDiscovery: moment(unusualActivity.dateOfDiscovery).format("YYYY-MM-DD"),
      escalationDateToBsa: moment(unusualActivity.escalationDateToBsa).format("YYYY-MM-DD"),
      bsaReviewDate: moment(unusualActivity.bsaReviewDate).format("YYYY-MM-DD"),
      sarFileDate: moment(unusualActivity.sarFileDate).format("YYYY-MM-DD"),
      escalationDateToBoard: moment(unusualActivity.escalationDateToBoard).format("YYYY-MM-DD"),
      uarType: unusualActivity && unusualActivity.uarType? unusualActivity.uarType : 'FIVE_THOUSAND',
      uarLevelType: unusualActivity && unusualActivity.uarLevelType ? unusualActivity.unusualActivityLevelType : 'COMPANY',
      accountHolderId: user ? user.id : null,
      companyId: unusualActivity ? unusualActivity.companyId : company ? company.id : null,
      userEmpty: (user && user.user) || !unusualActivity.id ? true : false,
      firstName: unusualActivity ? unusualActivity.firstName : user && user.user ? user.user.firstName : "",
      lastName: unusualActivity ? unusualActivity.lastName : user && user.user ? user.user.lastName : "",
      employeeName: unusualActivity ? unusualActivity.employeeName : "",
      ssnOrEin: ssnOrEin,
      description: unusualActivity ? unusualActivity.description : "",
      lawEnforcement: unusualActivity ? unusualActivity.lawEnforcement : false,
      lawEnforcementDetails: unusualActivity ? unusualActivity.lawEnforcementDetails : "",
      referralsInvolved: unusualActivity ? unusualActivity.referralsInvolved : false,
      referralDetails: unusualActivity ? unusualActivity.referralDetails : "",
      documents: unusualActivity ? unusualActivity.documents : "",
      employeesInvolved: unusualActivity ? unusualActivity.employeesInvolved : "",
      disguises: unusualActivity ? unusualActivity.disguises : "",
      bsaEscalation: unusualActivity ? unusualActivity.bsaEscalation : false,
      phone: unusualActivity ? unusualActivity.phone : "",
      email: unusualActivity ? unusualActivity.email : "",
      sarRequired: unusualActivity ? unusualActivity.sarRequired : false,
      sarReason: unusualActivity ? unusualActivity.sarReason : "",
      accountActionRequired: unusualActivity ? unusualActivity.accountActionRequired : false,
      accountAction: unusualActivity ? unusualActivity.accountAction : "",
      caseNumber: unusualActivity ? unusualActivity.caseNumber : null,
      } : {
      name: '',
      companyName: company ? company.name : '',
      id: null,
      uarDate: moment().format("YYYY-MM-DD"),
      escalationDate: null,
      dateOfActivity: null,
      dateOfDiscovery: null,
      escalationDateToBsa: null,
      bsaReviewDate: null,
      sarFileDate: null,
      escalationDateToBoard: null,
      uarType: 'FIVE_THOUSAND',
      uarLevelType: 'COMPANY',
      accountHolderId: user ? user.id : null,
      companyId: company ? company.id : null,
      userEmpty: (user && user.user) ? true : false,
      firstName: user && user.user ? user.user.firstName : "",
      lastName: user && user.user ? user.user.lastName : "",
      employeeName: "",
      ssnOrEin: ssnOrEin,
      description: "",
      lawEnforcement: false,
      lawEnforcementDetails: "",
      referralsInvolved: false,
      referralDetails: "",
      documents: "",
      employeesInvolved: "",
      disguises: "",
      bsaEscalation: false,
      phone: "",
      email: "",
      sarRequired: false,
      sarReason: "",
      accountActionRequired: false,
      accountAction: "",
      caseNumber: null,
    }}
//     validationSchema={
//       Yup.object().shape({
//       name: Yup.string()
//           .required('Name is Required'),
//       address: Yup.object().shape({
//           address1: Yup.string()
//               .required("Address is required"),
//           city: Yup.string()
//               .required("City is required"),
//           state:  Yup.string()
//               .required("State is required"),
//           zip:  Yup.string()
//               .required("Zip is required"),
//       }),
//       phone:  Yup.string()
//           .required("Phone is required"),
//       taxId:  Yup.string()
//           .test('len', 'Must be 9 characters', val => val.length === 9)
//           .required("Tax Id is required")
//   })
// }
    onSubmit={(values) => {

      values.escalationDate = values.escalationDate ? `${values.escalationDate}T12:00:00.000000Z` : null;
      values.uarDate = values.uarDate ? `${values.uarDate}T12:00:00.000000Z` : null;
      values.dateOfActivity = values.dateOfActivity ? `${values.dateOfActivity}T12:00:00.000000Z` : null;
      values.dateOfDiscovery = values.dateOfDiscovery ? `${values.dateOfDiscovery}T12:00:00.000000Z` : null;
      values.escalationDateToBsa = values.escalationDateToBsa ? `${values.escalationDateToBsa}T12:00:00.000000Z` : null;
      values.bsaReviewDate = values.bsaReviewDate ? `${values.bsaReviewDate}T12:00:00.000000Z` : null;
      values.escalationDateToBoard = values.escalationDateToBoard ? `${values.escalationDateToBoard}T12:00:00.000000Z` : null;
      values.sarFileDate = values.sarFileDate ? `${values.sarFileDate}T12:00:00.000000Z` : null;

      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <UnusualActivityInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen} isBSA={isBSA}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Save</PrimaryButton><PrimaryButton onClick={closeShowForm}>Cancel</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default UnusualActivityInfoForm;
