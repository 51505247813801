import React from 'react';
import { Formik, Form, useField } from 'formik';
import FieldGroup from "../components/FieldGroup";
import Select from "../components/Select";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import MaskedLabeledField from "../components/MaskedLabeledField";
import * as Yup from 'yup';
import moment from 'moment';
import DateInputRight from '../components/DateInputRight';

const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="" {...field} {...props} />
          {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
          ) : null}
      </>
  );
};

const ComplaintInfoFields = ({setFieldValue,  values, adminOpen}) => <div>
    <>
    <div className={'flex mt-4 mb-4'}>
      
    {/* <label class="block mb-2 text-sm my-4 mr-2"></label> */}
    <h3 className={'text-lg my-2 mr-3'}>Date of Complaint</h3>
    <DateInputRight 
      name={'complaintDate'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.complaintDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`complaintDate`, e.target.value);
      }}
    />

    {/* <LabeledField
      label={'Date of Complaint'}
      name={'complaintDate'}
      component={DateInputRight}
      className={"mr-8 container"}
      value={moment(values.complaintDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`complaintDate`, e.target.value);
      }}
    /> */}
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2 mr-2'}>Type</h3>
      <LabeledField
        label={''}
        name={'complaintType'}
        choices={[
          {label: 'Legal', value: 'LEGAL'},
          {label: 'Regulatory', value: 'REGULATORY'},
          {label: 'Product', value: 'PRODUCT'},
          {label: 'Service', value: 'SERVICE'},
          {label: 'Other', value: 'OTHER'},
        ]}
        component={Select}
      />
    </div>  
    <div className={'flex justify-between ml-4'}>
      <h3 className={'text-lg my-2'}>Case No:</h3>
      <span className='mt-2 ml-2'><strong>{values.caseNumber}</strong></span>
    </div>
  </div>
  <div className={'flex'}>
      
  
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2 mr-2'}>Complainant Level</h3>
      <LabeledField
        label={''}
        name={'complaintLevelType'}
        choices={[
          {label: 'Company', value: 'COMPANY'},
          {label: 'User', value: 'USER'},
        ]}
        component={Select}
      />
    </div>

  </div>
  <div className={'flex'}>
    <div className={'card-div-column mt-2 mb-2'}>
      Company Name: <u><strong>{values.companyName}</strong></u>
    </div>
  </div>
  {values.userEmpty ?
  <div className={'flex'}>
    <div className={'card-div-column mt-2 mb-2'}>
      Complainant Last Name: <u><strong>{values.lastName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      Complainant First Name: <u><strong>{values.firstName}</strong></u>
    </div>
    <div className={'card-div-column mt-2 mb-2'}>
      Complainant SSN or EIN: <u><strong>{values.ssnOrEin}</strong></u>
    </div>
  </div>
  : 
  <>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Complainant Last Name:</h3>
    <LabeledField
      name={'lastName'}
      style={{width:"75%"}}
    />
    <h3 className={'text-lg my-2 mr-2'}>Complainant First Name:</h3>
    <LabeledField
      name={'firstName'}
      style={{width:"75%"}}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Complainant SSN or EIN:</h3>
    <MaskedLabeledField name={'ssnOrEin'} mask={maskedSsn} 
          placeholder={'___-__-____'} value={values.ssnOrEin} style={{width:"15%"}}
          className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
              setFieldValue(`ssnOrEin`, e.target.value);
          }}/>
  </div>
  </>
  }
  <h3 className={'text-lg my-2'}>Detailed Description of Complaint:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="description"
      rows="6"
      style={{border: "solid", width: "100%"}}
      value={values.description}
    />
  </div>
  <div className={'flex mt-2'}>
  <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2'}>Complaint Received from:</h3>
      <LabeledField
        label={''}
        name={'complaintReporterType'}
        choices={[
          {label: 'Agency', value: 'AGENCY'},
          {label: 'Customer', value: 'USER'},
        ]}
        component={Select}
      />
    </div>  
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>If agency, name of agency:</h3>
    <LabeledField
      name={'agencyName'}
      style={{width:"850px"}}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2'}>Agency Contact Person Name:</h3>
    <LabeledField
      name={'agencyContactName'}
      style={{width:"585px"}}
    />
    <h3 className={'text-lg my-2 mr-2'}>Phone:</h3>
    <LabeledField
      name={'phone'}
      style={{width:"200px"}}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-6'}>Agent Email Address:</h3>
    <LabeledField
      name={'email'}
      style={{width:"852px"}}
    />
  </div>
  <h3 className={'text-lg my-2'}>Resolution Offered:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="resolution"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.resolution}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Resolution Accepted:</h3>
    <LabeledField
        name={'resolutionAccepted'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <h3 className={'text-lg my-2'}>Customer Response:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="customerResponse"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.customerResponse}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Monetary Remediation Involved:</h3>
    <LabeledField
        name={'monetaryResolutionNeeded'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
      <h3 className={'text-lg my-2 mr-2'}>If yes, amount:</h3>
      <LabeledField
        name={'amount'}
      />
  </div>
  <strong><h3 className={'text-lg my-2'}>ESCALATION to CRL (Required if Legal, Regulatory or if Resolution not fully Accepted) </h3></strong>
  <div className={'flex mt-2'}>
  <h3 className={'text-lg my-2 mr-2'}>Date Escalated to CRL in writing:</h3>
    <DateInputRight 
      name={'escalationDate'}
      className={"mr-8 container max-w-xxs"}
      value={moment(values.escalationDate).format("YYYY-MM-DD")}
      onChange={e => {
          setFieldValue(`escalationDate`, e.target.value);
      }}/>
    </div>
  <strong><h3 className={'text-lg my-2'}>INVESTIGATION of COMPLAINT</h3></strong>
  <h3 className={'text-lg my-2'}>List all documents gathered/reviewed surrounding the complaint:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="investigation"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.investigation}
    />
  </div>
  <h3 className={'text-lg my-2'}>List Employees Involved/Interviewed & their perspective(s):</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="employeesInvolved"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.employeesInvolved}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Complaint folder made:</h3>
    <LabeledField
        name={'folderMade'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <h3 className={'text-lg my-2'}>List any additional efforts:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="additionalEfforts"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.additionalEfforts}
    />
  </div>
  <h3 className={'text-lg my-2'}>Corrective Action:</h3> 
  <div className={'flex'}>
    <MyTextArea
      label=""
      name="correctiveAction"
      rows="3"
      style={{border: "solid", width: "100%"}}
      value={values.correctiveAction}
    />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-2'}>Written Response to Customer:</h3>
    <LabeledField
        name={'responseToCustomer'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
    <h3 className={'text-lg my-2 mr-2'}>Placed in folder:</h3>
    <LabeledField
        name={'customerResponsePlacedInFolder'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <div className={'flex mt-2'}>
    <h3 className={'text-lg my-2 mr-6'}>Written Response to Agency:</h3>
    <LabeledField
        name={'responseToAgency'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
    <h3 className={'text-lg my-2 mr-2'}>Placed in folder:</h3>
    <LabeledField
        name={'agencyResponsePlacedInFolder'}
        className={"mr-8"}
        choices={[
          {label: 'No', value: 'false'},
          {label: 'Yes', value: 'true'},          
        ]}
        component={Select}
      />
  </div>
  <input value={values.accountHolderId} name={'accountHolderId'} type={'hidden'}/>
  <input value={values.companyId} name={'companyId'} type={'hidden'}/>
  {/* <input value={values.ssnOrEin} name={'ssnOrEin'} type={'hidden'}/> */}
  <input value={values.caseNumber} name={'caseNumber'} type={'hidden'}/>
  <input value={values.id} name={'id'} type={'hidden'}/>
  </>
</div>;

const ComplaintInfoForm = ({doSubmit, errors, submitting, complaint, adminOpen, company, user, closeShowForm}) => {

  // const complaintDate = complaint.formationDate ? moment(complaint.complaintDate) : moment();
  // const escalationDate = complaint.escalationDate ? moment(complaint.escalationDate) : moment();
  if (complaint) {
    complaint = {...complaint};
    // complaint.complaintDate = complaintDate.format("YYYY-MM-DD");
    // complaint.escalationDate = escalationDate.format("YYYY-MM-DD");
  }

  let ssnOrEin = complaint ? complaint.ssnOrEin : null;
  if (ssnOrEin === undefined) {
    ssnOrEin = user ? user.ssn : null;
  }
  if (ssnOrEin === undefined) {
    ssnOrEin = company ? company.ein : null;
  }

  return <Formik
    initialValues={
      complaint.id ? {
      name: complaint ? complaint.name : '',
      companyName: complaint ? complaint.companyName : company ? company.name : '',
      id: company ? complaint.id : null,
      complaintDate: moment(complaint.complaintDate).format("YYYY-MM-DD"),
      escalationDate: moment(complaint.escalationDate).format("YYYY-MM-DD"),
      complaintType: complaint && complaint.complaintType? complaint.complaintType : 'LEGAL',
      complaintLevelType: complaint && complaint.complaintLevelType ? complaint.complaintLevelType : 'COMPANY',
      accountHolderId: user ? user.id : null,
      companyId: complaint ? complaint.companyId : company ? company.id : null,
      userEmpty: (user && user.user) || !complaint.id ? true : false,
      firstName: complaint ? complaint.firstName : user && user.user ? user.user.firstName : "",
      lastName: complaint ? complaint.lastName : user && user.user ? user.user.lastName : "",
      ssnOrEin: ssnOrEin,
      description: complaint ? complaint.description : "",
      complaintReporterType: complaint && complaint.complaintReporterType? complaint.complaintReporterType : "AGENCY",
      agencyName: complaint ? complaint.agencyName : "",
      agencyContactName: complaint ? complaint.agencyContactName : "",
      phone: complaint ? complaint.phone : "",
      email: complaint ? complaint.email : "",
      resolution: complaint ? complaint.resolution : "",
      resolutionAccepted: complaint ? complaint.resolutionAccepted : false,
      customerResponse: complaint ? complaint.customerResponse : "",
      monetaryResolutionNeeded: complaint ? complaint.monetaryResolutionNeeded : false,
      amount: complaint ? complaint.amount : "",
      investigation: complaint ? complaint.investigation : "",
      employeesInvolved: complaint ? complaint.employeesInvolved : "",
      folderMade: complaint ? complaint.folderMade : false,
      additionalEfforts: complaint ? complaint.additionalEfforts : "",
      correctiveAction: complaint ? complaint.correctiveAction : "",
      responseToCustomer: complaint ? complaint.responseToCustomer : false,
      responseToAgency: complaint ? complaint.responseToAgency : false,
      customerResponsePlacedInFolder: complaint ? complaint.customerResponsePlacedInFolder : false,
      agencyResponsePlacedInFolder: complaint ? complaint.agencyResponsePlacedInFolder : false,
      caseNumber: complaint ? complaint.caseNumber : null,
      } : {
      name: '',
      companyName: company ? company.name : '',
      id: null,
      complaintDate: moment(complaint.complaintDate).format("YYYY-MM-DD"),
      escalationDate: null,
      complaintType: 'LEGAL',
      complaintLevelType: 'COMPANY',
      accountHolderId: user ? user.id : null,
      companyId: company ? company.id : null,
      userEmpty: (user && user.user) ? true : false,
      firstName: user && user.user ? user.user.firstName : "",
      lastName: user && user.user ? user.user.lastName : "",
      ssnOrEin: ssnOrEin,
      description: "",
      complaintReporterType: "AGENCY",
      agencyName: "",
      agencyContactName: "",
      phone: "",
      email: "",
      resolution: "",
      resolutionAccepted: false,
      customerResponse: "",
      monetaryResolutionNeeded: false,
      amount: "",
      investigation: "",
      employeesInvolved: "",
      folderMade: false,
      additionalEfforts: "",
      correctiveAction: "",
      responseToCustomer: false,
      responseToAgency: false,
      customerResponsePlacedInFolder: false,
      agencyResponsePlacedInFolder: false,
      caseNumber: null,
    }}
//     validationSchema={
//       Yup.object().shape({
//       name: Yup.string()
//           .required('Name is Required'),
//       address: Yup.object().shape({
//           address1: Yup.string()
//               .required("Address is required"),
//           city: Yup.string()
//               .required("City is required"),
//           state:  Yup.string()
//               .required("State is required"),
//           zip:  Yup.string()
//               .required("Zip is required"),
//       }),
//       phone:  Yup.string()
//           .required("Phone is required"),
//       taxId:  Yup.string()
//           .test('len', 'Must be 9 characters', val => val.length === 9)
//           .required("Tax Id is required")
//   })
// }
    onSubmit={(values) => {
      values.complaintDate = values.complaintDate ? `${values.complaintDate}T12:00:00.000000Z` : null;
      values.escalationDate = values.escalationDate ? `${values.escalationDate}T12:00:00.000000Z` : null;
      doSubmit(values);
    }}
  >{({setFieldValue, values}) => <Form>
    <FormErrors errors={errors}>
      <ComplaintInfoFields setFieldValue={setFieldValue} values={values} adminOpen={adminOpen}/>
      <FieldGroup>
        {submitting ? <Loading size={30}/> : <><PrimaryButton className={'mr-4'}>Save</PrimaryButton><PrimaryButton onClick={closeShowForm}>Cancel</PrimaryButton></>}
      </FieldGroup>
    </FormErrors>
  </Form>}
  </Formik>;
};

export default ComplaintInfoForm;
