import React, {useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import FiservCardForm from "../../../forms/FiservCardForm";

const CardSettings = observer(({loadStep}) => {
  const {rootStore, authStore} = useContext(StoreContext);
  const [message, setMessage] = useState('');

  return <div>
    <div className={'md:p-8 p-4'}>
      <p className="text-green-500" >{message}</p>
      {/* <AlertProvider/> */}
      <FiservCardForm authStore={authStore} rootStore={rootStore} errors={rootStore.formErrors.toJSON()}
                          submitting={rootStore.submitting} setMessage={setMessage} loadStep={loadStep}/>
    </div>
  </div>;
});

export default CardSettings;
