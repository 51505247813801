import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import {useRouteMatch} from "react-router-dom";
import AdminEmployeeTable from "../../components/AdminEmployeeTable";
import DateInput from "../../components/DateInput";

const AdminEmployees = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  const [page, setPage] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [ssn, setSsn] = useState('');
  const [dob, setDOB] = useState(null);
  

  let { url } = useRouteMatch();
  useEffect(() => {
    adminStore.loadUsers(page);
  }, [firstName, lastName, email, dob, page, ssn, company, adminStore.userSearch]);
  
  
    
  
  function  EmployeeFilter(user,firstName,lastName,email,dob){    
    return  user.role === "EMPLOYEE" && (user.firstName === null? false: firstName === ''? true: user.firstName.toLowerCase().includes(firstName.toLowerCase())) && (user.lastName === null? false: lastName === ''? true: user.lastName.toLowerCase().includes(lastName.toLowerCase()))
    && (user.email === null? false: email === ''? true: user.email.toLowerCase().includes(email.toLowerCase()))
    && (user.companyName === null? false: company === ''? true: user.companyName.toLowerCase().includes(company.toLowerCase()))
    && (user.ssn === null? false: ssn === ''? true: user.ssn.toLowerCase().includes(ssn.toLowerCase()))
    && (dob ? user.dateOfBirth === dob : true);
}

function setDOBState(date){
  const ToDate = new Date();
  const FromDate = new Date(date);
  if (FromDate > ToDate) {      
      rootStore.setErrorMessage("The Date must be lower or equal to today date");
      return false;
  
    }else{
    setDOB(date)
    rootStore.setErrorMessage("");
  }

}

function getTodayDateFormatted(){
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate();
  var year = dtToday.getFullYear();

  if(month < 10)
      month = '0' + month.toString();
  if(day < 10)
      day = '0' + day.toString();

  return year + '-' + month + '-' + day;
}
  return <div>
    <h3 className={"text-xl"}>Employees</h3>
    <br />
    <div className={'flex flex-col md:flex-row mb-2'}>
      <div className={'mb-2'}>
        <label>First Name</label>
        <Input
          onChange={(e) => {
            setFirstName(e.target.value)//, setPage(1)
          }}
          value={firstName}
          placeholder={'Search By First Name'}
        />
      </div>
      <div className={'md:mx-4 mb-2'}>
        <label>Last Name</label>
        <Input
          onChange={(e) => {
            setLastName(e.target.value)//, setPage(1)
          }}
          value={lastName}
          placeholder={"Search By Last Name"}
        />
      </div>
      <div className={'md:mr-4 mb-2'}>
        <label>Company</label>
        <Input
          onChange={(e) => {
            setCompany(e.target.value)//, setPage(1)
          }}
          value={company}
          placeholder={"Search By Company"}
        />
      </div>
      <div className={'md:mr-4 mb-2'}>
        <label>Email</label>
        <Input
          onChange={(e) => {
            setEmail(e.target.value)//, setPage(1)
          }}
          value={email}
          placeholder={"Search By Email"}
        />
      </div>
      <div className={'md:mr-4 mb-2'}>
        <label>SSN</label>
        <Input
          onChange={(e) => {
            setSsn(e.target.value)//, setPage(1)
          }}
          value={ssn}
          placeholder={"Search By SSN"}
        />
      </div>
      <div style={{marginTop: "-1px"}} className={''}>
        <label>DOB</label>
        <DateInput 
          //value= {dob}
          max= { getTodayDateFormatted()}
          onChange={(evt) => setDOBState(evt.target.value)} 
        />
      </div>
    </div>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    {rootStore.errorMessage && <Alert kind={'error'}>{rootStore.errorMessage}</Alert>}
    {adminStore.userPages.size ? <div>
      <AdminEmployeeTable users={adminStore.userPage(page).filter((user) => EmployeeFilter(user,firstName,lastName,email, dob))}/>
    </div> : <Loading/>}
  </div>;
});

export default AdminEmployees;
