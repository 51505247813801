import React, {useState} from "react";
import LineChart from "./LineChart";
import moment from "moment";
import {formatMoney} from "../utils";
import Select from "./Select";

const EmployerContributionChart = ({contributionData}) => {
  const [chartInterval, setChartInterval] = useState('12m');
  if (chartInterval === '6m') {
    contributionData = contributionData['months'].slice(-7);
  } else if (chartInterval === '12m') {
    contributionData = contributionData['months'].slice(-13);
  } else if (chartInterval === '24m') {
    contributionData = contributionData['months'].slice(-25);
  } else if (chartInterval === '3y') {
    contributionData = contributionData['years'].slice(-4);
  } else if (chartInterval === '5y') {
    contributionData = contributionData['years'].slice(-6);
  } else {
    contributionData = contributionData['years'];
  }

  let labels = [];
  if (chartInterval.includes('m')) {
    labels = contributionData.map(e => {
      if (chartInterval === '24m') {
        return moment().month(e.month - 1).year(e.year).format('MM/YY');
      } else {
        return moment().month(e.month - 1).year(e.year).format('MMM');
      }
    });
  } else {
    labels = contributionData.map(e => {
      let d = new Date();
      if (d.getFullYear() !== e.year){//other years
        return moment().month(0).year(e.year).format("MM/YY");
      }else { //current year
        return moment().month(d.getMonth()).year(e.year).format("MM/YY");
      }
    });
  }

  return <div className={'border-solid border-gray-200 border-r-2 p-8 flex-1'}>
    <div className={'flex justify-between'}>
      <h3 className={'text-lg my-2'}>Contributions to Plan</h3>
      <div>
        <Select
          choices={[
            {label: '6 months', value: '6m'},
            {label: '1 year', value: '12m'},
            {label: '2 years', value: '24m'},
            {label: '3 years', value: '3y'},
            {label: '5 years', value: '5y'},
          ]}
          field={{value: chartInterval}}
          onChange={(e) => {
            setChartInterval(e.target.value);
          }}
        />
      </div>
    </div>
    <LineChart
      labels={labels}
      formatValue={formatMoney}
      datasets={[
        {
          label: "Employee",
          data: contributionData.map(e => parseFloat(e.base_contribution) + parseFloat(e.bonus_contribution)),
          fill: false,
          borderColor: '#252369',
          backgroundColor: '#252369',
        },
        {
          label: "Employer",
          fill: false,
          data: contributionData.map(e => parseFloat(e.match_due)),
          borderColor: '#81c1ff',
          backgroundColor: '#81c1ff',
        },
        {
          label: "Other",
          data: [],
          fill: false,
          borderColor: '#0057ff',
          backgroundColor: '#0057ff',
        }
      ]}
    />
  </div>
}

export default EmployerContributionChart;
