import {observer} from "mobx-react";
import Loading from "./Loading";
import PrimaryButton from "./PrimaryButton";
import React, {useContext, useState} from "react";
import StoreContext from "../StoreContext";
import Input from "./Input";
import { Formik,Form } from "formik";
import * as Yup from 'yup';
import AlertProvider from "../AlertProvider";
import { FormattedMessage, useIntl} from 'react-intl';
import {planDescription, formatMessage} from '../utils';
 
 const SavingPlanSchema = Yup.object().shape({
   matchPercent: Yup.string()
     .required('Required'),
   bonusMatchPercent: Yup.string()
     .required('Required'),
 });

const SavingsAmountSelector = observer(({employee, loadStep}) => {
  const {rootStore} = useContext(StoreContext);
  const [matchPercent, setMatchPercent] = useState(parseFloat(employee.matchPercent).toFixed(2));
  const [bonusMatchPercent, setBonusMatchPercent] = useState(parseFloat(employee.bonusMatchPercent).toFixed(2));
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();

  if (rootStore.currentUser.role === 'EMPLOYEE' && employee.company.companyPlans.length === 0) {
    return <div><FormattedMessage id={'dashboard.selector.incomplete'}/></div>
  }

  const plan = employee.company ? employee.company.companyPlans[0] : undefined;

  const handleSubmit = async e => { 
      e.preventDefault();
      rootStore.setErrorMessage('')
      setErrorMessage('');

      let checkMatchPercent = matchPercent === 'NaN' || matchPercent === '' ? '0':matchPercent;
      let checkBonusMatchPercent = bonusMatchPercent === 'NaN' || bonusMatchPercent === '' ? '0':bonusMatchPercent;
      if(checkMatchPercent === '' && checkBonusMatchPercent === ''){
      rootStore.setErrorMessage(formatMessage(intl, 'dashboard.selector.error', '', []))
      setErrorMessage(formatMessage(intl, 'dashboard.selector.error', '', []));
      } else {
      employee.updateSavings({
      matchPercent: matchPercent === 'NaN' || matchPercent === '' ? '0':matchPercent,
      bonusMatchPercent: bonusMatchPercent === 'NaN' || bonusMatchPercent === '' ? '0':bonusMatchPercent,
    })
    loadStep();
  }
  }
  
  return<Formik
  initialValues={{
    matchPercent: '',
    bonusMatchPercent: '',
  }}
  validationSchema={SavingPlanSchema}
  >{({ errors, touched, validateForm, setFieldValue }) => (
    <Form>
      <AlertProvider/>
  <div>
    <p className={'text-sm'}>
      {employee.company ?
      planDescription(plan, intl)
      : ''
      }
    </p>
    <div className={"flex my-4 items-center"}>
      <div className={"w-24 mr-8"}>
        <div className={'percent-input'}>
          <Input
            name="matchPercent"
            required={true}
            field={{value: matchPercent}}
            type= "number"
            onChange={(e) => setMatchPercent(e.target.value)}
          />
        </div>
      </div>
      <p><FormattedMessage id={'plan.employee.goals.regular'}/></p>
    </div>
    <div className={"flex my-4 items-center"}>
      {/* <div className={"w-24 mr-8"}>
        <div className={'percent-input'}>
          <Input
            name="bonusMatchPercent"
            field={{value: bonusMatchPercent}}
            required={true}
            type= "number"
            onChange={(e) =>setBonusMatchPercent(e.target.value)}
          />
        </div>
      </div>
      <p><FormattedMessage id={'plan.employee.goals.special'}/></p> */}
    </div>
    {rootStore.submitting ? <Loading/> :
      <PrimaryButton 
      onClick={handleSubmit}
      ><FormattedMessage id={'save.button'}/></PrimaryButton>}
  </div>
  </Form>
  )}
  </Formik>
});

export default SavingsAmountSelector;
