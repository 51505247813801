import React, { useEffect } from 'react';
import { useState } from "react";
import PrimaryButton from "../components/PrimaryButton";
import { apiGet, apiPost } from '../utils';
// import moment from 'moment';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';
import DisabledButton from '../components/DisabledButton';
import Loading from '../components/Loading';
import CancelCardDialog from '../components/CancelCardDialog';

const FiservCardForm = ({authStore, rootStore, submitting, setMessage, errors, loadStep}) => {
  const intl = useIntl();
  const [requested, setRequested] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [card, setCard] = useState();
  const [error, setError] = useState('');
  // const month = moment().year();
  const card_agreement = 'https://drive.google.com/file/d/1uQkkM1RhO1-l6liVVHoU-F3osNhOsozV/view?usp=sharing';
  const [accepted, setAccepted] = useState(false);
  // const delayed = moment().isSameOrBefore(moment('2022-12-31'));
  const [loading, setLoading] = useState(false);
  // const [showCancel, setShowCancel] = useState(false);

  const toggleAccept = () => {
    if (accepted === true) {
      setAccepted(false);
    } else {
      setAccepted(true);
    }
  }

  useEffect(() => {
    loadCardRequest();
  }, []);

  const loadCardRequest = async () => {
    const response = await apiGet(`/api/v1/bank/card/getCardRequest/${rootStore.currentEmployeeRecord.id}`, authStore);//company
    const resp = await response.json();

    if (resp && resp.cardId) {
      setRequested(true);
      setCard(resp);
    } else {
      setRequested(false)
    }
    setLoading(false);
  }

  const requestCard = async () => {
    setLoading(true);
    const response = await apiPost(`/api/v1/bank/card/new`, {id: rootStore.currentEmployeeRecord.id}, authStore, null);//company

    if (response.errors || response.errorMessage || response.status > 299) {
      setError(formatMessage(intl, 'card.failed', '', []));
      setLoading(false);
      toggleAccept();
    } else {
      if (response && response.cardId) {
        setRequested(true);
      }
    }
    loadCardRequest();
  }

  const cancelCard = async () => {
    // setLoading(true);
    // await apiPost(`/api/v1/bank/card/cancel/${rootStore.currentEmployeeRecord.id}`, {id: rootStore.currentEmployeeRecord.id}, authStore, null);//company
    // loadCardRequest();
    // setShowCancel(true);
  }

  if (loading) {
    return <Loading/>;
  }
  
  return (
    <>
    {error ? 
      <p className={'text-red-500 mb-4'}>{error}</p>
      : ''
    }

      {requested ?
        <>
          <p className='text-blue-500'><FormattedMessage id={'card.physical'}/></p>
          <br/>
          <p className={'mb-4 flex row '}>
          <CancelCardDialog dialogTitle={'Cancel Card'}/>
          {/* <FormattedMessage id={'card.stolen'}/> */}
          </p>
        </>
      :  !showPin ?
        <>
        

        {card && card.cardId !== null ?
        <>
        <h3 className={'text-lg font-bold mb-10'}><FormattedMessage id={'card.debit.offer'}/></h3>
        
        </>
        
        : ''
        }
        
        {rootStore.currentEmployeeRecord.totalAvailable > 500 ?
        <>
        <input type={'checkbox'} value={accepted} name='acceptTerms' onClick={toggleAccept} className={'mb-6 mr-4'}/>
        <label for='acceptTerms' className=''><FormattedMessage id={'card.agreement1'}/></label>
        <a href={card_agreement} target={"_blank"} rel="noreferrer"
        className="uppercase text-primary font-bold text-sm mt-4"><FormattedMessage id={'card.agreement2'}/></a> 
        </>
        : <p className={'mb-4 flex row text-red-500'}>
        <FormattedMessage id={'card.nsf'}/>
        </p>}
        {accepted === true ?
        <p className={'mb-4 flex row '}><PrimaryButton onClick={requestCard} className={'mr-4'}><FormattedMessage id={'card.send'}/></PrimaryButton>
        <FormattedMessage id={'card.send.instruction'}/>
        </p>
        : <p className={'mb-4 flex row '}><DisabledButton className={'mr-4'} ><FormattedMessage id={'card.send'}/></DisabledButton>
        <FormattedMessage id={'card.send.instruction'}/>
        </p>
        }
        
        </>
        :''
      }
    </>
  );
};

export default FiservCardForm;