import React, { version } from 'react';
import {Formik, Form, Field} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import StateInput from "../components/StateInput";
import MaskedLabeledField from "../components/MaskedLabeledField";
import {phoneNumberMask} from "../formikUtils";
import LicenseImageUpload from '../components/LicenseImageUpload';
import ReactTooltip from 'react-tooltip';
import DateInput from '../components/DateInput';
import moment from 'moment';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';

const UserAccountForm = ({doSubmit, errors, submitting, user, employee}) => {
  const intl = useIntl();
  const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return <Formik
    initialValues={{
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      address: {
        address1: employee.address && employee.address['address1'],
        address2: employee.address && employee.address['address2'],
        city: employee.address && employee.address['city'],
        state: employee.address && employee.address['state'],
        zip: employee.address && employee.address['zip'],
        id: employee.address && employee.address['id'],
        version: employee.address && employee.address['version'],
      },
      beneficiary: {
        firstName: employee.beneficiary ? employee.beneficiary.firstName : '',
        lastName: employee.beneficiary ? employee.beneficiary.lastName : '',
        relationship: employee.beneficiary ? employee.beneficiary.relationship : '',
        phoneNumber: employee.beneficiary ? employee.beneficiary.phoneNumber : '',
        ssn: employee.beneficiary ? employee.beneficiary.ssn : '',
        address: {
          address1: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['address1'] : '',
          address2: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['address2'] : '',
          city: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['city'] : '',
          state: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['state'] : '',
          zip: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['zip'] : '',
          id: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['id'] : '',
          version: employee.beneficiary ? employee.beneficiary.address && employee.beneficiary.address['version'] : ''
        },
        version:  employee.beneficiary ?  employee.beneficiary && employee.beneficiary.version: '',
      },
      license : employee.license,
      licenseImage : employee.licenseImage,
      dateOfBirth: employee.dateOfBirth ? moment(employee.dateOfBirth).format('YYYY-MM-DD') : '',
      ssn: employee.ssn,
      accountHolderVersion: employee.version,
      locked: employee.locked
    }}
    onSubmit={(values) => {
      doSubmit(values)
    }}
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <h3 className={'text-lg '}><FormattedMessage id={'user.contact'}/></h3>
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.first.name', '', [])}
            name={'firstName'}
            required
          />
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.last.name', '', [])}
            name={'lastName'}
            required
          />
          <LabeledField 
            required
            label={formatMessage(intl, 'user.dob', '', [])}
            name={'dateOfBirth'}
            component={DateInput} 
            className={'md:mr-10 sm:mr-0 sm:flex-1'}
            disabled={values.locked}
            />
          <div className={'text-md mt-12'}>
            <label>SSN: {employee.ssn}</label>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            label={formatMessage(intl, 'user.email', '', [])}
            name={'email'}
            required
          />
          <MaskedLabeledField
            label={formatMessage(intl, 'user.phone', '', [])}
            name={'phone'}
            required
            mask={phoneNumberMask}
            value={values.phone}
            className={'md:mr-8 sm:mr-0 sm:flex-1'}
            onChange={e => {
                setFieldValue(`phone`, e.target.value);
            }}
          />
          <LabeledField
            required 
            label={formatMessage(intl, 'user.license', '', [])} 
            name={'license'}
            className={'md:mr-4 sm:mr-0 sm:flex-1'}
            disabled={values.locked}
            />
          <div className={'text-md mt-10'}>
            <a data-tip={formatMessage(intl, 'user.license.help', '', [])}  className='text-primary underline' style={{float: 'right', marginTop: '10px'}}><FormattedMessage id={'user.license.hover'}/></a>
            <ReactTooltip place="right" type="dark" effect="float"/>
          </div>
        </div>
        {/* <Field className={'mr-4 mt-4'} name={'licenseUrl'} imageSource={employee.licenseUrl} onSelect={employee.uploadLicense} employee={employee} component={LicenseImageUpload} /> */}
          
        
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
        <h3 className={'text-lg mt-4'}><FormattedMessage id={'address.title'}/></h3>
        <div className={"flex flex-col md:flex-row"}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address1', '', [])}
            name={'address.address1'}
            required
          />
          <LabeledField
          className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address2', '', [])}
            name={'address.address2'}
          />
          <LabeledField
            className={"flex-1"}
            label={formatMessage(intl, 'address.city', '', [])}
            name={'address.city'}
            required
          />
        </div>
        <div className={''}>
          
        </div>
        <div className={"flex flex-col md:flex-row"}>
          
          <LabeledField
            label={formatMessage(intl, 'address.state', '', [])}
            className={"mr-2 md:mr-2"}
            name={'address.state'}
            component={StateInput}
            required
          />
          <LabeledField
            className={"min-w-24"}
            label={formatMessage(intl, 'address.zip', '', [])}
            name={'address.zip'}
            required
          />
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
        <h3 className={'text-lg mt-4'}><FormattedMessage id={'beneficiary.title'}/></h3>
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'user.first.name', '', [])}
            name={'beneficiary.firstName'}
            required
          />
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'user.last.name', '', [])}
            name={'beneficiary.lastName'}
            required
          />
          <MaskedLabeledField required label={'SSN'} name={'beneficiary.ssn'} mask={maskedSsn} placeholder={'___-__-____'} value={values.beneficiary.ssn}
          className={'md:mr-8 sm:mr-0 flex-1'}
            onChange={e => {
              setFieldValue(`beneficiary.ssn`, e.target.value);
            }}/>
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'beneficiary.relationship', '', [])}
            name={'beneficiary.relationship'}
            required
          />
          <MaskedLabeledField
            className={'md:mr-0 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'user.phone', '', [])}
            required
            name={'beneficiary.phoneNumber'}
            mask={phoneNumberMask}
            value={values.beneficiary.phoneNumber}
            onChange={e => {
                setFieldValue(`beneficiary.phoneNumber`, e.target.value);
            }}
          />
        </div>
        <div className={'flex flex-col md:flex-row'}>
          
          
        </div>
        <div className={'flex flex-col md:flex-row'}>
          <LabeledField
            className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address1', '', [])}
            name={'beneficiary.address.address1'}
            required
          />
          <LabeledField
          className={'md:mr-8 sm:mr-0 flex-1'}
            label={formatMessage(intl, 'address.address2', '', [])}
            name={'beneficiary.address.address2'}
          />
          <LabeledField
            className={"flex-1"}
            label={formatMessage(intl, 'address.city', '', [])}
            name={'beneficiary.address.city'}
            required
          />
        </div>
        <div className={''}>
          
        </div>
        <div className={"flex flex-col md:flex-row"}>
          
          <LabeledField
            label={formatMessage(intl, 'address.state', '', [])}
            className={"mr-2 md:mr-2"}
            name={'beneficiary.address.state'}
            component={StateInput}
            required
          />
          <LabeledField
            className={"min-w-24"}
            label={formatMessage(intl, 'address.zip', '', [])}
            name={'beneficiary.address.zip'}
            required
          />
        </div>

        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
      </FormErrors>

    </Form>}
  </Formik>;
};

export default UserAccountForm;
