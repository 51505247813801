import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import OutboundClearingForm from "../../../forms/OutboundClearingForm";
import { apiGet, apiPost } from "../../../utils";
import PrimaryButton from "../../../components/PrimaryButton";
import _ from "lodash";
import dayjs from "dayjs";
import { formatMoney } from "../../../utils";
import { Link } from "@material-ui/core";

const OutboundClearingSheet = observer((props) => {
  const {rootStore} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating || rootStore.impersonatingEmployee;
  const [outboundClearing, setOutboundClearing] = useState({});
  const [clearingTransactions, setClearingTransactions] = useState([]);
  const { outboundClearingId, closeShowForm } = props;
  const {authStore} = useContext(StoreContext);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  // const [load3, setLoad3] = useState(false);

  const loadOutboundClearing = async () => {
    if (outboundClearingId) {
      const response = await apiGet(`/api/v1/clearing/get/${outboundClearingId}/outbound`, authStore);
      const outboundClearing = await response.json();
      setOutboundClearing(outboundClearing);
      loadClearingAccount(outboundClearing.accountHolderId)
      // loadCompany(outboundClearing.companyId);
    }
    setLoad1(true);
  }

  const loadClearingAccount = async () => {
    const response = await apiGet(`/api/v1/clearing/get/clearing/checks`, authStore);
    const transactions = await response.json();

    let filtered = [];
    filtered.push({label: 'Please Choose One', value: null});

    await _.forEach(transactions, (tx) => {
      let desc = tx.data.AcctTrnInfo.Desc.length > 0 ? tx.data.AcctTrnInfo.Desc[0] : '';
      tx.label = `Posted: ${dayjs(tx.postedDt).format('MM-DD-YYYY')}${formatMoney(tx.amount).padStart(15, '.')} ..... Desc: ${desc}..... Check:${tx.data.AcctTrnInfo.ChkNum ? tx.data.AcctTrnInfo.ChkNum : ''}`;
      tx.value = tx.id;
      filtered.push(tx);
    });
    setClearingTransactions(filtered);
    setLoad2(true);
  }

  // const loadCompany = async (companyId) => {
  //   if (companyId) {
  //     const response = await apiGet(`/api/v1/company/get/${companyId}`, authStore);
  //     const company = await response.json();
  //     setCompany(company);      
  //   }
  //   setLoad3(true);

  // }

  const updateOutboundClearing = async (values) => {
    const response = await apiPost(`/api/v1/clearing/check/${values.id}/update`, values, authStore, 'Update Outstanding Checks');
    setOutboundClearing(response);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    loadOutboundClearing();
    // loadClearingAccount();
  }, []);


  if (!(load1 === true && load2 === true)) {
    return <Loading />;
  }

  return <div>
    <Link onClick={closeShowForm} className="text-primary underline block pb-3 cursor-pointer">&lt; Back</Link>
    <PrimaryButton onClick={window.print} className={'float-right'}>Print</PrimaryButton>
    {/* <PrimaryButton onClick={closeShowForm} className={'float-right mr-4'}>Cancel</PrimaryButton> */}
    <h3 className={'text-2xl mt-8'}>Outstanding Checks</h3>
    <p className={"mt-8 text-blue-600"}>Use this screen to track check clearance for all user withdrawal requests by check. This screen is also used to cancel checks.</p>  
    <AlertProvider/>    
    {load1 === true && load2 === true ?
    <div className="mt-10">
    <OutboundClearingForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      outboundClearing={outboundClearing} closeShowForm={closeShowForm} clearingTransactions={clearingTransactions}
      doSubmit={(values) => {
        updateOutboundClearing(values);
      }
      } />
    </div>
     : ''}
  </div>;
});

export default OutboundClearingSheet
