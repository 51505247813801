import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import WithdrawForm from "../../forms/WithdrawForm";
import {apiGet, apiPost, formatMoney} from "../../utils";
import {Link, useRouteMatch} from "react-router-dom";
import Loading from "../../components/Loading";
import { FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../../utils';

const Withdraw = observer(function ({userId, close}) {
  const {rootStore, rootStore: {currentEmployeeRecord}} = useContext(StoreContext);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const title = !close ? 'Withdraw From Savings' : 'Close Your Account';
  const [solidContactId, setSolidContactId] = useState(undefined);
  let {  url } = useRouteMatch();
  const [preAmount, setPreAmount] = useState(0);
  const intl = useIntl();

  useEffect(() => {
    loadBankContactId();
  }, []);

  const loadBankContactId = async () => {
    const response = await apiGet(`/api/v1/bankConnection/accountHolder/${currentEmployeeRecord.id}/all`, rootStore.authStore);
    const resp = await response.json();
    setSolidContactId(resp[0].id);
  }

  const withdraw = async (values) => {
    rootStore.setSubmitting(true);
    const response = await apiPost(`/api/v1/user/withdrawal/${currentEmployeeRecord.id}`, values, rootStore.authStore, formatMessage(intl, 'withdrawal.msg', '', []));
  
    if (response.errorMessage && response.errorMessage !== '') {
      setError(response.errorMessage);
    } else {
      setMessage(`${formatMessage(intl, 'withdrawal.progress', '', [`$${parseFloat(values.amount).toFixed(2)} `])}` );
      rootStore.loadCurrentUser(userId);
    }
    setPreAmount(0);
    rootStore.setSubmitting(false);
  }

  return <div className={'max-w-sm'}>
    {rootStore.submitting ? <Loading/> : ''}
    {solidContactId ? <>
    <h3 className={"text-xl mb-3"}>{title}</h3>
    <span className={"text-lg font-bold"}><FormattedMessage id={'withdrawal.balance'}/> {formatMoney(currentEmployeeRecord.totalAvailable)}</span>
    <p className="text-green-500" >{message}</p>
    <p className="text-red-500" >{error}</p>
    {!close ? 
    <WithdrawForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting} impersonatingEmployee={rootStore.impersonatingEmployee}
                          doSubmit={async (values) => {
                             withdraw(values);
                          }} solidContactId={solidContactId} employee={currentEmployeeRecord} preAmount={preAmount}/>
      : 
      <WithdrawForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting} preAmount={currentEmployeeRecord.totalAvailable}
        close={close} amount={currentEmployeeRecord.totalAvailable} impersonatingEmployee={rootStore.impersonatingEmployee}
        doSubmit={async (values) => {
          withdraw(values);
      }} solidContactId={solidContactId} employee={currentEmployeeRecord} />
    }
    </>
     : 
    <><FormattedMessage id={'withdrawal.nobank'}/>
    <div>
      <Link to={`./settings/bank`} className={"text-primary underline"}><FormattedMessage id={'withdrawal.bank'}/></Link>
    </div>
    </>
    }
  </div>;
});

export default Withdraw;
