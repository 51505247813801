import React, { useState } from "react";
import SettingsMenu from "../../components/SettingsMenu";
import SettingsMenuItem from "../../components/SettingsMenuItem";
import {Route, Switch, useRouteMatch, Redirect} from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Complaint } from "./Complaint";

export default function ComplaintReport({accountHolderId, companyId}) {
    let {path, url} = useRouteMatch();
    const [showForm, setShowForm] = useState(false);

    return <div>
        {showForm === false ?
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/legal`}>Legal/Regulatory</SettingsMenuItem>
            <SettingsMenuItem to={`${url}/product`}>Product/Service</SettingsMenuItem>
        </SettingsMenu>
        : ''}
        <Switch>
            <Route path={`${path}/legal`}>
                <ErrorBoundary key ={"complaintReport"}>
                    <Complaint reportType={'legal'} accountHolderId={accountHolderId} companyId={companyId} showForm={showForm} setShowForm={setShowForm}/>
                </ErrorBoundary>
            </Route>
            <Route path={`${path}/product`}>
                <ErrorBoundary key ={"complaintReport"}>
                    <Complaint reportType={'service'} accountHolderId={accountHolderId} companyId={companyId} showForm={showForm} setShowForm={setShowForm}/>
                </ErrorBoundary>
            </Route>
            <Route path="/complaints">
                <Redirect to={`${path}/legal`}/>
            </Route>
        </Switch>

    </div>;
}
