import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useParams, useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import GoBack from "../GoBack";
import AlertProvider from "../../AlertProvider";
import { apiGet, apiPost } from "../../utils";
import PolicyForm from "../../forms/PolicyForm";

const PolicyDetail = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  const {id} = useParams();
  const history = useHistory();
  const [policy, setPolicy] = useState(undefined);

  const loadPolicy = async () => {
    const response = await apiGet(`/api/v1/policy/${id}/get`, rootStore.authStore);
    const policy = await response.json();
    setPolicy(policy);
  }

  useEffect(() => {
    if (id && id !== 'create-policy') {
      // adminStore.loadPolicy(id);
      loadPolicy(id);
    }
  },[id]);

  // const policy = adminStore.policys.get(id);
  if (id && (!policy || rootStore.submitting) && id !== 'create-policy') {
    return <Loading />;
  }

  const createPolicy = async(values) => {
    const policy = await apiPost(`/api/v1/policy/create`, values, rootStore.authStore, 'Craeted Policy');
    setPolicy(policy);
    history.goBack();
  }

  const updatePolicy = async(values) => {
    const policy = await apiPost(`/api/v1/policy/${values.id}/update`, values, rootStore.authStore, 'Updated Policy');
    if (policy) {
      history.goBack();
    }
  }

  

  return <div>
    <GoBack/>
    <br/>
    <AlertProvider />
    <h3 className={"text-xl"}>{policy ? policy.full_name : 'Create Policy'}</h3>
    <PolicyForm
      submitting={rootStore.submitting}
      errors={rootStore.formErrors.toJSON()}
      policy={policy}
      doSubmit={(values) => values.id ? updatePolicy(values) : createPolicy(values)}
    />
  </div>;
});

export default PolicyDetail;
