import React from "react";

export default function RadioSelect({field, form, className, choices, ...props}) {
  return choices.map(({label, value}) => <div key={value} className={'flex my-2'}>
      <span className={'mr-2 block'}>
        <input id={`input-${field.name}-${value}`}
               className={className}
               type={"radio"}
               {...field} {...props}
               checked={field.value == value}
               value={value} />
      </span>
    <label htmlFor={`input-${field.name}-${value}`} className={'block text-sm'}>{label}</label>
  </div>);
}
