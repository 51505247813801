import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import AdminAccountForm from "../../../forms/AdminAccountForm";
import { apiGet, apiPost, scrollToTop } from "../../../utils";
import { useParams } from "react-router-dom";

const AccountSettings = observer(({loadStep}) => {
  const {rootStore} = useContext(StoreContext);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const change = params.get('change');
  const [address, setAddress] = useState(undefined);
  const [owner, setOwner] = useState(undefined);
  const {id} = useParams();

  const loadAddress = async (addressId) => {
    const response = await apiGet(`/api/v1/address/get/accountHolder/${addressId}`, rootStore.authStore);
    const address = await response.json();
    setAddress(address);
  }

  const loadOwner = async (ownerId) => {
    const response = await apiGet(`/api/v1/accountHolder/get/${ownerId}`, rootStore.authStore);
    const owner = await response.json();
    setOwner(owner);
  }

  useEffect(() => {
    loadAddress(id);
    loadOwner(id);
  }, [id]);
  

  return <div>
    <div className={'md:p-8 p-4'}>
      <AlertProvider/>
      {change && change === 'true' ?
        <p className={'text-red-500 mb-4'}>Please take a moment to update your profile. Also, it is strongly encouraged that you change your email address to a personal one other than your work email. Then use the updated email to login.</p>
        : ''
      }
      {address && owner &&
          <AdminAccountForm
          rootStore={rootStore}
        doSubmit={async (values) => {
          const {address, ...userData} = values;
          scrollToTop();
          if (address.address1 || address.address2 || address.city || address.state || address.zip) {
            let apiCall = address && address.id ? `/api/v1/address/update/${address.id}`
            : `/api/v1/address/new`
            const response = await apiPost(apiCall, address, rootStore.authStore);
            setAddress(response);
          }
          // await rootStore.updateCurrentUser(userData);

          const response = await apiPost(`/api/v1/user/update/${owner.user.id}`, userData, rootStore.authStore, 'Owner Updated');
          loadStep();
        }}
        user={owner.user}
        employee={owner}
        errors={rootStore.formErrors.toJSON()}
        submitting={rootStore.submitting}
        address={address}/>}
    </div>
  </div>;
});

export default AccountSettings;
