import React from "react";
import moment from "moment";
import Loading from "./Loading";
import {formatMoney} from "../utils";
import { FormattedMessage } from "react-intl";

const transform = (feeType) => {

    switch(feeType) {
      case 'CHECK':
          return 'Paper Check Fee';
      case 'CARD':
          return 'Card Fee';
      case 'NSF':
          return 'Insufficient Funds Fee';
      case 'INDIVIDUAL':
          return 'User Paid Monthly Fee';
      case 'CANCELLED_CHECK':
          return 'Cancelled Check Fee';
      default:
          return ''
    } 
}

export default function EmployeeTransactionDivTable({transactions}) {
  return transactions ? <>
  <div className={"flex row"}>
      <div className={`flex row ee-transaction-div-header`}>
        <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left"}><FormattedMessage id={'transaction.table.date'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header`}>
        <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left"}><FormattedMessage id={'transaction.table.deposit'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}><FormattedMessage id={'transaction.table.depositWithdrawal'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}><FormattedMessage id={'transaction.table.match'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header transaction-div-column-right`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left transaction-div-column-right"}><FormattedMessage id={'transaction.table.matchWithdrawal'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header`}>
        <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left"}><FormattedMessage id={'transaction.table.description'}/></div>
      </div>
      <div className={`flex row ee-transaction-div-header`}>
        <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left"}><FormattedMessage id={'transaction.table.status'}/></div>
      </div>
  </div> 
    {transactions.map((transaction, index) => {
      return <div className={`flex row float-left transaction-div-card`} style={{backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
        <div className={`flex row ee-transaction-div-column float-left`}>
          <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"} ><FormattedMessage id={'transaction.table.date'}/></div>
          <div className={"text-left text-sm px-2 py-3-over float-left"}>{moment(transaction.created).utc().format('L')}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left`}>
          <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.deposit'}/></div>
          <div className={"text-left text-sm px-2 py-3-over float-left"}>{transaction.depositAmount > 0 ? formatMoney(transaction.depositAmount) : ''}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.depositWithdrawal'}/></div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.withdrawalAmountD > 0 ? formatMoney(transaction.withdrawalAmountD) : ''}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.match'}/></div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.matchAmount > 0 ? formatMoney(transaction.matchAmount) : ''}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.matchWithdrawal'}/></div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.withdrawalAmountM > 0 ? formatMoney(transaction.withdrawalAmountM) : ''}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left transaction-div-column-left`}>
          <div className={"text-left text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.description'}/></div>
          <div className={"text-left text-sm px-2 py-3-over float-left"}>{transform(transaction.feeType)}</div>
        </div>
        <div className={`flex row ee-transaction-div-column float-left transaction-div-column-right`}>
          <div className={"text-right text-sm text-gray-400 py-3-over px-2 float-left  transaction-div-label"}><FormattedMessage id={'transaction.table.status'}/></div>
          <div className={"text-right text-sm px-2 py-3-over float-left"}>{transaction.status || ''}</div>
        </div>
      </div>
    })}
   
  </>
  : <Loading />;
}
