import React, {useContext} from "react";
import StoreContext from "../../../StoreContext";
// import EmployerMap from "../../../components/EmployerMap";

export default function EmployerSettings() {
  const {rootStore} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;
  const company = employee.company;

  // return <EmployerMap company={company} />;

  return <>
    

    
  </>;
}
