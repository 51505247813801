import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import UnusualActivityInfoForm from "../../../forms/UnusualActivityInfoForm";
import { apiGet, apiPost } from "../../../utils";
import GoBack from "../../GoBack";
import PrimaryButton from "../../../components/PrimaryButton";

const UnusualActivitySheet = observer((props) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating || rootStore.impersonatingEmployee;
  const [unusualActivity, setUnusualActivity] = useState({});
  const { accountHolderId, companyId, unusualActivityId, closeShowForm } = props;
  const {authStore} = useContext(StoreContext);
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);

  const loadUnusualActivity = async () => {
    if (unusualActivityId) {
      const response = await apiGet(`/api/v1/uar/get/${unusualActivityId}`, authStore);
      const unusualActivity = await response.json();
      setUnusualActivity(unusualActivity);
      loadAccount(unusualActivity.accountHolderId)
      loadCompany(unusualActivity.companyId);
    }
    setLoad1(true);
  }

  const loadAccount = async (accountHolderId) => {
    if (accountHolderId) {
      const response = await apiGet(`/api/v1/accountHolder/get/${accountHolderId}`, authStore);
      const accountHolder = await response.json();
      setUser(accountHolder);
      setCompany(accountHolder.company);
    }
    setLoad2(true);
  }

  const loadCompany = async (companyId) => {
    if (companyId) {
      const response = await apiGet(`/api/v1/company/get/${companyId}`, authStore);
      const company = await response.json();
      setCompany(company);      
    }
    setLoad3(true);

  }

  const updateUnusualActivity = async (values) => {
    const response = await apiPost(`/api/v1/uar/save/unusualActivity`, values, authStore, 'Added Unusual Activity');
    setUnusualActivity(response);   
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    loadUnusualActivity();
    loadAccount(accountHolderId);
    loadCompany(companyId);
  }, []);


  if (!(load1 === true && load2 === true && load3 === true)) {
    return <Loading />;
  }

  return <div>
    <PrimaryButton onClick={window.print} className={'float-right'}>Print</PrimaryButton>
    <PrimaryButton onClick={closeShowForm} className={'float-right mr-4'}>Cancel</PrimaryButton>
    <h1 className={'text-2xl mt-8'}>Customer Unusual Activity Tracking Worksheet</h1>
    <AlertProvider/>    
    {load1 === true && load2 === true && load3 === true ?
    <div className="mt-10">
    <UnusualActivityInfoForm adminOpen={adminOpen} isBSA={rootStore.isBSA}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      company={company} unusualActivity={unusualActivity} user={user} closeShowForm={closeShowForm}
      doSubmit={(values) => {
        updateUnusualActivity(values);
      }
      } />
    </div>
     : ''}
  </div>;
});

export default UnusualActivitySheet
