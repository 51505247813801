import React from "react";
import {Link} from "react-router-dom";
import Loading from "./Loading";
import moment from "moment";

export default function EmployeeTable({employees}) {
  if (!employees) {
    return <Loading/>;
  }

  return <div className={"overflow-auto w-screenn table-cuswidth md:w-full"}>
    <table className={'w-full table-auto whitespace-nowrap'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 p-3"}>Name</th>
        <th className={"text-left text-sm text-gray-400 p-3"}>Contact</th>
        {/* <th className={"text-left text-sm text-gray-400 p-3"}>Employee ID</th> */}
        <th className={"text-left text-sm text-gray-400 p-3"}>Hire Date</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {employees.map((employee) => <tr key={employee.id}>
        <td className={`text-left text-sm p-3 ${employee.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{employee.firstName} {employee.lastName}</td>
        <td className={`text-left text-sm p-3 ${employee.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{employee.email}</td>
        {/* <td className={"text-left text-sm p-3"}>{employee.employeeId}</td> */}
        <td className={`text-left text-sm p-3 ${employee.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{employee.hireDate.isValid() ? moment(employee.hireDate).utc().format('L') : ''}</td>
        <td className={`text-left text-sm p-3 ${employee.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>
          <Link to={`employees/${employee.id}`}
                className={"border border-solid border-gray-300 rounded p-2 text-black cursor-pointer"}>Details</Link>
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
