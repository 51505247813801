import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Link } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";
import ComplaintSheet from "../employer/settings/ComplaintSheet";

export const Complaint = (props) => {

    const {rootStore, rootStore: {currentCompany, currentUser}} = useContext(StoreContext);
    const { companyId, accountHolderId, reportType, showForm, setShowForm } = props;
    const {authStore} = useContext(StoreContext);
    const [invoices, setInvoices] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    const [endDate, setEndDate] = useState(endDateDay);
    
    const [complaintId, setComplaintId] = useState();

    useEffect(() => {
        getInvoices();
      }, [reportType]);

    const openShowForm = () => {
        window.scrollTo(0,0);
        setShowForm(true);        
    }

    const closeShowForm = () => {
        window.scrollTo(0,0);
        setSelectedComplaintId(null);
        getInvoices();
        setShowForm(false);
    }

    const setSelectedComplaintId = (id) => {
        setComplaintId(id);
        openShowForm(true);
    }

    const getInvoices = async () => {
        let url = `/api/v1/complaint/all/${dayjs(startDate).format("MMDDYYYY")}/${dayjs(endDate).format("MMDDYYYY")}/${reportType}`;
        if (companyId && !accountHolderId) {
            url = `/api/v1/complaint/by/${companyId}/company/${reportType}`
        }
        if (!companyId && accountHolderId) {
            url = `/api/v1/complaint/by/${accountHolderId}/user/${reportType}`
        }
        const response = await apiGet(url, authStore);
        const resp = await response.json();

        setInvoices(resp);
    }
   
    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };
    
    return (
        <>
        {companyId === undefined && accountHolderId === undefined && showForm === false ?
        <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <PrimaryButton style={{marginTop: "20px", marginRight: "10px"}} onClick={getInvoices} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid>
        : showForm !== true ?
        <Grid item>
            <PrimaryButton style={{marginTop: "20px"}} className={'mb-4'} onClick={openShowForm} variant="contained" >New Complaint</PrimaryButton>
        </Grid> : ''
        }
        {showForm === true ? 
        <ComplaintSheet companyId={companyId} accountHolderId={accountHolderId} closeShowForm={closeShowForm} complaintId={complaintId}/>
        :
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'Case No',
                    field: 'caseNumber',
                    render: (row) => {
                        return (
                        <>
                        <Link onClick={() => {setSelectedComplaintId(row.id)}} className={"cursor-pointer"}>
                            {row.caseNumber}
                        </Link>
                        </>
                        );
                    }
                },
                {
                    title: 'Date of Complaint',
                    field: 'complaintDate', 
                    render: (rowData) => {
                        if (rowData.complaintDate) {
                            return (<>{rowData.complaintDate ? dayjs(rowData.complaintDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'Complainant Last Name',
                    field: 'lastName'
                },
                {
                    title: 'Complainant First Name',
                    field: 'firstName'
                },
                {
                    title: 'Account Number',
                    field: 'ssnOrEin'
                },
                {
                    title: 'Company Name',
                    field: 'companyName'
                },
                {
                    title: 'Complaint Type',
                    field: 'complaintType'
                },
                {
                    title: 'Description',
                    field: 'description', 
                    render: (rowData) => {
                        if (rowData.description) {
                            return (<>{rowData.description.length > 100 ?  rowData.description.substring(0, 100) + '...': rowData.description}</>);
                        } 
                    }
                },
                {
                    title: 'Agency Name',
                    field: 'agencyName'
                },
                {
                    title: 'Resolution Offered',
                    field: 'resolution', 
                    render: (rowData) => {
                        if (rowData.resolution) {
                            return (<>{rowData.resolution.length > 100 ?  rowData.resolution.substring(0, 100) + '...': rowData.resolution}</>);
                        } 
                    }
                },
                {
                    title: 'Resolution Accepted',
                    field: 'resolutionAccepted'
                },
                {
                    title: 'Monetary Resolution/Amount',
                    field: 'monetaryResolutionNeeded', 
                    render: (rowData) => {
                        if (rowData.monetaryResolutionNeeded === true) {
                            return (<>{rowData.monetaryResolutionNeeded ? `Yes $${rowData.amount}` : ''}</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Date of Escalated to CRL in writing',
                    field: 'escalationDate', 
                    render: (rowData) => {
                        if (rowData.escalationDate) {
                            return (<>{rowData.escalationDate ? dayjs(rowData.escalationDate).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                },
                {
                    title: 'List of Documents',
                    field: 'investigation', 
                    render: (rowData) => {
                        if (rowData.investigation) {
                            return (<>{rowData.investigation.length > 100 ?  rowData.investigation.substring(0, 100) + '...': rowData.investigation}</>);
                        } 
                    }
                },
                {
                    title: 'List Involved Employees Interviewed ',
                    field: 'employeesInvolved', 
                    render: (rowData) => {
                        if (rowData.employeesInvolved) {
                            return (<>{rowData.employeesInvolved.length > 100 ?  rowData.employeesInvolved.substring(0, 100) + '...': rowData.employeesInvolved}</>);
                        } 
                    }
                },
                {
                    title: 'Complaint Folder Made',
                    field: 'folderMade', 
                    render: (rowData) => {
                        if (rowData.folderMade === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Additional Efforts',
                    field: 'additionalEfforts', 
                    render: (rowData) => {
                        if (rowData.additionalEfforts) {
                            return (<>{rowData.additionalEfforts.length > 100 ?  rowData.additionalEfforts.substring(0, 100) + '...': rowData.additionalEfforts}</>);
                        } 
                    }
                },
                {
                    title: 'Corrective Action',
                    field: 'correctiveAction', 
                    render: (rowData) => {
                        if (rowData.correctiveAction) {
                            return (<>{rowData.correctiveAction.length > 100 ?  rowData.correctiveAction.substring(0, 100) + '...': rowData.correctiveAction}</>);
                        } 
                    }
                },
                {
                    title: 'Written Response to Customer',
                    field: 'responseToCustomer', 
                    render: (rowData) => {
                        if (rowData.responseToCustomer === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                },
                {
                    title: 'Written Response to Agency',
                    field: 'responseToAgency', 
                    render: (rowData) => {
                        if (rowData.responseToAgency === true) {
                            return (<>Yes</>);
                        } else {
                            return (<>No</>);
                        }
                    }
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "invoices",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={invoices}
        />
        </div>
        }
        </>
        
    );
}