import React, { useEffect, useRef } from 'react';
import Chart from "chart.js";

export default function BarChart({data, height=300, width=950, barPercentage=1.0, IsFixedLength,displayLegend=true, ...props}) {
  const chartRef = useRef(null);

  useEffect(() => {
    new Chart(chartRef.current.getContext("2d"), {
      type: "bar",
      data: data,
      options: {
        animation: {
          duration: 0
        },
        barValueSpacing: 20,
        legend: {
          align: 'start',
          labels: {
            usePointStyle: true
          },
          padding: 15,
          display: displayLegend,
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            barPercentage: barPercentage,
            ticks: {
              maxRotation: 0,
              minRotation: 0
            }
          }],
          yAxes: [{
            ticks: {
              callback: function (label) {
                return label.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              },
              min: 0,
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return null;
            },
            label: function (tooltipItem, data) {
              const index = tooltipItem.index;
              const datasetIndex = tooltipItem.datasetIndex;
              let value = data.datasets[datasetIndex].data[index];
              if (typeof props.formatValue === 'function') {
                value = props.formatValue(value);
              }
              let label = data.labels[index];
              if (!label) {
                label = data.datasets[datasetIndex].label;
              }

              return `${label}: ${value}`;
            }
          }
        }
      },
    });
  }, [data, props]);

  return (
      <canvas ref={chartRef} height={height} width={width} className={IsFixedLength === true? "mt-2 donutchart":"mt-2 linechart"}/>
  );
}
