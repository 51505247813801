
import {flow, types} from 'mobx-state-tree';

const TokenStore = types.model("TokenStore", {
    token: ""
}).actions(self => ({
    setToken:function (token) {
        self.token = token;
    },
    getToken: function (){
        return self.token;
    }

}));
export default TokenStore;