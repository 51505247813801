import React from "react";
import Loading from "./Loading";
import dayjs from "dayjs";
import MaterialTable from "material-table";
import { Link } from "@material-ui/core";

export default function ACHFileTable({logs, toggleStatus}) {
  if (!logs) {
    return <Loading/>;
  }

  const download = async (name, file) => {
    var data = base64ToArrayBuffer(file);
    var blob=new Blob([data], {type: "application/txt"});
    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download=name;
    link.click();
  }

  const base64ToArrayBuffer = (base64) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

  return <div>
    <MaterialTable 
            components={{}}
            columns={[
                {
                    title: '',
                    field: 'id',
                    render: (rowData) => {
                        if (rowData.id) {
                            return (
                            <Link onClick={() => download(rowData.nachaFileName, rowData.nachaFile)} className={"cursor-pointer"}>
                              Download
                            </Link>);
                        } 
                    }
                },
                {
                    title: '',
                    field: 'id',
                    render: (rowData) => {
                        if (rowData.id) {
                            return (
                            <Link onClick={() => toggleStatus(rowData.id, rowData.uploaded)} className={"cursor-pointer"}>
                              <>{rowData.uploaded === true ? 'Mark As Unprocessed' : 'Mark As Uploaded'}</>
                            </Link>);
                        } 
                    }
                },
                {
                    title: 'File Name',
                    field: 'nachaFileName',
                    render: (rowData) => {
                        if (rowData.nachaFileName) {
                            return (<>{rowData.nachaFileName}</>);
                        } 
                    }
                },
                {
                  title: 'Payment Type',
                  field: 'paymentType', 
                  render: (rowData) => {
                      if (rowData.paymentType) {
                          return (<>{rowData.paymentType}</>);
                      } 
                  }
                },
                {
                    title: 'Created',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.created) {
                            return (<>{rowData.created ? dayjs(rowData.created).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "fees",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={logs}
        />
  </div>;
}
