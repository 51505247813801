import React, { useContext, useEffect, useState } from 'react';
import {Formik, Form} from 'formik';
import FieldGroup from "../components/FieldGroup";
import PrimaryButton from "../components/PrimaryButton";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import FormErrors from "../components/FormErrors";
import Select from "../components/Select";
import {phoneNumberMask} from "../formikUtils";
import MaskedLabeledField from "../components/MaskedLabeledField";
import * as Yup from "yup";
import { apiGet } from '../utils';
import StoreContext from '../StoreContext';
import _ from "lodash";

const PolicyForm = ({doSubmit, errors, submitting, policy, archive}) => {

  const {rootStore} = useContext(StoreContext);

  const doArchive = (id) => {
    if (id) {
      archive(id);
    }
  }

  return <Formik
    initialValues={policy ? {
      name: policy.name,
      url: policy.url,
      active: policy.active,
      id: policy.id,
      version: policy.version,
    } : {
      name: '',
      url: '',
      active: true,
      id: '',
      version: 0,
    }}
    onSubmit={(values) => {
      if (!values.password) {
        const {password, ...rest} = values;
        doSubmit(rest);
      } else {
        doSubmit(values);
      }
    }}
  //   validationSchema={
  //     Yup.object().shape({
  //       email: Yup.string()
  //           .required('Email Address is required'),
  //       // phone: Yup.string()
  //       //     .required('Phone Number is required'),
  //       firstName: Yup.string()
  //           .required('First name is required'),
  //       lastName: Yup.string()
  //           .required('Last name is required'),
  //       // dateOfBirth: Yup.string()
  //       //     .required('Date Of Birth is required'),
  //       role: Yup.string()
  //           .required('Role is required'),
  //       // ssn: Yup.string()
  //       //     .required('Ssn is required')

  //   })
  // }
  >{({setFieldValue, values, touched}) =>
    <Form>
      <FormErrors errors={errors}>
        <div className={'mb-4'}>
          <LabeledField
            label={'Name'}
            name={'name'}
          />
        </div>
        <div className={'flex'}>
          <LabeledField
            className={'mr-8 flex-1'}
            label={'URL'}
            name={'url'}
            
          />
        </div>
        
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
        </FieldGroup>
      </FormErrors>
    </Form>
    }
  </Formik>;
};

export default PolicyForm;
