import React from "react";

const Alert = ({children, kind='error'}) => {
  let color = 'bg-primary';
  switch (kind) {
    case 'error':
      color = 'bg-red-500';
      break;
    case 'success':
      color = 'bg-green-500';
      break;
  }
  return <div className={`${color} rounded-sm text-white p-4 mb-2`}>{children}</div>;
};

export default Alert;
