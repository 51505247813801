import React from 'react';
import {Formik, Form, Field} from 'formik';
import LabeledField from "../components/LabeledField";
import FormErrors from "../components/FormErrors";
import Select from "../components/Select";
import FieldGroup from "../components/FieldGroup";
import Loading from "../components/Loading";
import PrimaryButton from "../components/PrimaryButton";
import {observer} from "mobx-react";
import {FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../utils';


const GoalNameSelect = ({...props}) => {
  const intl = useIntl();
  return <LabeledField
    label={''}
    component={Select}
    choices={[
      {label: formatMessage(intl, 'plan.employee.goals.choose', '', []), value: ''},
      {label: formatMessage(intl, 'plan.employee.goals.holiday', '', []), value: 'plan.employee.goals.holiday'},
      {label: formatMessage(intl, 'plan.employee.goals.education', '', []), value: 'plan.employee.goals.education'},
      {label: formatMessage(intl, 'plan.employee.goals.moving', '', []), value: 'plan.employee.goals.moving'},
      {label: formatMessage(intl, 'plan.employee.goals.vacation', '', []), value: 'plan.employee.goals.vacation'},
      {label: formatMessage(intl, 'plan.employee.goals.car', '', []), value: 'plan.employee.goals.car'},
      {label: formatMessage(intl, 'plan.employee.goals.home', '', []), value: 'plan.employee.goals.home'},
      {label: formatMessage(intl, 'plan.employee.goals.computer', '', []), value: 'plan.employee.goals.computer'},
      {label: formatMessage(intl, 'plan.employee.goals.other', '', []), value: 'plan.employee.goals.other'},
    ]} {...props} />;
};

const EmployeeGoalForm = observer(({doSubmit, employee, errors, submitting, loadStep}) => {
  let goals = employee.accountHolderGoals;
  if (!goals.length) {
    goals = [
      {
        name: '',
        term: 'SHORT',
        amount: '',
      },
      {
        name: '',
        term: 'SHORT',
        amount: '',
      },
      {
        name: '',
        term: 'LONG',
        amount: '',
      },
      {
        name: '',
        term: 'LONG',
        amount: '',
      },
    ]
  }

  return <Formik
    initialValues={{goals, emergencySavingsGoal: parseFloat(employee.emergencySavingsGoal || 0).toFixed(2)}}
    onSubmit={(values) => {
      values.goals = values.goals.map(goal => {
        if (!goal.amount) {
          goal.amount = 0;
        }

        return goal;
      });

      doSubmit(values);
      loadStep();
    }}
  >
    <Form>
      <FormErrors errors={errors}>
        <div className={'flex flex-col sm:flex-row sm:items-center'}>
          <span className={'text-sm block mr-4'}><FormattedMessage id={'plan.employee.goals.title'}/></span>
          <LabeledField
            label={''}
            name={'emergencySavingsGoal'}
            required
          />
        </div>
        <p><FormattedMessage id={'plan.employee.goals.instruction1'}/></p>
        <div className={'flex items-center'}>
          <GoalNameSelect name={'goals.0.name'} />
          <span className={'mx-4'}><FormattedMessage id={'plan.employee.goals.goal'}/></span>
          <LabeledField label={''} name={'goals.0.amount'} required />
          <Field type={'hidden'} name={'goals.0.term'} />
        </div>
        <div className={'flex items-center'}>
          <GoalNameSelect name={'goals.1.name'} />
          <span className={'mx-4'}><FormattedMessage id={'plan.employee.goals.goal'}/></span>
          <LabeledField label={''} name={'goals.1.amount'} required />
          <Field type={'hidden'} name={'goals.1.term'} />
        </div>
        <p><FormattedMessage id={'plan.employee.goals.instruction2'}/></p>
        <div className={'flex items-center'}>
          <GoalNameSelect name={'goals.2.name'} />
          <span className={'mx-4'}><FormattedMessage id={'plan.employee.goals.goal'}/></span>
          <LabeledField label={''} name={'goals.2.amount'} required />
          <Field type={'hidden'} name={'goals.2.term'} />
        </div>
        <div className={'flex items-center'}>
          <GoalNameSelect name={'goals.3.name'} />
          <span className={'mx-4'}><FormattedMessage id={'plan.employee.goals.goal'}/></span>
          <LabeledField label={''} name={'goals.3.amount'} required />
          <Field type={'hidden'} name={'goals.3.term'} />
        </div>
        <FieldGroup>
          {submitting ? <Loading size={30}/> : <PrimaryButton><FormattedMessage id={'save.button'}/></PrimaryButton>}
        </FieldGroup>
      </FormErrors>
    </Form>
  </Formik>;
});

export default EmployeeGoalForm;
