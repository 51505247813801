import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useParams} from "react-router-dom";
import Loading from "../../components/Loading";
import Input from "../../components/Input";
import PrimaryButton from "../../components/PrimaryButton";
import CheckboxInput from "../../components/CheckboxInput";
import EmployeeDetailsForm from "../../forms/EmployeeDetailsForm";
import AlertProvider from "../../AlertProvider";

const EmployeeDetails = observer(function () {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const {id} = useParams();
  const currentEmployee = currentCompany.currentEmployee;

  useEffect(() => {
    currentCompany.setCurrentEmployeeById(id);
  }, [id]);

  if (!currentEmployee) {
    return <Loading/>;
  }

  return <div>
    <h3 className={"text-xl mb-3"}>Update {currentEmployee.name}</h3>
    <AlertProvider/>
    <EmployeeDetailsForm errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
                         employee={currentEmployee} doSubmit={currentCompany.companyUpdate} />
  </div>;
});

export default EmployeeDetails;
