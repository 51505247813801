import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import { apiGet, feeTypeToName, formatMoney } from "../../utils";
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import PrimaryButton from "../../components/PrimaryButton";

export const Fees = (props) => {

    const {authStore} = useContext(StoreContext);
    const [fees, setFees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('M'));
    const endDateDay = dayjs().add(1, 'M').startOf('M').subtract(1, 'd');
    const [endDate, setEndDate] = useState(endDateDay);

    useEffect(() => {
        getFees();
      }, []);

    const getFees = async () => {
        const response = await apiGet(`/api/v1/fees/all/${dayjs(startDate).format("MMDDYYYY")}/${dayjs(endDate).format("MMDDYYYY")}`, authStore);
        const resp = await response.json();

        setFees(resp);
    }
    
    const handleStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };
    
    return (
        <>
        <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Start"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <PrimaryButton style={{marginTop: "20px"}} onClick={getFees} variant="contained" >Search</PrimaryButton>
                </Grid>
            </Grid>
        <div className="container max-w-6xl mx-auto px-4">
        <MaterialTable 
            components={{}}
            columns={[
                {
                    title: 'Amount',
                    field: 'amount',
                    render: (rowData) => {
                        if (rowData.amount) {
                            return (<>{formatMoney(rowData.amount)}</>);
                        } 
                    }
                },
                {
                    title: 'Fee Type',
                    field: 'feeType',
                    render: (rowData) => {
                        if (rowData.feeType) {
                            return (<>{feeTypeToName(rowData.feeType)}</>);
                        } 
                    }
                },
                {
                    title: 'Created',
                    field: 'created', 
                    render: (rowData) => {
                        if (rowData.created) {
                            return (<>{rowData.created ? dayjs(rowData.created).format('MM/DD/YYYY') : ''}</>);
                        } 
                    }
                }
            ]}
            options={{
                exportButton: true,
                search: true,
                showTitle: false,
                exportFileName: "fees",
                rowStyle: {
                    verticalAlign: "baseline"
                },
                pageSize: 10,
                exportAllData: true,
                columnsButton: true,
            }}
            data={fees}
        />
        </div>
        </>
    );
}