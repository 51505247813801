import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Formik, Form} from "formik";
import StoreContext from "../../StoreContext";
import Header from "../../components/Header/Header";
import {useHistory, Redirect, Link, useRouteMatch} from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import EmployerMap from "../../components/EmployerMap";
import LabeledField from "../../components/LabeledField";
import DateInput from "../../components/DateInput";
import MaskedLabeledField from "../../components/MaskedLabeledField";
import moment from "moment";
import * as Yup from "yup";


const maskedSsn = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const ValidateEmployee =  observer(function () {
    const {rootStore} = useContext(StoreContext);
    const errorMessage = rootStore.errorMessage;
    const employee = rootStore.currentEmployeeRecord;
    const history = useHistory();


    const stopImpersonatingEmployee = () => {
        rootStore.stopImpersonatingEmployee();
        history.goBack();
        setTimeout(() => {
            window.location.reload();
        },300)
    };

    if (employee && employee.verified) {
        // history.push('/approuter/' + rootStore.currentUser.id + '/dashboard');
        history.push('/approuter/' + rootStore.currentUser.id + '/dashboard/settings/account?change=true');
    }

    if (!employee) {
        return <div className={"container mx-auto p-4"}>
            <Header title={""}/>
            <div className={"max-w-2xl mx-auto p-2"}>
                <h2 className={"text-center text-xl my-4"}>Welcome!</h2>
                <p className={'p-2'}>You are not yet enrolled in MatchSavings. Please contact
                    your employer and have them activate your account.</p>
            </div>
        </div>;
    }

    return <Formik
        initialValues={{
            'dateOfBirth': '',
            'ssn': '',
        }}

        onSubmit={(values) => {
            if (values.enrolled === 'true' || values.enrolled === true) {
                values.enrolled = true;
            } else {
                values.enrolled = false;
            }

        }}
        validationSchema={Yup.object().shape({
            dateOfBirth: Yup.string()
                .required('Date of Birth is required'),
            ssn: Yup.string()
                .required("SSN is required"),
        })
        }//end validation schema
    >{({setFieldValue, values}) =>
        <Form>
            <div className={"container mx-auto p-4"}>
                {rootStore.impersonatingEmployee && <div className={'bg-primary p-4'}>
                <Link className={'text-white font-bold'} to="" href={'#'} onClick={() => stopImpersonatingEmployee()}>Return to
                    Admin</Link>
                </div>}
                <Header title={""}/>
                <div className={"max-w-2xl mx-auto p-2"}>
                    <h2 className={"text-center text-xl my-4"}>Verify your Identity</h2>
                    <p className={"text-center"}>{employee.company ? employee.company.name : ''}</p>
                    {/* <EmployerMap company={employee.company ? employee.company : ''}/> */}
                    <div>
                        <LabeledField required label={'DOB'} name={'dateOfBirth'} component={DateInput}/>
                    </div>
                    <div>
                        <MaskedLabeledField label={'SSN'} name={'ssn'} mask={maskedSsn} placeholder={'___-__-____'}
                                            value={values.ssn}
                                            required
                                            onChange={e => {
                                                setFieldValue(`ssn`, e.target.value);
                                            }}/>
                    </div>
                    {errorMessage && <p className="text-red-500" >{errorMessage}</p>}
                    <PrimaryButton className={'mt-8'} onClick={() => {
                        employee.verify({verified: true, enrolled: true, values,});
                    }}>Verify</PrimaryButton>
                </div>
            </div>
            ;
        </Form>}
    </Formik>
});

export default ValidateEmployee;
