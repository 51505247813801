import React, { useState } from 'react';
import {FormattedMessage} from 'react-intl';

const ImageUpload = ({field, form, ...props}) => {
  const [imageSource, setImageSource] = useState(props.imageSource);
  const [company, setCompany] = useState(props.company);

  async function handleSelect(event) {
    const response = await props.onSelect(event.target.files[0]);

    setImageSource(response);
    setCompany(company);
  }

  return (
    <div>
      {imageSource && <img src={imageSource} className="block mb-3 w-50" alt={'companyLogo'}/>}
      {/* <img src={`data:image/png;base64,'.base64_encode($blob).'`}/> */}
      <label className="bg-primary px-6 py-3 mr-4 text-center text-white uppercase">
        <FormattedMessage id='image.upload.button'/><input type={'file'}
                      style={{display: 'none'}}
                      {...field}
                      onChange={event => handleSelect(event)}/>
      </label>
      {form.errors?.logoUrl &&
      <div className={'text-red-500 text-sm mt-4'}>{form.errors.logoUrl}</div>
      }
      {/* <img src={`/api/v1/company/logo/${company.id}`} alt={'Company Logo'} style={{float: "right", marginTop: '-40px'}}/> */}
      {company.logo ?
        <img src={`data:${company.logoType};base64,${company.logo}`} alt={'Company Logo'} style={{float: "right", marginTop: '-40px'}}/> : '' }
    </div>
  );
};

export default ImageUpload;
