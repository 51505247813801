import {flow, getRoot, types} from "mobx-state-tree"
import User from "./User";
import Company from "./Company";
import Employee from "./Employee";
import {apiGet, apiPost, normalizeArray} from "../utils";
import AdminStore from "./AdminStore";
import AuthStore from "./AuthStore";

const RootStore = types.model("RootStore", {
  loading: false,
  submitting: false,
  message: types.maybe(types.string),
  errorMessage: types.maybe (types.string),
  formErrors: types.map(types.string, {}),
  currentUser: types.maybe(User),
  currentCompany: types.safeReference(Company),
  currentEmployeeRecord: types.safeReference(Employee),
  adminStore: AdminStore,
  authStore: AuthStore,
  impersonatingEmployee: false,
  IsFromAdmin : false,
  adminId: types.maybe(types.string),
  bsaId: types.maybe(types.string),
  csaId: types.maybe(types.string),
  isBSA: false,
  isAdmin: false,
  csaTieId: types.maybe(types.string),
  companyId: types.maybe(types.string),
  acksShown: false,
}).actions(self => ({
  setMessage: flow(function* (message) {
    self.message = message;
  }),
  setErrorMessage: flow( function* (message){
    self.errorMessage = message;
  }),
  setSubmitting: flow(function* (submitting) {
    self.submitting = submitting;
  }),
  setLoading: flow(function* (loading) {
    self.loading = loading;
  }),
  setFormErrors: flow(function* (errors) {
    self.formErrors = errors;
  }),
  setIsFromAdminTrue : flow(function* (){
    self.IsFromAdmin =true;
  }),
  setIsBSATrue : flow(function* (){
    self.isBSA =true;
  }),
  setShowedAcks: flow(function* (){
    self.acksShown =true;
  }),
  setCurrentUser: function (data) {
    if (data.role === 'ADMIN') {
      self.loading = true;
      self.currentUser = User.create(data);
      self.currentUser = data;
      self.adminId =  data.id;
      self.isBSA = true;
      self.isAdmin = true;
      self.companyId = data.myCompany.id;
    } else if (data.role === 'BSA') {
      self.loading = true;
      self.currentUser = User.create(data);
      self.currentUser = data;
      self.bsaId =  data.id;
      self.isBSA = true;
      self.isAdmin = false;
    } else if (data.role === 'CSA') {
      self.loading = true;
      self.currentUser = User.create(data);
      self.currentUser = data;
      self.csaId =  data.id;
      self.isBSA = false;
      self.isAdmin = false;
      self.csaTieId = data.csaTieId;
    } else {
    // if (data.role === 'EMPLOYER') {
      data.companies = [];
      if (data.employeeRecords && data.employeeRecords.length > 0) {
        self.loading = true;
        self.currentUser = User.create(data);
        self.currentUser = data;
        
        if (self.currentUser.employeeRecords[0].company) {
          if (self.IsFromAdmin) {
            self.adminStore.setCompanies(undefined);
          } else {
            self.currentCompany = self.currentUser.employeeRecords[0].company;
          }
          
        }
      } else if (data.myCompany) {
        data.companies.push(data.myCompany);

        self.loading = true;
        self.currentUser = User.create(data);
        self.currentUser = data;

        
        if (self.currentUser.companies
          && self.currentUser.companies.length) {
          self.currentCompany = self.currentUser.companies[0];
        }
      } else { // company
        self.loading = true;
        self.currentUser = User.create(data);
        self.currentUser = data;
      }
    // }
    

      if (self.currentUser.employeeRecords && self.currentUser.employeeRecords.length) {
        self.loading = true;
        // self.currentEmployeeRecord = self.currentUser.employeeRecords[0];
        // if(data.role === 'EMPLOYEE'){
          self.currentEmployeeRecord = self.currentUser.employeeRecords[0];
          if (self.IsFromAdmin)
          {
              self.impersonatingEmployee = true;
          }
        // }
        // self.currentCompany = self.currentUser.employeeRecords[0].company;

        self.loading = false;

      }
    }

  },
  stopImpersonatingEmployee: flow(function* () {
    self.acksShown =false;
    self.impersonatingEmployee = false;
    self.setCurrentUser(yield self.loadCurrentUser(self.adminId || self.bsaId || self.csaId));
  }),
  loadCurrentUser: flow(function* (id) {
    self.loading = true;
    if (id ) {
      const response = yield apiGet(`/api/v1/user/get/${id}`, self.authStore)
      const body = yield response.json();
      self.setCurrentUser(body);
      self.loading = false;
      return self.currentUser;
    } else {
      self.setCurrentUser(undefined);
      self.loading = false;
      return self.currentUser;
    }


  }),
  updateCurrentUser: flow(function* (params) {

    self.submitting = true;
    params.id = self.currentUser.id;
    const response = yield apiPost(`/api/v1/user/update/${self.currentUser.id}`, params, self.authStore, 'User Updated');

    if (response.errors) {
      self.formErrors = response.errors;
    } else {
      self.setCurrentUser(response);
    }
    self.submitting = false;
  }),
  setCompany: flow(function* (company) {
     self.currentCompany = company;
  }),
}));

export default RootStore;
