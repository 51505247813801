import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import EmployeeTransactionTable from "../../components/EmployeeTransactionTable";
import Pagination from "../../components/Pagination";
import OutlineBox from "../../components/OutlineBox";
import {formatMoney} from "../../utils";
import {Link} from "react-router-dom";
import Alert from "../../components/Alert";
import DateInput from "../../components/DateInput";
import papa from "papaparse";
import { apiGet } from "../../utils";
import moment from "moment";
import Loading from "../../components/Loading";
import PrimaryButton from "../../components/PrimaryButton";
import { FormattedMessage, useIntl } from 'react-intl';
import {formatMessage} from '../../utils';
import EmployeeTransactionDivTable from "../../components/EmployeeTransactionDivTable";

const EmployeeTransactions = observer(function () {
  const {rootStore, rootStore: {currentEmployeeRecord}} = useContext(StoreContext);
  const employee = currentEmployeeRecord;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  if (rootStore.currentUser.role === 'EMPLOYEE') {
    if (!employee.company || employee.company.companyPlans.length === 0) {
      return <div><FormattedMessage id={'transactions.saving.incomplete'}/></div>
    }
  }

  const plan = employee.company ? employee.company.companyPlans[0] : undefined;
  const baseMatchPercent = plan ? parseFloat(plan.matchPercent || 0).toFixed(2) : 0;
  const bonusMatchPercent = plan ? parseFloat(plan.bonusMatchPercent || 0).toFixed(2) : 0;

  useEffect(() => {
    employee.loadTransactions({page, startDate, endDate, searchQuery});
  }, [page, startDate, endDate, searchQuery]);

  const totalSavings = employee.totalSavings;

  const download = async () => {
    setLoading(true);
    const result = await apiGet(`/api/v1/transaction/account/${employee.accountId}?pageNumber=1&pageSize=10000&startDate=${startDate ? startDate: ''}&endDate=${endDate ? endDate : ''}`, rootStore.authStore);

    let csv = await result.json();

    csv.content.forEach(item => {
      item.date = item.created;
      item.deposit = item.depositAmount;
      item.deposit_withdrawal = item.withdrawalAmountD;
      item.employer_match = item.matchAmount;
      item.match_withdrawal = item.withdrawalAmountM;
      item.company = item.name;

      delete item.matchAmount;
      delete item.depositAmount;
      delete item.withdrawalAmountD;
      delete item.withdrawalAmountM;
      delete item.name;
      delete item.created;
      delete item.name;
      delete item.userName;
      delete item.companyId;
      delete item.accountId;
    });

    const a = document.createElement("a");
    a.target = '_blank'
    a.href = URL.createObjectURL(new Blob([papa.unparse(csv.content)], { type: "text/csv" }));
    let dates = [moment().format("YYYY-MM-DD")];
    
    a.setAttribute("download", formatMessage(intl, 'transactions.title.csv', '', dates));
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setLoading(false);
  }
  return <div>
    {rootStore.message && <Alert kind={'success'}>{rootStore.message}</Alert>}
    <h3 className={"text-2xl mb-8"}><FormattedMessage id={'transactions.title'}/></h3>
    <div className={'flex flex-col lg:flex-row'}>
      <OutlineBox className={"lg:mr-8 flex-1 p-4 mb-8"}>
        <div className={'flex'}>
          <h3 className={'flex-1 text-lg'}><FormattedMessage id={'transactions.account.balance'}/></h3>
          <div>
            <Link className={'uppercase text-sm text-primary'} to={'withdraw'}><FormattedMessage id={'transactions.withdrawal'}/></Link>
          </div>
        </div>
        <span className={"text-primary text-4xl font-thin"}>{formatMoney(totalSavings)}</span>
      </OutlineBox>
      <OutlineBox className={"flex-1 p-4 mb-8"}>
        <h3 className={'flex-1 text-lg'}><FormattedMessage id={'transactions.saving.plan.settings'}/></h3>
        <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'transactions.saving.regular'}/></p>
          <p className={'text-lg'}>{parseFloat(employee.matchPercent).toFixed(2)}%</p>
        </div>
        {/* <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'transactions.saving.bonus'}/></p>
          <p className={'text-lg'}>{parseFloat(employee.bonusMatchPercent).toFixed(2)}%</p>
        </div> */}
        <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'transactions.saving.employer.regular'}/></p>
          <p className={'text-lg'}>{baseMatchPercent}%</p>
        </div>
        {/* <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'transactions.saving.employer.bonus'}/></p>
          <p className={'text-lg'}>{bonusMatchPercent}%</p>
        </div> */}
      </OutlineBox>
    </div>
    <div className={'flex flex-col md:flex-row'}>
      {/* <div className={'flex-1'}>
        <Input
          onChange={(e) => {
            setSearchQuery(e.target.value) //, setPage(1)
          }}
          value={searchQuery}
          placeholder={'Search transactions'}
        />
      </div> */}
      <div className={'mb-3'}>
        From
        <DateInput
          onChange={(e) => {
            setStartDate(e.target.value)//, setPage(1)
          }}
          placeholder={"From"}
        />
      </div>
      <div className={'md:mx-4 mb-3'}>
        To
        <DateInput
          onChange={(e) => {
            setEndDate(e.target.value)//, setPage(1)
          }}
          placeholder={"To"}
        />
      </div>
      <div className={"mt-3 md:mt-6 md:ml-6 mb-3"}>
    {loading ? <Loading/> : 
      <PrimaryButton onClick={download} className={'bg-primary px-6 py-3 mb-3 text-center text-white uppercase'}>
        <FormattedMessage id={'download.button'}/>
      </PrimaryButton>
    }
    </div>
    </div>
    {/* {employee.transactionPages && <EmployeeTransactionTable transactions={employee.transactionPages.get(page)}/>} */}
    {employee.transactionPages && <EmployeeTransactionDivTable transactions={employee.transactionPages.get(page)}/>}
    <Pagination
      page={page}
      totalPages={employee.transactionPageCount}
      setPage={setPage}
    />
    
  </div>;
});

export default EmployeeTransactions;
