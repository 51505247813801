import React, { useContext, useEffect, useState } from "react";
import { useFinchConnect } from '@tryfinch/react-connect';
import { apiGet } from "../utils";
import StoreContext from "../StoreContext";

const FinchLink = ({clientId, sandbox}) => {
  const {rootStore} = useContext(StoreContext);
  const [show, setShow] = useState(false);

  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log("User exited Finch Connect");
  

  const onSuccess = async ({ code }) => {
    const reponse = await apiGet(`/api/v1/finch/company/get/${code}/${rootStore.currentCompany.id}`, rootStore.authStore);

    const resp = await reponse.json();
    onClose();
    setShow(resp.error);
  }

  const { open } = useFinchConnect(clientId ? {
    clientId: clientId,
    // payrollProvider: '<payroll-provider-id>',
    products: ['company', 'directory','individual', 'ssn', 'employment', 'payment', 'pay_statement', 'benefits'],
    onSuccess,
    onError,
    onClose,
    sandbox: sandbox === true ? 'finch' : '',
  } : undefined);

  

  const hasAuthorization = async () => {
    const reponse = await apiGet(`/api/v1/finch/company/get/${rootStore.currentCompany.id}/byCompany`, rootStore.authStore);

    const resp = await reponse.json();

    setShow(resp.found === true ? false : true);
  }

  useEffect(() => {
    // getClientId();
    hasAuthorization();
  }, []);

  return <div className={"mt-10"}>
    {show === true ? 
      <button type="button" onClick={() => open()} disabled={!clientId} className={"bg-primary px-6 py-3 text-center text-white uppercase "}>
        Open Finch Connect
      </button>
      : 'You have successfully connected your Payroll system to MatchSavings!'
    }
  </div>
};


export default FinchLink;
