import React, { useContext, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import PrimaryButton from './PrimaryButton';
import { FormattedMessage } from 'react-intl';
import StoreContext from '../StoreContext';
import { apiGet } from '../utils';
import EmployeePolicyTable from './EmployeePolicyTable';

export default function AcknowledgmentDialog({dialogTitle, id}) {
  const [isOpen, setIsOpen] = useState(true);
  const {rootStore, authStore, currentUser} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;
  const [policies, setPolicies] = useState([]);
  const [skip, setSkip] = useState(false);

  const getNewAcks = async () => {
    const response = await apiGet(`/api/v1/acks/${employee ? employee.id : id}/newAcks`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  }

  const acceptNewAcks = async () => {
    const response = await apiGet(`/api/v1/acks/${employee.id}/accept`, rootStore.authStore);
    const policies = await response.json();
    setPolicies(policies);
  }

  const skipStatus = async () => {
    const response = await apiGet(`/api/v1/acks/${employee ? employee.id : id}/skipStatus`, rootStore.authStore);
    const acct = await response.json();
    setSkip(acct.newAcksSkippedCounter < 4);
  }

  useEffect(() => {
    if (employee || id) {
      getNewAcks();
      skipStatus();
    }

  }, [currentUser, id])

  function closeModal() {
    acceptNewAcks();
    setIsOpen(false);
  }

  function cancel() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (<>
  {/* <PrimaryButton onClick={openModal} className={'mr-4'}><FormattedMessage id={'card.cancel'}/></PrimaryButton> */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <FormattedMessage id={'acks.title'}/>
                    <br/><br/>
                  </Dialog.Title>
                  <p className="text-sm mb-2"><FormattedMessage id={'login.signup.par1'}/></p>

                  <p className="text-sm mb-5">
                      <FormattedMessage id={'login.signup.updates'}/>
                  </p>
                  
                  <EmployeePolicyTable policies={policies}/>

                  <div className="mt-4">
                    <PrimaryButton type={'button'} className={'pr-6 mr-4'}
                        onClick={closeModal}><FormattedMessage id={'acks.accept'}/></PrimaryButton>
                    {skip ?
                    <PrimaryButton type={'button'} className={'pr-6'}
                        onClick={cancel}><FormattedMessage id={'acks.cancel'}/></PrimaryButton>
                        : ''}
                  </div>
                  {skip ?
                  <p className="text-sm mt-5 text-red-500">
                      <FormattedMessage id={'remind.me.grace'}/>
                  </p> :
                  <p className="text-sm mt-5 text-red-500">
                  < FormattedMessage id={'remind.me.required'}/>
                  </p> }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
