import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../StoreContext";
import Loading from "../../../components/Loading";
import {observer} from "mobx-react";
import AlertProvider from "../../../AlertProvider";
import { apiGet, apiPost } from "../../../utils";
import PrimaryButton from "../../../components/PrimaryButton";
import DataRequestInfoForm from "../../../forms/DataRequestInfoForm";

const DataRequestSheet = observer((props) => {
  const {rootStore, rootStore: {currentCompany}} = useContext(StoreContext);
  const adminOpen = rootStore.adminStore.impersonating || rootStore.impersonatingEmployee;
  const [dataRequest, setDataRequest] = useState({});
  const { accountHolderId, companyId, dataRequestId, closeShowForm } = props;
  const {authStore} = useContext(StoreContext);
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);

  const loadDataRequest = async () => {
    if (dataRequestId) {
      const response = await apiGet(`/api/v1/dataRequest/get/${dataRequestId}`, authStore);
      const dataRequest = await response.json();
      setDataRequest(dataRequest);
      loadAccount(dataRequest.accountHolderId)
      loadCompany(dataRequest.companyId);
    }
    setLoad1(true);
  }

  const loadAccount = async (accountHolderId) => {
    if (accountHolderId) {
      const response = await apiGet(`/api/v1/accountHolder/get/${accountHolderId}`, authStore);
      const accountHolder = await response.json();
      setUser(accountHolder);
      setCompany(accountHolder.company);
    }
    setLoad2(true);
  }

  const loadCompany = async (companyId) => {
    if (companyId) {
      const response = await apiGet(`/api/v1/company/get/${companyId}`, authStore);
      const company = await response.json();
      setCompany(company);      
    }
    setLoad3(true);

  }

  const updateDataRequest = async (values) => {
    const response = await apiPost(`/api/v1/dataRequest/save/dataRequest`, values, authStore, 'Added Data Request');
    setDataRequest(response);   
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    loadDataRequest();
    loadAccount(accountHolderId);
    loadCompany(companyId);
  }, []);


  if (!(load1 === true && load2 === true && load3 === true)) {
    return <Loading />;
  }

  return <div>
    <PrimaryButton onClick={window.print} className={'float-right'}>Print</PrimaryButton>
    <PrimaryButton onClick={closeShowForm} className={'float-right mr-4'}>Cancel</PrimaryButton>
    <h3 className={'text-2xl mt-8'}>Customer Data Request Tracking Worksheet</h3>
    <AlertProvider/>    
    {load1 === true && load2 === true && load3 === true ?
    <div className="mt-10">
    <DataRequestInfoForm adminOpen={adminOpen}
      errors={rootStore.formErrors.toJSON()} submitting={rootStore.submitting}
      company={company} dataRequest={dataRequest} user={user} closeShowForm={closeShowForm}
      doSubmit={(values) => {
        updateDataRequest(values);
      }
      } />
    </div>
     : ''}
  </div>;
});

export default DataRequestSheet
