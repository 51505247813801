import {flow, getRoot, types} from "mobx-state-tree"
import User from "./User";
import Company from "./Company";
import {apiGet, apiPost} from "../utils";
import { type } from "@testing-library/user-event/dist/type";

const AdminStore = types.model({
  users: types.map(User),
  userSearch: '',
  userPages: types.map(types.array(types.string)),
  userPageCount: 0,
  companies: types.map(Company),
  companyPages: types.map(types.array(types.string)),
  companyPageCount: 0,
  impersonating: false,
  companyId: types.maybeNull(types.string),

}).views(self => ({
  userPage(page) {
    const ids = self.userPages.get(page) || [];
    return ids.map(id => self.users.get(id));
  },
  companyPage(page) {
    const ids = self.companyPages.get(page) || [];
    return ids.map(id => self.companies.get(id));
  }
})).actions(self => ({
  startImpersonating: flow(function* (company) {
    yield getRoot(self).setCompany(company);
    self.impersonating = true;
  }),
  startImpersonatingUser: flow(function* (user) {
    getRoot(self).setIsFromAdminTrue();
    getRoot(self).loadCurrentUser(user.userId);
    if (self.isBSA === true) {
      getRoot(self).setIsBSATrue();
    }
  }),
  stopImpersonating: flow(function* () {
    yield getRoot(self).setCompany(undefined);
    self.impersonating = false;
  }),
  setCompanies: flow(function* (companies) {
    yield self.companies = companies;
  }),
  setUserSearch: flow(function* (query) {
    self.userSearch = query;
    self.userPages.clear();
  }),
  loadUser: flow(function* (id) {
    const response = yield apiGet(`/api/v1/admin/user/get/${id}`, getRoot(self).authStore);
    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error retrieving User Information");
    }
    const json = yield response.json();
    if (json.employeeRecords && json.employeeRecords.length) {
      delete json.employeeRecords[0].company;
    }
    self.users.put(json);
  }),
  loadUsers: flow(function* (page) {
    const response = yield apiGet(`/api/v1/admin/user/all`, getRoot(self).authStore);

    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error Retrieving Users");
    }
    const json = yield response.json();
    self.userPages.set(page, json.map(user => {
      self.users.put(user);
      return user.id
    }));
  }),
  loadCompanies: flow(function* (page) {
    const response = yield apiGet(`/api/v1/company/all`, getRoot(self).authStore);
    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error Retrieving Companies");
    }
    const json = yield response.json();
    self.companyPages.set(page, json.map(company => {
      self.companies.put(company);
      return company.id
    }));
  }),
  updateUser: flow(function* (id, data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/admin/user/update/ah/${id}`, data, getRoot(self).authStore, 'User Updated');

    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error updating user");
    }
    else if (response.errors) {//field specific form errors
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.users.put(response)
    }
    yield getRoot(self).setSubmitting(false);
    return response;
  }),
  updateUserById: flow(function* (id, data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/admin/user/update/${id}`, data, getRoot(self).authStore, 'User Updated');

    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error updating user");
    }
    else if (response.errors) {//field specific form errors
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.users.put(response)
    }
    yield getRoot(self).setSubmitting(false);
    return response;
  }),
  createUser: flow(function* (data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost('/api/v1/admin/user/new', data, getRoot(self).authStore, 'User Added');
    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error Creating User");
    }
    else if (response.errors) {//form field errors
      yield getRoot(self).setFormErrors(response.errors);
    } else {
      self.users.put(response)
    }
    yield getRoot(self).setSubmitting(false);
    return response;
  }),
  userWithdrawal: flow(function *(id, data) {
    yield getRoot(self).setSubmitting(true);
    const response = yield apiPost(`/api/v1/user/withdrawal/${id}`, data, getRoot(self).authStore, 'User Withdrawal');
    response.error = true;
    if (response.error){//general error/500
      yield getRoot(self).setErrorMessage("Error User Withdrawal");
    }
    else if (response.errors) {//form field errors
      yield getRoot(self).setFormErrors(response.errors);
    } else {
    }
    yield getRoot(self).setSubmitting(false);
    return response;
  })
}));

export default AdminStore;
