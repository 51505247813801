import { observable, action } from 'mobx';

import {flow, getRoot, types} from "mobx-state-tree"
import {apiPost} from "../utils";
import TokenStore from "./TokenStore";


const AuthStore = types.model("AuthStore", {

    inProgress: false,
    error: '',
    email: '',
    password: '',
    userId: '',
    tokenStore: TokenStore,
    message: '',
    role: '',
    companyId: ''


}).actions(self => ({
    setCompanyId: function(companyId) {
        self.companyId = companyId;
    },
    setRole: function(role) {
        self.role = role;
    },
    setError: function(error) {
        self.error = error;
    },
    setMessage: function(message) {
        self.message = message;
    },
    setEmail: function (email) {
        self.email = email;
    },
    setAuthError: function (authError){
        self.error = authError;
    },
    getAuthError: function (){
        return self.error;
    },
    hasAuthError: function (){
       return self.error!==undefined;
    },
    setPassword: function (password) {
        self.password = password;
    },
    setInProgress:  function (inProgress) {
        self.inProgress = inProgress;
    },
    getUserId: function (){
        return self.userId;
    },
    getEmail: function (){
        return self.email;
    },
    getToken: function (){
        return self.tokenStore.getToken();
    },
    getRole: function (){
        return self.role;
    },
    login:  flow( function* () {
        self.inProgress = true;
        self.error = undefined;

        let username = self.email;
        let password = self.password;
        let role = self.role.toUpperCase();
        let company = self.companyId;

        const response = yield apiPost('/authenticate', { username, password, role, company }, undefined,'User Authenticatd')
        if (response.error){
            self.error = response.error;
        }else {
           self.tokenStore.setToken(response.token);
           self.username ="";
           self.password = "";
           self.userId = response.userId;
           self.role = '';
           return response.userId;
        }
    }),
    logout: function(error) {
        self.tokenStore.setToken('');
        if(error){
            self.setAuthError(error)
        }
        window.location = "/";
    }

}));


export default AuthStore;