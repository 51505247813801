import React, {useContext, useEffect, useState} from 'react';
import './App.css';

import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AppRouter from "./router";
import Home from "./pages/Home";
import Login from "./pages/Login";
import {useAuthStatus} from "./AuthHook";
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/employee/settings/ResetPassword';
import ErrorBoundary from "./components/ErrorBoundary";
import { IntlProvider } from 'react-intl';
import {createIntl, createIntlCache} from 'react-intl';

const locales = {
    'en': {selectalanguage: 'Select a language', en: 'English', es: 'Spanish'},
    'es': {selectalanguage: 'Selecione uma linguagem', en: 'Ingles', es: 'Espanol'},
  }

/**
 * Load the messages for the locales.
 * CAVEAT: This is required for code splitting to work.
 */
 const messageLoader = {
    en: () => import('./components/locale/en-US'),
    es: () => import('./components/locale/es-US'),
 }

 const formats = {
    number: {
       USD: {
          style: 'currency',
          currency: 'USD'
       }
    }
 };

 const initialLocale = 'en';

 export const cache = createIntlCache()
/** You can use this variable in other files even after reassigning it. */
export let intl = createIntl(
  {locale: initialLocale, messages: locales[initialLocale]},
  cache
)
export let fmt = intl.formatMessage

function App() {
    const isLoggedIn = useAuthStatus();
    const [messages, setMessages] = useState({});
    const [locale, setLocale] = useState(initialLocale);

    useEffect(() => {
        messageLoader.en().then((messages) => {
           setMessages(messages);
        });
        localStorage.clear();
     }, []);

    const changeLanguage = (newLocale) => {
        intl = createIntl({locale: newLocale, messages: locales[newLocale]}, cache);
        fmt = intl.formatMessage;
        document.documentElement.lang = newLocale;
        setLocale(newLocale);


        switch (newLocale) {
            case 'en':
                messageLoader.en().then((messages) => {
                    setMessages(messages);
                });
                break;
            case 'es':
                messageLoader.es().then((messages) => {
                    setMessages(messages);
                });
                break;
            default:
                messageLoader.en().then((messages) => {
                    setMessages(messages);
                });
                break;
        }
    }

    return (

       <ErrorBoundary>
            <IntlProvider messages={messages} locale={'en'} formats={formats}>
            <BrowserRouter>
                <main>
                    <Switch>
                             <Route exact path={"/"} render={(props) => <Home locale={locale} 
                                locales={locales} changeLanguage={changeLanguage} fmt={fmt}/>}/>
                             <Route path={"/login/:role"} component={Login} render={(props) => <Login fmt={fmt}/>}/>
                             <Route path="/register/:role" component={Register}/>
                             <Route path="/forgotPassword/:role" component={ForgotPassword}/>
                             <Route path="/reset" component={ResetPassword}/>
                             <Route path="/approuter/:userId/dashboard" render={(props) => <AppRouter locale={locale} 
                                locales={locales} changeLanguage={changeLanguage} fmt={fmt}/>}>
                                 {!isLoggedIn? <Redirect to={"/"}/>:""}
                             </Route>
                             {/* <Route path="/approuter/:userId/dashboard" component={AppRouter}>
                                 {!isLoggedIn? <Redirect to={"/"}/>:""}
                             </Route> */}
                             <Route path="/error" component={AppRouter}>
                                 {<Redirect to={"/?loggedout=true"}/>}
                             </Route>


                      </Switch>
                </main>
            </BrowserRouter>
            </IntlProvider>
        </ErrorBoundary>


    );

}

export default App;
//  <Route path="/login/:role" component={ AppRouter} />