import React, {useEffect, useContext, useState} from "react";
import {observer} from "mobx-react";
import StoreContext from "../../StoreContext";
import {useParams, useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import AdminUserForm from "../../forms/AdminUserForm";
import Alert from "../../components/Alert";
import DateInput from "../../components/DateInput";
import moment from "moment";
import Input from "../../components/Input";
import PrimaryButton from "../../components/PrimaryButton";
import GoBack from "../GoBack";
import AlertProvider from "../../AlertProvider";
import { apiGet } from "../../utils";

const AdminUserDetail = observer(function () {
  const {adminStore, rootStore} = useContext(StoreContext);
  const {id} = useParams();
  const history = useHistory();
  const [withdrawalDate, setWithdrawalDate] = useState(new Date);
  const [withdrawalAmount, setWithdrawalAmount] = useState('0');
  const [user, setUser] = useState(undefined);

  const loadUser = async () => {
    const response = await apiGet(`/api/v1/admin/user/get/${id}`, rootStore.authStore);
    const user = await response.json();
    setUser(user);
  }

  useEffect(() => {
    if (id && id !== 'create-user') {
      // adminStore.loadUser(id);
      loadUser(id);
    }
  },[id]);

  // const user = adminStore.users.get(id);
  if (id && (!user || rootStore.submitting) && id !== 'create-user') {
    return <Loading />;
  }

  const createUser = async(values) => {
     const user = await adminStore.createUser(values);
     if (user) {
       history.goBack();
     }
  }

  return <div>
    <GoBack/>
    <br/>
    <AlertProvider />
    <h3 className={"text-xl"}>{user ? user.full_name : 'Create User'}</h3>
    <AdminUserForm
      submitting={rootStore.submitting}
      errors={rootStore.formErrors.toJSON()}
      user={user}
      doSubmit={(values) => values.id ? adminStore.updateUserById(values.id, values) : createUser(values)}
    />
    {/* {!user && <div className={'pt-2'}>
      <h3 className={"text-xl"}>Make Withdrawal</h3>
      <DateInput defaultValue={moment().utc().format('YYYY-MM-DD')}
                 className={'mt-2'}
                 onChange={(e) => setWithdrawalDate(e.target.value)} />
      <Input
        onChange={(e) => {
          setWithdrawalAmount(e.target.value)
        }}
        value={withdrawalAmount}
        className={"my-2"}
        placeholder={'Amount'}
      />
      <PrimaryButton onClick={() => adminStore.userWithdrawal(id, {date: withdrawalDate, amount: withdrawalAmount})}>Make Withdrawal</PrimaryButton>
    </div>} */}
  </div>;
});

export default AdminUserDetail;
