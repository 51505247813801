import React, {useState, useContext} from "react";
import {useRouteMatch, useHistory} from "react-router-dom";
import Loading from "./Loading";
import PrimaryButton from "./PrimaryButton";
import StoreContext from "../StoreContext";
import moment from "moment";

export default function AdminEmployeeTable({users}) {
  const {adminStore,rootStore} = useContext(StoreContext);
  const history = useHistory();
  let { url } = useRouteMatch();
  const [loading, setLoading] = useState(false);

  if (!users || loading) {
    return <Loading/>;
  }

  function viewDashboard(user) {
    setLoading(true);
    adminStore.startImpersonatingUser(user);
    history.push(`${url}/employeeDashboard`);
  }

  return <div>
    {<table className={'w-full table-auto'}>
      <thead>
      <tr>
        <th className={"text-left text-sm text-gray-400 py-3"}>First Name</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>Last Name</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>Company</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>Email</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>DOB</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>SSN</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>KYC Status</th>
        <th className={"text-left text-sm text-gray-400 py-3"}>KYC Date</th>
      </tr>
      </thead>
      <tbody>
      {users.filter(user => (user.role === "EMPLOYEE" || user.role === "CSA") && (user.userId !== rootStore.csaTieId) ).map(user =>
      <tr key={user.id}>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.firstName}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.lastName}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.companyName}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.email}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.dateOfBirth ? moment(user.dateOfBirth).format('MM-DD-YYYY'): ''}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.ssn}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.kycStatus}</td>
        <td className={`text-left text-sm p-3  ${user.enrolledStatus === 'ENROLLED' ? 'color-orange' : ''}`}>{user.kycStatusDate ? moment(user.kycStatusDate).utc().format('L') : ''}</td>
        <td className={"text-right text-sm p-3"}>
          <PrimaryButton onClick={() => viewDashboard(user)}>View Dashboard</PrimaryButton>
        </td>
      </tr>)}
      </tbody>
    </table>}
  </div>;
}
